import * as d3 from "d3";
import { useEffect, useRef, React } from "react";
import "./BarLineChartWidget.css";

const ComboBarLineChart = (id, legendObj, svgElement, _h, _w) => {
  /**
   *  Initialize
   */
  let options = {
    bar: {
      visible: true,
      values: true,
      stacked: true,
    },
    line: {
      visible: true,
      values: true,
      secondaryAxis: true,
    },
  };
  let colors = {
    bar: [
      "#5f8b95",
      "#ba4d51",
      "#af8a53",
      "#955f71",
      "#859666",
      "#7e688c",
      "#91bdc7",
      "#ec7f83",
      "#e1bc85",
      "#c791a3",
      "#b7c898",
      "#B09ABE",
      "#2D5963",
      "#881B1F",
      "#7D5821",
      "#632D3F",
      "#536434",
      "#4C365A",
    ],
    line: [
      "#5f8b95",
      "#ba4d51",
      "#af8a53",
      "#955f71",
      "#859666",
      "#7e688c",
      "#91bdc7",
      "#ec7f83",
      "#e1bc85",
      "#c791a3",
      "#b7c898",
      "#B09ABE",
      "#2D5963",
      "#881B1F",
      "#7D5821",
      "#632D3F",
      "#536434",
      "#4C365A",
    ],
  };

  let legends = {
    bar: ["Open", "In Progress"],
    line: ["Acutal", "Target"],
  };

  colors.bar.forEach(function (d) {
    colors.bar.push(d);
    colors.line.push(d);
  });

  // d3.schemeCategory20.forEach(function (d) {
  //   colors.bar.push(d);
  //   colors.line.push(d);
  // });

  d3.schemeCategory10.forEach(function (d) {
    colors.bar.push(d);
    colors.line.push(d);
  });

  // d3.schemeCategory20c.forEach(function (d) {
  //   colors.bar.push(d);
  //   colors.line.push(d);
  // });

  // d3.schemeCategory20b.forEach(function (d) {
  //   colors.bar.push(d);
  //   colors.line.push(d);
  // });

  if (legendObj["settings"]["colorsPallet"]["bar"].length > 0) {
    colors.bar = legendObj["settings"]["colorsPallet"]["bar"];
  }

  if (legendObj["settings"]["colorsPallet"]["line"].length > 0) {
    colors.line = legendObj["settings"]["colorsPallet"]["line"];
  }

  legends = legendObj;

  //generate legend
  let getLegend = legendGenerator(colors, legends);
  // const dom = (document.getElementById(
  //   id
  // ).innerHTML = `<svg id="${id}-svg"></svg><div id="${id}-dom-legend" data-elementId="${id}" style="padding: 5px 0; margin-left:16px;margin-right:16px;margin-top:-6px;">${getLegend}</div>`);
  const dom = (document.getElementById(
    `${id}-dom-legend`
  ).innerHTML = `${getLegend}`);
  let svg = svgElement; //d3.select(`#${id}-svg`);
  let legend = document.getElementById(`${id}-dom-legend`);
  svg.selectAll("g").remove();
  let graph = svg.append("g").attr("class", "graph");
  let margin = {
    left: 10,
    right: 10,
    top: 15,
    bottom: 20,
  };

  let xAxisClass = "x-axis-line";
  let barChartGroupClass = "bar-chart-group";
  let lineChartActualPathClass = "line-chart-actual-path";
  let lineChartActualValuesClass = "line-chart-actual-values";
  let lineChartTargetPathClass = "line-chart-target-path";
  let lineChartTargetValuesClass = "line-chart-target-values";
  let namesClass = "name";
  let data = [];
  let names = [];
  let max = 0;
  let max2 = 0;
  /**
   *  Draw & Update
   */

  let draw = function draw(_data) {
    let _options =
      arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    /* Data Formatting */
    data = getStacked(_data);
    if (_options) options = _options;

    names = data.map(function (d) {
      return d.name;
    });
    max = d3.max(data, function (d) {
      let barMax = 0;
      let lineMax = 0;

      if (options.bar.stacked) {
        barMax = d.statesTotal;
      } else {
        barMax = d.statesMax;
      }

      if (!options.line.secondaryAxis) {
        lineMax = Math.max(d.maxLineVal); //Math.max(d.target, d.actual);
      }

      return Math.max(barMax, lineMax);
    });
    max2 = d3.max(data, function (d) {
      return d.maxLineVal;
      //return Math.max(d.target, d.actual);
    });

    if (
      options.line.secondaryAxis &&
      options.line.visible &&
      options.bar.visible
    ) {
      max2 = max2 + parseInt(Math.abs(options.line.secondaryAxisMinValue));
    }

    /* Sizing */

    let fullWidth = _w; //document.getElementById(id).clientWidth;
    let fullHeight = _h - (legend.clientHeight + 5);
    // document.getElementById(id).clientHeight - (legend.clientHeight + 5);

    svg.attr("width", 0);
    svg.attr("height", 0);

    // New dimension
    let _width = _w; //document.getElementById(id).clientWidth;
    let _height = _h + 6 - (legend.clientHeight + 5);
    //document.getElementById(id).clientHeight + 6 - (legend.clientHeight + 5);

    let graphWidth = fullWidth - margin.left - margin.right;
    let graphHeight = fullHeight - margin.top - margin.bottom;

    // Set SVG
    svg.attr("width", _width);
    svg.attr("height", _height);

    //add attr data for resizing
    svg.attr("max", max);
    svg.attr("max2", max2);
    svg.attr("names", JSON.stringify(names));
    svg.attr("data", JSON.stringify(data));
    svg.attr("options", JSON.stringify(options));
    svg.attr("legends", JSON.stringify(legends));

    graph.attr(
      "transform",
      "translate(".concat(margin.left, ", ").concat(margin.top, ")")
    );
    /* Scaling */

    let customHeight = graphHeight;
    let customLineHeight = graphHeight;

    if (
      options.line.secondaryAxis &&
      options.line.secondaryAxisMinValue &&
      options.line.secondaryAxisMinValue < 0 &&
      options.line.visible &&
      options.bar.visible
    ) {
      let getBarHeight = 0;
      let getLineHeight = 0;
      if (max2 > max) {
        let getMaxHeight = max + max2;
        let getDiffOfLineAndBar = max2 - max;
        let calcPerBarYAxis = (max / getMaxHeight) * 100;
        getBarHeight = (calcPerBarYAxis / 100) * graphHeight;
        getLineHeight = ((100 - calcPerBarYAxis) / 100) * graphHeight;

        customHeight = getBarHeight;
        customLineHeight = getLineHeight;
      } else {
        customHeight = graphHeight; ///2;
        customLineHeight = graphHeight; ///2;
      }
    }

    let xScale = d3
      .scaleBand()
      .rangeRound([0, graphWidth])
      .domain(names)
      .paddingInner(0.2)
      .paddingOuter(0.1);
    let yScale = d3
      .scaleLinear()
      .rangeRound([customHeight, 20])
      .domain([0, max]);
    let yScale2 = d3
      .scaleLinear()
      .rangeRound([customLineHeight, 20])
      .domain([0, max2]);
    /* Clean Old */

    clean();
    /* Axis */

    graph
      .append("line")
      .attr("class", xAxisClass)
      .attr("x1", 0)
      .attr("y1", graphHeight)
      .attr("x2", graphWidth)
      .attr("y2", graphHeight)
      .style("stroke", "#C6C6C6")
      .style("stroke-width", "2px");

    /* Names */
    let nameGroup = graph
      .append("g")
      .attr("class", namesClass)
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d) {
        return "translate("
          .concat(xScale(d.name) + xScale.bandwidth() / 2, ", ")
          .concat(graphHeight + 10, ")");
      })
      .append("text")
      .attr("x", 0)
      .attr("y", 0)
      .attr("dy", ".33em")
      .style("text-anchor", "middle")
      .style("fill", "rgb(118, 118, 118)")
      .style("font-weight", "400")
      .style("font-size", "12px")
      .style(
        "font-family",
        '"Segoe UI", "Helvetica Neue", "Trebuchet MS", Verdana'
      )
      .text(function (d) {
        return d.name;
      });

    nameGroup.text(function (d) {
      let width = d3.select(this).node().getBBox().width;
      d.width = width;

      if (width > xScale.bandwidth() * 1.2) {
        return getAbbrName(d.name);
      }

      return d.name;
    });

    /* Bar Chart */

    if (options.bar.visible) {
      let groups = graph
        .append("g")
        .attr("class", barChartGroupClass)
        .attr("transform", function (d) {
          if (
            options.line.secondaryAxis &&
            options.line.secondaryAxisMinValue < 0 &&
            options.line.visible &&
            options.bar.visible
          ) {
            if (max2 > max) {
              return "translate(0,".concat(customLineHeight).concat(")");
            } else {
              return "translate(0,0)";
            }
          } else {
            return "translate(0,0)";
          }
        })
        .selectAll("g")
        .data(data)
        .enter()
        .append("g")
        .attr("transform", function (d) {
          return "translate(".concat(xScale(d.name), ", 0)");
        });

      if (options.bar.stacked) {
        /* Stacked */
        groups
          .selectAll("rect")
          .data(function (d) {
            return d.states;
          })
          .enter()
          .append("rect")
          .attr("x", 0)
          .attr("y", function (state) {
            return yScale(state.stackEnd);
          })
          .attr("width", xScale.bandwidth())
          .attr("height", function (state) {
            if (state.value > 0) {
              //return graphHeight - yScale(state.value) + 1;
              return customHeight - yScale(state.value) + 1;
            } else {
              return 0;
            }
          })
          .attr("fill", function (state) {
            if (options.bar.legendGenerated) {
              let indexOfItem = legendObj.bar.findIndex(function (_dt) {
                return state.label == _dt;
              });
              //return colors.bar[state.index];
              return colors.bar[indexOfItem];
            } else {
              return colors.bar[state.index];
            }
          });

        if (options.bar.values) {
          groups
            .selectAll("text")
            .data(function (d) {
              return d.states;
            })
            .enter()
            .append("text")
            .attr("x", xScale.bandwidth() / 2)
            .attr("y", function (state) {
              return (yScale(state.stackStart) + yScale(state.stackEnd)) / 2;
            })
            .attr("dy", ".33em")
            .style("text-anchor", "middle")
            .style("fill", "white")
            .style("font-weight", "400")
            .style("font-size", "12px")
            .style("fill", "white")
            .style(
              "font-family",
              '"Segoe UI", "Helvetica Neue", "Trebuchet MS", Verdana'
            )
            .text(function (d) {
              if (d.value > 0) return d.value;
              else return "";
            });
        }
      } else {
        /* Side-By-Side */
        groups
          .selectAll("rect")
          .data(function (d) {
            return d.states;
          })
          .enter()
          .append("rect")
          .attr("x", function (state) {
            return (state.index * xScale.bandwidth()) / state.length;
          })
          .attr("y", function (state) {
            return yScale(state.value);
          })
          .attr("width", function (state) {
            return xScale.bandwidth() / state.length;
          })
          .attr("height", function (state) {
            return customHeight - yScale(state.value) + 1;
          })
          .attr("fill", function (d, i) {
            if (options.bar.legendGenerated) {
              let indexOfItem = legendObj.bar.findIndex(function (_dt) {
                return d.label == _dt;
              });
              return colors.bar[indexOfItem];
            } else {
              return colors.bar[i];
            }
          });

        if (options.bar.values) {
          groups
            .selectAll("text")
            .data(function (d) {
              return d.states;
            })
            .enter()
            .append("text")
            .attr("x", function (state) {
              return ((state.index + 0.5) * xScale.bandwidth()) / state.length;
            })
            .attr("y", function (state) {
              if (state.value > 0) {
                return yScale(state.value / 2);
              } else {
                return yScale(0.5 / 2);
              }
            })
            .attr("dy", ".33em")
            .style("text-anchor", "middle")
            .style("fill", "white")
            .style("font-weight", "400")
            .style("font-size", "12px")
            .style("fill", "white")
            .style(
              "font-family",
              '"Segoe UI", "Helvetica Neue", "Trebuchet MS", Verdana'
            )
            .text(function (d) {
              if (d.value > 0) {
                return d.value;
              } else {
                return "";
              }
            });
        }
      }
    }
    /* Line Chart */

    if (options.line.visible) {
      let yScaleMain = yScale;

      if (options.line.secondaryAxis) {
        yScaleMain = yScale2;
      }

      for (let i = 0; i < legends.line.length; i++) {
        let actualLine = d3
          .line()
          .x(function (d) {
            return xScale(d.name) + xScale.bandwidth() / 2;
          })
          .y(function (d) {
            if (
              options.line.secondaryAxis &&
              d.line[i].value > 0 &&
              options.line.visible &&
              options.bar.visible
            ) {
              let addMinVal =
                d.line[i].value + Math.abs(options.line.secondaryAxisMinValue);
              return yScaleMain(addMinVal);
            } else {
              return yScaleMain(d.line[i].value);
            }
          });

        let lineClass = lineChartActualPathClass.concat("_".concat(i)); //+ "_" + i;
        let lineValueClass = lineChartActualValuesClass.concat("_".concat(i)); //+ "_" + i;
        /* Actual */
        graph
          .append("path")
          .data([data])
          .attr("class", lineClass)
          .attr("d", actualLine)
          .attr("stroke", colors.line[i])
          .attr("stroke-width", 6)
          .attr("fill", "none");

        if (options.line.values) {
          let actualValues = graph
            .append("g")
            .attr("class", lineValueClass)
            .selectAll("g")
            .data(data)
            .enter()
            .append("g")
            .attr("transform", function (d) {
              if (
                options.line.secondaryAxis &&
                d.line[i].value > 0 &&
                options.line.visible &&
                options.bar.visible
              ) {
                let addMinVal =
                  d.line[i].value +
                  Math.abs(options.line.secondaryAxisMinValue);
                return "translate("
                  .concat(xScale(d.name) + xScale.bandwidth() / 2, ", ")
                  .concat(yScaleMain(addMinVal), ")");
              } else {
                return "translate("
                  .concat(xScale(d.name) + xScale.bandwidth() / 2, ", ")
                  .concat(yScaleMain(d.line[i].value), ")");
              }
            });
          actualValues
            .append("circle")
            .attr("cx", 0)
            .attr("cy", 0)
            .attr("r", function (d) {
              return 25;
            })
            .attr("fill", colors.line[i]);
          actualValues
            .append("text")
            .attr("dy", ".33em")
            .style("text-anchor", "middle")
            .style("font-weight", "400")
            .style("font-size", "12px")
            .style("fill", "white")
            .style(
              "font-family",
              '"Segoe UI", "Helvetica Neue", "Trebuchet MS", Verdana'
            )
            .text(function (d) {
              return kFormatter(d.line[i].value);
            });
        }
      }
    }
  };

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }

  const clean = () => {
    graph.select("line").remove();
    graph.selectAll("g").remove();
    graph.selectAll("path").remove();
  };

  // d3.select(window).on('resize.barLineChart', function () {
  //     refresh();

  // });
  /**
   *  Helpers
   */

  let __assign =
    (this && this.__assign) ||
    function () {
      __assign =
        Object.assign ||
        function (t) {
          for (let s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (let p in s)
              if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
          }
          return t;
        };
      return __assign.apply(this, arguments);
    };

  function getStacked(data) {
    return data.map(function (d) {
      let states = d.states.map(function (state, i) {
        let stackEnd = d.states
          .map(function (state) {
            return state.value;
          })
          .slice(0, i + 1)
          .reduce(function (state, sum) {
            return state + sum;
          });
        return __assign(__assign({}, state), {
          stackEnd: stackEnd,
          stackStart: stackEnd - state.value,
          length: d.states.length,
          index: i,
        });
      });
      let statesTotal = states
        .map(function (d) {
          return d.value;
        })
        .reduce(function (state, sum) {
          return state + sum;
        });
      let statesMax = d3.max(states, function (state) {
        return state.value;
      });
      return __assign(__assign({}, d), {
        states: states,
        statesMax: statesMax,
        statesTotal: statesTotal,
      });
    });
  }

  function getAbbrName(name) {
    let heads = name.split(" ").map(function (n, index) {
      return n[0];
    });

    return heads.reduce(function (head, abbr, index) {
      return head + abbr;
    });
  }

  function legendGenerator(_colors, legends) {
    let colorsObj = {
      bar: [
        "#5F8b95",
        "#BA4D51",
        "#AF8A53",
        "#955F71",
        "#859666",
        "#7E688C",
        "#91BDC7",
        "#EC7F83",
        "#E1BC85",
        "#C791A3",
      ],
      line: ["#5F9CD3", "#EA7D3C"],
    };

    colorsObj = _colors;
    let getLineLongName = findLongestWord(legends.line);
    let getBarLongName = findLongestWord(legends.bar);

    let barLegendDOM = "";
    let lineLegendDOM = "";

    for (let i = 0; i < legends.bar.length; i++) {
      let color = colorsObj.bar[i];
      let boxDOM =
        '<div style="display:inline-block;width:12px;height:12px;background:'.concat(
          color,
          '"></div>'
        );
      let labelDOM =
        "<div style=\"display:inline-block;margin-left:4px;color:#767676;font-family: 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;font-weight: 400;font-size: 12px;\">".concat(
          legends.bar[i],
          "</div>"
        );
      barLegendDOM += "<div class='legendBoxContent'>"
        .concat(boxDOM)
        .concat(labelDOM, "</div>");
    }

    if (legends.settings.bar.visible && !legends.settings.line.visible) {
      barLegendDOM =
        "<div class='barLegendBox' style=\"flex-basis: 100% !important;justify-content: flex-start;\">".concat(
          barLegendDOM,
          "</div>"
        );
    } else {
      barLegendDOM = "<div class='barLegendBox'>".concat(
        barLegendDOM,
        "</div>"
      );
    }

    if (legends.line.length > 0 && legends.line[0] !== "others_hide") {
      for (let j = 0; j < legends.line.length; j++) {
        let _color = colorsObj.line[j];

        let _boxDOM =
          '<div style="display:inline-block;width:12px;height:12px;border-radius:6px;background:'.concat(
            _color,
            '"></div>'
          );
        let _labelDOM =
          "<div style=\"display:inline-block;margin-left:4px;color:#767676;font-family: 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;font-weight: 400;font-size: 12px;\">".concat(
            legends.line[j],
            "</div>"
          );
        lineLegendDOM +=
          '<div style="display:flex;align-items:center;margin-right:10px;padding-bottom:8px;">'
            .concat(_boxDOM)
            .concat(_labelDOM, "</div>");
      }
    }

    if (!legends.settings.bar.visible && legends.settings.line.visible) {
      lineLegendDOM =
        '<div style="display:flex;flex-wrap: wrap;flex-basis:100%;justify-content:flex-start;">'.concat(
          lineLegendDOM,
          "</div>"
        );
    } else {
      lineLegendDOM =
        '<div style="display:flex;flex-wrap: wrap;flex-basis:50%;justify-content:flex-end;">'.concat(
          lineLegendDOM,
          "</div>"
        );
    }

    let legendHtml =
      '<div style="display:flex;flex-wrap: wrap;box-sizing: border-box;">'
        .concat(barLegendDOM)
        .concat(lineLegendDOM, "</div>");

    return legendHtml;
  }

  function findLongestWord(array) {
    var longestWord = "";

    array.forEach(function (word) {
      if (word.length > longestWord.length) {
        longestWord = word;
      }
    });

    return longestWord;
  }

  return {
    draw: draw,
    //refresh: refresh,
  };
};

const BarLinechartD3Component = (props) => {
  const d3Container = useRef(null);
  useEffect(() => {
    props.getWidgetSize();
    if (props.data !== undefined && props.data.length > 0) {
      const svg = d3.select(d3Container.current);
      svg.selectAll("*").remove();
      const w = props.width;
      const h = props.height;
      const chart = ComboBarLineChart(
        props.widgetId,
        props.chartSettings.legends,
        svg,
        h,
        w
      );
      chart.draw(props.data, props.chartSettings.options);
    }
  }, [props.data, props.width, props.height, props.wid]);

  return (
    <div id={`barline${props.widgetId}`} className="barlinechartWidget">
      <svg
        id={`${props.widgetId}-svg`}
        ref={d3Container}
        width={props.width}
        height={props.height}
      ></svg>
      {/* <div
        id={`${props.widgetId}-dom-legend`}
        style={{ textAlign: "center", padding: "5px 0" }}
      ></div> */}

      <div
        id={`${props.widgetId}-dom-legend`}
        data-elementid={props.widgetId}
        style={{
          padding: "5px 0",
          marginLeft: "16px",
          marginRight: "16px",
          marginTop: "-2px",
        }}
      ></div>
    </div>
  );
};

export default BarLinechartD3Component;
