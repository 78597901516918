const GetQueueSizeDt = (gridData, settings) => {
  let needleVal = 0;
  let actualVal = 0;
  let optimalVal = 0;

  if (gridData[0] != undefined) {
    actualVal = gridData[0][settings.actualvalue];
    optimalVal = gridData[0][settings.optimalvalue];
  }
  let actual = parseInt(actualVal);
  let optimal = parseInt(optimalVal);

  if (isNaN(actual) || actual == undefined) {
    actual = 0;
  }

  if (isNaN(optimal) || optimal == undefined) {
    optimal = 0;
  }

  if (actual > 0 && optimal > 0) {
    needleVal = Math.round((actual / optimal) * 100);
  }

  if (actual == 0 && optimal == 0) {
    needleVal = 100;
  }

  return {
    needleVal: needleVal,
    actual: actual,
    //color: colorVal,
    optimal: optimal,
  };
};

export default GetQueueSizeDt;
