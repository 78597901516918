import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, React } from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import gearImg from "../../assets/gear2.png";
import { layoutActions } from "./../../store/layout-slice";
import classes from "./Widget.module.css";
import EmptyWidget from "./EmptyWidget";
import { SwapWidgetOrders } from "../../Helper/Services";
import TwitterWidget from "./Content/Twitter/TwitterWidget";
import TableWidget from "./Content/TableWidget/TableWidget";
import {
  ConvertToLocalDateTimeWithFormat,
  GetRefreshDataFormat,
} from "../../Helper/WidgetDataCommon";
import OtccWidget from "./Content/OtccWidget/OtccWidget";
import PieChartWidget from "./Content/PieChartWidget/PieChartWidget";
import TextWidget from "./Content/TextNumericWidget/TextNumericWidget";
import QueueSizeGaugeWidget from "./Content/QueueSizeGuageWidget/QueueSizeGaugeWidget";
import BarChartWidget from "./Content/BarChartWidget/BarChartWidget";
import VideoFeedWidget from "./Content/VideoFeedWidget/VideoFeedWidget";
import SalesActivityWidget from "./Content/SAW/SalesActivityWidget";
import BarLineChart from "./Content/BarLineChartWidget/BarLineChartWidget";
import OpenOpportunitySummaryWidget from "./Content/OOS/OpenOpportunitySummaryWidget";

const Widget = (props) => {
  const dispatch = useDispatch();
  const IsOtherUserPage = useSelector((state) => state.layout.IsOtherUserPage);
  const ballistixUserId = useSelector((state) => state.layout.BallistixUserId);
  const getTheme = useSelector((state) => state.layout.theme);
  const [notificationMsg, setNotificationMsg] = useState("");
  const [isFailure, setIsFailure] = useState(false);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  useEffect(() => {
    (function ($) {
      // Detect touch support
      $.support.touch = "ontouchend" in document;
      // Ignore browsers without touch support
      if (!$.support.touch) {
        return;
      }
      var mouseProto = $.ui.mouse.prototype,
        _mouseInit = mouseProto._mouseInit,
        _mouseDestroy = mouseProto._mouseDestroy,
        touchHandled;
      /**
       * Simulate a mouse event based on a corresponding touch event
       * @param {Object} event A touch event
       * @param {String} simulatedType The corresponding mouse event
       */
      function simulateMouseEvent(event, simulatedType) {
        // Ignore multi-touch events
        if (event.originalEvent.touches.length > 1) {
          return;
        }
        event.preventDefault();
        var touch = event.originalEvent.changedTouches[0],
          simulatedEvent = document.createEvent("MouseEvents");
        // Initialize the simulated mouse event using the touch event's coordinates
        simulatedEvent.initMouseEvent(
          simulatedType, // type
          true, // bubbles
          true, // cancelable
          window, // view
          1, // detail
          touch.screenX, // screenX
          touch.screenY, // screenY
          touch.clientX, // clientX
          touch.clientY, // clientY
          false, // ctrlKey
          false, // altKey
          false, // shiftKey
          false, // metaKey
          0, // button
          null // relatedTarget
        );
        // Dispatch the simulated event to the target element
        event.target.dispatchEvent(simulatedEvent);
      }
      /**
       * Handle the jQuery UI widget's touchstart events
       * @param {Object} event The widget element's touchstart event
       */
      mouseProto._touchStart = function (event) {
        var self = this;
        // Ignore the event if another widget is already being handled
        if (
          touchHandled ||
          !self._mouseCapture(event.originalEvent.changedTouches[0])
        ) {
          return;
        }
        // Set the flag to prevent other widgets from inheriting the touch event
        touchHandled = true;
        // Track movement to determine if interaction was a click
        self._touchMoved = false;
        // Simulate the mouseover event
        simulateMouseEvent(event, "mouseover");
        // Simulate the mousemove event
        simulateMouseEvent(event, "mousemove");
        // Simulate the mousedown event
        simulateMouseEvent(event, "mousedown");
      };
      /**
       * Handle the jQuery UI widget's touchmove events
       * @param {Object} event The document's touchmove event
       */
      mouseProto._touchMove = function (event) {
        // Ignore event if not handled
        if (!touchHandled) {
          return;
        }
        // Interaction was not a click
        this._touchMoved = true;
        // Simulate the mousemove event
        simulateMouseEvent(event, "mousemove");
      };
      /**
       * Handle the jQuery UI widget's touchend events
       * @param {Object} event The document's touchend event
       */
      mouseProto._touchEnd = function (event) {
        // Ignore event if not handled
        if (!touchHandled) {
          return;
        }
        // Simulate the mouseup event
        simulateMouseEvent(event, "mouseup");
        // Simulate the mouseout event
        simulateMouseEvent(event, "mouseout");
        // If the touch interaction did not move, it should trigger a click
        if (!this._touchMoved) {
          // Simulate the click event
          simulateMouseEvent(event, "click");
        }
        // Unset the flag to allow other widgets to inherit the touch event
        touchHandled = false;
      };
      /**
       * A duck punch of the $.ui.mouse _mouseInit method to support touch events.
       * This method extends the widget with bound touch event handlers that
       * translate touch events to mouse events and pass them to the widget's
       * original mouse event handling methods.
       */
      mouseProto._mouseInit = function () {
        var self = this;
        // Delegate the touch handlers to the widget's element
        self.element.bind({
          touchstart: $.proxy(self, "_touchStart"),
          touchmove: $.proxy(self, "_touchMove"),
          touchend: $.proxy(self, "_touchEnd"),
        });
        // Call the original $.ui.mouse init method
        _mouseInit.call(self);
      };
      /**
       * Remove the touch event handlers
       */
      mouseProto._mouseDestroy = function () {
        var self = this;
        // Delegate the touch handlers to the widget's element
        self.element.unbind({
          touchstart: $.proxy(self, "_touchStart"),
          touchmove: $.proxy(self, "_touchMove"),
          touchend: $.proxy(self, "_touchEnd"),
        });
        // Call the original $.ui.mouse destroy method
        _mouseDestroy.call(self);
      };
    })($);

    $(".dragableContainer").draggable({
      handle: ".header",
      cursor: "move",
      revertDuration: 0,
      zIndex: 99,
      disabled: IsOtherUserPage,
      revert: function (event) {
        return true;
      },
      start: onDrag,
    });
    $(".dropableContainer").droppable({
      disabled: IsOtherUserPage,
      accept: onAccept,
      drop: onDrop,
      tolerance: "pointer",
    });
  }, [props.wid]);

  const onEditHandler = () => {
    dispatch(layoutActions.setSelectedWidgetId(props.widgetId));
    dispatch(layoutActions.setSelectedWidgetTypeId(props.widgetTypeId));
    dispatch(layoutActions.setSelectedWidgetType(props.widgetType));
    dispatch(layoutActions.setEditWidgetPopup());
  };

  const onDrag = (event, ui) => {
    dispatch(
      layoutActions.setDragItemOrder(event.target.getAttribute("sortOrderId"))
    );
  };

  const onDrop = (event, ui) => {
    event.preventDefault();
    $(event.target).find(".header").removeClass("header-drag-hover");
    $(event.target)
      .find(".dragableContainer")
      .removeClass("content-drag-hover");
    const dropWidgetType = event.target.getAttribute("widgettype");
    if (dropWidgetType === "Empty") return false;

    const dropWidgetSortOrder = event.target.getAttribute("wid");
    dispatch(
      layoutActions.swapWidget({
        dropWidgetSortOrder: parseInt(dropWidgetSortOrder),
      })
    );

    if (ballistixUserId !== 0) {
      SwapWidgetOrders(dropWidgetSortOrder, props.pageId)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onAccept = (event, ui) => {
    return !($(event).parent().attr("wid") === props.wid);
  };

  const onGetNotification = (data) => {
    if (data) {
      const getRefreshData = GetRefreshDataFormat(data);
      if (getRefreshData.length > 0) {
        var refreshDate = getRefreshData[0]["lastRefreshTime"];
        refreshDate = ConvertToLocalDateTimeWithFormat(
          refreshDate,
          "MMM DD, h:mm A"
        );

        var result = getRefreshData[0]["status"]; //((griddata1["Last Refreshed"])[0]).lastRefreshResult;
        if (result === "Failure") {
          setIsFailure(true);
          setNotificationMsg(
            "Last update: " + refreshDate + " (the last update failed)"
          );
        } else if (result === "Warning") {
          setIsFailure(false);
          setNotificationMsg(
            "Last update: " + refreshDate + " (" + result + ")"
          );
        } else if (result === "Success") {
          setIsFailure(false);
          setNotificationMsg("Last update: " + refreshDate);
        } else if (result) {
          setIsFailure(false);
          setNotificationMsg("Last update: " + refreshDate);
        } else {
          setIsFailure(true);
          setNotificationMsg(
            "Last update: " + refreshDate + " (the last update failed)"
          );
        }
      }
    }
  };

  const onMouseOverHandler = () => {
    setIsNotificationVisible(true);
  };

  const onMouseLeaveHandler = () => {
    setIsNotificationVisible(false);
  };

  return (
    <div
      className={`${classes["dashBoxPosition"]} dropableContainer`}
      style={props.styleCss}
      wid={props.wid}
      widgettype={props.widgetType}
    >
      <div
        id={"widget_" + props.widgetId}
        className={`${
          getTheme !== "Light"
            ? classes["widgetContainer-dark"]
            : classes["widgetContainer"]
        } dragableContainer`}
        sortorderid={props.wid}
      >
        {props.widgetType !== "Empty" && (
          <div
            className={`${
              getTheme !== "Light" ? classes["header-dark"] : classes["header"]
            } header`}
          >
            <span
              className={classes["title"]}
              onMouseOver={onMouseOverHandler}
              onMouseLeave={onMouseLeaveHandler}
            >
              {props.title}
              {isFailure && (
                <i
                  style={{
                    position: "relative",
                    left: "5px",
                    cursor: "pointer",
                    color: "orange",
                  }}
                  className="fa fa-exclamation-triangle"
                ></i>
              )}

              {isNotificationVisible && (
                <span style={{ fontWeight: "normal", fontSize: "13px" }}>
                  &nbsp; {notificationMsg}
                </span>
              )}
            </span>
            {/* <span class="shortTitle" ng-show="shortTitleVisible">
              {props.shortTitle}
            </span> */}
            {!IsOtherUserPage && (
              <div className={classes["gear"]} onClick={onEditHandler}>
                <img src={gearImg} title="Settings" alt="" />
              </div>
            )}
          </div>
        )}
        <div className={classes["content"]} id={`content_${props.widgetId}`}>
          {props.widgetType === "Empty" && (
            <EmptyWidget widgetId={props.widgetId} />
          )}
          {props.widgetTypeId === 2 && (
            <TwitterWidget widgetId={props.widgetId} title={props.title} />
          )}
          {props.widgetTypeId === 3 && (
            <TableWidget
              widgetId={props.widgetId}
              title={props.title}
              wid={props.wid}
              onGetNotification={onGetNotification}
              RefreshVersion={props.RefreshVersion}
            />
          )}
          {props.widgetTypeId === 4 && (
            <OtccWidget
              widgetId={props.widgetId}
              title={props.title}
              wid={props.wid}
              onGetNotification={onGetNotification}
              RefreshVersion={props.RefreshVersion}
            />
          )}
          {props.widgetTypeId === 5 && (
            <PieChartWidget
              widgetId={props.widgetId}
              title={props.title}
              wid={props.wid}
              onGetNotification={onGetNotification}
              RefreshVersion={props.RefreshVersion}
            />
          )}
          {props.widgetTypeId === 7 && (
            <TextWidget
              widgetId={props.widgetId}
              title={props.title}
              wid={props.wid}
              onGetNotification={onGetNotification}
              RefreshVersion={props.RefreshVersion}
            />
          )}
          {props.widgetTypeId === 8 && (
            <QueueSizeGaugeWidget
              widgetId={props.widgetId}
              title={props.title}
              wid={props.wid}
              onGetNotification={onGetNotification}
              RefreshVersion={props.RefreshVersion}
            />
          )}
          {props.widgetTypeId === 9 && (
            <BarChartWidget
              widgetId={props.widgetId}
              title={props.title}
              wid={props.wid}
              onGetNotification={onGetNotification}
              RefreshVersion={props.RefreshVersion}
            />
          )}
          {props.widgetTypeId === 10 && (
            <VideoFeedWidget
              widgetId={props.widgetId}
              title={props.title}
              wid={props.wid}
              parentStyle={props.styleCss}
              RefreshVersion={props.RefreshVersion}
            />
          )}
          {props.widgetTypeId === 11 && (
            <SalesActivityWidget
              widgetId={props.widgetId}
              title={props.title}
              wid={props.wid}
              onGetNotification={onGetNotification}
              RefreshVersion={props.RefreshVersion}
            />
          )}
          {props.widgetTypeId === 12 && (
            <BarLineChart
              widgetId={props.widgetId}
              title={props.title}
              onGetNotification={onGetNotification}
              RefreshVersion={props.RefreshVersion}
              wid={props.wid}
            />
          )}
          {props.widgetTypeId === 13 && (
            <OpenOpportunitySummaryWidget
              widgetId={props.widgetId}
              title={props.title}
              onGetNotification={onGetNotification}
              RefreshVersion={props.RefreshVersion}
              wid={props.wid}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Widget;
