const GetColorPalletItems = (salesPersonColorPallet, salesPersons) => {
  let colorsPallet = [];
  const salesPersonList = [...salesPersons].sort();
  if (salesPersonColorPallet != null && salesPersonColorPallet.length > 0) {
    colorsPallet = salesPersonList.map((item) => {
      let getColor = salesPersonColorPallet.find((cls) => {
        return cls.Value === item;
      });
      if (getColor != null) {
        return getColor.Colour;
      }
    });
  }

  return colorsPallet;
};

export const GetColorPalletList = GetColorPalletItems;
