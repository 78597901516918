import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { pageActions } from "../../../store/page-slice";
import TopBar from "./TopBar/TopBar";
import classes from "./Header.module.css";
import logo from "../../../assets/nsyteful-logo.png";
import UserInfo from "./UserInfo/UserInfo";
import { layoutActions } from "../../../store/layout-slice";
import { Update_Layout_Theme } from "../../../Constants";
import RotationMenuOption from "./Rotation/RotationMenu";

const Header = (props) => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const isPageLoaded = useSelector((state) => state.login.isPageLoaded);
  const getTheme = useSelector((state) => state.layout.theme);
  const userId = useSelector((state) => state.layout.UserId);
  const [isFullViewMode, setIsFullViewMode] = useState(false);
  const [isHideHeader, setIsHideHeader] = useState(false);
  let headerCss = "page-header";
  let toolbarCss = "toolbar";

  const onLoadPages = () => {
    if (isUserLoggedIn) {
      dispatch(pageActions.triggerPageList());
    }
  };

  const themeChangeHandler = async () => {
    let theme = getTheme;
    if (getTheme === "Light") {
      document.body.classList.add("dark");
      theme = "Dark";
    } else {
      document.body.classList.remove("dark");
      theme = "Light";
    }
    dispatch(layoutActions.updateTheme(theme));
    const updateTheme = await fetch(
      `${Update_Layout_Theme}?layoutId=${userId}&theme=${theme}`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }
    );

    if (updateTheme.ok) {
      //const response = await updateTheme.json();
      //console.log("response", response);
    } else {
      console.log("error");
      //if (!updateTheme.ok);
    }
  };

  if (getTheme !== "Light") {
    headerCss = "page-header-dark";
    toolbarCss = "toolbar-dark";
    document.body.classList.add("dark");
  } else {
    document.body.classList.remove("dark");
  }

  const fullViewScreenHandlerEnabled = () => {
    if (!isFullViewMode) {
      document.documentElement.requestFullscreen();
      setIsHideHeader(true);
      dispatch(layoutActions.setHeaderDisplay(true));
    } else {
      document.exitFullscreen();
      setIsHideHeader(false);
      dispatch(layoutActions.setHeaderDisplay(false));
    }
    setIsFullViewMode(!isFullViewMode);
  };

  const hideHeaderHandler = (val) => {
    setIsHideHeader(val);
    dispatch(layoutActions.setHeaderDisplay(val));
  };

  return (
    <div>
      <TopBar
        hideHeaderHandler={hideHeaderHandler}
        isHeaderDisplayed={isHideHeader}
      />
      <div
        className={classes[headerCss]}
        style={{ display: isHideHeader ? "none" : "" }}
      >
        <div
          style={{
            position: "absolute",
            width: "calc(100% - 24px)",
            left: "12px",
            top: "12px",
          }}
        >
          <div className={classes["logo"]}>
            <img src={logo} />
          </div>
          <div className={classes[toolbarCss]}>
            <div
              className={`${
                getTheme !== "Light" ? classes["button-dark"] : ""
              } loadPages dx-button dx-button-normal dx-widget dx-button-has-icon`}
              title="Pages"
              style={{ marginRight: "4px" }}
              onClick={onLoadPages}
            >
              <div className="dx-button-content" style={{ fontSize: "0px" }}>
                <i
                  className={`dx-icon ${
                    getTheme !== "Light"
                      ? classes["dx-icon-PageIcon-dark"]
                      : classes["dx-icon-PageIcon"]
                  } `}
                ></i>
              </div>
            </div>
            <RotationMenuOption getTheme={getTheme} />
            <div
              className={`${
                getTheme !== "Light" ? classes["button-dark"] : ""
              } changetheme theme-ootc dx-button dx-button-normal dx-widget dx-button-has-icon`}
              title="Themes"
              onClick={themeChangeHandler}
              style={{ marginRight: "4px" }}
            >
              <div className="dx-button-content" style={{ fontSize: "0px" }}>
                <i
                  className={`dx-icon ${
                    getTheme !== "Light"
                      ? classes["icon-themeIcon-dark"]
                      : classes["icon-themeIcon"]
                  }`}
                ></i>
              </div>
            </div>

            <div
              className={`${
                getTheme !== "Light" ? classes["button-dark"] : ""
              } enterfullscreen dx-button dx-button-normal dx-widget dx-button-has-icon`}
              ng-show="IsFullScreen"
              title="Enter full-screen mode"
              onClick={fullViewScreenHandlerEnabled}
            >
              <div className="dx-button-content" style={{ fontSize: "0px" }}>
                <i
                  className={`dx-icon ${
                    getTheme !== "Light"
                      ? classes["icon-fullscreenIcon-dark"]
                      : !isFullViewMode
                      ? classes["icon-fullscreenIcon"]
                      : classes["icon-exitFullscreenIcon"]
                  }`}
                ></i>
              </div>
            </div>
          </div>
          {isPageLoaded && <UserInfo onShowLoginHandler={props.onShowLogin} />}
        </div>
      </div>
    </div>
  );
};

export default Header;
