import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styles from "./FormatColumn.module.css";

const ColumnList = React.memo(({ items, doubleClickEvent }) => {
  return items.map((item, index) => (
    <ColumnItem
      item={item}
      index={index}
      key={index}
      onDoubleClick={doubleClickEvent}
    />
  ));
});

const ColumnItem = ({ item, index, onDoubleClick }) => {
  return (
    <Draggable
      key={item.dataField}
      draggableId={"draggable-" + index}
      index={index}
    >
      {(provided, snapshot) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          id={item.dataField}
          key={index}
          onDoubleClick={(ele) => onDoubleClick(ele, index)}
          style={{
            ...provided.draggableProps.style,
            opacity: item.visible === false ? "0.5" : "1",
          }}
        >
          <span className={styles.myHandle} {...provided.dragHandleProps}>
            <i aria-hidden="true" className="fa-lg fa fa-bars"></i>
          </span>
          {item.caption}
        </li>
      )}
    </Draggable>
  );
};

const FormatColumns = (props) => {
  const [itemList, setItemList] = useState([...props.dtList]);
  const [intialLoaded, setIntialLoaded] = useState(0);

  useEffect(() => {
    setItemList(props.dtList);
    setIntialLoaded(intialLoaded + 1);
  }, [props.dtList]);

  const onDragEnd = (param) => {
    const srcI = param.source.index;
    const desI = param.destination?.index;
    if (!param.destination) {
      return;
    }

    const getOrderColumns = reorder(
      itemList,
      param.source.index,
      param.destination.index
    );

    const finalItem = getOrderColumns.map((item, index) => {
      return { ...item, index: index };
    });
    setItemList([...finalItem]);

    props.onFinalResult(finalItem);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDoubleClick = (ele, index) => {
    let getItem = itemList[index];
    getItem.visible = !getItem.visible;
    let UpdatedItems = itemList;
    UpdatedItems[index] = getItem;
    setItemList(itemList);
    setIntialLoaded(intialLoaded + 1);
    UpdateFinalResult();
  };

  const UpdateFinalResult = () => {
    props.onFinalResult(itemList);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-1" type="PERSON">
        {(provided, _) => (
          <ul
            className={styles["item_list"]}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <ColumnList items={itemList} doubleClickEvent={onDoubleClick} />
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default FormatColumns;
