import React from "react";
const VideoFeedTemplate = (props) => {
  return (
    <div
      style={{
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          top: props.topVal + "px",
          margin: "0 auto",
          position: "relative",
          background: "transparent",
          textAlign: "center",
          zIndex: "12",
        }}
      >
        {props.videoOption !== "Nsyteful Video Mixer V2" && (
          <iframe
            allowFullScreen=""
            mozallowfullscreen=""
            webkitallowfullscreen=""
            frameBorder="0"
            src={props.vidUrl}
            height={props.actualHeight}
            width={props.actualWidth}
          ></iframe>
        )}
        {props.videoOption === "Nsyteful Video Mixer V2" && (
          <img
            style={{
              display: "block",
              WebkitUserSelect: "none",
              margin: "auto",
              backgroundColor: "hsl(0, 0%, 25%)",
            }}
            src={props.vidUrl}
            height={props.actualHeight}
            width={props.actualWidth}
          />
        )}
      </div>
    </div>
  );
};

export default VideoFeedTemplate;
