import React from "react";
import styles from "./TableTemplate.module.css";

const EmptyRow = (props) => {
  return (
    <>
      {props.freeRowHeight > 0 && (
        <tr
          className={`${styles["tbl-row"]} tbl-data-row ${styles["tbl-column-lines"]}`}
          style={{ height: `${props.freeRowHeight - 5}px` }}
        >
          {props.columnList.map((item, index) => {
            return item.visible === true ? (
              <td
                key={index}
                data-index={index}
                style={{
                  textAlign:
                    item.alignment && item.alignment === "right"
                      ? "right"
                      : "left",
                }}
              ></td>
            ) : (
              ""
            );
          })}
        </tr>
      )}
    </>
  );
};

export default EmptyRow;
