import { Fragment, React, useRef, useState } from "react";
import { useEffect } from "react";
import * as d3 from "d3";
import * as moment from "moment";
import _ from "lodash";

import "./SalesActivity.css";

const margin = { top: 40, right: 40, bottom: 60, left: 70 };

const SalesActivityWidget = (svgElement, widgetId) => {
  const svg = svgElement;
  const id = widgetId;
  let time_intervals,
    zones_data,
    tokens_data,
    actual_data,
    time_mark_data,
    cost_data,
    startTime,
    endTime,
    y_axis_value,
    activityTarget,
    slotCount,
    max_value = 0;

  const draw = (json) => {
    const parseTime = d3.timeParse("%-I:%M %p");
    time_intervals = json.time_intervals.map(function (d) {
      if (max_value < d.attempts + d.msi) {
        max_value = d.attempts + d.msi;
      }
      return {
        attempts: d.attempts,
        msi: d.msi,
        time: parseTime(d.time),
      };
    });

    startTime = json.time_intervals[0].time;
    endTime = json.endtime;
    y_axis_value = parseInt(json.capacity);
    zones_data = json.zones.map(function (d) {
      return {
        start: parseTime(d.start),
        end: parseTime(d.end),
      };
    });
    tokens_data = json.tokens.map(function (d) {
      return {
        time: parseTime(d.time),
        label: d.label,
      };
    });
    actual_data = json.actual;
    time_mark_data = json.time_mark;
    cost_data = json.cost;
    slotCount = json.slotCount;
    activityTarget = json.activityTarget;

    refresh();
  };

  const refresh = () => {
    // Dimention
    const legend_height = document.getElementById(id).children[1].clientHeight; //+ 3;
    const _width = document.getElementById("content_" + id).clientWidth;
    const _height =
      document.getElementById("content_" + id).clientHeight - legend_height;
    const width = _width - margin.left - margin.right;
    const height = _height - margin.top - margin.bottom;

    // Remove Old Graph & Bubble
    svg.selectAll("g").remove();

    //Container for the gradients
    const defs = svg.append("defs");
    const filter = defs
      .append("filter")
      .attr("id", "glow")
      .attr("x", "-200%")
      .attr("y", "-200%")
      .attr("width", "500%")
      .attr("height", "500%");
    filter
      .append("feGaussianBlur")
      .attr("stdDeviation", "3")
      .attr("in", "SourceGraphic");
    filter
      .append("feMorphology")
      .attr("operator", "dilate")
      .attr("radius", "2");
    const feMerge = filter.append("feMerge");
    feMerge.append("feMergeNode").attr("in", "coloredBlur");
    feMerge.append("feMergeNode").attr("in", "SourceGraphic");

    // Graph
    const graph = svg
      .append("g")
      .attr("class", "graph")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Axis Definition
    const parseTime2 = d3.timeParse("%-I:%M %p");
    const _startTime = moment(startTime, "h:mm a").format("h:mm a");
    const _endTime = moment(endTime, "h:mm a").format("h:mm a");

    const start_time = parseTime2(_startTime);
    const end_time = parseTime2(_endTime);

    const x = d3
      .scaleTime()
      .rangeRound([0, width])
      .domain([start_time, end_time]);

    const y = d3
      .scaleLinear()
      .rangeRound([height, 0])
      .domain([0, Math.max(max_value, y_axis_value)]);

    const xAxis = d3
      .axisBottom()
      .scale(x)
      .ticks(d3.timeMinute.every(30))
      .tickSizeInner(0)
      .tickSizeOuter(0)
      .tickPadding(20)
      .tickFormat(d3.timeFormat("%-I:%M %p"));

    const yAxis = d3
      .axisLeft(y)
      .tickSizeInner(-width)
      .tickSizeOuter(0)
      .tickPadding(10)
      .ticks(5);

    const xaxis_group = graph
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    graph.append("g").attr("class", "y-axis").call(yAxis);

    xaxis_group
      .selectAll("text")
      .attr("transform", "translate(-10,0) rotate(-45)");

    // Stacked Area Graph
    const stack = d3.stack();
    const area_chart_colors = ["#afabab", "#70ad47"];
    const main_area_data = stack.keys(["attempts", "msi"])(time_intervals);
    const stacked_area = d3
      .area()
      .x(function (d) {
        return x(d.data.time);
      })
      .y0(function (d) {
        return y(d[0]);
      })
      .y1(function (d) {
        return y(d[1]);
      });

    graph
      .append("g")
      .attr("class", "main-area-graph")
      .selectAll(".main_layer")
      .data(main_area_data)
      .enter()
      .append("path")
      .attr("class", "main_layer")
      .attr("d", function (d) {
        return stacked_area(d);
      })
      .style("fill", function (d, i) {
        return area_chart_colors[i];
      })
      .style("stroke", function (d, i) {
        return area_chart_colors[i];
      });

    // Shaded Area Graph
    const last_of_main = time_intervals[time_intervals.length - 1];

    let intialVal = last_of_main.attempts + last_of_main.msi;
    if (intialVal === undefined || isNaN(intialVal)) {
      intialVal = 0;
    }

    const shaded_area_data = [
      {
        time: last_of_main.time,
        value: intialVal,
      },
      {
        time: end_time,
        //value: 250
        value: y_axis_value,
      },
    ];

    const shaded_area = d3
      .area()
      .x(function (d) {
        return x(d.time);
      })
      .y0(function (d) {
        return y(0);
      })
      .y1(function (d) {
        return y(d.value);
      });
    graph
      .append("g")
      .attr("class", "shaded-area-graph")
      .append("path")
      .datum(shaded_area_data)
      .attr("d", shaded_area);

    // Zone
    const zone = graph.append("g").attr("class", "zone");
    zone
      .selectAll("rect")
      .data(zones_data)
      .enter()
      .append("rect")
      .attr("x", function (d) {
        return x(d.start);
      })
      .attr("y", function (d) {
        return 0;
      })
      .attr("width", function (d) {
        return x(d.end) - x(d.start);
      })
      .attr("height", function (d) {
        return height;
      });
    zone
      .selectAll(".zone-start")
      .data(zones_data)
      .enter()
      .append("line")
      .attr("class", "zone-start")
      .attr("x1", function (d) {
        return x(d.start);
      })
      .attr("x2", function (d) {
        return x(d.start);
      })
      .attr("y1", function (d) {
        return 0;
      })
      .attr("y2", function (d) {
        return y(0);
      });
    zone
      .selectAll(".zone-end")
      .data(zones_data)
      .enter()
      .append("line")
      .attr("class", "zone-end")
      .attr("x1", function (d) {
        return x(d.end);
      })
      .attr("x2", function (d) {
        return x(d.end);
      })
      .attr("y1", function (d) {
        return 0;
      })
      .attr("y2", function (d) {
        return y(0);
      });

    //draw blue line

    if (slotCount > 0) {
      graph
        .append("line")
        .attr("x1", 0)
        .attr("y1", y(slotCount))
        .attr("x2", width)
        .attr("y2", y(slotCount))
        .attr("stroke", "#4286f4")
        .style("stroke-dasharray", "6px 2px")
        .style("stroke-width", "3px");
    }

    // Activity Count Marker
    const activity_count_marker = graph
      .append("g")
      .attr("class", "activity_count_marker");
    // Activity Count Marker Start
    const activity_count_marker_start_attempts = activity_count_marker
      .append("g")
      .attr("class", "activity_count_marker_start_attempts")
      .selectAll("g")
      .data(zones_data)
      .enter()
      .append("g")
      .attr("transform", function (d) {
        const t = time_intervals.filter(function (e) {
          if (e.time == null || d.start == null) return false;
          return e.time.getTime() === d.start.getTime();
        })[0];

        if (t) {
          d.start_attempts = t.attempts;
          d.start_msi = t.msi;
        } else {
          d.start_attempts = 0;
          d.start_msi = 0;
        }
        const cx = x(d.start);
        const cy = y(d.start_attempts);
        return `translate(${cx}, ${cy})`;
      })
      .attr("opacity", function (d) {
        if (d.start_attempts + d.start_msi) {
          return 1;
        } else {
          return 0;
        }
      });
    activity_count_marker_start_attempts
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 25);
    activity_count_marker_start_attempts
      .append("text")
      .attr("dy", ".33em")
      .attr("text-anchor", "middle")
      .text(function (d) {
        return d.start_attempts;
      });
    const activity_count_marker_start_msi = activity_count_marker
      .append("g")
      .attr("class", "activity_count_marker_start_msi")
      .selectAll("g")
      .data(zones_data)
      .enter()
      .append("g")
      .attr("transform", function (d) {
        const cx = x(d.start);
        const cy = Math.min(
          y(d.start_attempts + d.start_msi),
          y(d.start_attempts) - 52
        );
        return `translate(${cx}, ${cy})`;
      })
      .attr("opacity", function (d) {
        if (d.start_attempts + d.start_msi) {
          return 1;
        } else {
          return 0;
        }
      });
    activity_count_marker_start_msi
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 25);
    activity_count_marker_start_msi
      .append("text")
      .attr("dy", ".33em")
      .attr("text-anchor", "middle")
      .text(function (d) {
        return d.start_msi;
      });

    // Activity Count Marker End
    const activity_count_marker_end_attempts = activity_count_marker
      .append("g")
      .attr("class", "activity_count_marker_end_attempts")
      .selectAll("g")
      .data(zones_data)
      .enter()
      .append("g")
      .attr("transform", function (d) {
        let t = time_intervals.filter(function (e) {
          if (
            e.time === null ||
            e.time === undefined ||
            d.end === null ||
            d.end === undefined
          )
            return false;
          return e.time.getTime() === d.end.getTime();
        })[0];
        if (t) {
          d.end_attempts = t.attempts;
          d.end_msi = t.msi;
        } else {
          d.end_attempts = 0;
          d.end_msi = 0;
        }
        let cx = x(d.end);
        let cy = y(d.end_attempts);
        return `translate(${cx}, ${cy})`;
      })
      .attr("opacity", function (d) {
        if (d.end_attempts + d.end_msi) {
          return 1;
        } else {
          return 0;
        }
      });
    activity_count_marker_end_attempts
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 25);
    activity_count_marker_end_attempts
      .append("text")
      .attr("dy", ".33em")
      .attr("text-anchor", "middle")
      .text(function (d) {
        return d.end_attempts;
      });
    const activity_count_marker_end_msi = activity_count_marker
      .append("g")
      .attr("class", "activity_count_marker_end_msi")
      .selectAll("g")
      .data(zones_data)
      .enter()
      .append("g")
      .attr("transform", function (d) {
        const cx = x(d.end);
        const cy = Math.min(
          y(d.end_attempts + d.end_msi),
          y(d.end_attempts) - 52
        );
        return `translate(${cx}, ${cy})`;
      })
      .attr("opacity", function (d) {
        if (d.end_attempts + d.end_msi) {
          return 1;
        } else {
          return 0;
        }
      });
    activity_count_marker_end_msi
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 25);
    activity_count_marker_end_msi
      .append("text")
      .attr("dy", ".33em")
      .attr("text-anchor", "middle")
      .text(function (d) {
        return d.end_msi;
      });

    const cur_time_data = time_intervals[time_intervals.length - 1];

    // Zone Count Marker
    const zone_count_marker = graph
      .append("g")
      .attr("class", "zone_count_marker")
      .selectAll("g")
      .data(zones_data)
      .enter()
      .append("g")
      .attr("transform", function (d) {
        let cx = (x(d.start) + x(d.end)) / 2;
        let cy = 50;
        return `translate(${cx}, ${cy})`;
      });
    zone_count_marker
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 25);
    zone_count_marker
      .append("text")
      .attr("dy", ".33em")
      .attr("text-anchor", "middle")
      .text(function (d) {
        if (d.start === null || d.end === null) {
          return (
            "+" +
            (isNaN(d.end_msi - d.start_msi)
              ? 0
              : Math.abs(d.end_msi - d.start_msi))
          );
        } else {
          if (
            d.start.getTime() < cur_time_data.time.getTime() &&
            d.end.getTime() > cur_time_data.time.getTime()
          ) {
            return (
              "+" +
              (isNaN(cur_time_data.msi - d.start_msi)
                ? 0
                : Math.abs(cur_time_data.msi - d.start_msi))
            );
          } else {
            return (
              "+" +
              (isNaN(d.end_msi - d.start_msi)
                ? 0
                : Math.abs(d.end_msi - d.start_msi))
            );
          }
        }
      });

    // Activity Count Marker - Current
    const activity_count_marker_total = graph
      .append("g")
      .attr("class", "activity_count_marker");
    const activity_count_marker_cur_attempts = activity_count_marker_total
      .append("g")
      .attr("class", "activity_count_marker_cur_attempts")
      .selectAll("g")
      .data([cur_time_data])
      .enter()
      .append("g")
      .attr("transform", function (d) {
        let cx = x(d.time);
        let cy = y(d.attempts);
        return `translate(${cx}, ${cy})`;
      });
    activity_count_marker_cur_attempts
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 35);
    activity_count_marker_cur_attempts
      .append("text")
      .attr("dy", ".33em")
      .attr("text-anchor", "middle")
      .text(function (d) {
        return isNaN(d.attempts) ? 0 : d.attempts;
      });
    const activity_count_marker_cur_msi = activity_count_marker_total
      .append("g")
      .attr("class", "activity_count_marker_cur_msi")
      .selectAll("g")
      .data([cur_time_data])
      .enter()
      .append("g")
      .attr("transform", function (d) {
        let cx = x(d.time);
        let cy = Math.min(y(d.attempts + d.msi), y(d.attempts) - 72);
        return `translate(${cx}, ${cy})`;
      });
    activity_count_marker_cur_msi
      .append("circle")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", 35);
    activity_count_marker_cur_msi
      .append("text")
      .attr("dy", ".33em")
      .attr("text-anchor", "middle")
      .text(function (d) {
        return isNaN(d.msi) ? 0 : d.msi;
      });

    // Tokens
    const tokens = graph
      .append("g")
      .attr("class", "tokens")
      .selectAll("g")
      .data(tokens_data)
      .enter()
      .append("g")
      .attr("transform", function (d) {
        let t = time_intervals.filter(function (e) {
          return e.time.getTime() === d.time.getTime();
        })[0];
        let cx = x(d.time);
        let cy = y(t.attempts + t.msi);
        return `translate(${cx}, ${cy})`;
      });
    tokens.append("circle").attr("class", "point").attr("r", 3);
    tokens
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", -50);
    tokens
      .append("circle")
      .attr("class", "token-circle")
      .attr("cy", -80)
      .attr("r", 30);

    tokens
      .append("circle")
      .attr("class", "round")
      .attr("cy", -80)
      .attr("r", 30)
      .style("filter", "url(#glow)");
    tokens
      .append("text")
      .attr("y", -80)
      .attr("text-anchor", "middle")
      .attr("dy", ".33em")
      .text(function (d) {
        return d.label;
      });
    // Slots
    svg
      .append("g")
      .attr("class", "slots")
      .attr("transform", "translate(20," + (margin.top + height / 2) + ")")
      .append("text")
      .attr("text-anchor", "middle")
      .text("Slots")
      .attr("transform", "rotate(-90)");

    // Donut
    const donut_data = [actual_data, 100 - actual_data];
    const colors = ["#a70606", "#afabab"];
    const arc = d3.arc().innerRadius(40).outerRadius(80);
    const pie = d3
      .pie()
      .value(function (d) {
        return d;
      })
      .sort(null);
    const donut = svg
      .append("g")
      .attr("class", "donut")
      .attr(
        "transform",
        "translate(" + (margin.left + 100) + "," + (margin.top + 100) + ")"
      );
    donut.append("circle").attr("class", "donut-circle").attr("r", 85);
    donut
      .selectAll("path")
      .data(pie(donut_data))
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", function (d, i) {
        return colors[i];
      });
    donut
      .append("text")
      .attr("class", "cost")
      .attr("text-anchor", "middle")
      .text("$" + cost_data);
    donut
      .append("text")
      .attr("class", "label")
      .attr("y", 15)
      .attr("text-anchor", "middle")
      .text("Expected");
    donut
      .append("text")
      .attr("class", "label")
      .attr("y", 25)
      .attr("text-anchor", "middle")
      .text("Dollars");

    const time_marker_from = get_radius_pos(40, time_mark_data);
    const time_marker_to = get_radius_pos(80, time_mark_data);
    donut
      .append("line")
      .attr("class", "time_marker")
      .attr("x1", time_marker_from[0])
      .attr("y1", time_marker_from[1])
      .attr("x2", time_marker_to[0])
      .attr("y2", time_marker_to[1]);

    const actual_pos = get_radius_pos(60, donut_data[0]);
    donut
      .append("circle")
      .attr("class", "actual_circle")
      .attr("cx", actual_pos[0])
      .attr("cy", actual_pos[1])
      .attr("r", 16);

    donut
      .append("text")
      .attr("class", "actual_text")
      .attr("x", actual_pos[0])
      .attr("y", actual_pos[1])
      .attr("text-anchor", "middle")
      .attr("dy", ".33em")
      .text(donut_data[0] + "%");
  };

  const get_radius_pos = (radius, percent) => {
    var arc = d3.arc().innerRadius(radius).outerRadius(radius);
    var pie = d3
      .pie()
      .value(function (d) {
        return d;
      })
      .sort(null);
    if (percent <= 50) {
      return arc.centroid(pie([2 * percent, 100 - 2 * percent])[0]);
    } else {
      percent = 100 - percent;
      var pos = arc.centroid(pie([2 * percent, 100 - 2 * percent])[0]);
      return [-pos[0], pos[1]];
    }
  };

  return { draw: draw };
};

const SalesActivityD3Component = ({
  widgetId,
  widgetDt,
  height,
  width,
  getWidgetSize,
  wid,
}) => {
  const d3Container = useRef(null);
  const [svgHeight, setSvgHeight] = useState(0);

  useEffect(() => {
    getWidgetSize();
    setSvgHeight(height);
    if (!_.isEmpty(widgetDt)) {
      updateSvgHeight();
      const svg = d3.select(d3Container.current);
      const chart = SalesActivityWidget(svg, widgetId);
      chart.draw(widgetDt);
    }
  }, [widgetDt, height, width, wid]);

  const updateSvgHeight = () => {
    const legend_height = document.getElementById(
      `legend${widgetId}`
    ).clientHeight;
    const _svgHeight = height - legend_height;
    setSvgHeight(_svgHeight);
  };

  return (
    <Fragment>
      <div id={widgetId} className="salesv2Widget sales-activity-widget">
        <svg ref={d3Container} height={svgHeight.toString()}></svg>
        <div className="chart-legend" id={`legend${widgetId}`}>
          <div className="chart-legend-item">
            <span className="legend-box attempt">.</span>
            <span className="legend-label">Attempt</span>
          </div>

          <div className="chart-legend-item">
            <span className="legend-box msi">.</span>
            <span className="legend-label">MSI</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SalesActivityD3Component;
