import TableRow from "./TableRow";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  React,
} from "react";
import _debounce from "lodash.debounce";
import _ from "lodash";
import EmptyRow from "./EmptyRows";

const TableBody = forwardRef((props, ref) => {
  const [height, setHeight] = useState(0);
  const [widgetDt, setWidgetDt] = useState([]);
  const [freeRowHeight, setFreeRowHeight] = useState(0);
  const [currentItemCount, setCurrentItemCount] = useState(0);

  useEffect(() => {
    setWidgetDt(props.widgetData);
    GetHeight();
    GetRowToDisplay();
    //resize to update height and width of container
    window.addEventListener("resize", () => {
      GetHeight();
    });
  }, [
    props.widgetData,
    props.columnList,
    height,
    props.sortColumName,
    props.sortColumDir,
    props.wid,
  ]);

  useImperativeHandle(ref, () => ({
    AddItemOnScroll() {
      addItemsOnScroll();
    },
  }));

  const GetRowToDisplay = () => {
    let getList = [];
    const getPropertyDetails = props.columnList.find(
      (item) =>
        item.dataField === props.sortColumName && item.dataType === "date"
    );

    if (getPropertyDetails !== undefined && getPropertyDetails !== null) {
      getList = props.widgetData.map((item) => {
        if (item[props.sortColumName] === null) {
          item[props.sortColumName] = "";
        }
        return item;
      });
    } else {
      getList = props.widgetData;
    }

    const rowToDisplay = parseInt(height / 25);
    let dt = [];
    if (props.sortColumDir !== "") {
      dt = _.orderBy(getList, getValue, [props.sortColumDir]).slice(
        0,
        rowToDisplay
      );
    } else {
      dt = getList.slice(0, rowToDisplay);
    }

    setFreeRowHeight(0);
    if (
      rowToDisplay > props.widgetData.length &&
      props.widgetData.length != 0
    ) {
      const getRestOfRow = parseFloat(
        (rowToDisplay - props.widgetData.length) * 25
      );
      setFreeRowHeight(getRestOfRow);
    }
    setCurrentItemCount(rowToDisplay);
    props.getDisplayedItem(rowToDisplay);
    setWidgetDt(dt);
  };

  const GetHeight = () => {
    const getHeight = document.getElementById("content_" + props.widgetId);
    if (getHeight !== undefined && getHeight !== null) {
      setHeight(getHeight.clientHeight);
      props.getHeight(getHeight.clientHeight);
    }
  };

  const addItemsOnScroll = () => {
    if (props.widgetData.length > currentItemCount) {
      let getList = [];
      const getPropertyDetails = props.columnList.find(
        (item) =>
          item.dataField === props.sortColumName && item.dataType === "date"
      );

      if (getPropertyDetails !== undefined && getPropertyDetails !== null) {
        getList = props.widgetData.map((item) => {
          if (item[props.sortColumName] === null) {
            item[props.sortColumName] = "";
          }
          return item;
        });
      } else {
        getList = props.widgetData;
      }

      const nextItems = currentItemCount + 20;
      const dt = _.orderBy(
        getList,
        //[props.sortColumName],
        getValue,
        [props.sortColumDir]
      ).slice(currentItemCount, nextItems);
      setCurrentItemCount(nextItems);
      setWidgetDt((currentItem) => currentItem.concat(dt));
      props.getDisplayedItem(nextItems);
    }
  };

  const getValue = (item) => {
    const value = item[props.sortColumName];
    if (typeof value === "string") {
      return value.toLowerCase(); // Case-insensitive for strings
    }
    return value; // Fallback for other types
  };

  return (
    <tbody className="tbody_content">
      {widgetDt.map((item, index) => {
        return (
          <TableRow
            columnList={props.columnList}
            rowDt={item}
            key={index}
            settings={props.settings}
            freeRowHeight={freeRowHeight}
          />
        );
      })}
      <EmptyRow freeRowHeight={freeRowHeight} columnList={props.columnList} />
    </tbody>
  );
});

export default TableBody;
