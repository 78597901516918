import React from "react";
import SaveWidgetButton from "../Buttons/SaveWidget";
import WidgetButtonControl from "../Buttons/WidgetButton";
import { useSelector } from "react-redux";

const ButtonControls = (props) => {
  const isUserLoggedIn = useSelector((state) => state.login.isLoggedIn);
  return (
    <div id="settings-buttons">
      <SaveWidgetButton
        onClick={props.OnSaveHandler}
        text={props.SaveBtnText}
      />
      <WidgetButtonControl
        title="Close"
        cssStyle={{ marginLeft: "3px" }}
        onClick={props.onCloseModal}
      />
      {!props.IsEditSetting && isUserLoggedIn && (
        <WidgetButtonControl
          title="Clone Existing Widget"
          cssStyle={{ marginLeft: "3px", width: "150px" }}
          onClick={props.onCloneWidgetModal}
        />
      )}
      {props.IsEditSetting && (
        <WidgetButtonControl
          title="Remove"
          cssStyle={{ float: "right" }}
          onClick={props.onRemoveWidget}
        />
      )}
    </div>
  );
};

export default ButtonControls;
