import * as d3 from "d3";
import { useEffect, useRef, React } from "react";

const CalloutBar = (
  svgElement,
  widgetId,
  categorySelected,
  callout,
  stackCategory,
  colorPallets,
  colorsCategoryPallet,
  w,
  h
) => {
  // member variable
  const id = widgetId;
  const svg = svgElement;
  const legend = document.getElementById(`${id}-dom-legend`);
  const margin = { left: 10, right: 10, top: 10, bottom: 20 };
  let data = [];
  let stackCategories = stackCategory;
  let stackCategoriesColors = [];
  let colors = [
    "#5f8b95",
    "#ba4d51",
    "#af8a53",
    "#955f71",
    "#859666",
    "#7e688c",
    "#91bdc7",
    "#ec7f83",
    "#e1bc85",
    "#c791a3",
    "#b7c898",
    "#B09ABE",
    "#2D5963",
    "#881B1F",
    "#7D5821",
    "#632D3F",
    "#536434",
    "#4C365A",
  ];

  colors.forEach(function (d) {
    colors.push(d);
  });

  if (colorPallets.length > 0) {
    colors = colorPallets;
  }

  if (colorsCategoryPallet.length > 0) {
    stackCategoriesColors = colorsCategoryPallet;
  } else {
    stackCategoriesColors = colors;
  }

  colors = colors;
  let newarray;

  const insertLegend = function () {
    let val = Math.max.apply(
      Math,
      data.map((el) => {
        return el.values.length;
      })
    );

    data.forEach(function (item) {
      if (item.values.length == val) {
        newarray = item.values;
      }
    });
    newarray = stackCategories.sort();
    legend.innerHTML = "";
    newarray.forEach(function (d, i) {
      legend.innerHTML += `
  <div class="dom-legend-item">
      <span class="dom-legend-box" style="background: ${stackCategoriesColors[i]};color: ${stackCategoriesColors[i]};">.</span>
      <span class ="dom-legend-label" style="color:#767676;font-family:'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;font-weight:400;font-size:12px;cursor:default;"> ${newarray[i]}</span>
  </div>
`;
    });
  };

  if (categorySelected) {
    // Legend Generate
    insertLegend();
  }

  // Update data & redraw
  const loadData = (dt) => {
    let scope = this;
    data = dt;
    if (categorySelected) {
      insertLegend();
    }
    drawChart(dt);
    // d3.select(window).on("resize", function () {
    //   scope.drawChart();
    // });
  };

  // draw
  const drawChart = (dt) => {
    let scope = this;
    let data = JSON.parse(JSON.stringify(dt));
    let names = data.map(function (d) {
      return d.name;
    });
    let namesArr = stackCategories;

    // Clear Old
    //svg.attr("width", 0);
    //svg.attr("height", 0);
    //svg.selectAll("g").remove();

    // New dimension
    let _width = w; //document.getElementById(id).clientWidth;
    let _height = h - legend.clientHeight - 5; //document.getElementById(id).clientHeight - this.legend.clientHeight - 5;
    let width = _width - margin.left - margin.right;
    let height = _height - margin.top - margin.bottom;

    // Set SVG
    svg.attr("width", _width);
    svg.attr("height", _height);

    // Scale
    let max_sum = d3.max(data, function (d) {
      return sum(d.values);
    });
    let x = d3
      .scaleBand()
      .rangeRound([0, width])
      .domain(names)
      .paddingInner(0.4)
      .paddingOuter(0.3);
    let y = d3
      .scaleLinear()
      .rangeRound([height, 0])
      .domain([0, max_sum])
      .nice();

    // Graph Background
    let graph = svg
      .append("g")
      .attr("class", "graph")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);
    graph
      .append("line")
      .attr("x1", 0)
      .attr("y1", height)
      .attr("x2", width)
      .attr("y2", height)
      .style("stroke", "#c6c6c6")
      .style("stroke-width", "2px");

    let stacked_bar;
    // Stacked Bar
    if (!categorySelected) {
      stacked_bar = graph
        .append("g")
        .attr("class", "stacked-bar")
        .selectAll(".group")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "group")
        .attr("transform", function (d) {
          return `translate(${x(d.name)}, 0)`;
        })
        .style("fill", function (d, i) {
          return colors[i];
        });
    } else {
      stacked_bar = graph
        .append("g")
        .attr("class", "stacked-bar")
        .selectAll(".group")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "group")
        .attr("transform", function (d) {
          return `translate(${x(d.name)}, 0)`;
        });
    }
    // Bar
    if (!categorySelected) {
      stacked_bar
        .selectAll("rect")
        .data(function (d) {
          return getStack(d.values);
        })
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", 0)
        .attr("y", function (d) {
          return y(d.y + d.value);
        })
        .attr("width", x.bandwidth())
        .attr("height", function (d) {
          return y(d.y) - y(d.y + d.value);
        });
      //.style("stroke", "#fff");
    } else {
      stacked_bar
        .selectAll("rect")
        .data(function (d) {
          return getStack(d.values);
        })
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", 0)
        .attr("y", function (d) {
          return y(d.y + d.value);
        })
        .attr("width", x.bandwidth())
        .attr("height", function (d) {
          return y(d.y) - y(d.y + d.value);
        })
        .style("fill", function (d, i) {
          let getIndex = namesArr.findIndex((x) => x.indexOf(d.name_type) > -1);
          return stackCategoriesColors[getIndex];
        });
      //.style("stroke", "#fff");
    }
    // Name & Abbreviation
    let name = stacked_bar
      .append("text")
      .attr("class", "name")
      .attr("x", x.bandwidth() / 2)
      .attr("y", margin.top + height)
      .attr("dy", ".33em")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#767676")
      .style("font-weight", "400")
      .style("cursor", "#default")
      .style(
        "font-family",
        "'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana"
      )
      .text(function (d) {
        return d.name;
      });
    name.text(function (d) {
      let width = d3.select(this).node().getBBox().width;
      if (width > (x.bandwidth() * 5) / 3) {
        return getAbbrName(d.name);
      }
      return d.name;
    });

    // Callout
    let callout_data = getCalloutBoxData(data, y, height);
    let max_box_width = 0;
    let callout_box_group = graph.append("g").attr("class", "callout-box");
    let callout_box;
    if (categorySelected) {
      callout_box = callout_box_group
        .selectAll(".group")
        .data(callout_data)
        .enter()
        .append("g")
        .attr("class", "group")
        .attr("transform", function (d) {
          return `translate(${x(d.name) + x.bandwidth()}, 0)`;
        })
        .selectAll("g")
        .data(function (d) {
          return d.boxs;
        })
        .enter()
        .append("g")
        .attr("fill", function (d, i) {
          let getIndex = namesArr.findIndex((x) => x.indexOf(d.name_type) > -1);
          return stackCategoriesColors[getIndex];
          // return colors[i]
        })
        .attr("transform", function (d) {
          return `translate(0, ${d.y})`;
        });
    } else {
      callout_box = callout_box_group
        .selectAll(".group")
        .data(callout_data)
        .enter()
        .append("g")
        .attr("class", "group")
        .attr("fill", function (d, i) {
          return colors[i];
        })
        .attr("transform", function (d) {
          return `translate(${x(d.name) + x.bandwidth()}, 0)`;
        })
        .selectAll("g")
        .data(function (d) {
          return d.boxs;
        })
        .enter()
        .append("g")
        .attr("transform", function (d) {
          return `translate(0, ${d.y})`;
        });
    }
    callout_box
      .append("rect")
      .attr("x", 10)
      .attr("width", function (d) {
        if (d.text.toString().length == 1) {
          max_box_width = Math.max(10 * 2, max_box_width);
          return 10 * 2;
        } else {
          max_box_width = Math.max(
            10 * d.text.toString().length,
            max_box_width
          );
          return 10 * d.text.toString().length;
        }
      })
      .attr("height", 22);

    callout_box
      .append("text")
      .attr("x", function (d) {
        if (d.text.toString().length == 1) return 10 + 5 * 2;
        else return 10 + 5 * d.text.toString().length;
      })
      .attr("y", 11)
      .attr("dy", ".33em")
      .style("text-anchor", "middle")
      .style("fill", "white")
      .style("font-size", "12px")
      .text(function (d) {
        return d.text;
      });

    // Callout Connect Line
    let callout_lines = graph.append("g").attr("class", "callout-lines");
    if (categorySelected) {
      callout_lines
        .selectAll(".group")
        .data(callout_data)
        .enter()
        .append("g")
        .attr("class", "group")
        .attr("transform", function (d) {
          return `translate(${x(d.name) + x.bandwidth()}, 0)`;
        })
        .selectAll("line")
        .data(function (d) {
          return d.boxs;
        })
        .enter()
        .append("line")
        .attr("class", "callout-line")
        .attr("x1", -1)
        .attr("y1", function (d) {
          return d.h;
        })
        .attr("x2", 11)
        .attr("y2", function (d) {
          return d.y + 11;
        })
        .style("stroke", function (d, i) {
          let getIndex = namesArr.findIndex((x) => x.indexOf(d.name_type) > -1);
          return stackCategoriesColors[getIndex];
          //return colors[i]
        })
        .style("stroke-width", "1px");
    } else {
      callout_lines
        .selectAll(".group")
        .data(callout_data)
        .enter()
        .append("g")
        .attr("class", "group")
        .style("stroke", function (d, i) {
          return colors[i];
        })
        .attr("transform", function (d) {
          return `translate(${x(d.name) + x.bandwidth()}, 0)`;
        })
        .selectAll("line")
        .data(function (d) {
          return d.boxs;
        })
        .enter()
        .append("line")
        .attr("class", "callout-line")
        .attr("x1", -1)
        .attr("y1", function (d) {
          return d.h;
        })
        .attr("x2", 11)
        .attr("y2", function (d) {
          return d.y + 11;
        })
        .style("stroke-width", "1px");
    }

    if (max_box_width + 10 > (x.bandwidth() * 2) / 3 || !callout) {
      callout_box_group.style("opacity", 0);
      callout_lines.style("opacity", 0);
    }
  };

  // Get sum of array values
  const sum = (data) => {
    let sum = 0;
    data.forEach(function (d) {
      sum += parseInt(d.value);
    });
    return sum;
  };

  const getAbbrName = (name) => {
    let list = name.split(" ");
    let abbr = "";
    list.forEach(function (d) {
      abbr += d[0];
    });
    return abbr;
  };

  const getStack = (data) => {
    let mid_sum = 0;
    let stack_data = [];
    data.forEach(function (d, i) {
      stack_data.push({
        value: d.value,
        y: mid_sum,
        index: i,
        length: data.length,
        name_type: d.label,
      });
      mid_sum += d.value;
    });
    return stack_data;
  };

  const getCalloutBoxData = (data, scale_y, height) => {
    let callout_data = [];
    data.forEach(function (d) {
      let stack_values = getStack(d.values);
      let callout_boxs = stack_values.map(function (d) {
        return {
          name_type: d.name_type,
          y: scale_y(d.y + d.value),
          text: d.value,
          h: (scale_y(d.y) + scale_y(d.y + d.value)) / 2,
        };
      });
      callout_data.push({
        //name_type: d.label,
        name: d.name,
        boxs: cleanCalloutSpace(callout_boxs, height),
      });
    });
    return callout_data;
  };

  const cleanCalloutSpace = (data, height) => {
    // Adjust Overlap
    let prev = height;
    data.forEach(function (d, i) {
      if (prev < d.y + 24) {
        d.y = prev - 24;
      }
      prev = d.y;
    });

    // Adjust Overall
    let len = data.length;
    if (len > 0) {
      if (data[len - 1].y < 0) {
        data[len - 1].y = 0;
        for (let i = len - 2; i >= 0; i--) {
          let offset = data[i].y - data[i + 1].y - 24;
          if (offset < 0) {
            data[i].y -= offset;
          }
        }
      }
    }
    return data;
  };

  return { loadData: loadData };
};

const BarchartD3Component = (props) => {
  const d3Container = useRef(null);
  useEffect(() => {
    props.getWidgetSize();
    if (
      props.data !== undefined &&
      d3Container.current &&
      props.data.barValues.length > 0
    ) {
      const svg = d3.select(d3Container.current);
      svg.selectAll("*").remove();
      const w = props.width;
      const h = props.height;
      const calloutBar = CalloutBar(
        svg,
        props.widgetId,
        props.data.categorySelected,
        props.callout,
        props.data.mainStackCategories,
        props.data.colorsPallet,
        props.data.colorsCategoryPallet,
        w,
        h
      );
      calloutBar.loadData(props.data.barValues);
    }
  }, [
    props.data,
    props.width,
    props.height,
    props.wid,
    props.data.barValues,
    props.callout,
  ]);

  return (
    <div>
      <svg ref={d3Container} width={props.width} height={props.height}></svg>
      <div
        id={`${props.widgetId}-dom-legend`}
        style={{ textAlign: "center", padding: "5px 0" }}
      ></div>
    </div>
  );
};

export default BarchartD3Component;
