import React from "react";
import VideoFeedTemplate from "./VideoFeedTemplate";

const VideoFeedComponent = (props) => {
  return (
    <div>
      {(props.videofeedurl !== "" || props.rtspUrl !== "") && (
        <VideoFeedTemplate
          topVal={props.topVal}
          videoOption={props.videoOption}
          vidUrl={props.vidUrl}
          actualHeight={props.actualHeight}
          actualWidth={props.actualWidth}
        />
      )}
    </div>
  );
};

export default VideoFeedComponent;
