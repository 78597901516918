import { useDispatch } from "react-redux";
import Modal from "../UI/Modal";
import { useSelector } from "react-redux";
import { useState } from "react";
import AccountService from "./AccountService";
import { useHistory } from "react-router-dom";

export const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const getTheme = useSelector((state) => state.layout.theme);
  const email = useSelector((state) => state.login.userDetail.email);
  let history = useHistory();
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErr, setNewPasswordErr] = useState("");
  const [cnfPassword, setcnfPassword] = useState("");
  const [cnfPasswordErr, setcnfPasswordErr] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const onformSubmit = () => {
    if (newPassword === "") {
      setNewPasswordErr("Required");
      setTimeout(() => {
        setNewPasswordErr("");
      }, 3000);

      return false;
    }

    var format = /(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])/;
    if (!format.test(newPassword)) {
      setNewPasswordErr(
        "Passwords must be between 8 and 20 characters.Must contain one lower & uppercase letter, and one non-alpha character (a number or a symbol.)"
      );
      setTimeout(() => {
        setNewPasswordErr("");
      }, 3000);

      return false;
    }

    if (cnfPassword === "") {
      setcnfPasswordErr("Please confirm your password.");
      setTimeout(() => {
        setcnfPasswordErr("");
      }, 3000);

      return false;
    }

    if (cnfPassword !== newPassword) {
      setcnfPasswordErr("Passwords do not match.");
      setTimeout(() => {
        setcnfPasswordErr("");
      }, 3000);

      return false;
    }

    const array = props.forgotResponseMsg.split("__");
    const email_cf = array[0].replace(/"/g, "").replace("\\", "");
    const pass_cf_old = array[1].replace(/"/g, "");

    AccountService.changePassword(email_cf, pass_cf_old, newPassword)
      .then((res) => {
        const message = res.replace(/"/g, "");
        setResponseMessage(message);
        history.push("/");
      })
      .catch((err) => {
        const message = err.replace(/"/g, "");
        setResponseMessage(message);
        history.push("/");
      });
  };

  return (
    <Modal title="Reset Password" onClose={props.onCloseResetPwdHandler}>
      {responseMessage && (
        <div>
          <div style={{ padding: "5px" }}>{responseMessage}</div>
          <div style={{ paddingTop: "15px", textAlign: "right" }}>
            <button
              className={`dx-widget dx-button-has-text dx-button dx-button-normal ${
                getTheme !== "Light" ? "button-dark" : ""
              }`}
              type="button"
              onClick={props.onCloseResetPwdHandler}
            >
              Ok
            </button>
          </div>
        </div>
      )}
      {!responseMessage && (
        <form className="settings">
          <p htmlFor="cngPasspassword1">New Password</p>
          <input
            type="password"
            minLength="8"
            maxLength="20"
            name="cngPasspassword1"
            autoComplete="false"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {newPasswordErr && (
            <span style={{ color: "red" }}>{newPasswordErr}</span>
          )}
          <br />
          <p htmlFor="cngPasspassword_c1">Confirm Password</p>
          <input
            type="password"
            minLength="8"
            maxLength="20"
            name="cngPasspassword_c1"
            autoComplete="false"
            value={cnfPassword}
            onChange={(e) => setcnfPassword(e.target.value)}
          />
          {cnfPasswordErr && (
            <span style={{ color: "red", display: "block" }}>
              {cnfPasswordErr}
            </span>
          )}
          <div style={{ paddingTop: "15px" }}>
            <button
              className={`dx-widget dx-button-has-text dx-button dx-button-normal ${
                getTheme !== "Light" ? "button-dark" : ""
              }`}
              type="button"
              onClick={onformSubmit}
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </Modal>
  );
};
