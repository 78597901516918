import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SharedUtil from "./../Util";
import ConfiguratorServices from "../ConfiguratorService";
import FormControl from "../Common/FormControl";

export const AddConfigurationSetting = ({
  formDetails,
  onAddSettingHandler,
  settingName,
  timeZoneList,
  dataSourceId,
  columnSettingId,
  dataCount,
  sessionId,
  onFinishAddSettings,
  teamList,
  columnList,
  salesOppOwnerList,
}) => {
  const [formControls, setFormControls] = useState([]);
  const [validationCounter, setValidationCounter] = useState(0);
  const email = useSelector((state) => state.login.userDetail.email);
  const userName = useSelector((state) => state.login.userDetail.userFullName);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    setFormControls(formDetails);
  }, [formDetails, timeZoneList]);

  const handleFormChange = (index, event, isDate) => {
    let value = "",
      name = "";

    if (isDate) {
      value = event;
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    const updateFormControl = SharedUtil.bindValueOnChange(
      settingName,
      formControls,
      index,
      value,
      name,
      isDate,
      teamList,
      salesOppOwnerList
    );

    setFormControls(updateFormControl);
  };

  const addNewSettings = (event) => {
    event.preventDefault();
    if (isAdding) return false;

    setIsAdding(true);
    const validateForm = SharedUtil.validateSettings(
      formControls,
      settingName,
      validationCounter
    );

    if (!validateForm.isValid) {
      const data = [...validateForm.result];
      setFormControls(data);
      hideValidation(data);

      if (
        validateForm.validationCounter !== undefined &&
        validateForm.validationCounter > 0
      ) {
        setValidationCounter(validateForm.validationCounter);
      }
      setIsAdding(false);
      return false;
    }

    let finalFormDetails = {};
    for (const key of formControls) {
      finalFormDetails[key["dataField"]] = key["value"];

      if (key["type"] === "Clock") {
        finalFormDetails[key["dataField"]] = SharedUtil.tConvert(key["value"]);
      }

      if (finalFormDetails["timezoneID"] !== undefined) {
        var getTimezoneObj = timeZoneList.filter(function (dt) {
          return dt.timezoneID === finalFormDetails["time_zone"];
        });
        if (getTimezoneObj && getTimezoneObj.length > 0) {
          finalFormDetails["time_zone"] = getTimezoneObj[0]["timezone"];
          finalFormDetails["timezoneID"] = getTimezoneObj[0]["timezoneID"];
        }
      }
    }

    //get column name and values seperated two different array
    const columnNames = Object.keys(finalFormDetails);
    const columnValues = Object.values(finalFormDetails);

    //new data format
    let objData = {
      datasourceType: "",
      dataSourceId: "",
      columnSettingId: "",
      actionName: "insert",
      settingsId: "",
      columnNames: ["string"],
      newValues: ["string"],
      displayOrder: 1,
    };

    //fill object data for server call
    objData.actionName = "insert";
    objData.datasourceType = 0;
    objData.dataSourceId = dataSourceId;
    objData.columnNames = columnNames;
    objData.settingsId = "";
    objData.newValues = columnValues;
    objData.columnSettingId = columnSettingId;
    objData.userEmail = email;
    objData.lastModifiedBy = userName;
    objData.displayOrder = dataCount + 1;

    ConfiguratorServices.InsertUpdateSettings(objData, sessionId)
      .then((res) => {
        if (res.indexOf("Data is already exists") > -1) {
          const fieldName = formControls[0];
          const isDuplicate = duplicateMessage(
            fieldName["dataField"],
            "Record already exists"
          );
          setIsAdding(false);
          if (!isDuplicate) return false;
        } else {
          onFinishAddSettings();
          setIsAdding(false);
        }
      })
      .catch((err) => {
        console.log("InsertUpdateSettings", err);
        setIsAdding(false);
      });
  };

  const hideValidation = (formControls) => {
    setTimeout(() => {
      const findIndex = formControls.findIndex((x) => x["errorMessage"] !== "");
      if (findIndex > -1) {
        let teamName = formControls[findIndex];
        let data = [...formControls];
        teamName["errorMessage"] = "";
        data[findIndex] = teamName;
        setFormControls(data);
      }
    }, 3000);
  };

  const duplicateMessage = (name, erroMessage) => {
    const fieldIndex = formControls.findIndex((x) => x["dataField"] === name);
    let fieldName = formControls[fieldIndex];
    fieldName["errorMessage"] = erroMessage;
    let data = [...formControls];
    data[fieldIndex] = fieldName;
    setFormControls(data);
    hideValidation(formControls);
    return false;
  };

  return (
    <tr>
      {formControls.map((column, index) => {
        if (
          column["dataField"] !== "id" &&
          column["dataField"] !== "client_id" &&
          column["dataField"] !== "dataSourceId" &&
          column["dataField"] !== "timezoneID" &&
          column["dataField"] !== "case_typescol" &&
          column["dataField"] !== "nsyteful_optionscol" &&
          column["dataField"] !== "target_opp_points"
        ) {
          return (
            <td key={index}>
              <FormControl
                column={column}
                handleFormChange={handleFormChange}
                timeZoneList={timeZoneList}
                teamList={teamList}
                columnList={columnList}
                salesOppOwnerList={salesOppOwnerList}
                index={index}
                settingName={settingName}
                isReadonly={
                  ((settingName === "Case Owners" ||
                    settingName === "Sales Opp Owners") &&
                    (column["dataField"] === "start_time" ||
                      column["dataField"] === "finish_time")) ||
                  column["dataField"] === "discount" ||
                  column["dataField"] === "number" ||
                  column["dataField"] === "probability" ||
                  column["dataField"] === "multiplier" ||
                  column["dataField"] === "pct_no" ||
                  (settingName === "Sales Opp Owners Capacity" &&
                    column["dataField"] === "team_name")
                }
              />
            </td>
          );
        }
        return "";
      })}
      {formControls.length > 0 && (
        <td className="action-col">
          <a
            href=""
            className="icons_cls"
            title="Save"
            style={{
              fontSize: "18px",
              color: "gray",
              textDecoration: "none",
              cursor: isAdding === true ? "not-allowed" : "",
            }}
            onClick={addNewSettings}
          >
            <i className="fa fa-check-square-o" aria-hidden="true"></i>
          </a>
          <a
            href=""
            className="icons_cls"
            title="Cancel"
            style={{ fontSize: "18px", color: "gray", textDecoration: "none" }}
            onClick={(event) => {
              event.preventDefault();
              onAddSettingHandler(false);
            }}
          >
            &nbsp;<i className="fa fa-times" aria-hidden="true"></i>
          </a>
        </td>
      )}
    </tr>
  );
};
