import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LOGIN_Detail_URL,
  IS_TESTAPI_SERVICE_URL_Enabled,
  SESSION_DETAIL_URL,
} from "../Constants";
import { layoutActions } from "../store/layout-slice";
import { loginActions } from "../store/login-slice";
import "../App.css";
import Layout from "../component/Layout/Layout";
import Login from "../component/Account/Login";
import Page from "../component/Page/Page";
import AddPage from "../component/Page/AddPage/AddPage";
import EditPage from "../component/Page/EditPage/EditPage";
import ClonePage from "../component/Page/ClonePage/ClonePage";
import AddWidgetSettings from "../component/Widgets/Settings/AddWidget/AddWidget";
import EditWidgetSettings from "./../component/Widgets/Settings/EditWidget/EditWidgetSetting";
import { pageActions } from "../store/page-slice";
import CreateVideoFeedRTSP from "../component/Widgets/Settings/AddWidget/ConfigTemplate/ConfigContent/VideoFeedRTSPForm";
import FilterComponent from "../component/Filter";
import SharedService from "../Helper/SharedService";
import RotationSettings from "../component/Layout/Header/Rotation/Settings";
import { ConfiguratorSetting } from "../component/Layout/Header/Configurator";
import { CloneWidget } from "../component/Widgets/Settings/CloneWidget";
import { ChangePassword } from "../component/Account/ChangePassword";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { ActivateNewUser, DecodeFrom64Sting } from "../Helper/Services";
import { ResetPassword } from "../component/Account/ResetPassword";
import RecycleBin from "../component/Layout/Header/RecycleBin";

const MainPage = () => {
  const [loginShow, setLoginShow] = useState(false);
  const [resetPasswordShow, setResetPasswordShow] = useState(false);
  const isUserLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const newPageId = useSelector((state) => state.login.newPageId);
  const isNewPageCreated = useSelector((state) => state.login.isNewPageCreated);
  const isEditPageEvent = useSelector((state) => state.login.isEditPageEvent);
  const editPageId = useSelector((state) => state.login.editPageId);
  const isClonePageEvent = useSelector((state) => state.login.isClonePageEvent);
  const clonePageId = useSelector((state) => state.login.clonePageId);
  const sessionIdFromContext = useSelector((state) => state.login.sessionId);
  const isLogoutEvent = useSelector((state) => state.login.isLogoutEvent);
  const isLoadPages = useSelector((state) => state.page.isPageListOpen);
  const isAddPage = useSelector((state) => state.page.isAddPageOpen);
  const isEditPage = useSelector((state) => state.page.isEditPageOpen);
  const isClonePage = useSelector((state) => state.page.isClonePageOpen);
  const loadPageId = useSelector((state) => state.page.loadPageId);
  const isLoadPage = useSelector((state) => state.page.isLoadPage);
  const isFilterOpen = useSelector((state) => state.layout.IsFilterPopupOpen);
  const isEditFilter = useSelector((state) => state.layout.IsEditFilter);
  const isCloneFilter = useSelector((state) => state.layout.IsCloneFilter);
  const rotationPageId = useSelector((state) => state.page.rotationPageId);
  const activeRotationId = useSelector((state) => state.layout.PageRotation);
  const isCloneWidgetOpen = useSelector(
    (state) => state.layout.IsCloneWidgetPopup
  );
  const isChangePasswordPopup = useSelector(
    (state) => state.layout.IsChangePasswordPopup
  );

  const IsRotationSettings = useSelector(
    (state) => state.layout.IsRotationPopup
  );
  const IsConfiguratorPopup = useSelector(
    (state) => state.layout.IsConfiguratorPopup
  );
  const isCreateVideoFeedUrlFormOpen = useSelector(
    (state) => state.page.isCreateVideoFeedUrlFormOpen
  );
  const isAddWidgetSettings = useSelector(
    (state) => state.layout.IsAddWidgetPopup
  );
  const isEditWidgetSettings = useSelector(
    (state) => state.layout.IsEditWidgetPopup
  );
  const isRecycleBinPopup = useSelector(
    (state) => state.layout.IsRecycleBinPopup
  );

  const isWidgetRestored = useSelector(
    (state) => state.layout.IsWidgetRestored
  );

  const isRotationUpdated = useSelector(
    (state) => state.layout.IsRotationUpdateVersion
  );

  const [registrationMsg, setRegistrationMsg] = useState("");

  const dispatch = useDispatch();
  let { sessionId, pageId } = useParams();
  const { search } = useLocation();
  let history = useHistory();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const edit = 0;

  useEffect(() => {
    if (pageId == undefined) {
      pageId = newPageId;
    }

    if (newPageId !== "") {
      pageId = newPageId;
      dispatch(loginActions.resetNewPageId());
    }

    if (editPageId !== "") {
      pageId = editPageId;
      dispatch(loginActions.resetEditPageId());
    }

    if (clonePageId !== "") {
      pageId = clonePageId;
      dispatch(loginActions.resetClonePageId());
    }

    if (loadPageId !== "") {
      pageId = loadPageId;
      dispatch(pageActions.setLoadPageId(""));
    }

    if (rotationPageId !== "") {
      pageId = rotationPageId;
    }

    if (sessionId === undefined) {
      sessionId = sessionIdFromContext;
    }

    if (sessionId === undefined || sessionId === "") {
      //check existing login sessionid if available direct load the dashboard and pass the login process
      if (
        SharedService.getLocalStorageSessionId() !== null &&
        SharedService.getLocalStorageSessionId() !== ""
      ) {
        sessionId = SharedService.getLocalStorageSessionId();
        history.push("/" + sessionId);
      }
    }

    setIsPageLoading(true);
    const loginDetailCheck = async () => {
      const url = `${LOGIN_Detail_URL}?SessionId=${sessionId}&PageId=${pageId}&edit=${edit}&isTestApi=${IS_TESTAPI_SERVICE_URL_Enabled}`;
      const getLoginDetail = await fetch(url);

      if (getLoginDetail.ok) {
        const getResponse = await getLoginDetail.json();
        checkSessionDetails(getResponse);
        if (getResponse.Success) {
          setIsPageLoading(false);
          dispatch(loginActions.setPageLoaded(true));
          let isPageIdMatched = true;
          isPageIdMatched = IsRotationPageFound(getResponse, isPageIdMatched);
          if (isPageIdMatched) {
            dispatch(layoutActions.setIntialLayout(getResponse.Message.Layout));
          }
        }
      } else {
        setIsPageLoading(false);
        dispatch(loginActions.setPageLoaded(true));
      }

      if (sessionId != null && sessionId !== "") {
        const getSessionDetail = await fetch(
          `${SESSION_DETAIL_URL}?sessionId=${sessionId}`
        );
        if (getSessionDetail.ok) {
          const getResponse = await getSessionDetail.json();
          checkSessionDetails(getResponse);
          const userdata = JSON.parse(getResponse);
          if (userdata != null) {
            dispatch(loginActions.setUserDetail(userdata[0]));
            dispatch(loginActions.login(sessionId));
          }
        }
      }
    };

    loginDetailCheck();
    resetAndRegisterUserDetails();
  }, [
    isUserLoggedIn,
    isNewPageCreated,
    isEditPageEvent,
    isClonePageEvent,
    isLoadPage,
    rotationPageId,
    isWidgetRestored,
    isRotationUpdated,
  ]);

  function resetAndRegisterUserDetails() {
    if (search !== "") {
      const params = Object.fromEntries([...new URLSearchParams(search)]);
      const token = params.token;

      if (token !== undefined && token !== "") {
        DecodeFrom64Sting(token)
          .then((res) => {
            setRegistrationMsg(res);
            const parameters = JSON.stringify(res);
            const array = parameters.split("__");
            const p2 = array[2]
              .replace('"', "")
              .replace('"', "")
              .replace("\\", "");
            if (p2 === "Registration") {
              const email = array[0]
                .replace('"', "")
                .replace('"', "")
                .replace("\\", "");

              ActivateNewUser(email)
                .then((res) => {
                  const mesg = res.replace(/"/g, "");
                  if (mesg !== "Activation failed") {
                    //set sessionid local storage
                    SharedService.setLocalStorageSessionId(mesg);
                    dispatch(loginActions.login(mesg));
                    const url = "/" + mesg;
                    history.replace(url);
                  } else {
                    setLoginShow(true);
                    setRegistrationMsg(mesg);
                  }
                })
                .catch((err) => {
                  const mesg = res.replace(/"/g, "");
                  setRegistrationMsg(mesg);
                  setLoginShow(true);
                });
            } else if (p2 === "ForgotPassword") {
              setResetPasswordShow(true);
            }
          })
          .catch((err) => {});
      }
    }
  }

  const IsRotationPageFound = (getResponse, isPageIdMatched) => {
    const getRotationVal = getResponse.Message.Layout.PageRotation;
    if (
      getRotationVal !== "30000" &&
      getRotationVal !== "90000" &&
      getRotationVal !== "240000" &&
      getRotationVal !== "Off" &&
      getRotationVal !== "false"
    ) {
      const rotationList = getResponse.Message.Layout.RotationList;
      const getRotationObj = rotationList.filter(function (res) {
        return res.Id.toString() === getRotationVal;
      });

      if (getRotationObj && getRotationObj.length > 0) {
        var getPages = getRotationObj[0]["PageList"];
        var isPageFound = false;
        if (getPages !== undefined && getPages.length > 0) {
          var getFilteredPage = getPages.filter(function (pg) {
            return pg.PageId.toString() === pageId.toString();
          });
          if (getFilteredPage !== undefined && getFilteredPage.length > 0) {
            console.log("page found");
            isPageFound = true;
          }
        }

        var getPage = getRotationObj[0]["PageList"][0];
        if (getPage && getPage !== undefined) {
          if (!isPageFound) {
            isPageIdMatched = false;
            console.log("not page found");
            dispatch(pageActions.setRotationPageId(getPage["PageId"]));
            history.push("/" + sessionId + "/" + getPage["PageId"]);
          }
        }
      }
    }
    return isPageIdMatched;
  };

  const showLoginHandler = (event) => {
    event.preventDefault();
    setLoginShow(true);
    setRegistrationMsg("");
  };

  const hideLoginHandler = () => {
    setLoginShow(false);
  };

  const hideResetPasswordHandler = () => {
    setResetPasswordShow(false);
  };

  function checkSessionDetails(data) {
    if (isString(data)) {
      if (!isLogoutEvent) {
        if (
          data.indexOf("Invalid Session Id") > -1 ||
          data.indexOf("Session has expired") > -1 ||
          data.indexOf("session not found or invalid") > -1
        ) {
          alert("Session has expired. Please log in again.");
          SharedService.removeLocalStorageSessionId(sessionId);
          history.push("/");
          window.location.reload(false);
        }
      }
    }
  }

  function isString(value) {
    return typeof value === "string" || value instanceof String;
  }

  return (
    <React.Fragment>
      {loginShow && (
        <Login
          onCloseLoginHandler={hideLoginHandler}
          registrationMsg={registrationMsg}
        />
      )}
      {isLoadPages && <Page />}
      {isAddPage && <AddPage />}
      {isEditPage && <EditPage />}
      {isClonePage && <ClonePage />}
      {isAddWidgetSettings && <AddWidgetSettings />}
      {isEditWidgetSettings && <EditWidgetSettings />}
      {isCreateVideoFeedUrlFormOpen && <CreateVideoFeedRTSP />}
      {isFilterOpen && (
        <FilterComponent isEdit={isEditFilter} isCloneFilter={isCloneFilter} />
      )}
      {IsRotationSettings && <RotationSettings />}
      {IsConfiguratorPopup && <ConfiguratorSetting />}
      {isCloneWidgetOpen && <CloneWidget />}
      {isChangePasswordPopup && (
        <ChangePassword onShowLoginHandler={showLoginHandler} />
      )}
      {resetPasswordShow && (
        <ResetPassword
          onCloseResetPwdHandler={hideResetPasswordHandler}
          forgotResponseMsg={registrationMsg}
        />
      )}
      {isRecycleBinPopup && <RecycleBin />}
      <Layout onShowLoginHandler={showLoginHandler} />
    </React.Fragment>
  );
};

export default MainPage;
