export const ConfigurationTypes = ({
  configurationType,
  onDataSourceClickHandler,
  dataSourceTitle,
  onConfigurationTypeClick,
}) => {
  return (
    <div className="configurator">
      <ul className="breadcrumb">
        <li>
          <a
            href=""
            onContextMenu={() => {
              return false;
            }}
            onClick={onDataSourceClickHandler}
          >
            Data Sources
          </a>
        </li>
        <li>{dataSourceTitle}</li>
      </ul>

      <div className="main-container">
        <ul className="list-group">
          {configurationType.map((item) => {
            return (
              <li
                key={item.columnSettingsId}
                className="list-group-item dark-background"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  onConfigurationTypeClick(
                    item.settingName,
                    item.columnSettingsId,
                    false
                  )
                }
              >
                {item.settingName}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
