import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LOGIN_URL, EncodeSessionIdToBase64_URL } from "../../Constants";
import { loginActions } from "../../store/login-slice";
import Modal from "../UI/Modal";
import classes from "./Account.module.css";
import useInput from "../../hooks/use-input";
import Loader from "../UI/Loader";
import SharedService from "../../Helper/SharedService";
import { ForgotPassword } from "./ForgotPassword";
import Registration from "./Registration";

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => value.includes("@");

const Login = (props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [isLogginProcessed, setIsLogginProcessed] = useState(false);
  const getTheme = useSelector((state) => state.layout.theme);
  const dispatch = useDispatch();
  let history = useHistory();
  const [isRegistrationForm, setIsRegistrationForm] = useState(false);
  const [isForgotPasswordForm, setIsForgotPasswordForm] = useState(false);
  const [formTitle, setFormTitle] = useState("Login");

  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
  } = useInput(isNotEmpty);
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail);

  let formIsValid = false;

  if (passwordIsValid && emailIsValid) {
    formIsValid = true;
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLogginProcessed(true);
    if (!formIsValid) {
      emailBlurHandler("");
      passwordBlurHandler("");
      setIsLogginProcessed(false);
      return;
    }

    const loginRes = await fetch(LOGIN_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ Username: emailValue, Password: passwordValue }),
    });

    if (loginRes.ok) {
      const response = await loginRes.json();
      let getResult = response.replace(/"/g, "");
      var res = getResult.toString().indexOf("!@#");
      if (res != -1) {
        var array = getResult.split("!@#");
        var p1 = array[0].replace(/"/g, "");
        if (p1.indexOf("Logged in Successfully") > -1) {
          const EncodedSessionId = array[1].replace('"', "");
          await EncodeSessionIdToBase64(EncodedSessionId);
          setIsLogginProcessed(false);
        } else if (p1 == "Invalid Login") {
          setErrorMsg(response);
          return false;
        }
      } else {
        setErrorMsg(response);
        return false;
      }
    } else {
      console.log("error");
      if (!loginRes.ok) setErrorMsg(await loginRes.text());
    }
  };

  const EncodeSessionIdToBase64 = async (encodedSessionId) => {
    const getSession = await fetch(EncodeSessionIdToBase64_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ sessionId: encodedSessionId }),
    });

    if (getSession.ok) {
      const sessionData = await getSession.json();
      const sessionID = sessionData.replace(/"/g, "");

      //set sessionid local storage
      SharedService.setLocalStorageSessionId(sessionID);
      dispatch(loginActions.login(sessionID));
      const url = "/" + sessionID;
      history.replace(url);
      props.onCloseLoginHandler();
    }

    if (!getSession.ok) setErrorMsg(await getSession.text());
  };

  const passwordClasses = passwordHasError ? "required-error" : "";

  const emailClasses = emailHasError ? "required-error" : "";

  // if (props.registrationMsg) {
  //   setFormTitle("Register");
  // }

  return (
    <Modal
      title={props.registrationMsg ? "Register" : formTitle}
      onClose={props.onCloseLoginHandler}
    >
      {!isForgotPasswordForm && !isRegistrationForm && (
        <div>
          {!errorMsg && !props.registrationMsg && (
            <form
              onSubmit={submitHandler}
              id="settings-form"
              className="settings"
              name="loginForm"
            >
              <p htmlFor="logemail">Email</p>
              <input
                className={emailClasses}
                type="email"
                id="logemail"
                name="logemail"
                value={emailValue}
                onBlur={emailBlurHandler}
                onChange={emailChangeHandler}
              />
              {emailHasError && <span className="error">Required</span>}
              <br />
              <p htmlFor="logpassword">Password</p>
              <input
                className={passwordClasses}
                type="password"
                id="logpassword"
                name="logpassword"
                value={passwordValue}
                onBlur={passwordBlurHandler}
                onChange={passwordChangeHandler}
              />
              {passwordHasError && <span className="error">Required</span>}
              <br />
              <div style={{ paddingTop: "15px" }}>
                <button
                  className={`dx-widget dx-button-has-text dx-button dx-button-normal ${
                    getTheme !== "Light" ? "button-dark" : ""
                  } ${classes["custom-width"]}`}
                  type="submit"
                >
                  Login
                </button>
                <br />
                <br />
                <a
                  id="lnkReg"
                  className={classes["linkClass"]}
                  href=""
                  onClick={(event) => {
                    event.preventDefault();
                    setIsRegistrationForm(true);
                    setFormTitle("Register");
                  }}
                >
                  New user? (you can register here)
                </a>
                <br />
                <a
                  id="lnkFrgPass"
                  className={classes["linkClass"]}
                  href=""
                  onClick={(event) => {
                    event.preventDefault();
                    setIsForgotPasswordForm(true);
                    setFormTitle("Forget Password");
                  }}
                >
                  Forgot password? (reset it here)
                </a>
                <br />
              </div>
              {isLogginProcessed && (
                <Loader styles={{ height: "80px", marginTop: "-260px" }} />
              )}
            </form>
          )}
          {errorMsg && !props.registrationMsg && (
            <div>
              <div style={{ padding: "5px" }}>{errorMsg}</div>
              <div style={{ paddingTop: "15px", textAlign: "right" }}>
                <button
                  type="submit"
                  className={`dx-widget dx-button-has-text dx-button dx-button-normal button ${classes["custom-width"]}`}
                  onClick={props.onCloseLoginHandler}
                >
                  Ok
                </button>
              </div>
            </div>
          )}

          {props.registrationMsg && (
            <div>
              <div style={{ padding: "5px" }}>{props.registrationMsg}</div>
              <div style={{ paddingTop: "15px", textAlign: "right" }}>
                <button
                  type="submit"
                  className={`dx-widget dx-button-has-text dx-button dx-button-normal button ${classes["custom-width"]}`}
                  onClick={() => {
                    props.onCloseLoginHandler();
                    const url = "/";
                    history.replace(url);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {isForgotPasswordForm && (
        <ForgotPassword onCloseLoginHandler={props.onCloseLoginHandler} />
      )}

      {isRegistrationForm && (
        <Registration onCloseLoginHandler={props.onCloseLoginHandler} />
      )}
    </Modal>
  );
};

export default Login;
