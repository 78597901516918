import { useSelector } from "react-redux";
import {
  GetConnectionList,
  GetDataSourcesAndFilters,
  GetLastRefreshDateTime,
  GetSalesWidgetData,
  GetWidgetSettings,
} from "../../../../Helper/Services";
import React, { useEffect, useState } from "react";
import GetActivityData, {
  FinalSAWJsonObject,
  GetCapacityData,
  GetPCTData,
  GetStageData,
} from "./SalesActivityService";
import SalesActivityD3Component from "./SalesActivityD3Component";
import NoShowDiv from "../../../UI/NoShowTemplate";
import { IsSharedFilterAlert } from "../../../../Helper/WidgetDataCommon";
import { SAWRefreshTime } from "../../../../Constants";

const SalesActivityWidget = (props) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const email = useSelector((state) => state.login.userDetail.email);
  const sharedEmail = useSelector((state) => state.layout.SharedUserEmail);
  const isSharedPage = useSelector((state) => state.layout.IsSharedPage);
  const [isConnectionFound, setIsConnectionFound] = useState(true);
  const [widgetData, setWidgetData] = useState({});
  const [screenMsg, setScreenMsg] = useState("No Data");
  const [showNoData, setShowNoData] = useState(false);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const getWidgetSetting = () => {
      let isSharedFilter = false;
      let sharedFilterUserId = "";
      let filterId = "";

      GetWidgetSettings(props.widgetId)
        .then((res) => {
          let datasourceName = res["reports"];
          var filter = null;
          if (res["Filter"] !== undefined && res["Filter"] !== "") {
            filter = res["Filter"];
          }

          if (
            res["IsSharedFilter"] !== undefined &&
            res["IsSharedFilter"] === "true"
          ) {
            isSharedFilter = true;
            sharedFilterUserId = res["OriginalUserId"];
            filterId = res["FilterId"];
          }

          GetDataSourcesAndFilters(email, sessionId)
            .then((datasource) => {
              GetConnectionList(
                datasourceName,
                datasource["EncodeEmail"],
                sessionId
              )
                .then((connections) => {
                  let connectionList = JSON.parse(connections);
                  if (connectionList.length > 1) {
                    //activity data call
                    GetSalesWidgetData(
                      filter,
                      datasourceName,
                      sessionId,
                      isSharedPage,
                      sharedEmail,
                      isSharedFilter,
                      sharedFilterUserId,
                      filterId,
                      "activity"
                    )
                      .then((activityData) => {
                        GetLastRefreshDateTime(res["reports"], "", sessionId)
                          .then((lstReponseDt) => {
                            props.onGetNotification(lstReponseDt);
                            if (IsSharedFilterAlert(activityData)) {
                              setScreenMsg(activityData);
                              setShowNoData(true);
                              return false;
                            }

                            const activity_dt = GetActivityData(activityData);
                            //stage data call
                            GetSalesWidgetData(
                              filter,
                              datasourceName,
                              sessionId,
                              isSharedPage,
                              sharedEmail,
                              isSharedFilter,
                              sharedFilterUserId,
                              filterId,
                              "stage"
                            )
                              .then((stageData) => {
                                const stage_dt = GetStageData(stageData);

                                //capacity data call
                                GetSalesWidgetData(
                                  filter,
                                  datasourceName,
                                  sessionId,
                                  isSharedPage,
                                  sharedEmail,
                                  isSharedFilter,
                                  sharedFilterUserId,
                                  filterId,
                                  "capacity"
                                )
                                  .then((capData) => {
                                    const capacity_Dt =
                                      GetCapacityData(capData);

                                    //pct data call
                                    GetSalesWidgetData(
                                      filter,
                                      datasourceName,
                                      sessionId,
                                      isSharedPage,
                                      sharedEmail,
                                      isSharedFilter,
                                      sharedFilterUserId,
                                      filterId,
                                      "pct"
                                    )
                                      .then((pctData) => {
                                        const pct_dt = GetPCTData(
                                          pctData,
                                          activity_dt.startDateTime,
                                          activity_dt.activity
                                        );

                                        const final_dt = FinalSAWJsonObject(
                                          activity_dt,
                                          capacity_Dt,
                                          stage_dt,
                                          pct_dt
                                        );
                                        setWidgetData(final_dt);
                                      })
                                      .catch((err) => {
                                        console.log("pct data call", err);
                                      });
                                  })
                                  .catch((err) => {
                                    console.log("capacity data call:-", err);
                                  });
                              })
                              .catch((err) => {
                                console.log("stage data call:-", err);
                              });
                          })
                          .catch((err) => {
                            console.log("GetLastRefreshDateTime:-", err);
                          });
                      })
                      .catch((err) => {
                        console.log("GetConnectionList:-", err);
                      });
                  } else {
                    setIsConnectionFound(false);
                  }
                })
                .catch((err) => {
                  console.log("GetConnectionList:-", err);
                });
            })
            .catch((err) => {
              console.log("GetDataSourcesAndFilters:-", err);
            });
        })
        .catch((err) => {});
    };

    if (sessionId !== "" && sessionId != null) {
      getWidgetSetting();
      GetWidgetSize();
    }

    window.addEventListener("resize", () => {
      GetWidgetSize();
    });

    const refreshWidget = setInterval(() => {
      console.log("SAW set Timout..", new Date().toLocaleTimeString());
      getWidgetSetting();
    }, SAWRefreshTime);

    return () => {
      //clear setinterval on unmount component
      clearInterval(refreshWidget);
    };
  }, [sessionId, props.RefreshVersion, email]);

  const GetWidgetSize = () => {
    const getParentContainer = document.getElementById(
      "content_" + props.widgetId
    );
    if (getParentContainer !== undefined && getParentContainer != null) {
      const getHeight = getParentContainer.clientHeight;
      const getWidth = getParentContainer.clientWidth;
      setHeight(getHeight);
      setWidth(getWidth);
    }
  };

  return (
    <React.Fragment>
      {showNoData === false && (
        <div>
          {!isConnectionFound && "No Connection found for datasource."}
          {isConnectionFound && height > 0 && (
            <SalesActivityD3Component
              widgetId={props.widgetId}
              widgetDt={widgetData}
              height={height}
              width={width}
              getWidgetSize={GetWidgetSize}
              wid={props.wid}
            />
          )}
        </div>
      )}
      {showNoData === true && <NoShowDiv screenMsg={screenMsg} />}
    </React.Fragment>
  );
};

export default SalesActivityWidget;
