export const filterDetail = {
  issharedfilter: false,
  filterid: "",
  originaluserid: "",
  filterValue: "null",
};

export const DataSectionObject = {
  dataSourceName: "",
  connectionName: "",
  filterName: "",
  filterDetails: filterDetail,
};

export const PieContentSection = {
  isValid: true,
  data: {
    category: "",
    values: "",
    operation: "",
    callout: "",
  },
};
