import { useState, React } from "react";
import { ReplaceTextToSpecialChar } from "../../Helper/WidgetDataCommon";
import WidgetButtonControl from "../UI/Buttons/WidgetButton";
import FilterServices from "./FilterServices";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../store/layout-slice";
import Loader from "../UI/Loader";

const CloneFilter = (props) => {
  const dispatch = useDispatch();
  const [filterId, setFilterId] = useState("0");
  const [filterName, setFilterName] = useState("");
  const [filterSelected, setFilterSelected] = useState(false);
  const [enableLoader, setEnableLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const saveHandler = () => {
    if (filterId === "0") {
      setFilterSelected(true);
      setTimeout(() => {
        setFilterSelected(false);
      }, 3000);
      return false;
    }

    if (filterName.length === 0) {
      displayError("Please Enter Filter Name");
      return false;
    }

    if (filterName.length > 0) {
      var getSpecialCharacterMatched =
        FilterServices.matchSpecialaCharcter(filterName);
      if (getSpecialCharacterMatched) {
        displayError("Allow only following special character &!_-%");
        return false;
      }

      setEnableLoader(true);

      FilterServices.cloneFilter(
        props.dataSourceName,
        filterId,
        filterName,
        props.email,
        props.sessionId
      )
        .then((data) => {
          if (
            data === "Special characters not allowed in Filter name" ||
            data === "Filter Already Exist" ||
            data === "Unsuccess"
          ) {
            displayError(data);
            setEnableLoader(false);
            return false;
          } else {
            dispatch(
              layoutActions.setAddUpdateFilter({
                filterName: filterName,
                filterId: filterId,
                isSharedfilter: "",
                userId: "",
              })
            );
            setEnableLoader(false);
            props.onCloseHandler();
          }
        })
        .catch((err) => {
          console.log("cloneFilter", err);
        });
    } else {
      displayError("Please Enter Filter Name");
    }
  };

  const filterChangeHandler = (e) => {
    const filterValue = e.target.value;
    setFilterId(filterValue);
  };

  const onFilterNameChangeHandler = (event) => {
    setFilterName(event.target.value);
  };

  const displayError = (message) => {
    setErrorMsg(message);
    setTimeout(() => {
      setErrorMsg("");
    }, 3000);
  };

  return (
    <div>
      <div style={{ display: enableLoader ? "none" : "" }}>
        <p>Select Filter For Clone</p>
        <select onChange={filterChangeHandler} defaultValue={filterId}>
          <option value="0">Select Filter</option>
          {props.filterList.map((item) => {
            const displayName = ReplaceTextToSpecialChar(
              item.displayName
            ).replace(/ /g, "\u00A0");
            if (item.isOwnFilter === true || item.isSharedFilter === false) {
              return (
                <option
                  value={item.filterId}
                  data-filterid={item.filterId}
                  key={item.filterId}
                >
                  {displayName}
                </option>
              );
            }
            return "";
          })}
        </select>
        <br />
        {filterSelected && (
          <span style={{ color: "red" }}>Please select filter</span>
        )}
        <div style={{ width: "250px" }}>
          <p>Filter Name</p>
          <input
            type="text"
            style={{
              width: "300px",
              border: "1px solid #cfcfcf",
              padding: "3px",
            }}
            value={filterName}
            onChange={onFilterNameChangeHandler}
          />
          {errorMsg && <span style={{ color: "red" }}>{errorMsg}</span>}
        </div>
        <div style={{ paddingTop: "15px" }}>
          <WidgetButtonControl
            title="Clone Filter"
            cssStyle={{ width: "85px" }}
            onClick={saveHandler}
          />
          <WidgetButtonControl
            title="Close"
            cssStyle={{ marginLeft: "3px" }}
            onClick={props.onCloseHandler}
          />
        </div>
      </div>
      {enableLoader && <Loader styles={{ height: "80px" }} />}
    </div>
  );
};

export default CloneFilter;
