import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "../UI/Modal";
import { layoutActions } from "../../store/layout-slice";
import { useSelector } from "react-redux";
import { useState } from "react";
import AccountService from "./AccountService";
import { LOGOUT_URL } from "../../Constants";
import SharedService from "../../Helper/SharedService";
import { loginActions } from "../../store/login-slice";

const message =
  "You have successfully reset your password. Please log in now, with this new password.";

export const ChangePassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getTheme = useSelector((state) => state.layout.theme);
  const email = useSelector((state) => state.login.userDetail.email);
  const sessionId = useSelector((state) => state.login.sessionId);
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordErr, setOldPasswordErr] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErr, setNewPasswordErr] = useState("");
  const [cnfPassword, setcnfPassword] = useState("");
  const [cnfPasswordErr, setcnfPasswordErr] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const closeModal = async () => {
    dispatch(layoutActions.setChangePasswordPopup());
  };

  const OnSuccess = async (e) => {
    dispatch(layoutActions.setChangePasswordPopup());
    if (responseMessage === message) {
      const resp = await fetch(`${LOGOUT_URL}/${email}/${sessionId}`);
      if (resp.ok) {
        SharedService.removeLocalStorageSessionId(sessionId);
      } else if (!resp.ok) {
        SharedService.removeLocalStorageSessionId(sessionId);
      }
      setTimeout(() => {
        dispatch(loginActions.logOut());
      }, 350);
      history.replace("/");
      props.onShowLoginHandler(e);
    }
  };

  const onformSubmit = () => {
    if (oldPassword === "") {
      setOldPasswordErr("Required");
      setTimeout(() => {
        setOldPasswordErr("");
      }, 3000);

      return false;
    }

    if (newPassword === "") {
      setNewPasswordErr("Required");
      setTimeout(() => {
        setNewPasswordErr("");
      }, 3000);

      return false;
    }

    var format = /(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])/;
    if (!format.test(newPassword)) {
      setNewPasswordErr(
        "Passwords must be between 8 and 20 characters.Must contain one lower & uppercase letter, and one non-alpha character (a number or a symbol.)"
      );
      setTimeout(() => {
        setNewPasswordErr("");
      }, 3000);

      return false;
    }

    if (cnfPassword === "") {
      setcnfPasswordErr("Please confirm your password.");
      setTimeout(() => {
        setcnfPasswordErr("");
      }, 3000);

      return false;
    }

    if (cnfPassword !== newPassword) {
      setcnfPasswordErr("Passwords do not match.");
      setTimeout(() => {
        setcnfPasswordErr("");
      }, 3000);

      return false;
    }

    AccountService.changePassword(email, oldPassword, newPassword)
      .then((res) => {
        const message = res.replace(/"/g, "");
        setResponseMessage(message);
      })
      .catch((err) => {
        const message = err.replace(/"/g, "");
        setResponseMessage(message);
      });
  };

  return (
    <Modal title="Change Password" onClose={closeModal}>
      {responseMessage && (
        <div>
          <div style={{ padding: "5px" }}>{responseMessage}</div>
          <div style={{ paddingTop: "15px", textAlign: "right" }}>
            <button
              className={`dx-widget dx-button-has-text dx-button dx-button-normal ${
                getTheme !== "Light" ? "button-dark" : ""
              }`}
              type="button"
              onClick={OnSuccess}
            >
              Ok
            </button>
          </div>
        </div>
      )}
      {!responseMessage && (
        <form id="settings-form" className="settings" name="cngPassForm">
          <p htmlFor="Oldpassword">Old Password</p>
          <input
            type="password"
            minLength="8"
            maxLength="20"
            name="Oldpassword"
            autoComplete="false"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          {oldPasswordErr && (
            <span style={{ color: "red" }}>{oldPasswordErr}</span>
          )}
          <br />
          <p htmlFor="cngPasspassword1">New Password</p>
          <input
            type="password"
            minLength="8"
            maxLength="20"
            name="cngPasspassword1"
            autoComplete="false"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {newPasswordErr && (
            <span style={{ color: "red" }}>{newPasswordErr}</span>
          )}
          <br />
          <p htmlFor="cngPasspassword_c1">Confirm Password</p>
          <input
            type="password"
            minLength="8"
            maxLength="20"
            name="cngPasspassword_c1"
            autoComplete="false"
            value={cnfPassword}
            onChange={(e) => setcnfPassword(e.target.value)}
          />
          {cnfPasswordErr && (
            <span style={{ color: "red", display: "block" }}>
              {cnfPasswordErr}
            </span>
          )}

          <div style={{ paddingTop: "15px" }}>
            <button
              className={`dx-widget dx-button-has-text dx-button dx-button-normal ${
                getTheme !== "Light" ? "button-dark" : ""
              }`}
              type="button"
              onClick={onformSubmit}
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </Modal>
  );
};
