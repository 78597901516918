import React from "react";
export const EditButton = ({ isDisable, editHandler }) => {
  let stylesCss = { fontSize: "18px", color: "gray", textDecoration: "none" };
  if (isDisable) {
    stylesCss = {
      fontSize: "18px",
      color: "gray",
      textDecoration: "none",
      cursor: "not-allowed",
    };
  }
  return (
    <a
      href=""
      className="icons_cls"
      title="Edit"
      style={stylesCss}
      onClick={editHandler}
    >
      <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
    </a>
  );
};
