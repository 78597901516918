import $ from "jquery";
import * as d3 from "d3";
import { useEffect, useRef, React } from "react";
import { useSelector } from "react-redux";

const drawSeparator = (svg, w, h, theme) => {
  //var color = "#7F7F7F"; // dark color
  var color = "#cfcfcf"; // light color for separator
  if (theme == "Dark") {
    color = "#7F7F7F";
  }

  svg
    .append("line")
    .attr("class", "separator1")
    .attr("x1", 0)
    .attr("y1", h * 0.7)
    .attr("x2", w)
    .attr("y2", h * 0.7)
    .attr("stroke", color)
    .attr("stroke-width", 5);
  svg
    .append("line")
    .attr("class", "separator2")
    .attr("x1", w / 3)
    .attr("y1", h * 0.7)
    .attr("x2", w / 3)
    .attr("y2", h)
    .attr("stroke", color)
    .attr("stroke-width", 5);
  svg
    .append("line")
    .attr("class", "separator3")
    .attr("x1", (w * 2) / 3)
    .attr("y1", h * 0.7)
    .attr("x2", (w * 2) / 3)
    .attr("y2", h)
    .attr("stroke", color)
    .attr("stroke-width", 5);
};

const updateSeparator = (svg, w, h) => {
  svg
    .select(".separator1")
    .attr("x1", 0)
    .attr("y1", h * 0.7)
    .attr("x2", w)
    .attr("y2", h * 0.7);
  svg
    .select(".separator2")
    .attr("x1", w / 3)
    .attr("y1", h * 0.7)
    .attr("x2", w / 3)
    .attr("y2", h);
  svg
    .select(".separator3")
    .attr("x1", (w * 2) / 3)
    .attr("y1", h * 0.7)
    .attr("x2", (w * 2) / 3)
    .attr("y2", h);
};

const drawChart = (data, svg, w, h, theme) => {
  var fieldNames = ["id", "bigBar", "tinyBar", "dot"];
  //var fieldNames = ["id", "AvgOpenedCount", "AvgClosedCount", "Avgpercentage"];

  var outerWidth = (w * 41) / 40;
  var outerHeight = h * 0.14 - 10;

  var bigBarPadding = 0.1;
  var tinyBarPadding = 0.6;
  var dotPadding = 0.6;

  var margin = { left: 0, top: 16, right: 0, bottom: 0 };
  var innerWidth = outerWidth - margin.left - margin.right;
  var innerHeight = outerHeight - margin.top - margin.bottom;
  var height = h * 0.56 + 8;

  //var color = "#7F7F7F"; // dark color
  var color = "#f2f2f2"; // light color for separator
  if (theme == "Dark") {
    color = "#7F7F7F";
  }

  var chart_back = svg
    .append("rect")
    .attr("transform", "translate(0," + h * 0.56 + ")")
    .attr("class", "chart_back")
    .attr("width", w)
    .attr("height", outerHeight)
    .attr("fill", color);

  var chart = svg
    .append("g")
    .attr("transform", "translate(-" + w / 80 + "," + height + ")")
    .attr("class", "chart");

  var bigBarScale = d3
    .scaleBand()
    .range([0, innerWidth])
    .paddingInner(bigBarPadding)
    .paddingOuter(0.1);
  var tinyBarScale = d3
    .scaleBand()
    .range([0, innerWidth])
    .paddingInner(tinyBarPadding)
    .paddingOuter(0.4);
  var dotScale = d3.scalePoint().range([0, innerWidth]).padding(dotPadding);
  var yScale = d3.scaleLinear().range([innerHeight, 0]);

  bigBarScale.domain([0, 1, 2, 3]);
  tinyBarScale.domain([0, 1, 2, 3]);
  dotScale.domain([0, 1, 2, 3]);
  yScale.domain([0, 100]);

  var bigBars = chart
    .selectAll(".bigBars")
    .data(data)
    .enter()
    .append("rect")
    .attr("class", "bigBars")
    .attr("fill", "#DDD6E5")
    .attr("x", function (d) {
      return bigBarScale(d[fieldNames[0]]);
    })
    .attr("y", function (d) {
      return yScale(d[fieldNames[1]]);
    })
    // --- updated start ---
    .attr("width", bigBarScale.bandwidth())
    // --- updated end ---
    .attr("height", function (d) {
      return innerHeight - yScale(d[fieldNames[1]]);
    });

  var tinyBars = chart
    .selectAll(".tinyBars")
    .data(data)
    .enter()
    .append("rect")
    .attr("class", "tinyBars")
    .attr("fill", "#B7DDE8")
    .attr("x", function (d) {
      return tinyBarScale(d[fieldNames[0]]);
    })
    .attr("y", function (d) {
      return yScale(d[fieldNames[2]]);
    })
    // --- updated start ---
    .attr("width", tinyBarScale.bandwidth())
    // --- updated end ---
    .attr("height", function (d) {
      return innerHeight - yScale(d[fieldNames[2]]);
    });

  let line = d3
    .line()
    .x(function (d) {
      return dotScale(d[fieldNames[0]]);
    })
    .y(function (d) {
      return yScale(d[fieldNames[3]]);
    });

  var path = chart
    .append("path")
    .attr("class", "path")
    .attr("d", line(data))
    .attr("fill", "none")
    .attr("stroke", "#F59D56")
    .attr("stroke-width", "2px");

  var dots = chart
    .selectAll(".dot")
    .data(data)
    .enter()
    .append("circle")
    .attr("class", "dot")
    .attr("r", 6)
    .attr("fill", "#F59D56")
    .attr("cx", function (d) {
      return dotScale(d[fieldNames[0]]);
    })
    .attr("cy", function (d) {
      return yScale(d[fieldNames[3]]);
    });
};

const updateChart = (svg, w, h) => {
  var data = [];
  var fieldNames = ["id", "bigBar", "tinyBar", "dot"];
  var outerWidth = (w * 41) / 40;
  var outerHeight = h * 0.14 - 10;
  var bigBarPadding = 0.1;
  var tinyBarPadding = 0.6;
  var dotPadding = 0.6;
  var margin = { left: 0, top: 16, right: 0, bottom: 0 };
  var innerWidth = outerWidth - margin.left - margin.right;
  var innerHeight = outerHeight - margin.top - margin.bottom;
  var height = h * 0.56 + 8;
  var chart_back = svg
    .select(".chart_back")
    .attr("transform", "translate(0," + h * 0.56 + ")")
    .attr("width", w)
    .attr("height", outerHeight);

  var chart = svg
    .select(".chart")
    .attr("transform", "translate(-" + w / 80 + "," + height + ")");
  var bigBarScale = d3
    .scaleBand()
    .range([0, innerWidth])
    .paddingInner(bigBarPadding)
    .paddingOuter(0.1);
  var tinyBarScale = d3
    .scaleBand()
    .range([0, innerWidth])
    .paddingInner(tinyBarPadding)
    .paddingOuter(0.4);
  var dotScale = d3.scalePoint().range([0, innerWidth]).padding(dotPadding);
  var yScale = d3.scaleLinear().range([innerHeight, 0]);

  bigBarScale.domain([0, 1, 2, 3]);
  tinyBarScale.domain([0, 1, 2, 3]);
  dotScale.domain([0, 1, 2, 3]);
  yScale.domain([0, 100]);

  var bigBars = chart.selectAll(".bigBars"); //.data(data);
  bigBars
    .attr("x", function (d) {
      data.push(d);
      return bigBarScale(d[fieldNames[0]]);
    })
    .attr("y", function (d) {
      return yScale(d[fieldNames[1]]);
    })
    .attr("width", bigBarScale.bandwidth())
    .attr("height", function (d) {
      return innerHeight - yScale(d[fieldNames[1]]);
    });

  var tinyBars = chart.selectAll(".tinyBars"); //.data(data);
  tinyBars
    .attr("x", function (d) {
      return tinyBarScale(d[fieldNames[0]]);
    })
    .attr("y", function (d) {
      return yScale(d[fieldNames[2]]);
    })
    .attr("width", tinyBarScale.bandwidth())
    .attr("height", function (d) {
      return innerHeight - yScale(d[fieldNames[2]]);
    });

  var path = svg.selectAll(".path");
  var line = d3
    .line()
    .x(function (d) {
      return dotScale(d[fieldNames[0]]);
    })
    .y(function (d) {
      return yScale(d[fieldNames[3]]);
    });
  path.attr("d", line(data));

  var dots = chart.selectAll(".dot"); //.data(data);
  dots
    .attr("cx", function (d) {
      return dotScale(d[fieldNames[0]]);
    })
    .attr("cy", function (d) {
      return yScale(d[fieldNames[3]]);
    });
};

const drawBigbox = (data, svg, w, h) => {
  var bigbox = svg.append("g");

  var bigbox_title = bigbox.append("text").attr("class", "bigbox_title");
  bigbox_title
    .attr("x", 10)
    .attr("y", 26)
    .attr("font-size", "14px")
    .attr("fill", "#7f7f7f")
    .text(data.title);

  var bigbox_open = bigbox.append("text").attr("class", "bigbox_open");
  bigbox_open
    .attr("x", w / 2 - 4)
    .attr("y", h * 0.56 - 14)
    .attr("font-size", "14px")
    .attr("fill", "#ccc5d4")
    .attr("text-anchor", "end")
    .attr("style", "font-weight:bold")
    .text("Opened: " + data.open);

  var bigbox_closed = bigbox.append("text").attr("class", "bigbox_closed");
  bigbox_closed
    .attr("x", w / 2 + 4)
    .attr("y", h * 0.56 - 14)
    .attr("font-size", "14px")
    .attr("fill", "#a6ccd7")
    .attr("style", "font-weight:bold")
    .text("Closed: " + data.closed);

  var bigbox_split = bigbox
    .append("text")
    .attr("class", "bigbox_split")
    .attr("x", w / 2 - 3)
    .attr("y", h * 0.56 - 14)
    .attr("font-size", "14px")
    .attr("fill", "#888")
    .attr("text-anchor", "center")
    .text("|");

  var bigbox_percent = bigbox
    .append("g")
    .attr("class", "bigbox_percent")
    .attr("transform", "translate(0," + (h * 0.56 - 40) * 0.96 + ")");
  var max_w = w;
  var text_h = (h * 0.56 - 80) * 1.3;
  var bigbox_percent_text = bigbox_percent
    .append("text")
    .attr("x", w / 2)
    .attr("font-size", text_h + "px")
    .attr("fill", "#7f7f7f")
    .attr("text-anchor", "middle")
    .text(data.percent + "%");
  var bbox = bigbox_percent.node().getBBox();
  if (bbox.width > max_w) {
    var text_h2 = (max_w * text_h) / bbox.width;
    bigbox_percent_text.attr("font-size", text_h2 + "px");
  }
};

const updateBigbox = (svg, w, h) => {
  svg.select(".bigbox_title").attr("x", 10).attr("y", 26);
  svg
    .select(".bigbox_open")
    .attr("x", w / 2 - 4)
    .attr("y", h * 0.56 - 14);
  svg
    .select(".bigbox_split")
    .attr("x", w / 2 - 3)
    .attr("y", h * 0.56 - 14);
  svg
    .select(".bigbox_closed")
    .attr("x", w / 2 + 4)
    .attr("y", h * 0.56 - 14);

  var bigbox_percent = svg.select(".bigbox_percent");
  bigbox_percent.attr(
    "transform",
    "translate(0," + (h * 0.56 - 40) * 0.96 + ")"
  );

  var bigbox_percent_text = bigbox_percent.select("text");
  var max_w = w;
  var text_h = (h * 0.56 - 80) * 1.3;
  bigbox_percent_text.attr("x", w / 2).attr("font-size", text_h + "px");
  var bbox = bigbox_percent_text.node().getBBox();

  if (bbox.width > max_w) {
    var text_h2 = (max_w * text_h) / bbox.width;
    bigbox_percent_text.attr("font-size", text_h2 + "px");
  }
};

const drawSmallbox = (data, svg, w, h) => {
  var max = 0,
    max_index = 0;
  $.each(data, function (i, d) {
    if (d.percent > max) {
      max = d.percent;
      max_index = i;
    }
  });
  var smallbox = svg
    .selectAll(".smallbox")
    .data(data)
    .enter()
    .append("g")
    .attr("class", "smallbox");
  smallbox
    .append("text")
    .attr("class", "smallbox_title")
    .attr("x", function (d, i) {
      return (w * i) / 3 + 10;
    })
    .attr("y", function (d) {
      return h * 0.7 + 20;
    })
    .attr("font-size", "14px")
    .attr("fill", "#7f7f7f")
    .text(function (d) {
      return d.title;
    });

  var smallbox_open = smallbox
    .append("text")
    .attr("class", "smallbox_open")
    .attr("x", function (d, i) {
      return (w * (1 + 2 * i)) / 6 - 4;
    })
    .attr("y", function (d) {
      return h - 10;
    })
    .attr("font-size", "14px")
    .attr("text-anchor", "end")
    .attr("fill", "#ccc5d4")
    .attr("style", "font-weight:bold")
    .text(function (d) {
      return "Opened: " + d.open;
    });
  var smallbox_closed = smallbox
    .append("text")
    .attr("class", "smallbox_closed")
    .attr("x", function (d, i) {
      return (w * (1 + 2 * i)) / 6 + 8;
    })
    .attr("y", function (d) {
      return h - 10;
    })
    .attr("font-size", "14px")
    .attr("fill", "#a6ccd7")
    .attr("style", "font-weight:bold")
    .text(function (d) {
      return "Closed: " + d.closed;
    });
  var smallbox_split = smallbox
    .append("text")
    .attr("class", "smallbox_split")
    .attr("x", function (d, i) {
      return (w * (1 + 2 * i)) / 6;
    })
    .attr("y", function (d) {
      return h - 10;
    })
    .attr("font-size", "14px")
    .attr("fill", "#888")
    .attr("text-anchor", "center")
    .text("|");
  if (w < 600) {
    smallbox_open.text(function (d) {
      return d.open;
    });
    smallbox_closed.text(function (d) {
      return d.closed;
    });
  }

  var smbox_percent = smallbox
    .append("g")
    .attr("class", "smbox_percent")
    .attr("transform", "translate(0," + (h - 30 - (h * 0.3 - 60) * 0.05) + ")");
  var smbox_percent_text = smbox_percent.append("text");

  var max_w = w / 3 - 20;
  var text_h = (h * 0.3 - 60) * 1.3;
  smbox_percent_text
    .attr("x", function (d, i) {
      return (w * (1 + 2 * i)) / 6;
    })
    .attr("font-size", text_h + "px")
    .attr("fill", "#7f7f7f")
    .attr("text-anchor", "middle")
    .text(function (d) {
      return d.percent + "%";
    });
  var max_box = smbox_percent_text.filter(function (d, i) {
    return i === max_index;
  });
  var bbox = max_box.node().getBBox();
  if (bbox.width > max_w) {
    var text_h2 = (max_w * text_h) / bbox.width;
    smbox_percent_text.attr("font-size", text_h2 + "px");
  }
};

const updateSmallbox = (svg, w, h) => {
  var data = [];

  var smallbox = svg.selectAll(".smallbox");
  smallbox
    .select(".smallbox_title")
    .attr("x", function (d, i) {
      data.push(d);
      return (w * i) / 3 + 10;
    })
    .attr("y", function (d) {
      return h * 0.7 + 20;
    });
  smallbox
    .select(".smallbox_open")
    .attr("text-anchor", "end")
    .attr("x", function (d, i) {
      return (w * (1 + 2 * i)) / 6 - 4;
    })
    .attr("y", function (d) {
      return h - 10;
    })
    .text(function (d) {
      return "Opened: " + d.open;
    });
  smallbox
    .select(".smallbox_closed")
    .attr("text-anchor", "start")
    .attr("x", function (d, i) {
      return (w * (1 + 2 * i)) / 6 + 8;
    })
    .attr("y", function (d) {
      return h - 10;
    })
    .text(function (d) {
      return "Closed: " + d.closed;
    });
  smallbox
    .select(".smallbox_split")
    .attr("x", function (d, i) {
      return (w * (1 + 2 * i)) / 6;
    })
    .attr("y", function (d) {
      return h - 10;
    });
  if (w < 600) {
    smallbox
      .select(".smallbox_open")
      .attr("x", function (d, i) {
        return (w * (1 + 2 * i)) / 6 - 4;
      })
      .text(function (d) {
        return d.open;
      });
    smallbox
      .select(".smallbox_closed")
      .attr("x", function (d, i) {
        return (w * (1 + 2 * i)) / 6 + 8;
      })
      .text(function (d) {
        return d.closed;
      });
  }
  var smbox_percent = smallbox
    .select(".smbox_percent")
    .attr("transform", "translate(0," + (h - 30 - (h * 0.3 - 60) * 0.05) + ")");
  var smbox_percent_text = smbox_percent.select("text");
  var max_w = w / 3 - 20;
  var text_h = (h * 0.3 - 60) * 1.3;

  smbox_percent_text
    .attr("x", function (d, i) {
      return (w * (1 + 2 * i)) / 6;
    })
    .attr("font-size", text_h + "px");

  var max = 0,
    max_index = 0;
  $.each(data, function (i, d) {
    if (d.percent > max) {
      max = d.percent;
      max_index = i;
    }
  });
  var max_box = smbox_percent_text.filter(function (d, i) {
    return i === max_index;
  });
  var bbox = max_box.node().getBBox();
  if (bbox.width > max_w) {
    var text_h2 = (max_w * text_h) / bbox.width;
    smbox_percent_text.attr("font-size", text_h2 + "px");
  }
};

const drawHelper = (svg, w, h) => {
  svg
    .append("line")
    .attr("class", "helper1")
    .attr("x1", 0)
    .attr("y1", h * 0.56)
    .attr("x2", w)
    .attr("y2", h * 0.56)
    .attr("stroke", "#00f")
    .style("stroke-dasharray", "5, 1")
    .attr("stroke-width", 1);
  svg
    .append("line")
    .attr("class", "helper2")
    .attr("x1", 0)
    .attr("y1", 40)
    .attr("x2", w)
    .attr("y2", 40)
    .attr("stroke", "#00f")
    .style("stroke-dasharray", "5, 1")
    .attr("stroke-width", 1);
  svg
    .append("line")
    .attr("class", "helper3")
    .attr("x1", 0)
    .attr("y1", h * 0.56 - 40)
    .attr("x2", w)
    .attr("y2", h * 0.56 - 40)
    .attr("stroke", "#00f")
    .style("stroke-dasharray", "5, 1")
    .attr("stroke-width", 1);
  svg
    .append("line")
    .attr("class", "helper4")
    .attr("x1", 0)
    .attr("y1", h * 0.7 + 30)
    .attr("x2", w)
    .attr("y2", h * 0.7 + 30)
    .attr("stroke", "#00f")
    .style("stroke-dasharray", "5, 1")
    .attr("stroke-width", 1);
  svg
    .append("line")
    .attr("class", "helper5")
    .attr("x1", 0)
    .attr("y1", h - 30)
    .attr("x2", w)
    .attr("y2", h - 30)
    .attr("stroke", "#00f")
    .style("stroke-dasharray", "5, 1")
    .attr("stroke-width", 1);
};

const updateHelper = (svg, w, h) => {
  svg
    .select(".helper1")
    .attr("x1", 0)
    .attr("y1", h * 0.56)
    .attr("x2", w)
    .attr("y2", h * 0.56);
  svg
    .select(".helper2")
    .attr("x1", 0)
    .attr("y1", 40)
    .attr("x2", w)
    .attr("y2", 40);
  svg
    .select(".helper3")
    .attr("x1", 0)
    .attr("y1", h * 0.56 - 40)
    .attr("x2", w)
    .attr("y2", h * 0.56 - 40);
  svg
    .select(".helper4")
    .attr("x1", 0)
    .attr("y1", h * 0.7 + 30)
    .attr("x2", w)
    .attr("y2", h * 0.7 + 30);
  svg
    .select(".helper5")
    .attr("x1", 0)
    .attr("y1", h - 30)
    .attr("x2", w)
    .attr("y2", h - 30);
};

export const OtccD3Component = (props) => {
  const d3Container = useRef(null);
  const getTheme = useSelector((state) => state.layout.theme);

  useEffect(() => {
    props.getWidgetSize();
    if (
      props.data !== undefined &&
      (props.data.chart !== undefined ||
        props.data.bigbox !== undefined ||
        props.data.smbox !== undefined) &&
      d3Container.current
    ) {
      const svg = d3.select(d3Container.current);
      svg.selectAll("*").remove();
      const w = props.width - 10;
      const h = props.height - 5;
      drawSeparator(svg, w, h, getTheme);
      drawChart(props.data.chart, svg, w, h, getTheme);
      drawBigbox(props.data.bigbox, svg, w, h);
      drawSmallbox(props.data.smbox, svg, w, h);
    }
  }, [
    props.data,
    d3Container.current,
    props.width,
    props.height,
    props.wid,
    getTheme,
  ]);

  return (
    <svg
      className="d3-component"
      width={props.width}
      height={props.height}
      ref={d3Container}
      style={{ marginLeft: "5px" }}
    />
  );
};

export default OtccD3Component;
