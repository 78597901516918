import { useDispatch } from "react-redux";
import Modal from "../../../../UI/Modal";
import { layoutActions } from "../../../../../store/layout-slice";
import RotationList from "./Rotations";
import { useState, React } from "react";
import { RotationPages } from "./RotationPages/PageList";

const RotationSettings = () => {
  const dispatch = useDispatch();
  const [displayPages, setDisplayPages] = useState(false);
  const [selectedRotation, setSelectedRotation] = useState({
    Id: "",
    PageList: [],
    RotationName: "",
  });

  const onCloseHandler = () => {
    dispatch(layoutActions.setRotationPopup());
  };

  const displayPagesHandler = (rotation) => {
    if (rotation) {
      setDisplayPages(true);
      setSelectedRotation({
        ...selectedRotation,
        Id: rotation.Id,
        PageList: rotation.PageList,
        RotationName: rotation.RotationName,
      });
    }
  };

  return (
    <Modal
      title="Rotation Settings"
      width={{ width: "850px" }}
      onClose={onCloseHandler}
      contentCss={{ padding: "0px" }}
    >
      {!displayPages && (
        <RotationList displayPagesHandler={displayPagesHandler} />
      )}
      {displayPages && (
        <RotationPages
          rotationItem={selectedRotation}
          hidePageList={() => setDisplayPages(false)}
        />
      )}
    </Modal>
  );
};

export default RotationSettings;
