import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetWidgetSettings } from "../../../../Helper/Services";
import VideoFeedComponent from "./VideoFeedComponent";
import BubbleD3Component from "./VideoFeedD3BubbleComponent";
import { VideoFeedRefreshTime } from "../../../../Constants";

const VideoFeedWidget = (props) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const [vidUrl, setVidUrl] = useState("");
  const [videoOption, setVideoOption] = useState("");
  const [rtspUrl, setRtspUrl] = useState("");
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [topVal, setTopVal] = useState(0);
  const [actualHeight, setActualHeight] = useState(0);
  const [actualWidth, setaActualWidth] = useState(0);

  useEffect(() => {
    const getWidgetSetting = () => {
      GetWidgetSettings(props.widgetId)
        .then((settings) => {
          setVideoOption(settings["videooption"]);
          setRtspUrl(settings["rtspUrl"]);
          let vid_url = settings.videofeedurl;
          if (settings.videooption !== undefined) {
            if (settings.videooption === "Nest Camera") {
              if (vid_url.indexOf("https") > -1) {
                vid_url = settings.videofeedurl;
              } else {
                vid_url =
                  "https://video.nest.com/embedded/live/" +
                  settings.videofeedurl +
                  "?autoplay=1";
              }
            } else if (settings.videooption === "Nsyteful Video Mixer") {
              if (vid_url.indexOf("https") > -1) {
                vid_url = settings.videofeedurl;
              } else {
                vid_url =
                  "https://videoplayer.nsyteful.com/player/" +
                  settings.videofeedurl;
              }
            } else if (settings.videooption === "Nsyteful Video Mixer V2") {
              vid_url =
                "https://videomixer.nsyteful.com:447/?stream=" +
                settings.rtspUrl;
            }
            setVidUrl(vid_url);
          }
        })
        .catch((err) => {});
    };
    if (sessionId !== "" && sessionId != null) {
      getWidgetSetting();
      GetWidgetSize();
    }

    window.addEventListener("resize", () => {
      GetWidgetSize();
    });

    const refreshWidget = setInterval(() => {
      console.log("Video feed set Timout..", new Date().toLocaleTimeString());
      getWidgetSetting();
    }, VideoFeedRefreshTime);

    return () => {
      //clear setinterval on unmount component
      clearInterval(refreshWidget);
    };
  }, [sessionId, props.RefreshVersion]);

  const GetWidgetSize = () => {
    const getParentContainer = document.getElementById(
      "content_" + props.widgetId
    );
    if (getParentContainer !== undefined && getParentContainer != null) {
      const getHeight = getParentContainer.clientHeight;
      const getWidth = getParentContainer.clientWidth;
      setHeight(getHeight);
      setWidth(getWidth);
      calculateTopVal(getHeight, getWidth);
    }
  };

  const calculateTopVal = (height, width) => {
    let actualHeight = height - 20; //* 0.98;
    let actualWidth = (parseFloat(actualHeight) * 16) / 9;

    if (height > width) {
      actualWidth = width - 10; //width * 0.992;
      actualHeight = (parseFloat(actualWidth) * 9) / 16;
    }

    if (actualWidth > width) {
      actualWidth = width - 10;
      actualHeight = (parseFloat(actualWidth) * 9) / 16;
    }

    let getStyle = props.parentStyle.height;
    let topVal = parseInt((height - actualHeight) / 2);
    if (getStyle.indexOf("calc(100% + 8px)") > -1) {
      topVal = parseInt((height - actualHeight - 8) / 2);
    }
    setTopVal(topVal);
    setActualHeight(actualHeight);
    setaActualWidth(actualWidth);
  };

  return (
    <div id={props.widgetId}>
      <VideoFeedComponent
        videoOption={videoOption}
        vidUrl={vidUrl}
        rtspUrl={rtspUrl}
        height={height}
        width={width}
        topVal={topVal}
        actualHeight={actualHeight}
        actualWidth={actualWidth}
      />
      <BubbleD3Component height={height} width={width} />
    </div>
  );
};

export default VideoFeedWidget;
