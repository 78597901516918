import React from "react";

const WidgetTitleControl = (props) => {
  return (
    <React.Fragment>
      <p>Widget Title (long)</p>
      <input
        type="text"
        name="Title"
        id="title"
        value={props.titleVal}
        onChange={props.OnTitleChangeHandler}
        value-key="Title"
      />
      <span
        style={{
          display: props.isTitleValid ? "none" : "block",
          color: "red",
          paddingLeft: "5px",
        }}
      >
        Required
      </span>
      <p>Widget Title (short)</p>
      <input
        type="text"
        name="Short Title"
        id="short_title"
        value={props.shortTitleVal}
        style={{ width: "200px" }}
        onChange={props.OnShortTitleChangeHandler}
        value-key="ShortTitle"
      />
      <span
        style={{
          display: props.isShortTitleValid ? "none" : "block",
          color: "red",
          paddingLeft: "5px",
        }}
      >
        Required
      </span>
    </React.Fragment>
  );
};

export default WidgetTitleControl;
