import { useState, React } from "react";
import { EditButton } from "../../Common/EditActionControl";
import { DeleteButton } from "../../Common/DeleteActionControl";
import { UpdateButton } from "../../Common/UpdateActionControl";
import { CancelButton } from "../../Common/CancelActionControl";
import { PageDropdownList } from "../../Common/PageListDropdown";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ConcateDateWithPageName from "../../../../../../Helper/PageOperations";
import { TimeIntervalControl } from "../../Common/TimeIntervalControl";
import RotationServices from "../../RotationService";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../../../../../store/layout-slice";
import { Draggable } from "react-beautiful-dnd";

export const PageListItem = ({
  isSavePageEnabled,
  diabledAddEdit,
  isDisableAddEdit,
  item,
  rotationId,
  loaderHandler,
  index,
}) => {
  const dispatch = useDispatch();
  const pageList = useSelector((state) => state.layout.PageList);
  const userId = useSelector((state) => state.layout.UserId);
  const activeRotationId = useSelector((state) => state.layout.PageRotation);
  const [pageItems, setPageItems] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [pageId, setPageId] = useState("");
  const [pageIdErr, setPageIdErr] = useState("");
  const [timeInterval, setTimeInterval] = useState("");
  const [timeIntervalErr, setTimeIntervalErr] = useState("");

  useEffect(() => {
    let items = ConcateDateWithPageName([...pageList], true);
    setPageItems(items);
  }, [pageList]);

  const updateHandler = (event, id) => {
    event.preventDefault();

    if (pageId === undefined || pageId === "" || pageId === null) {
      setPageIdErr("Please select page.");
      setTimeout(() => {
        setPageIdErr("");
      }, 3000);
      return false;
    }

    if (timeInterval === undefined) {
      setTimeIntervalErr("Enter only numeric value i.e. 0.50, 1");
      setTimeout(() => {
        setTimeIntervalErr("");
      }, 3000);
      return false;
    }

    if (
      timeInterval === undefined ||
      timeInterval === "" ||
      timeInterval === null
    ) {
      setTimeIntervalErr("Time interval required.");
      setTimeout(() => {
        setTimeIntervalErr("");
      }, 3000);
      return false;
    }

    if (timeInterval < 0.5) {
      setTimeIntervalErr("Time interval must be equal or greater than 0.5");
      setTimeout(() => {
        setTimeIntervalErr("");
      }, 3000);
      return false;
    }

    loaderHandler(true);
    RotationServices.updateRotationPage(
      id,
      pageId,
      rotationId,
      parseFloat(timeInterval).toFixed(2)
    )
      .then((resp) => {
        if (resp.Success) {
          getRotations();
        } else {
          loaderHandler(false);
        }
      })
      .catch((err) => {
        console.log("updateRotationPage:", err);
        loaderHandler(false);
      });
  };

  const editHandler = (event, pageId, timeInterval) => {
    event.preventDefault();
    if (isSavePageEnabled || isDisableAddEdit) return false;
    setIsEdit(true);
    setTimeInterval(timeInterval);
    setPageId(pageId);
    diabledAddEdit(true);
  };

  const deleteHandler = (event, id) => {
    event.preventDefault();
    if (isSavePageEnabled || isDisableAddEdit) return false;

    if (activeRotationId.toString() == rotationId.toString()) {
      alert("Cannot delete pages from active rotation.");
      return false;
    }

    var r = window.confirm("Are you sure you want to delete this page?");
    if (r === true) {
      loaderHandler(true);
      RotationServices.deleteRotationPage(id)
        .then((resp) => {
          if (resp.Success) {
            getRotations();
          }
        })
        .catch((err) => {
          console.log("deleteRotationPage:", err);
          loaderHandler(false);
        });
    }
  };

  const onChangeHandler = (event) => {
    setPageId(event.target.value);
  };

  const getRotations = () => {
    RotationServices.getRotation(userId)
      .then((res) => {
        if (res.Success) {
          dispatch(layoutActions.updateRotationList(res.Message.rotationlist));
          setIsEdit(false);
          diabledAddEdit(false);
          loaderHandler(false);
        }
      })
      .catch((err) => {
        setIsEdit(false);
        diabledAddEdit(false);
        loaderHandler(false);
      });
  };

  return (
    <Draggable
      draggableId={`draggable-${item.Id}`}
      index={index}
      key={item.Id}
      isDragDisabled={isDisableAddEdit || isSavePageEnabled}
    >
      {(provided, snapshot) => (
        <tr ref={provided.innerRef} {...provided.draggableProps} key={item.Id}>
          <td style={{ width: "50%" }}>
            {!isEdit && (
              <span
                {...provided.dragHandleProps}
                style={{
                  cursor:
                    (isDisableAddEdit || isSavePageEnabled) === true
                      ? "not-allowed"
                      : "",
                }}
              >
                <i aria-hidden="true" className="fa-md fa fa-arrows"></i>
                &nbsp;&nbsp;
              </span>
            )}
            {!isEdit && <span>{item.PageName}</span>}
            {isEdit && (
              <PageDropdownList
                value={pageId}
                errMsg={pageIdErr}
                onChageHandler={onChangeHandler}
                items={pageItems}
              />
            )}
          </td>
          <td style={{ width: "40%" }}>
            {!isEdit && <span>{item.TimeInterval} mins</span>}
            {isEdit && (
              <TimeIntervalControl
                onChangeHandler={(event) => setTimeInterval(event.target.value)}
                value={timeInterval}
                timeIntervalErr={timeIntervalErr}
              />
            )}
          </td>
          <td className="action-col" style={{ width: "40%" }}>
            {!isEdit && (
              <>
                <EditButton
                  isDisable={isSavePageEnabled || isDisableAddEdit}
                  editHandler={(event) =>
                    editHandler(event, item.PageId, item.TimeInterval)
                  }
                />
                <DeleteButton
                  isDisable={isSavePageEnabled || isDisableAddEdit}
                  onClickHandler={(event) => deleteHandler(event, item.Id)}
                />
              </>
            )}
            {isEdit && (
              <>
                <UpdateButton
                  onClickHandler={(event) => updateHandler(event, item.Id)}
                />
                <CancelButton
                  onClickHandler={(event) => {
                    event.preventDefault();
                    diabledAddEdit(false);
                    setIsEdit(false);
                  }}
                />
              </>
            )}
          </td>
        </tr>
      )}
    </Draggable>
  );
};
