import { useState } from "react";
import { useSelector } from "react-redux";
import useInput from "../../hooks/use-input";
import AccountService from "./AccountService";

const Registration = (props) => {
  const getTheme = useSelector((state) => state.layout.theme);
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [firstName, setFirstName] = useState("");
  const [errMsgFirstName, setErrMsgFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errMsgLastName, setErrMsgLastName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErr, setNewPasswordErr] = useState("");
  const [cnfPassword, setcnfPassword] = useState("");
  const [cnfPasswordErr, setcnfPasswordErr] = useState("");
  const [responseMsg, setResponseMsg] = useState("");

  const onformSubmit = () => {
    if (email === "") {
      setErrMsg("Required");
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
      return false;
    }

    const isEmailValid = emailValidation();
    if (!isEmailValid) {
      setErrMsg("Invalid email");
      setTimeout(() => {
        setErrMsg("");
      }, 3000);
      return false;
    }

    if (firstName === "") {
      setErrMsgFirstName("Required");
      setTimeout(() => {
        setErrMsgFirstName("");
      }, 3000);
      return false;
    }

    if (lastName === "") {
      setErrMsgLastName("Required");
      setTimeout(() => {
        setErrMsgLastName("");
      }, 3000);
      return false;
    }

    if (newPassword === "") {
      setNewPasswordErr("Required");
      setTimeout(() => {
        setNewPasswordErr("");
      }, 3000);

      return false;
    }

    var format = /(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])/;
    if (!format.test(newPassword)) {
      setNewPasswordErr(
        "Passwords must be between 8 and 20 characters.Must contain one lower & uppercase letter, and one non-alpha character (a number or a symbol.)"
      );
      setTimeout(() => {
        setNewPasswordErr("");
      }, 3000);

      return false;
    }

    if (cnfPassword === "") {
      setcnfPasswordErr("Please confirm your password.");
      setTimeout(() => {
        setcnfPasswordErr("");
      }, 3000);

      return false;
    }

    if (cnfPassword !== newPassword) {
      setcnfPasswordErr("Passwords do not match.");
      setTimeout(() => {
        setcnfPasswordErr("");
      }, 3000);

      return false;
    }

    const getUrl = window.location.href;
    AccountService.registerNewUser(
      email,
      newPassword,
      firstName,
      lastName,
      getUrl
    )
      .then((res) => {
        const message = res.replace(/"/g, "");
        setResponseMsg(message);
      })
      .catch((err) => {
        const message = err.replace(/"/g, "");
        setResponseMsg(message);
      });
  };

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; //eslint-disable-line
    return !(!email || regex.test(email) === false);
  };

  return (
    <div>
      {!responseMsg && (
        <form className="settings" name="RegForm" autoComplete="off">
          <p htmlFor="emailFrgPass">Email</p>
          <input
            type="email"
            className={errMsg ? "required-error" : ""}
            value={email}
            name="emailFrgPass"
            autoComplete="off"
            onChange={(e) => {
              setEmail(e.target.value);
              setErrMsg("");
            }}
            onBlur={() => {
              if (email === "") setErrMsg("Required");
            }}
          />
          {errMsg && (
            <span style={{ color: "red", display: "block" }}>{errMsg}</span>
          )}
          <p for="name">First Name</p>
          <input
            type="text"
            name="name"
            value={firstName}
            className={errMsgFirstName ? "required-error" : ""}
            autoComplete="off"
            onChange={(e) => {
              setFirstName(e.target.value);
              setErrMsgFirstName("");
            }}
            onBlur={() => {
              if (firstName === "") setErrMsgFirstName("Required");
            }}
          />
          {errMsgFirstName && (
            <span style={{ color: "red" }}>{errMsgFirstName}</span>
          )}
          <p for="lastName">Last Name</p>
          <input
            type="text"
            name="lastName"
            value={lastName}
            className={errMsgLastName ? "required-error" : ""}
            autoComplete="off"
            onChange={(e) => {
              setLastName(e.target.value);
              setErrMsgLastName("");
            }}
            onBlur={() => {
              if (lastName === "") setErrMsgLastName("Required");
            }}
          />
          {errMsgLastName && (
            <span style={{ color: "red" }}>{errMsgLastName}</span>
          )}
          <p>New Password</p>
          <input
            type="password"
            minLength="8"
            maxLength="20"
            className={newPasswordErr ? "required-error" : ""}
            autoComplete="off"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
              setNewPasswordErr("");
            }}
            onBlur={() => {
              if (newPassword === "") setNewPasswordErr("Required");
            }}
          />
          {newPasswordErr && (
            <span style={{ color: "red" }}>{newPasswordErr}</span>
          )}
          <p htmlFor="cngPasspassword_c1">Confirm Password</p>
          <input
            type="password"
            minLength="8"
            maxLength="20"
            name="cngPasspassword_c1"
            className={cnfPasswordErr ? "required-error" : ""}
            autoComplete="off"
            value={cnfPassword}
            onChange={(e) => {
              setcnfPassword(e.target.value);
              setcnfPasswordErr("");
            }}
            onBlur={() => {
              if (cnfPassword === "") setcnfPasswordErr("Required");
            }}
          />
          {cnfPasswordErr && (
            <span style={{ color: "red", display: "block" }}>
              {cnfPasswordErr}
            </span>
          )}

          <div style={{ paddingTop: "15px" }}>
            <button
              className={`dx-widget dx-button-has-text dx-button dx-button-normal ${
                getTheme !== "Light" ? "button-dark" : ""
              }`}
              type="button"
              onClick={onformSubmit}
            >
              Submit
            </button>
          </div>
        </form>
      )}

      {responseMsg && (
        <div>
          <div style={{ padding: "5px" }}>{responseMsg}</div>
          <div style={{ paddingTop: "15px", textAlign: "right" }}>
            <button
              type="submit"
              className={`dx-widget dx-button-has-text dx-button dx-button-normal button`}
              onClick={props.onCloseLoginHandler}
            >
              Ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Registration;
