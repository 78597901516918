import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ConcateDateWithPageName from "../../../../../../Helper/PageOperations";
import RotationServices from "../../RotationService";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../../../../../store/layout-slice";
import { PageDropdownList } from "../../Common/PageListDropdown";
import { TimeIntervalControl } from "../../Common/TimeIntervalControl";

export const AddRotationPage = ({
  enableSaveButtonHandler,
  rotationId,
  loaderHandler,
}) => {
  const dispatch = useDispatch();
  const pageList = useSelector((state) => state.layout.PageList);
  const userId = useSelector((state) => state.layout.UserId);
  const [pageItems, setPageItems] = useState([]);
  const [pageId, setPageId] = useState("");
  const [pageIdErr, setPageIdErr] = useState("");
  const [timeInterval, setTimeInterval] = useState("");
  const [timeIntervalErr, setTimeIntervalErr] = useState("");

  useEffect(() => {
    let items = ConcateDateWithPageName([...pageList], true);
    setPageItems(items);
  }, [pageList]);

  const isNumberKey = (evt) => {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 46 && evt.srcElement.value.split(".").length > 1) {
      return false;
    }
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  };

  const saveHandler = (event) => {
    event.preventDefault();
    if (pageId === undefined || pageId === "" || pageId === null) {
      setPageIdErr("Please select page.");
      setTimeout(() => {
        setPageIdErr("");
      }, 3000);
      return false;
    }

    if (timeInterval === undefined) {
      setTimeIntervalErr("Enter only numeric value i.e. 0.50, 1");
      setTimeout(() => {
        setTimeIntervalErr("");
      }, 3000);
      return false;
    }

    if (
      timeInterval === undefined ||
      timeInterval === "" ||
      timeInterval === null
    ) {
      setTimeIntervalErr("Time interval required.");
      setTimeout(() => {
        setTimeIntervalErr("");
      }, 3000);
      return false;
    }

    if (timeInterval < 0.5) {
      setTimeIntervalErr("Time interval must be equal or greater than 0.5");
      setTimeout(() => {
        setTimeIntervalErr("");
      }, 3000);
      return false;
    }

    loaderHandler(true);
    RotationServices.addRotationPage(pageId, rotationId, timeInterval)
      .then((res) => {
        if (res.Success) {
          getRotations();
        } else {
          loaderHandler(false);
        }
      })
      .catch((err) => {
        console.log("addRotationPage", err);
        loaderHandler(false);
      });
  };

  const getRotations = () => {
    RotationServices.getRotation(userId)
      .then((res) => {
        if (res.Success) {
          dispatch(layoutActions.updateRotationList(res.Message.rotationlist));
          enableSaveButtonHandler(false);
          loaderHandler(false);
        }
      })
      .catch((err) => {
        enableSaveButtonHandler(false);
        loaderHandler(false);
      });
  };

  return (
    <tr>
      <td>
        <PageDropdownList
          value={pageId}
          errMsg={pageIdErr}
          onChageHandler={(event) => setPageId(event.target.value)}
          items={pageItems}
        />
      </td>
      <td>
        <TimeIntervalControl
          onChangeHandler={(event) => setTimeInterval(event.target.value)}
          value={timeInterval}
          timeIntervalErr={timeIntervalErr}
        />
      </td>
      <td className="action-col">
        <a
          href=""
          onClick={saveHandler}
          className="icons_cls"
          title="Save"
          style={{ fontSize: "18px", color: "gray", textDecoration: "none" }}
        >
          <i className="fa fa-check-square-o" aria-hidden="true"></i>
        </a>
        <a
          href=""
          onClick={(event) => {
            event.preventDefault();
            enableSaveButtonHandler(false);
          }}
          className="icons_cls"
          title="Cancel"
          style={{ fontSize: "18px", color: "gray", textDecoration: "none" }}
        >
          &nbsp;<i className="fa fa-times" aria-hidden="true"></i>
        </a>
      </td>
    </tr>
  );
};
