import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  React,
} from "react";

const PieChartContent = forwardRef((props, ref) => {
  const [category, setCategory] = useState("");
  const [isCategoryValid, setIsCategoryValid] = useState(true);
  const [values, setvalues] = useState("None");
  const [operation, setOperation] = useState("N/A");
  const [callout, setCallout] = useState("1");

  useEffect(() => {
    setCategory(props.categoryVal);
    setvalues(props.values);
    setOperation(props.operationVal);
    setCallout(props.calloutVal);
  }, [props.categoryVal, props.values, props.operationVal, props.calloutVal]);

  useImperativeHandle(ref, () => ({
    validatePieContent() {
      validateForm();
    },
  }));
  const onCategoryChangeHandler = (event) => {
    setCategory(event.target.value);
  };

  const onValuesChangeHandler = (event) => {
    setvalues(event.target.value);
    setOperation("Sum");
  };

  const onOperationChangeHandler = (event) => {
    setOperation(event.target.value);
  };

  const onCalloutChangeHandler = (event) => {
    setCallout(event.target.value);
  };

  const validateCategory = () => {
    if (category === "") {
      setIsCategoryValid(false);
      setTimeout(() => {
        setIsCategoryValid(true);
      }, 3000);

      return false;
    }

    return true;
  };

  const validateForm = () => {
    const isCategoryValid = validateCategory();
    props.onContentChange({
      isValid: isCategoryValid,
      data: {
        category: category,
        values: values,
        operation: operation,
        callout: callout,
      },
    });
  };

  return (
    <>
      <p>Column Containing Categories</p>
      <select
        value-key="category"
        onChange={onCategoryChangeHandler}
        value={category}
      >
        <option value="">None</option>
        {props.categoryList.map((item, index) => {
          return (
            <option key={index} value={item.id}>
              {item.text}
            </option>
          );
        })}
      </select>
      {!isCategoryValid && (
        <span style={{ color: "red", paddingLeft: "5px" }}>Required</span>
      )}

      <p>Column Containing Values (Will be Summed)</p>
      <select value={values} value-key="value" onChange={onValuesChangeHandler}>
        <option value="None">None</option>
        {props.valueList.map((item, index) => {
          return (
            <option key={index} value={item.id}>
              {item.text}
            </option>
          );
        })}
      </select>

      <p>Operation to Perform on Value Items</p>
      <select
        value-key="operation"
        onChange={onOperationChangeHandler}
        value={operation}
      >
        {values === "None" && <option value="N/A">Not Applicable</option>}
        {values !== "None" && (
          <>
            <option value="Sum">Sum</option>
            <option value="Average">Average</option>
            <option value="Max">Max</option>
            <option value="Min">Min</option>
          </>
        )}
      </select>

      <p>Show Values (Callouts)</p>
      <select
        value-key="callout"
        onChange={onCalloutChangeHandler}
        value={callout}
      >
        <option value="1">Off</option>
        <option value="2">Absolute</option>
        <option value="3">Percentage</option>
      </select>
    </>
  );
});

export default PieChartContent;
