import { useState, React } from "react";
import RotationServices from "../RotationService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../../../../store/layout-slice";

export const AddRotation = ({ loaderHandler, enableSaveButtonHandler }) => {
  const [rotationName, setRotationName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const userId = useSelector((state) => state.layout.UserId);
  const dispatch = useDispatch();

  const saveRotation = (event) => {
    event.preventDefault();
    if (rotationName === "") {
      setErrorMsg("Rotation name required.");
      setTimeout(() => {
        setErrorMsg("");
      }, 3000);
      return false;
    }
    loaderHandler(true);
    RotationServices.addRotation(rotationName, userId)
      .then((res) => {
        if (res.Success) {
          getRotations();
          // enable save mode as false
        } else {
          loaderHandler(false);
          setErrorMsg(res.ErrorMessage);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        loaderHandler(false);
        console.log("addRotation", err);
      });
  };

  const getRotations = () => {
    RotationServices.getRotation(userId)
      .then((res) => {
        if (res.Success) {
          dispatch(layoutActions.updateRotationList(res.Message.rotationlist));
          enableSaveButtonHandler(false);
          loaderHandler(false);
        }
      })
      .catch((err) => {
        loaderHandler(false);
      });
  };

  return (
    <li
      className="list-group-item dark-background"
      style={{ display: "flex", flexWrap: "wrap", flexBasis: "100%" }}
    >
      <div style={{ justifyContent: "flex-start", flexBasis: "80%" }}>
        <input
          style={{ width: "100%" }}
          placeholder="Rotation Name"
          type="text"
          value={rotationName}
          onChange={(event) => setRotationName(event.target.value)}
        />
        {errorMsg && <span style={{ color: "red" }}>{errorMsg}</span>}
      </div>
      <div
        style={{
          justifyContent: "flex-end",
          flexBasis: "20%",
          display: "flex",
        }}
      >
        <a
          href=""
          title="Save"
          className="icons_cls"
          style={{ fontSize: "18px", color: "gray", textDecoration: "none" }}
          onClick={saveRotation}
        >
          <i className="fa fa-check-square-o" aria-hidden="true"></i>
        </a>
        <a
          href=""
          className="icons_cls"
          title="Cancel"
          style={{ fontSize: "18px", color: "gray", textDecoration: "none" }}
          onClick={(event) => {
            event.preventDefault();
            enableSaveButtonHandler(false);
            setRotationName("");
          }}
        >
          &nbsp;<i className="fa fa-times" aria-hidden="true"></i>
        </a>
      </div>
    </li>
  );
};
