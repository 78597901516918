import React, { useState } from "react";
import { useEffect } from "react";
import ConfiguratorServices from "../ConfiguratorService";
import { useSelector } from "react-redux";
import Loader from "../../../../UI/Loader";
import { ConfigurationTypes } from "./ConfigurationTypeList";
import { ConfigurationTypeSettings } from "./ConfigurationTypeSettings";

export const ConfigurationType = ({
  dataSourceTitle,
  datasourceId,
  dataSourceType,
  onDatasourceClick,
}) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const [configurationType, setConfigurationType] = useState([]);
  const [enableLoader, setEnableLoader] = useState(false);
  const [configrationTypeDetails, setConfigrationTypeDetails] = useState({
    settingName: "",
    columnSettingsId: "",
  });
  const [showConfigurationType, setShowConfigurationType] = useState(true);

  useEffect(() => {
    getConfugurationType();
  }, [datasourceId]);

  const getConfugurationType = () => {
    setEnableLoader(true);
    ConfiguratorServices.GetConfigurationType(sessionId, datasourceId)
      .then((resp) => {
        setConfigurationType(resp);
        setEnableLoader(false);
      })
      .catch((err) => {
        console.log("GetConfigurationType:-", err);
        setEnableLoader(false);
      });
  };

  const onDataSourceClickHandler = (event) => {
    event.preventDefault();
    onDatasourceClick({ id: "", name: "" }, true);
  };

  const onConfigurationTypeClickHandler = (
    settingName,
    columnSettingsId,
    value
  ) => {
    setConfigrationTypeDetails({
      settingName: settingName,
      columnSettingsId: columnSettingsId,
    });
    setShowConfigurationType(value);
  };

  return (
    <>
      {!enableLoader && (
        <>
          <div style={{ display: showConfigurationType ? "block" : "none" }}>
            <ConfigurationTypes
              onDataSourceClickHandler={onDataSourceClickHandler}
              dataSourceTitle={dataSourceTitle}
              configurationType={configurationType}
              onConfigurationTypeClick={onConfigurationTypeClickHandler}
            />
          </div>
          {!showConfigurationType && (
            <ConfigurationTypeSettings
              dataSourceTitle={dataSourceTitle}
              dataSourceChildTitle={configrationTypeDetails.settingName}
              sessionId={sessionId}
              dataSourceId={datasourceId}
              dataSourceType={dataSourceType}
              settingId={configrationTypeDetails.columnSettingsId}
              onDataSourceClickHandler={onDataSourceClickHandler}
              onConfigurationTypeClickHandler={onConfigurationTypeClickHandler}
              configurationType={configurationType}
            />
          )}
        </>
      )}
      {enableLoader && <Loader styles={{ height: "80px" }} />}
    </>
  );
};
