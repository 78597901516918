import { Fragment, React } from "react";

const BarLineContent = (props) => {
  return (
    <Fragment>
      <p>Bar and Line Chart</p>

      <div style={{ paddingLeft: "10px" }}>
        <p>Chart Type</p>
        <select
          value-key="charttype"
          onChange={props.onChangeChartType}
          value={props.chartType}
        >
          <option value="Bar">Bar</option>
          <option value="Line">Line</option>
          <option value="BarLine">Bar and Line</option>
        </select>

        {props.chartType !== "Bar" && (
          <div id="periodType">
            <p>X-Axis:Period Type </p>
            <select
              value-key="periodtype"
              value={props.periodType}
              onChange={props.periodTypeChangeHandler}
            >
              <option value="days">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
            </select>
          </div>
        )}

        {props.chartType !== "Bar" && (
          <div id="periodTypeNumber">
            <p>X-Axis:Number of Periods</p>
            <input
              type="number"
              value-key="numberofperiodtype"
              min="1"
              value={props.periodTypeNumber}
              onChange={props.periodTypeNumberChangeHandler}
            />
          </div>
        )}

        <p>X-Axis Values</p>
        <select
          value-key="barname"
          value={props.barname.value}
          onChange={props.barnameChangeHandler}
        >
          <option value="">Please Select</option>
          {props.chartType !== "Bar" &&
            props.ddlDateTypeList.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.text}
                </option>
              );
            })}
          {props.chartType === "Bar" &&
            props.ddlList.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.text}
                </option>
              );
            })}
        </select>
        {!props.barname.isValid && (
          <span
            id="validateBarname"
            style={{ color: "red", paddingLeft: "5px" }}
          >
            Required
          </span>
        )}
      </div>

      {(props.chartType === "Bar" || props.chartType === "BarLine") && (
        <div id="barChartSettings">
          <p>Bar Chart</p>
          <div style={{ paddingLeft: "10px" }}>
            <p>Values</p>
            <select
              value-key="barvalues"
              value={props.barvalue}
              onChange={props.valueChange}
            >
              <option value="None">None (Items will be Counted)</option>
              {props.valueList.map((item, i) => {
                return (
                  <option key={i} value={item.id}>
                    {item.text}
                  </option>
                );
              })}
            </select>

            <p>Operation To Perform</p>
            <select
              value-key="operationperform"
              value={props.operationPerform}
              onChange={props.onOperationPerformSelect}
            >
              {props.barvalue === "None" && (
                <option value="N/A">Not Applicable</option>
              )}
              {props.barvalue !== "None" && (
                <>
                  <option value="Sum">Sum</option>
                  <option value="Average">Average</option>
                  <option value="Max">Max</option>
                  <option value="Min">Min</option>
                </>
              )}
            </select>

            <p>Categories</p>
            <select
              value-key="stackcategory"
              value={props.stackCategory}
              onChange={props.onStackCategorySelect}
            >
              <option value="">None</option>
              {props.ddlList.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.text}
                  </option>
                );
              })}
            </select>

            <p>Category Display</p>
            <select
              value-key="categorydisplay"
              value={props.category}
              onChange={props.onCategoryDisplaySelect}
            >
              <option value="stacked">Stacked</option>
              <option value="sidebyside">Side by Side</option>
            </select>

            <p>Show Values</p>
            <select
              value-key="callouts"
              value={props.callout}
              onChange={props.onCalloutSelect}
            >
              <option value="">On</option>
              <option value="Off">Off</option>
            </select>
          </div>
        </div>
      )}

      {(props.chartType === "Line" || props.chartType === "BarLine") && (
        <div id="lineChartSettings">
          <p>Line Chart</p>
          <div style={{ paddingLeft: "10px" }}>
            <p>Values</p>
            <select
              value-key="linevalues"
              value={props.lineValues}
              onChange={props.onLineChartHandler}
            >
              <option value="None">None (Items will be Counted)</option>
              {props.valueList.map((item, i) => {
                return (
                  <option key={i} value={item.id}>
                    {item.text}
                  </option>
                );
              })}
            </select>

            <p>Operation To Perform</p>
            <select
              value-key="operationLineperform"
              value={props.operationLineperform}
              onChange={props.onOperationLineperform}
            >
              {props.lineValues === "None" && (
                <option value="N/A">Not Applicable</option>
              )}
              {props.lineValues !== "None" && (
                <>
                  <option value="Sum">Sum</option>
                  <option value="Average">Average</option>
                  <option value="Max">Max</option>
                  <option value="Min">Min</option>
                </>
              )}
            </select>

            <p>Categories</p>
            <select
              value-key="linecategory"
              value={props.lineCategory}
              onChange={props.onLineCategoryChange}
            >
              <option value="">None</option>
              {props.ddlList.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.text}
                  </option>
                );
              })}
            </select>

            {props.chartType === "BarLine" && (
              <div id="secondary_y_axis">
                <p>Assign To Secondary Y-Axis </p>
                <select
                  value-key="lineyaxis"
                  value={props.lineyaxis}
                  onChange={props.onChangeLineyaxis}
                >
                  <option value="">On</option>
                  <option value="Off">Off</option>
                </select>
              </div>
            )}

            {props.chartType === "BarLine" && (
              <div id="yaxisminvalue_div">
                <p>Secondary Y-Axis Min Value </p>
                <input
                  type="number"
                  value-key="yaxisminvalue"
                  max="0"
                  value={props.yaxisMinValue}
                  onKeyUp={props.onYaxisMinValue}
                  onChange={props.onChangeYaxisMinValue}
                />
              </div>
            )}

            <p>Show Values</p>
            <select
              value-key="linecallouts"
              value={props.lineCallout}
              onChange={props.onLineCalloutsChange}
            >
              <option value="">On</option>
              <option value="Off">Off</option>
            </select>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default BarLineContent;
