import React from "react";
import TableTd from "./TableCell";
import styles from "./TableTemplate.module.css";
const TableRow = (props) => {
  return (
    <>
      <tr
        className={`${styles["tbl-row"]} tbl-data-row ${styles["tbl-column-lines"]}`}
      >
        {props.columnList.map((item, index) => {
          return item.visible === true ? (
            <td
              key={index}
              data-index={index}
              style={{
                textAlign:
                  item.alignment && item.alignment === "right"
                    ? "right"
                    : "left",
              }}
            >
              <TableTd
                cellItem={item}
                rowItem={props.rowDt}
                settings={props.settings}
              />
            </td>
          ) : (
            ""
          );
        })}
      </tr>
    </>
  );
};

export default TableRow;
