import _ from "lodash";
import { ConvertToLocalDateTimeWithFormat } from "../../../../Helper/WidgetDataCommon";
import $ from "jquery";
import moment from "moment";

const getcolumnName = (dataSourceName, type) => {
  let columnName = "";

  if (dataSourceName == "Sayfa Case Register" && type == "closed") {
    columnName = "Closed Date";
  } else if (dataSourceName == "Sayfa Case Register" && type == "opened") {
    columnName = "Opened Date";
  } else if (dataSourceName == "Lamar Case Register" && type == "opened") {
    columnName = "Ticket Start Date";
  } else {
    if (type == "opened") columnName = "Opened";
    else if (type == "closed") columnName = "Closed";
  }

  return columnName;
};

const GetFinalData = (gridData, dataSourceName, periodType) => {
  let FinaData_Relative = [];
  let output = [];
  let finalData;
  let count = 0;
  let countRec = 0;
  let keys = "";
  let column_name_Opened = getcolumnName(dataSourceName, "opened");
  let column_name_Closed = getcolumnName(dataSourceName, "closed");

  //convert opened and closed datetime to local
  ConvertedDateTimetoLocal(gridData, column_name_Opened, column_name_Closed);

  let NewArr = [],
    NewArrOpen = [],
    NewArrClosed = [],
    openCounter = 0,
    closeCounter = 0,
    uniqueValues = [],
    fndMax = [];

  let lastUpdatedDate = undefined;

  for (let i = 0; i < 4; i++) {
    let PeriodTypeDate = new Date();
    PeriodTypeDate = excludeWeekdays(PeriodTypeDate);

    let countRec = 0;
    NewArr = [];
    NewArrOpen = [];
    NewArrClosed = [];
    openCounter = 0;
    closeCounter = 0;
    let perTargerCounter = 0;
    let NewArrItem,
      totalperTarget = 0,
      totalRecords = 0;

    if (periodType == "1") {
      if (lastUpdatedDate == undefined) {
        lastUpdatedDate = PeriodTypeDate;
      }

      if (i > 0) {
        lastUpdatedDate.setDate(lastUpdatedDate.getDate() - 1);
        lastUpdatedDate = excludeWeekdays(lastUpdatedDate);
      }
      PeriodTypeDate = lastUpdatedDate;

      let filteredEl1 = _.find(gridData, function (data) {
        //counter++;
        let gridDate = new Date(data[column_name_Opened]).toLocaleDateString();
        let sysDate = new Date(PeriodTypeDate).toLocaleDateString();
        if (
          new Date(data[column_name_Opened]).toLocaleDateString() ==
          new Date(PeriodTypeDate).toLocaleDateString()
        ) {
          openCounter++;
          NewArrOpen.push(data);
          if ($.inArray(data, NewArr) === -1) NewArr.push(data); //for pecentageoftarget////to manage duplicate values
        }
        if (
          new Date(data[column_name_Closed]).toLocaleDateString() ==
          new Date(PeriodTypeDate).toLocaleDateString()
        ) {
          closeCounter++;

          NewArrClosed.push(data);
          if ($.inArray(data, NewArr) === -1) NewArr.push(data); //for pecentageoftarget////to manage duplicate values

          if (
            parseFloat(data["percentageOfTarget"]) * 100 <= 100 ||
            parseFloat(data["PercentageOfTarget"]) * 100 <= 100
          ) {
            perTargerCounter++;
          }
        }
      });
    }
    if (periodType == "7" || periodType == "30") {
      let periodTypeDays;
      if (periodType == "7") {
        periodTypeDays = 7;
      } else if (periodType == "30") {
        periodTypeDays = 30;
      }
      PeriodTypeDate.setDate(PeriodTypeDate.getDate() - i * periodTypeDays);

      PeriodTypeDate = excludeWeekdays(PeriodTypeDate);

      let uppelimit = PeriodTypeDate;
      let lowerLimit = new Date();

      lowerLimit = excludeWeekdays(lowerLimit);

      //set period according date
      lowerLimit.setDate(lowerLimit.getDate() - (i + 1) * periodTypeDays);

      lowerLimit = excludeWeekdays(lowerLimit);

      let openedCountMissing = 0;
      let openedCounterDateMissing = [];
      let closedCountMissing = 0;
      let closedCounterDateMissing = [];

      let filteredEl1 = _.find(gridData, function (data) {
        if (
          new Date(data[column_name_Opened]).setHours(0, 0, 0, 0) <=
            new Date(uppelimit).setHours(0, 0, 0, 0) &&
          new Date(data[column_name_Opened]).setHours(0, 0, 0, 0) >=
            new Date(lowerLimit).setHours(0, 0, 0, 0)
        ) {
          openCounter++;
          NewArrOpen.push(data);
          if ($.inArray(data, NewArr) === -1) NewArr.push(data); //for pecentageoftarget////to manage duplicate values
        } else {
          openedCountMissing++;
          openedCounterDateMissing.push(data);
        }

        if (
          new Date(data[column_name_Closed]).setHours(0, 0, 0, 0) <=
            new Date(uppelimit).setHours(0, 0, 0, 0) &&
          new Date(data[column_name_Closed]).setHours(0, 0, 0, 0) >=
            new Date(lowerLimit).setHours(0, 0, 0, 0)
        ) {
          closeCounter++;
          NewArrClosed.push(data);
          if ($.inArray(data, NewArr) === -1) NewArr.push(data); //for pecentageoftarget////to manage duplicate values

          if (
            parseFloat(data["percentageOfTarget"]) * 100 <= 100 ||
            parseFloat(data["PercentageOfTarget"]) * 100 <= 100
          ) {
            perTargerCounter++;
          }
        } else {
          closedCountMissing++;
          closedCounterDateMissing.push(data);
        }
      });

      closedCountMissing = 0;
      closedCounterDateMissing = [];

      openedCountMissing = 0;
      openedCounterDateMissing = [];
    }

    // $.each(NewArr, function (i, data11) {
    //   $.each(data11, function (i, e) {
    //     if (i == "percentageOfTarget") {
    //       totalperTarget = totalperTarget + e;
    //       totalRecords++;
    //     }
    //   });
    // });

    let avg;
    if (closeCounter > 0) {
      avg = Math.round((perTargerCounter / closeCounter) * 100);
    } else {
      avg = Math.round(perTargerCounter);
    }

    let per_dt = new Date(PeriodTypeDate);
    let item = {
      OpenedCount: openCounter,
      ClosedCount: closeCounter,
      Avgpercentage: avg,
      FilterDate: per_dt,
    };
    output.push(item);
  }

  $.each(output, function (i, datamx) {
    $.each(datamx, function (i, e) {
      if (i == "OpenedCount" || i == "ClosedCount") {
        fndMax.push(e);
      }
    });
  });

  var maxValue = arrayMax(fndMax);

  var j = 3;
  $.each(output, function (i, datamx) {
    var fnlOpenCnt = 0,
      fnlCloseCnt = 0,
      AvgfnlOpenCnt = 0,
      AvgfnlCloseCnt = 0,
      fnlFilterDate,
      fnlAvg;
    $.each(datamx, function (i, e) {
      if (i == "OpenedCount") {
        AvgfnlOpenCnt = Math.round((e / maxValue) * 100);
        fnlOpenCnt = Math.round(e);

        // set as 100 if greater
        if (AvgfnlOpenCnt > 100) {
          AvgfnlOpenCnt = 100;
        }
      }
      if (i == "ClosedCount") {
        //alert(e);
        AvgfnlCloseCnt = Math.round((e / maxValue) * 100);
        fnlCloseCnt = Math.round(e);

        // set as 100 if greater
        if (AvgfnlCloseCnt > 100) {
          AvgfnlCloseCnt = 100;
        }
      } else if (i == "Avgpercentage") {
        //alert(e);
        fnlAvg = Math.round(e);

        // set as 100 if greater
        if (fnlAvg > 100) {
          fnlAvg = 100;
        }
      } else if (i == "FilterDate") {
        //  alert(e);
        fnlFilterDate = e;
      }
      //FinaData_Relative.push(e);
    });

    var fnlItem = {
      id: j,
      OpenedCount: fnlOpenCnt,
      AvgOpenedCount: AvgfnlOpenCnt,
      ClosedCount: fnlCloseCnt,
      AvgClosedCount: AvgfnlCloseCnt,
      Avgpercentage: fnlAvg,
      FilterDate: fnlFilterDate,
    };

    FinaData_Relative.push(fnlItem);

    j--;
  });

  var date = moment(new Date(FinaData_Relative[0].FilterDate)).format(
    "DD MMM YYYY"
  );
  var date3 = moment(new Date(FinaData_Relative[3].FilterDate)).format(
    "DD MMM YYYY"
  );
  var date2 = moment(new Date(FinaData_Relative[2].FilterDate)).format(
    "DD MMM YYYY"
  );
  var date1 = moment(new Date(FinaData_Relative[1].FilterDate)).format(
    "DD MMM YYYY"
  );

  var data1 = {
    chart: [
      {
        id: FinaData_Relative[3].id,
        bigBar: FinaData_Relative[3].AvgOpenedCount,
        tinyBar: FinaData_Relative[3].AvgClosedCount,
        dot: FinaData_Relative[3].Avgpercentage,
      },
      {
        id: FinaData_Relative[2].id,
        bigBar: FinaData_Relative[2].AvgOpenedCount,
        tinyBar: FinaData_Relative[2].AvgClosedCount,
        dot: FinaData_Relative[2].Avgpercentage,
      },
      {
        id: FinaData_Relative[1].id,
        bigBar: FinaData_Relative[1].AvgOpenedCount,
        tinyBar: FinaData_Relative[1].AvgClosedCount,
        dot: FinaData_Relative[1].Avgpercentage,
      },
      {
        id: FinaData_Relative[0].id,
        bigBar: FinaData_Relative[0].AvgOpenedCount,
        tinyBar: FinaData_Relative[0].AvgClosedCount,
        dot: FinaData_Relative[0].Avgpercentage,
      },
    ],
    bigbox: {
      title: "Current period (" + periodType + " days, ending: " + date + ")", //"Current period (5 work days, ending: 17 Feb 2015)",
      percent: FinaData_Relative[0].Avgpercentage,
      open: FinaData_Relative[0].OpenedCount,
      closed: FinaData_Relative[0].ClosedCount,
    },
    smbox: [
      {
        title: date3,
        percent: FinaData_Relative[3].Avgpercentage,
        open: FinaData_Relative[3].OpenedCount,
        closed: FinaData_Relative[3].ClosedCount,
      },
      {
        title: date2,
        percent: FinaData_Relative[2].Avgpercentage,
        open: FinaData_Relative[2].OpenedCount,
        closed: FinaData_Relative[2].ClosedCount,
      },
      {
        title: date1,
        percent: FinaData_Relative[1].Avgpercentage,
        open: FinaData_Relative[1].OpenedCount,
        closed: FinaData_Relative[1].ClosedCount,
      },
    ],
  };
  return data1;
};

const ConvertedDateTimetoLocal = (
  grid_data,
  column_name_Opened,
  column_name_Closed
) => {
  for (let i = 0; i < grid_data.length; i++) {
    grid_data[i][column_name_Opened] = ConvertToLocalDateTimeWithFormat(
      grid_data[i][column_name_Opened],
      "DD MMM YYYY hh:mm A"
    );
    grid_data[i][column_name_Closed] = ConvertToLocalDateTimeWithFormat(
      grid_data[i][column_name_Closed],
      "DD MMM YYYY hh:mm A"
    );
  }
};

const excludeWeekdays = (dateVal) => {
  if (dateVal.getDay() == 6) {
    dateVal.setDate(dateVal.getDate() - 1);
  } else if (dateVal.getDay() == 0) {
    dateVal.setDate(dateVal.getDate() - 2);
  }
  return dateVal;
};

const arrayMax = (arr) => {
  let len = arr.length,
    max = -Infinity;
  while (len--) {
    if (arr[len] > max) {
      max = arr[len];
    }
  }
  return max;
};

export default GetFinalData;
