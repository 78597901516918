import {
  Get_MetaData_List_Url,
  Get_All_Filter_List_Url,
  SwapOrder_Widget,
  Get_Widget_Settings,
  Get_Widget_Data_Url,
  Get_Last_Refresh_Datetime,
  Get_Widget_Data_By_Column,
  Get_Video_Feeds,
  Save_Video_Feed,
  IS_TESTAPI_SERVICE_URL_Enabled,
  Get_DataSources,
  Connection_List_Url,
  Get_Filter_Details_Url,
  Get_Widgets,
  Clone_Widget,
  DecodeFrom64Str,
  Activate_New_User,
  Get_Opportunity_Data_Url,
} from "../Constants";
import appStore from "../store";
import { ReplaceSpecialCharToText } from "./WidgetDataCommon";

let googleDataSource = "Generic Google Sheet DataSource";

const GetMetaData = (datasourceName, connectionName, SessionId) => {
  let url = `${Get_MetaData_List_Url}/${datasourceName}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: SessionId,
    },
  }).then((response) => response.json());
};

const GetAllFilter = (datasourceName, connectionName, SessionId, email) => {
  let domainName = "";
  if (email !== "") {
    // if (email.toLowerCase().indexOf("gmail.com")) {
    //   domainName = email;
    // } else {
    //domainName = email.split("@")[1];
    //}
    domainName = email.split("@")[1];
  }

  let url = `${Get_All_Filter_List_Url}/${datasourceName}/${domainName}`;
  return fetch(url, {
    method: "GET",
    //mode: "no-cors",
    //credentials: "omit",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: SessionId,
    },
  }).then((response) => response.json());
};

const GridMetaDataFormat = (metaData, isTeamEnabled = false) => {
  var gridMetaDataObj = [];
  if (Array.isArray(metaData)) {
    for (var i = 0; i < metaData.length; i++) {
      var jsonObj = {};
      var gridObj = metaData[i];

      var getDataField = gridObj.find(function (data) {
        return data["value"] === "_id" && data["name"] === "dataField";
      });

      if (!getDataField) {
        for (var j = 0; j < gridObj.length; j++) {
          var getValue = gridObj[j]["value"];
          var getName = gridObj[j]["name"];
          if (getName !== "_id" && getName !== "datasourceName") {
            jsonObj[getName] = getValue;
          }
        }

        //check and add team column conditionally
        if (jsonObj.dataField !== "team" || isTeamEnabled) {
          gridMetaDataObj.push(jsonObj);
        }
      }
    }
  }

  var griddata1 = {};
  griddata1["Grid Meta-Data"] = gridMetaDataObj;

  return griddata1;
};

const SwapWidgetOrder = (order1, layoutId) => {
  const order2 = appStore.getState().layout.dragItemOrderId;
  let order = [];
  order.push(order1);
  order.push(order2);
  order.push(layoutId);
  if (order1 !== order2) {
    let url = `${SwapOrder_Widget}`;
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(order),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then((response) => response);
  }

  return new Promise((resolve, reject) => {
    resolve();
  });
};

const GetWidgetSetting = (widgetId) => {
  const url = `${Get_Widget_Settings}?widgetId=${widgetId}`;
  return fetch(url).then((response) => response.json());
};

const GetWidgetDt = (
  FilterId,
  datasourceName,
  connectionName,
  SessionId,
  isSharedPage,
  sharedUserEmail,
  isSharedFilter,
  sharedFilterUserId,
  filterId
) => {
  let filterName = getFilterIdSpecialCharToText(FilterId);
  if (filterName === "" || filterName === undefined) {
    filterName = null;
  }

  let queryStr = "";
  if (isSharedPage) {
    queryStr =
      "?sharedPageUserId=" + sharedUserEmail + "&isSharedPage=" + isSharedPage;
  }

  if (isSharedFilter === true) {
    if (queryStr.length > 0) {
      queryStr =
        "&sharedFilterUserId=" +
        sharedFilterUserId +
        "&isSharedFilter=" +
        isSharedFilter +
        "&filterId=" +
        filterId;
    } else {
      queryStr =
        "?sharedFilterUserId=" +
        sharedFilterUserId +
        "&isSharedFilter=" +
        isSharedFilter +
        "&filterId=" +
        filterId;
    }
  }

  let url = `${Get_Widget_Data_Url}/${datasourceName}/${filterName}/caseregister${queryStr}`;
  if (datasourceName === googleDataSource) {
    url = `${Get_Widget_Data_Url}/${datasourceName} ${connectionName}/${filterName}/caseregister${queryStr}`;
  }
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: SessionId,
    },
  }).then((response) => {
    return response.json();
  });
};

const GetLastRefreshDateTimeDt = (datasourceName, connetionName, SessionId) => {
  let url = `${Get_Last_Refresh_Datetime}/${datasourceName}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: SessionId,
    },
  }).then((response) => response.json());
};

const getColorPallets = (datasourceName, columnName, SessionId) => {
  let url = `${Get_Widget_Data_By_Column}/${datasourceName}/${columnName}`;
  if (columnName !== "") {
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        sessionId: SessionId,
      },
    }).then((response) => response.json());
  } else {
    return Promise.resolve();
  }
};

const getVideoFeeds = (datasourceName, SessionId) => {
  let url = `${Get_Video_Feeds}?datasourceName=${datasourceName}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: SessionId,
    },
  }).then((response) => response.json());
};

const saveVideoFeedUrl = (datasourceName, feedUrl, userEmail, sessionId) => {
  let formdata = new URLSearchParams({
    streamUrl: feedUrl,
    userId: userEmail,
    dataSourceName: datasourceName,
    id: "0",
  });

  let url = `${Save_Video_Feed}`;
  return fetch(url, {
    method: "POST",
    body: formdata.toString(),
    headers: {
      sessionId: sessionId,
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  }).then((response) => response.json());
};

const getConnectionList = (datasourceName, email, sessionId) => {
  const url = `${Connection_List_Url}/${datasourceName}/${email}/${sessionId}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response.json());
};

const getDataSourcesAndFilters = (email, sessionId) => {
  const url = `${Get_DataSources}?SessionId=${sessionId}&email=${email}&isTestApi=${IS_TESTAPI_SERVICE_URL_Enabled}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response.json());
};

const getSalesWidgetData = (
  FilterId,
  datasourceName,
  sessionId,
  isSharedPage,
  sharedUserEmail,
  isSharedFilter,
  sharedFilterUserId,
  filterId,
  type
) => {
  const filterName = getFilterIdSpecialCharToText(FilterId);
  if (filterName === "" || filterName === undefined) {
    filterName = null;
  }

  let queryStr = "";
  if (isSharedPage) {
    queryStr =
      "?sharedPageUserId=" + sharedUserEmail + "&isSharedPage=" + isSharedPage;
  }

  if (isSharedFilter === true) {
    if (queryStr.length > 0) {
      queryStr =
        "&sharedFilterUserId=" +
        sharedFilterUserId +
        "&isSharedFilter=" +
        isSharedFilter +
        "&filterId=" +
        filterId;
    } else {
      queryStr =
        "?sharedFilterUserId=" +
        sharedFilterUserId +
        "&isSharedFilter=" +
        isSharedFilter +
        "&filterId=" +
        filterId;
    }
  }

  let url = `${Get_Widget_Data_Url}/${datasourceName}/${filterName}/${type}${queryStr}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: sessionId,
    },
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getFilterDetail = (filterId, SessionId) => {
  let url = `${Get_Filter_Details_Url}/${filterId}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: SessionId,
    },
  }).then((response) => response.json());
};

const getWidgetList = (pageId) => {
  const url = `${Get_Widgets}?pageId=${pageId}`;
  return fetch(url).then((response) => response.json());
};

const cloneWidget = (currentWidgetId, cloneWidgetId) => {
  let url = `${Clone_Widget}?currentWidgetId=${currentWidgetId}&cloneWidgetId=${cloneWidgetId}`;
  return fetch(url, {
    method: "POST",
  }).then((response) => response.json());
};

const decodeFrom64String = (token) => {
  let url = `${DecodeFrom64Str}`;
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      encodedData: token,
    }),
  }).then((response) => {
    return response.text();
  });
};

const activateNewUser = (email) => {
  let url = `${Activate_New_User}`;
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      emailid: email,
    }),
  }).then((response) => {
    return response.text();
  });
};

const getFilterIdSpecialCharToText = (filterVal) => {
  if (filterVal !== null && filterVal.indexOf("undefined") === -1) {
    filterVal = ReplaceSpecialCharToText(filterVal);
  }
  return filterVal;
};

const getOpportunityData = (
  FilterId,
  datasourceName,
  sessionId,
  isSharedPage,
  sharedUserEmail,
  isSharedFilter,
  sharedFilterUserId,
  filterId
) => {
  const filterName = getFilterIdSpecialCharToText(FilterId);
  if (filterName === "" || filterName === undefined) {
    filterName = null;
  }

  let queryStr = "";
  if (isSharedPage) {
    queryStr =
      "?sharedPageUserId=" + sharedUserEmail + "&isSharedPage=" + isSharedPage;
  }

  if (isSharedFilter === true) {
    if (queryStr.length > 0) {
      queryStr =
        "&sharedFilterUserId=" +
        sharedFilterUserId +
        "&isSharedFilter=" +
        isSharedFilter +
        "&filterId=" +
        filterId;
    } else {
      queryStr =
        "?sharedFilterUserId=" +
        sharedFilterUserId +
        "&isSharedFilter=" +
        isSharedFilter +
        "&filterId=" +
        filterId;
    }
  }

  //let url = `${Get_Opportunity_Data_Url}/${datasourceName}/${filterName}/${type}${queryStr}`;
  let url = `${Get_Opportunity_Data_Url}/${datasourceName}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: sessionId,
    },
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const GetOOSWidgetDt = (
  FilterId,
  datasourceName,
  connectionName,
  SessionId,
  isSharedPage,
  sharedUserEmail,
  isSharedFilter,
  sharedFilterUserId,
  filterId
) => {
  let filterName = getFilterIdSpecialCharToText(FilterId);
  if (filterName === "" || filterName === undefined) {
    filterName = null;
  }

  let queryStr = "";
  if (isSharedPage) {
    queryStr =
      "?sharedPageUserId=" + sharedUserEmail + "&isSharedPage=" + isSharedPage;
  }

  if (isSharedFilter === true) {
    if (queryStr.length > 0) {
      queryStr =
        "&sharedFilterUserId=" +
        sharedFilterUserId +
        "&isSharedFilter=" +
        isSharedFilter +
        "&filterId=" +
        filterId;
    } else {
      queryStr =
        "?sharedFilterUserId=" +
        sharedFilterUserId +
        "&isSharedFilter=" +
        isSharedFilter +
        "&filterId=" +
        filterId;
    }
  }

  let url = `${Get_Widget_Data_Url}/${datasourceName}/${filterName}/bubblewidget${queryStr}`;
  if (datasourceName === googleDataSource) {
    url = `${Get_Widget_Data_Url}/${datasourceName} ${connectionName}/${filterName}/bubblewidget${queryStr}`;
  }
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: SessionId,
    },
  }).then((response) => {
    return response.json();
  });
};

export default GetMetaData;

export const GridMetaData_Format = GridMetaDataFormat;
export const GetAllFilterList = GetAllFilter;
export const SwapWidgetOrders = SwapWidgetOrder;
export const GetWidgetSettings = GetWidgetSetting;
export const GetWidgetData = GetWidgetDt;
export const GetLastRefreshDateTime = GetLastRefreshDateTimeDt;
export const GetColorPallets = getColorPallets;
export const GetVideoFeeds = getVideoFeeds;
export const SaveVideoFeedUrl = saveVideoFeedUrl;
export const GetConnectionList = getConnectionList;
export const GetDataSourcesAndFilters = getDataSourcesAndFilters;
export const GetSalesWidgetData = getSalesWidgetData;
export const GetFilterDetail = getFilterDetail;
export const GetWidgetList = getWidgetList;
export const AddCloneWidget = cloneWidget;
export const DecodeFrom64Sting = decodeFrom64String;
export const ActivateNewUser = activateNewUser;
export const GetOpportunityData = getOpportunityData;
export const GetOOSWidgetData = GetOOSWidgetDt;
