import React from "react";
import styles from "./Template.module.css";

const TwitterTemplate = (props) => {
  return (
    <div>
      <div id="tw1" className={styles["twitter-article"]}>
        <div className={styles["twitter-pic"]}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://twitter.com/${props.item.screenName}`}
          >
            <img
              width="42"
              height="42"
              alt="twitter icon"
              src={props.item.profileImage}
            />
          </a>
        </div>
        <div className={styles["twitter-text"]}>
          <p>
            <span className="tweetprofilelink">
              <strong>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://twitter.com/${props.item.screenName}`}
                >
                  {props.item.screenName}
                </a>
              </strong>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://twitter.com/${props.item.screenName}`}
              >
                &nbsp;@{props.item.screenName}
              </a>
            </span>
            <span className={styles["tweet-time"]}>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://twitter.com/${props.item.screenName}/status/${props.item.id_str}`}
              >
                {props.item.createdDate}
              </a>
            </span>
            <br />
            <span dangerouslySetInnerHTML={{ __html: props.item.text }}></span>
          </p>
          <div
            id={styles["twitter-actions"]}
            style={{ display: "block", opacity: "1", marginTop: "5px" }}
          >
            <div id={styles["intent-reply"]} className={styles["intent"]}>
              <a
                title="Reply"
                rel="noreferrer"
                href={`https://twitter.com/intent/tweet?in_reply_to=${props.item.id_str}`}
              ></a>
            </div>
            <div id={styles["intent-retweet"]} className={styles["intent"]}>
              <a
                title="Retweet"
                rel="noreferrer"
                href={`https://twitter.com/intent/retweet?tweet_id=${props.item.id_str}`}
              ></a>
            </div>
            <div id={styles["intent-fave"]} className={styles["intent"]}>
              <a
                title="Favourite"
                rel="noreferrer"
                href={`https://twitter.com/intent/favorite?tweet_id=${props.item.id_str}`}
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwitterTemplate;
