export const Today = "custom_filter_today";
export const Yesterday = "custom_filter_yesterday";
export const Last7days = "custom_filter_last_7_days";
export const Last30days = "custom_filter_last_30_days";
export const WorkingDaysOnly = "custom_filter_working_days_only";
export const LastMonth = "custom_filter_last_month";
export const ThisMonth = "custom_filter_this_month";
export const BeforeToday = "custom_filter_before_td";

export const Operator_Today = "today";
export const Operator_Yesterday = "yesterday";
export const Operator_Last7days = "last_7_days";
export const Operator_Last30days = "last_30_days";
export const Operator_WorkingDaysOnly = "working_days_only";
export const Operator_LastMonth = "last_month";
export const Operator_ThisMonth = "this_month";
export const Operator_BeforeToday = "before_td";
export const Operator_Null = "null";
export const Operator_NotNull = "notNull";
