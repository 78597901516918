import React, { useState } from "react";
import GridLayout from "react-grid-layout";
import _ from "lodash";

const PageLayout = (props) => {
  /**
   * The `react-grid-layout` lib is not swapping items during horizontal dragover
   * Rather it moves the items into a new row
   * Since we need a static 3x3 row, let's fix that
   */
  const fixLayout = (layout) => {
    // `y` is calculated by `h` in the layout object, since `h` is 20
    // first row will be 0, second 20, third 40
    const maxY = 40;

    // when an item goes to a new row, there is an empty column in the maxY row
    // so here we find which columns exist
    // tslint:disable-next-line:max-line-length
    const maxRowXs = layout
      .map((item) => (item.y === maxY ? item.x : null))
      .filter((value) => value !== null);

    // xs or cols, we only have 3 cols
    const xs = [0, 1, 2];

    // find the missing col
    // tslint:disable-next-line:max-line-length
    const missingX = xs.find((value) =>
      maxRowXs.every((maxRowX) => maxRowX !== value)
    );

    // bring the item from the new row into maxY row
    // and place it in the missing column
    const fixedLayout = layout.map((item) => {
      if (item.y > maxY) {
        return {
          ...item,
          y: maxY,
          x: missingX,
        };
      }
      return item;
    });
    return fixedLayout;
  };

  return (
    <div id="NewTemplate">
      {/* <div>
        {addBtnDisabled || <button onClick={onAddItem}>Add Item</button>}
      </div> */}

      <GridLayout
        className="layout"
        cols={4}
        onLayoutChange={props.onLayoutChange}
        layouts={{ lg: props.layout }}
        rowHeight={props.rowHeight}
        width={800}
        maxRows={3}
        margin={[12, 12]}
        preventCollision={true}
        compactType={null}
        onResizeStop={props.onResizeStop}
      >
        {props.items.map((el) => props.createElementHandler(el))}
      </GridLayout>
    </div>
  );
};
export default PageLayout;
