import {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import DataSectionSettings from "../../../../UI/TemplateControls/DataSection";
import WidgetTitleControl from "../../../../UI/TemplateControls/WidgetTitleControl";
import { DataSectionObject } from "../../../../../Helper/CommonModel";
import OOSContent from "./ConfigContent/OOSContent";
import { BUBBLE_CHART } from "../../../../../Constants";
import { useDispatch } from "react-redux";
import { pageActions } from "../../../../../store/page-slice";

const OpenOpportunitySummaryTemplate = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [showSettingsPanel, setShowSettingsPanel] = useState(true);
  const [widgetContentPanel, setWidgetContentPanel] = useState(false);
  const [titleContentPanel, setTitleContentPanel] = useState(false);
  const [dtSourceItems, setDtSourceItems] = useState([]);
  const [title, setTitle] = useState("Open Opportunity Summary");
  const [shortTitle, setShortTitle] = useState("Open Opportunity Summary");
  const [titleIsValid, setTitleIsValid] = useState(true);
  const [shortTitleIsValid, setShortTitleIsValid] = useState(true);
  const [xAxisVal, setXAxisVal] = useState({ value: "", isValid: true });
  const xAxisVal1 = useRef("");
  const bubbleText1 = useRef("");
  const [bubbleText, setBubbleText] = useState({ value: "", isValid: true });
  const dataSectionRef = useRef();
  const dataSection = useRef(DataSectionObject);

  useEffect(() => {
    if (
      props.dataSources !== undefined &&
      Object.keys(props.dataSources.Reports).length !== 0
    ) {
      const _items = Object.entries(props.dataSources.Reports)
        .filter((item) => {
          if (item[1] === BUBBLE_CHART) {
            return true;
          }
          return false;
        })
        .map((item) => item[0]);
      setDtSourceItems(_items);
    }

    if (
      props.dataSources !== undefined &&
      props.isEdit === true &&
      props.settings !== undefined &&
      props.settings["reports"] !== undefined
    ) {
      setTitle(props.settings["Title"]);
      setShortTitle(props.settings["ShortTitle"]);
      setBubbleText({ value: props.settings["bubbleText"], isValid: true });
      setXAxisVal({ value: props.settings["xAxisValue"], isValid: true });
      xAxisVal1.current = props.settings["xAxisValue"];
      bubbleText1.current = props.settings["bubbleText"];
      dispatch(
        pageActions.setXAxisValBubbleChart(
          parseInt(props.settings["xAxisValue"])
        )
      );
    }
  }, [props.dataSources, props.settings]);

  useImperativeHandle(ref, () => ({
    GetValidateData() {
      const getVaildForm = validateForm();
      if (getVaildForm) {
        return {
          formIsValid: getVaildForm,
          data: {
            Filter: dataSection.current.filterName,
            FilterId: dataSection.current.filterDetails.filterid,
            IsSharedFilter: dataSection.current.filterDetails.filterid,
            OriginalUserId: dataSection.current.filterDetails.originaluserid,
            ShortTitle: shortTitle,
            Title: title,
            reports: dataSection.current.dataSourceName,
            xAxisValue: xAxisVal.value,
            bubbleText: bubbleText.value,
          },
        };
      } else {
        return {
          formIsValid: getVaildForm,
        };
      }
    },
  }));

  const onSettingsClicked = (val) => {
    if (val) {
      setShowSettingsPanel(val);
    } else {
      setShowSettingsPanel(!showSettingsPanel);
    }
    setTitleContentPanel(false);
    setWidgetContentPanel(false);
  };

  const onTitleContentPanelClicked = (val) => {
    if (val) {
      setTitleContentPanel(val);
    } else {
      setTitleContentPanel(!titleContentPanel);
    }
    setShowSettingsPanel(false);
    setWidgetContentPanel(false);
  };

  const OnTitleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const OnShortTitleChangeHandler = (event) => {
    setShortTitle(event.target.value);
  };

  const onDatasourceChangeHandler = (val) => {};

  const onWidgetContentClicked = (val) => {
    if (val) {
      setWidgetContentPanel(val);
    } else {
      setWidgetContentPanel(!widgetContentPanel);
    }
    setShowSettingsPanel(false);
    setTitleContentPanel(false);
  };

  const validateTitle = () => {
    if (title === "") {
      onTitleContentPanelClicked(true);
      setTitleIsValid(false);
      setTimeout(() => {
        setTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateShortTitle = () => {
    if (shortTitle === "") {
      onTitleContentPanelClicked(true);
      setShortTitleIsValid(false);
      setTimeout(() => {
        setShortTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateXAxisVal = () => {
    if (xAxisVal.value === "") {
      onWidgetContentClicked(true);
      setXAxisVal({ ...xAxisVal, isValid: false });
      setTimeout(() => {
        setXAxisVal({ ...xAxisVal, value: xAxisVal1.current, isValid: true });
      }, 3000);
      return false;
    }

    return true;
  };

  const validateBubbleText = () => {
    if (bubbleText.value === "") {
      onWidgetContentClicked(true);
      setBubbleText({ ...bubbleText, isValid: false });
      setTimeout(() => {
        setBubbleText({
          ...bubbleText,
          value: bubbleText1.current,
          isValid: true,
        });
      }, 3000);
      return false;
    }
    return true;
  };

  const validateForm = () => {
    const dataSectionVal = dataSectionRef.current.validateDataSection();
    dataSection.current = dataSectionVal.data;
    if (!dataSectionVal.isDataSectionValid) {
      onSettingsClicked(true);
      return false;
    }

    const isTitleValid = validateTitle();
    if (!isTitleValid) {
      return false;
    }

    const isShortTitleValid = validateShortTitle();
    if (!isShortTitleValid) {
      return false;
    }

    const isXAxisValValid = validateXAxisVal();
    if (!isXAxisValValid) {
      return false;
    }

    const isBubbleTextValid = validateBubbleText();
    if (!isBubbleTextValid) {
      return false;
    }

    return true;
  };

  const onChangeXAxisHandler = (event) => {
    xAxisVal1.current = event.target.value;
    setXAxisVal({ ...xAxisVal, value: event.target.value });
    dispatch(pageActions.setXAxisValBubbleChart(parseInt(event.target.value)));
  };

  const onBubbleTextChangeHandler = (event) => {
    bubbleText1.current = event.target.value;
    setBubbleText({ ...xAxisVal, value: event.target.value });
  };

  return (
    <Fragment>
      {!props.isEdit && <hr className="style-one" />}
      <div onClick={onSettingsClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {showSettingsPanel && "-"}
          {!showSettingsPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>DATA</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: showSettingsPanel ? "block" : "none",
        }}
      >
        <DataSectionSettings
          dataSourceList={dtSourceItems}
          dtSourceChange={onDatasourceChangeHandler}
          ref={dataSectionRef}
          settings={props.settings}
          isEdit={props.isEdit}
        />
      </div>
      <div onClick={onTitleContentPanelClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {titleContentPanel && "-"}
          {!titleContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET TITLE</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: titleContentPanel ? "block" : "none",
        }}
      >
        <WidgetTitleControl
          titleVal={title}
          shortTitleVal={shortTitle}
          OnTitleChangeHandler={OnTitleChangeHandler}
          OnShortTitleChangeHandler={OnShortTitleChangeHandler}
          isTitleValid={titleIsValid}
          isShortTitleValid={shortTitleIsValid}
        />
      </div>

      <div onClick={onWidgetContentClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {widgetContentPanel && "-"}
          {!widgetContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET CONTENT</p>
      </div>
      <div
        style={{
          paddingLeft: "12px",
          display: widgetContentPanel ? "block" : "none",
        }}
      >
        <OOSContent
          onChangeXAxisHandler={onChangeXAxisHandler}
          xaxisVal={xAxisVal}
          bubbleText={bubbleText}
          bubbleTextList={[
            { text: "Organization", value: "organization" },
            { text: "Objective", value: "objective" },
            { text: "Stage Name", value: "stageName" },
            { text: "Sales Person", value: "salesPerson" },
          ]}
          onBubbleTextChange={onBubbleTextChangeHandler}
        />
      </div>
    </Fragment>
  );
});

export default OpenOpportunitySummaryTemplate;
