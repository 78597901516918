import React from "react";
import { pageActions } from "../../../../store/page-slice";
import { useDispatch } from "react-redux";
import clonePageImage from "../../../../assets/clone.png";
import styles from "./CloneImg.module.css";

const CloneImg = (props) => {
  const dispatch = useDispatch();
  const onClonePagehandler = () => {
    dispatch(pageActions.triggerClonePage());
    dispatch(pageActions.setClonePageId(props.id));
  };

  return (
    <React.Fragment>
      <img
        title="Clone Page"
        className={styles["clone-img"]}
        src={clonePageImage}
        alt="No Image"
        onClick={onClonePagehandler}
      />
      <span
        title="Clone Page"
        className={styles["clone-spn"]}
        onClick={onClonePagehandler}
      >
        CLONE
      </span>
    </React.Fragment>
  );
};

export default CloneImg;
