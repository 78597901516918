import { useEffect, useState } from "react";
import Loader from "../../../UI/Loader";
import RecycleBinService from "./RecycleBinService";
import { useSelector } from "react-redux";
import { ConvertToLocalDateTimeWithFormat } from "../../../../Helper/WidgetDataCommon";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../../../store/layout-slice";

const RestoreWidgets = ({ resetToMainMenu }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.layout.UserId);
  const layoutId = useSelector((state) => state.layout.layoutId);
  const sessionId = useSelector((state) => state.login.sessionId);
  const [enableLoader, setEnableLoader] = useState(false);
  const [deletedWidgets, setDeletedWidgets] = useState([]);

  useEffect(() => {
    getDeletedWidgets();
  }, []);

  const getDeletedWidgets = () => {
    setEnableLoader(true);
    RecycleBinService.getDeletedWidgets(sessionId)
      .then((resp) => {
        if (resp) {
          const widgets = [...resp.Message.Widgets];
          let _widgets = [...widgets].map((x) => {
            let item = { ...x };
            item["DeletedOn"] = ConvertToLocalDateTimeWithFormat(
              item.DeletedOn,
              "MMM DD YYYY, h:mm A"
            );
            return item;
          });

          setDeletedWidgets(_widgets);
        }
        setEnableLoader(false);
      })
      .catch((err) => {
        console.log("getDeletedWidgets err:", err);
        setEnableLoader(false);
      });
  };

  const restoreWidget = (id, pageId) => {
    console.log("id", id);
    RecycleBinService.isAlreadyWidgetExists(id)
      .then((res) => {
        console.log("res", res);
        if (res === "true") {
          const r = window.confirm(
            "Widget is already exists. Do you want to replace with this widget?"
          );
          if (r) {
            RecycleBinService.restoreWidget(id)
              .then((res) => {
                if (layoutId === pageId) {
                  console.log("layout matched");
                  dispatch(layoutActions.setWidgetRestore());
                }
                getDeletedWidgets();
              })
              .catch((err) => {});
          }
        } else {
          RecycleBinService.restoreWidget(id)
            .then((res) => {
              if (layoutId === pageId) {
                console.log("layout matched");
                dispatch(layoutActions.setWidgetRestore());
              }
              getDeletedWidgets();
            })
            .catch((err) => {});
        }
      })
      .catch((err) => {
        console.log("isAlreadyWidgetExists err:", err);
      });
  };

  return (
    <div>
      {!enableLoader && (
        <div className="recycle-bin">
          <ul className="breadcrumb">
            <li>
              <a
                href=""
                onContextMenu={() => {
                  return false;
                }}
                onClick={(event) => {
                  event.preventDefault();
                  resetToMainMenu();
                }}
              >
                Rycycle Bin
              </a>
            </li>
            <li>Widgets</li>
          </ul>
          <div className="main-container">
            <table
              className="table table-hover"
              style={{ tableLayout: "fixed", wordWrap: "break-word" }}
            >
              <thead>
                <tr>
                  <th>Widget Title</th>
                  <th>Page Name</th>
                  <th>Last Modified</th>
                  <th className="action-col">Action</th>
                </tr>
              </thead>
              <tbody>
                {deletedWidgets.map((item) => {
                  return (
                    <tr key={item.Id}>
                      <td className="wrap-content" title={item.Title}>
                        {item.Title}
                      </td>
                      <td className="wrap-content" title={item.PageName}>
                        {item.PageName}
                      </td>
                      <td
                        className="wrap-content"
                        title={`${item.UserName}, ${item.DeletedOn}`}
                      >{`${item.UserName}, ${item.DeletedOn}`}</td>
                      <td style={{ textAlign: "center" }}>
                        <a
                          href=""
                          title="Restore Widget"
                          className="icons_cls"
                          style={{
                            fontSize: "18px",
                            color: "gray",
                            textDecoration: "none",
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            restoreWidget(item.Id, item.PageId);
                          }}
                        >
                          <i title="Restore Widget" className="fa fa-undo"></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {enableLoader && <Loader styles={{ height: "80px" }} />}
    </div>
  );
};

export default RestoreWidgets;
