import React from "react";

const ConnectionControl = (props) => {
  return (
    <React.Fragment>
      <p>Connection</p>
      <select
        value-key="connection"
        value={props.value}
        onChange={props.onChangeHandler}
      >
        <option value="">Please Select</option>
        {props.dtList.map((item, index) => {
          return (
            <option key={index} value={item.value}>
              {item.text}
            </option>
          );
        })}
      </select>
      <span
        style={{
          display: props.isValid ? "none" : "block",
          color: "red",
          paddingLeft: "5px",
        }}
      >
        Required
      </span>
    </React.Fragment>
  );
};

export default ConnectionControl;
