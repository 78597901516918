import { memo } from "react";
import { ConfiguratorDeleteButton } from "../Common/DeleteActionButton";
import { ConfiguratorEditButton } from "../Common/EditActionButton";
import { useEffect } from "react";
import { useState } from "react";
import FormControl from "../Common/FormControl";
import SharedUtil from "../Util";
import { ConfiguratorUpdateButton } from "../Common/UpdateActionControl";
import { ConfiguratorCancelButton } from "../Common/CancelActionButton";
import { useSelector } from "react-redux";
import ConfiguratorServices from "../ConfiguratorService";
import { Draggable } from "react-beautiful-dnd";
import ConfigrationConstant from "../Common/ConfigurationConstant";
import PopoverControl from "../Common/PopOverControl";

const ConfigurationTypeSettingItem = ({
  settingColumns,
  index,
  isSaveMode,
  settings,
  timeZoneList,
  isAddEditDisable,
  disabledAddEdit,
  settingName,
  dataSourceId,
  columnSettingId,
  sessionId,
  onFinishAddSettings,
  dataSourceType,
  onChangeHandler,
  onCancelEvent,
  teamList,
  salesOppOwnerList,
}) => {
  const email = useSelector((state) => state.login.userDetail.email);
  const userName = useSelector((state) => state.login.userDetail.userFullName);
  const [rowItems, setRowItems] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [validationCounter, setValidationCounter] = useState(0);
  useEffect(() => {
    bindEditData();
  }, [settingColumns, settings]);

  const bindEditData = () => {
    let formObjList = [];
    for (const key of settingColumns) {
      const formObj = { ...key };
      formObj["errorMessage"] = "";
      formObj["displayValue"] = settings[key["dataField"]];

      //bind parameter to form property
      if (settingName === "Calculation Options") {
        formObj["parameter"] = settings["parameter"];
      }

      if (key["type"] === "Clock") {
        formObj["value"] = SharedUtil.getTwentyFourHourTime(
          settings[key["dataField"]]
        );
      } else {
        formObj["value"] = settings[key["dataField"]];
      }

      //map timezone id to time zone column
      if (key["dataField"] === "time_zone") {
        formObj["value"] = settings["timezoneID"];
      }

      formObjList.push(formObj);
    }
    setRowItems(formObjList);
  };

  const editHandler = (event, setting) => {
    event.preventDefault();
    if (isSaveMode || isAddEditDisable) return false;
    bindEditData();
    setIsEdit(true);
    disabledAddEdit(true);
  };

  const handleFormChange = (index, event, isDate) => {
    let value = "",
      name = "";

    if (isDate) {
      value = event;
    } else {
      value = event.target.value;
      name = event.target.name;
    }
    const updateFormControl = SharedUtil.bindValueOnChange(
      settingName,
      rowItems,
      index,
      value,
      name,
      isDate,
      teamList,
      salesOppOwnerList
    );

    setRowItems(updateFormControl);
    onChangeHandler(value);
  };

  const onUpdateHandler = (event) => {
    event.preventDefault();
    if (isEditing) return false;

    setIsEditing(true);
    const validateForm = SharedUtil.validateSettings(
      rowItems,
      settingName,
      validationCounter
    );

    if (!validateForm.isValid) {
      const data = [...validateForm.result];
      setRowItems(data);
      hideValidation(data);

      if (
        validateForm.validationCounter !== undefined &&
        validateForm.validationCounter > 0
      ) {
        setValidationCounter(validateForm.validationCounter);
      }
      setIsEditing(false);
      return false;
    }

    let finalFormDetails = {};
    for (const key of rowItems) {
      finalFormDetails[key["dataField"]] = key["value"];

      if (key["type"] === "Clock") {
        finalFormDetails[key["dataField"]] = SharedUtil.tConvert(key["value"]);
      }

      if (finalFormDetails["timezoneID"] !== undefined) {
        var getTimezoneObj = timeZoneList.filter(function (dt) {
          return dt.timezoneID === finalFormDetails["time_zone"];
        });
        if (getTimezoneObj && getTimezoneObj.length > 0) {
          finalFormDetails["time_zone"] = getTimezoneObj[0]["timezone"];
          finalFormDetails["timezoneID"] = getTimezoneObj[0]["timezoneID"];
        }
      }
    }

    //get column name and values seperated two different array
    const columnNames = Object.keys(finalFormDetails);
    const columnValues = Object.values(finalFormDetails);

    //new data format
    let objData = {
      datasourceType: dataSourceType,
      dataSourceId: dataSourceId,
      columnSettingId: columnSettingId,
      actionName: "update",
      settingsId: settings["settingsId"],
      columnNames: columnNames,
      newValues: columnValues,
      displayOrder: settings["displayOrder"],
      userEmail: email,
      lastModifiedBy: userName,
    };

    ConfiguratorServices.InsertUpdateSettings(objData, sessionId)
      .then((res) => {
        if (res.indexOf("Data is already exists") > -1) {
          const fieldName = rowItems[0];
          const isDuplicate = duplicateMessage(
            fieldName["dataField"],
            "Record already exists"
          );
          setIsEditing(false);
          if (!isDuplicate) return false;
        } else {
          setIsEdit(false);
          disabledAddEdit(false);
          onFinishAddSettings();
          setIsEditing(false);
        }
      })
      .catch((err) => {
        console.log("InsertUpdateSettings", err);
        setIsEditing(false);
      });
  };

  const duplicateMessage = (name, erroMessage) => {
    const fieldIndex = rowItems.findIndex((x) => x["dataField"] === name);
    let fieldName = rowItems[fieldIndex];
    fieldName["errorMessage"] = erroMessage;
    let data = [...rowItems];
    data[fieldIndex] = fieldName;
    setRowItems(data);
    hideValidation(rowItems);
    return false;
  };

  const hideValidation = (formControls) => {
    setTimeout(() => {
      const findIndex = formControls.findIndex((x) => x["errorMessage"] !== "");
      if (findIndex > -1) {
        let teamName = formControls[findIndex];
        let data = [...formControls];
        teamName["errorMessage"] = "";
        data[findIndex] = teamName;
        setRowItems(data);
      }
    }, 3000);
  };

  const deleteSetting = (event) => {
    event.preventDefault();
    if (isSaveMode || isAddEditDisable) return false;

    var r = window.confirm("Are you sure you want to delete this?");
    if (r === true) {
      ConfiguratorServices.DeleteSettings(
        dataSourceId,
        sessionId,
        columnSettingId,
        settings["settingsId"],
        dataSourceType,
        userName,
        email
      )
        .then((res) => {
          if (res === "Setting data is used in another collection") {
            alert("Can't delete this item, Already in use.");
          } else {
            disabledAddEdit(false);
            onFinishAddSettings();
          }
        })
        .catch((err) => {
          console.log("DeleteSettings:", err);
        });
    }
  };

  return (
    <Draggable
      draggableId={`${settings.settingsId}`}
      index={index}
      key={settings.settingsId}
      isDragDisabled={
        isSaveMode ||
        isAddEditDisable ||
        settingName === ConfigrationConstant.Calculation_Options
      }
    >
      {(provided, snapshot) => (
        <tr key={index} ref={provided.innerRef} {...provided.draggableProps}>
          {rowItems.map((column, i) => {
            if (
              column["dataField"] !== "id" &&
              column["dataField"] !== "client_id" &&
              column["dataField"] !== "dataSourceId" &&
              column["dataField"] !== "timezoneID" &&
              column["dataField"] !== "case_typescol" &&
              column["dataField"] !== "nsyteful_optionscol" &&
              column["dataField"] !== "target_opp_points"
            ) {
              //wrap-content
              return (
                <td key={i} className={!isEdit ? "wrap-content" : ""}>
                  {i === 0 && !isEdit && (
                    <span
                      {...provided.dragHandleProps}
                      style={{
                        cursor:
                          (isSaveMode || isAddEditDisable) === true
                            ? "not-allowed"
                            : "",
                        display:
                          (isSaveMode ||
                            isAddEditDisable ||
                            settingName ===
                              ConfigrationConstant.Calculation_Options) === true
                            ? "none"
                            : "",
                      }}
                    >
                      <i aria-hidden="true" className="fa-md fa fa-arrows"></i>
                      &nbsp;&nbsp;
                    </span>
                  )}
                  {!isEdit && (
                    <>
                      {column["dataField"] !== "help" && (
                        <span
                          title={`${
                            column["dataField"] === "target_exp_dollars"
                              ? "$"
                              : ""
                          }${column["displayValue"]}`}
                        >
                          {column["dataField"] === "target_exp_dollars" && "$"}
                          {column["displayValue"]}
                        </span>
                      )}
                      {column["dataField"] === "help" && (
                        <PopoverControl text={column["displayValue"]} />
                      )}
                    </>
                  )}
                  {isEdit && (
                    <FormControl
                      column={column}
                      handleFormChange={handleFormChange}
                      timeZoneList={timeZoneList}
                      index={i}
                      teamList={teamList}
                      salesOppOwnerList={salesOppOwnerList}
                      isReadonly={
                        (settingName ===
                          ConfigrationConstant.Calculation_Options &&
                          column["dataField"] === "parameter") ||
                        ((settingName === "Case Owners" ||
                          settingName === "Sales Opp Owners") &&
                          (column["dataField"] === "start_time" ||
                            column["dataField"] === "finish_time")) ||
                        column["dataField"] === "discount" ||
                        column["dataField"] === "number" ||
                        column["dataField"] === "probability" ||
                        column["dataField"] === "multiplier" ||
                        column["dataField"] === "pct_no" ||
                        (settingName === "Sales Opp Owners Capacity" &&
                          column["dataField"] === "team_name")
                      }
                    />
                  )}
                </td>
              );
            }
            return "";
          })}
          <td className="action-col">
            {!isEdit && (
              <>
                {settingName !== "Synchronize Colors" && (
                  <ConfiguratorEditButton
                    isDisable={isSaveMode || isAddEditDisable}
                    editHandler={(event) => editHandler(event, settings)}
                  />
                )}
                {settingName !== "Calculation Options" && (
                  <ConfiguratorDeleteButton
                    isDisable={isSaveMode || isAddEditDisable}
                    onClickHandler={deleteSetting}
                  />
                )}
              </>
            )}
            {isEdit && (
              <>
                <ConfiguratorUpdateButton
                  onClickHandler={onUpdateHandler}
                  isDisable={isEditing}
                />
                <ConfiguratorCancelButton
                  onClickHandler={(event) => {
                    event.preventDefault();
                    disabledAddEdit(false);
                    setIsEdit(false);
                    setValidationCounter(0);
                    onCancelEvent();
                  }}
                />
              </>
            )}
          </td>
        </tr>
      )}
    </Draggable>
  );
};

export default memo(ConfigurationTypeSettingItem);
