import React, {
  Fragment,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import WidgetTitleControl from "../../../../UI/TemplateControls/WidgetTitleControl";
import DataSectionSettings from "../../../../UI/TemplateControls/DataSection";
import { DataSectionObject } from "../../../../../Helper/CommonModel";

const OtccWidgetTemplate = forwardRef((props, ref) => {
  const [showSettingsPanel, setShowSettingsPanel] = useState(true);
  const [widgetContentPanel, setWidgetContentPanel] = useState(false);
  const [titleContentPanel, setTitleContentPanel] = useState(false);
  const [title, setTitle] = useState("On Time Case Completion");
  const [shortTitle, setShortTitle] = useState("OTCC");
  const [dtSourceItems, setDtSourceItems] = useState([]);
  const [titleIsValid, setTitleIsValid] = useState(true);
  const [shortTitleIsValid, setShortTitleIsValid] = useState(true);
  const [periodTypeVal, setPeriodTypeVal] = useState("1");
  const dataSectionRef = useRef();
  const dsObjRef = useRef(DataSectionObject);

  useEffect(() => {
    if (
      props.dataSources != undefined &&
      Object.keys(props.dataSources.Reports).length !== 0
    ) {
      const _items = Object.entries(props.dataSources.Reports)
        .filter((item) => {
          if (item[1] === "Generic Table") {
            return true;
          }
        })
        .map((item) => item[0]);
      setDtSourceItems(_items);
    }

    if (
      props.dataSources != undefined &&
      props.isEdit === true &&
      props.settings !== undefined &&
      props.settings["reports"] != undefined
    ) {
      setTitle(props.settings["Title"]);
      setShortTitle(props.settings["ShortTitle"]);
      setPeriodTypeVal(props.settings["period"]);
    }
  }, [props.dataSources]);

  useImperativeHandle(ref, () => ({
    GetValidateData() {
      const getVaildForm = validateform();
      if (getVaildForm) {
        return {
          formIsValid: getVaildForm,
          data: {
            reports: dsObjRef.current.dataSourceName,
            connection: dsObjRef.current.connectionName,
            Filter: dsObjRef.current.filterName,
            IsSharedFilter: dsObjRef.current.filterDetails.issharedfilter,
            OriginalUserId: dsObjRef.current.filterDetails.originaluserid,
            FilterId: dsObjRef.current.filterDetails.filterid,
            Title: title,
            ShortTitle: shortTitle,
            period: periodTypeVal,
          },
        };
      } else {
        return {
          formIsValid: getVaildForm,
        };
      }
    },
  }));

  const onSettingsClicked = (val) => {
    if (val) {
      setShowSettingsPanel(val);
    } else {
      setShowSettingsPanel(!showSettingsPanel);
    }
    setWidgetContentPanel(false);
    setTitleContentPanel(false);
  };

  const onWidgetContentClicked = () => {
    setWidgetContentPanel(!widgetContentPanel);
    setShowSettingsPanel(false);
    setTitleContentPanel(false);
  };

  const onTitleContentPanelClicked = (val) => {
    if (val) {
      setTitleContentPanel(val);
    } else {
      setTitleContentPanel(!titleContentPanel);
    }
    setWidgetContentPanel(false);
    setShowSettingsPanel(false);
  };

  const dataSourceChangeHandler = async (dataSourceVal) => {};

  const OnTitleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const OnShortTitleChangeHandler = (event) => {
    setShortTitle(event.target.value);
  };

  const onPeriodChangeHandler = (event) => {
    setPeriodTypeVal(event.target.value);
  };

  const validateTitle = () => {
    if (title === "") {
      onTitleContentPanelClicked(true);
      setTitleIsValid(false);
      setTimeout(() => {
        setTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateShortTitle = () => {
    if (shortTitle === "") {
      onTitleContentPanelClicked(true);
      setShortTitleIsValid(false);
      setTimeout(() => {
        setShortTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateform = () => {
    const dataSectionVal = dataSectionRef.current.validateDataSection();
    dsObjRef.current = dataSectionVal.data;
    if (!dataSectionVal.isDataSectionValid) {
      onSettingsClicked(true);
      return false;
    }
    const isTitleValid = validateTitle();
    if (!isTitleValid) {
      return false;
    }

    const isShortTitleValid = validateShortTitle();
    if (!isShortTitleValid) {
      return false;
    }

    return true;
  };

  return (
    <Fragment>
      {!props.isEdit && <hr className="style-one" />}
      <div onClick={onSettingsClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {showSettingsPanel && "-"}
          {!showSettingsPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>DATA</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: showSettingsPanel ? "block" : "none",
        }}
      >
        <DataSectionSettings
          dataSourceList={dtSourceItems}
          dtSourceChange={dataSourceChangeHandler}
          ref={dataSectionRef}
          settings={props.settings}
          isEdit={props.isEdit}
        />
      </div>
      <div onClick={onTitleContentPanelClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {titleContentPanel && "-"}
          {!titleContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET TITLE</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: titleContentPanel ? "block" : "none",
        }}
      >
        <WidgetTitleControl
          titleVal={title}
          shortTitleVal={shortTitle}
          OnTitleChangeHandler={OnTitleChangeHandler}
          OnShortTitleChangeHandler={OnShortTitleChangeHandler}
          isTitleValid={titleIsValid}
          isShortTitleValid={shortTitleIsValid}
        />
      </div>
      <div onClick={onWidgetContentClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {widgetContentPanel && "-"}
          {!widgetContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET CONTENT</p>
      </div>
      <div
        style={{
          paddingLeft: "12px",
          display: widgetContentPanel ? "block" : "none",
        }}
      >
        <p>Period Type</p>
        <select
          value-key="period"
          value={periodTypeVal}
          onChange={onPeriodChangeHandler}
        >
          <option value="1">1 day</option>
          <option value="7">7 days</option>
          <option value="30">30 days</option>
        </select>
      </div>
    </Fragment>
  );
});

export default OtccWidgetTemplate;
