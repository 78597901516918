import { useDispatch } from "react-redux";
import Modal from "../../UI/Modal";
import { layoutActions } from "../../../store/layout-slice";
import { useSelector } from "react-redux";
import { AddCloneWidget, GetWidgetList } from "../../../Helper/Services";
import { useRef, useState } from "react";
import WidgetButtonControl from "../../UI/Buttons/WidgetButton";

export const CloneWidget = () => {
  const dispatch = useDispatch();
  const pageList = useSelector((state) => state.layout.PageList);
  const currentWidgetId = useSelector((state) => state.layout.selectedWidgetId);
  const [widgets, setWidgets] = useState([]);
  const [pageDetail, setPageDetail] = useState({ value: "", error: "" });
  const [widgetDetail, setWidgetDetail] = useState({
    value: "",
    error: "",
    title: "",
    type: "",
    widgetType: "",
  });
  const onCloseHandler = () => {
    dispatch(layoutActions.setCloneWidgetPopup());
  };

  const pageChangeHandler = (event) => {
    const updatedVals = { ...pageDetail, value: event.target.value, error: "" };
    setPageDetail(updatedVals);
    if (event.target.value !== "") {
      GetWidgetList(event.target.value)
        .then((resp) => {
          setWidgets(resp.Message.widgetList);
        })
        .catch((err) => {
          console.log("GetWidgetList err:", err);
        });
    }
  };

  const onWidgetChangeHandler = (event) => {
    const type =
      event.target[event.target.selectedIndex].getAttribute("data-type");
    const widgetType =
      event.target[event.target.selectedIndex].getAttribute("data-widgettype");
    const title =
      event.target[event.target.selectedIndex].getAttribute("data-title");
    const updatedVals = {
      ...widgetDetail,
      value: event.target.value,
      error: "",
      title: title,
      widgetType: widgetType,
      type: type,
    };
    setWidgetDetail(updatedVals);
  };

  const onCloneWidget = () => {
    if (pageDetail.value === "") {
      setPageDetail({ ...pageDetail, error: "Required" });
      // setTimeout(() => {
      //   setPageDetail({ ...pageDetail, error: "" });
      // }, 3000);
      return false;
    }

    if (widgetDetail.value === "") {
      let updatedVals = { ...widgetDetail, error: "Required" };
      setWidgetDetail(updatedVals);
      // setTimeout(() => {
      //   updatedVals.error = "";
      //   console.log("updatedVals", updatedVals);
      //   setWidgetDetail(updatedVals);
      // }, 3000);
      return false;
    }

    AddCloneWidget(currentWidgetId, widgetDetail.value)
      .then((resp) => {
        dispatch(
          layoutActions.updateWidget({
            title: `CLONE ${widgetDetail.title}`,
            shortTitle: widgetDetail.title,
            Type: parseInt(widgetDetail.type),
            WidgetType: widgetDetail.widgetType,
            WidgetId: currentWidgetId,
            widgetTypeId: parseInt(widgetDetail.type),
          })
        );
        dispatch(layoutActions.setAddWidgetPopup());
        dispatch(layoutActions.setCloneWidgetPopup());
      })
      .catch((err) => {
        console.log("AddCloneWidget err:", err);
      });
  };

  return (
    <Modal
      title="Clone Existing Widget"
      onClose={onCloseHandler}
      width={{ width: "416px" }}
    >
      <div className="">
        <div style={{ width: "250px" }}>
          <p>Page Name</p>
          <select value={pageDetail.value} onChange={pageChangeHandler}>
            <option value="">Select</option>
            {pageList.map((item) => {
              if (item.Id > 0 && item.IsSharedByOtherUser === false)
                return (
                  <option key={item.Id} value={item.Id}>
                    {item.PageName}
                  </option>
                );
              else return "";
            })}
          </select>
          {pageDetail.error && (
            <span style={{ color: "red" }}>{pageDetail.error}</span>
          )}
        </div>
        <div style={{ width: "250px" }}>
          <p>Widget Title</p>
          <select value={widgetDetail.value} onChange={onWidgetChangeHandler}>
            <option value="">Select</option>
            {widgets.map((item) => {
              return (
                <option
                  key={item.Id}
                  value={item.Id}
                  data-type={item.Type}
                  data-widgettype={item.WidgetType}
                  data-title={item.Title}
                >
                  {item.Title}
                </option>
              );
            })}
          </select>
          {widgetDetail.error && (
            <span style={{ color: "red" }}>{widgetDetail.error}</span>
          )}
        </div>
        <div style={{ paddingTop: "15px", paddingBottom: "10px" }}>
          <WidgetButtonControl
            title="Clone Widget"
            cssStyle={{ marginLeft: "3px", width: "100px" }}
            onClick={onCloneWidget}
          />

          <WidgetButtonControl
            title="Close"
            cssStyle={{ marginLeft: "3px" }}
            onClick={onCloseHandler}
          />
        </div>
      </div>
    </Modal>
  );
};
