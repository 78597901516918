import React from "react";
import { pageActions } from "../../../store/page-slice";
import { useDispatch } from "react-redux";
import classes from "./PageItem.module.css";

const AddPageItem = () => {
  const dispatch = useDispatch();
  const onAddPagehandler = () => {
    dispatch(pageActions.closePageList());
    dispatch(pageActions.triggerAddPage());
  };

  return (
    <div className={classes["widgetSize"]}>
      <div className={classes["mainwrapper"]}>
        <div className={classes["addPageBox"]} onClick={onAddPagehandler}>
          <i title="Add Page" className="fa fa-plus"></i>
        </div>
      </div>
    </div>
  );
};

export default AddPageItem;
