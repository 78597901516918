import React from "react";
import { Route, Switch } from "react-router-dom";

import MainPage from "./Pages/MainPage";
import { useSelector } from "react-redux";

function App() {
  const isUserLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const onBodyclick = (event) => {
    if (!isUserLoggedIn) return false;
    //event.target.className
    //event.target.classList.contains(className)
    // console.log(
    //   event.target.id +
    //     " and " +
    //     event.target.className +
    //     event.target.classList.contains("page-rotation-icon")
    // );

    if (
      event.target.classList.contains("page-rotation-icon") ||
      event.target.classList.contains("pagerotate")
    ) {
      // console.log("event.target.style", event.target.style.display);
      const element = document.querySelector(".page-rotation-menu-items");
      const style = window.getComputedStyle(element);
      if (style.display === "block") {
        document.getElementsByClassName(
          "page-rotation-menu-items"
        )[0].style.display = "none";
      } else {
        document.getElementsByClassName(
          "page-rotation-menu-items"
        )[0].style.display = "block";
      }
    } else {
      document.getElementsByClassName(
        "page-rotation-menu-items"
      )[0].style.display = "none";
    }
  };

  return (
    <div onClick={onBodyclick}>
      <Switch>
        <Route path="/:sessionId/:pageId">
          <MainPage />
        </Route>
        <Route path="/:sessionId">
          <MainPage />
        </Route>
        <Route path="/">
          <MainPage />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
