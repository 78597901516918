import React from "react";
export const TimeIntervalControl = ({
  onChangeHandler,
  timeIntervalErr,
  value,
}) => {
  const isNumberKey = (evt) => {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 46 && evt.srcElement.value.split(".").length > 1) {
      return false;
    }
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  };
  return (
    <>
      <input
        type="number"
        min="0"
        step="0.50"
        style={{ width: "95%", height: "23px" }}
        onKeyUp={isNumberKey}
        onChange={onChangeHandler}
        value={value}
      />
      {timeIntervalErr && (
        <span style={{ color: "red" }}>{timeIntervalErr}</span>
      )}
    </>
  );
};
