import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { pageActions } from "../../../../store/page-slice";
import openPageImage from "../../../../assets/open_page.png";
import styles from "./OpenImg.module.css";
import { layoutActions } from "../../../../store/layout-slice";
import RotationServices from "../../../Layout/Header/Rotation/RotationService";
import { ROTATION_OFF } from "../../../../Constants";

const OpenImg = (props) => {
  const dispatch = useDispatch();
  const sessionId = useSelector((state) => state.login.sessionId);
  const rotationList = useSelector((state) => state.layout.RotationList);
  const pageRotationId = useSelector((state) => state.layout.PageRotation);
  const userId = useSelector((state) => state.layout.UserId);
  const history = useHistory();

  const loadPage = () => {
    if (pageRotationId !== ROTATION_OFF) {
      const rotationPages = rotationList.filter(
        (x) => x.Id === parseInt(pageRotationId)
      );
      if (rotationPages?.length > 0) {
        const pages = rotationPages[0].PageList.filter(
          (page) => page.PageId === props.id
        );

        if (!pages.length) {
          deactivateRotation(); // Deactivate rotation if the user confirms
        } else {
          dispatch(pageActions.closePageList());
          dispatch(pageActions.setRotationPageId(props.id));
        }
      }
    } else {
      dispatch(pageActions.closePageList());
      dispatch(pageActions.setLoadPageId(props.id));
      dispatch(pageActions.setPageLoadEvent());
    }

    history.push("/" + sessionId + "/" + props.id);
  };

  function deactivateRotation() {
    const result = window.confirm(
      "You are attempting to navigate to a page outside your active rotation sequence. Would you like to deactivate that sequence and continue to the selected page?"
    );
    if (result) {
      dispatch(layoutActions.setRotationTime(0));
      dispatch(layoutActions.setClockRotation(false));
      dispatch(layoutActions.setActiveRotationId(ROTATION_OFF));
      RotationServices.updatePageRotationStatus(userId, ROTATION_OFF)
        .then(() => {
          dispatch(layoutActions.setRotationUpdateVersion());
          dispatch(pageActions.setRotationPageId(""));
        })
        .catch((err) => {
          console.log("updatePageRotationStatus:", err);
        });
    }
    dispatch(pageActions.closePageList());
  }

  return (
    <React.Fragment>
      <img
        title="Open Page"
        className={styles["open-img"]}
        src={openPageImage}
        alt="No Image"
        onClick={loadPage}
      />
      <span title="Open Page" className={styles["open-spn"]} onClick={loadPage}>
        OPEN
      </span>
    </React.Fragment>
  );
};

export default OpenImg;
