import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageActions } from "../../store/page-slice";
import Modal from "../UI/Modal";
import classes from "./Page.module.css";
import AddPageItem from "./PageList/AddPageItem";
import PageItem from "./PageList/PageItem";
import ConcateDateWithPageName from "../../Helper/PageOperations.js";

const Page = () => {
  const dispatch = useDispatch();
  const pageList = useSelector((state) => state.layout.PageList);
  const [pageItems, setPageItems] = useState([]);
  const [currentPageCount, setCurrentPage] = useState(0);
  const itemsPerPage = 4;
  let pageCount = Math.ceil(pageList.length / itemsPerPage) - 1;

  useEffect(() => {
    let items = ConcateDateWithPageName(
      pageList.slice(
        currentPageCount * itemsPerPage,
        currentPageCount * itemsPerPage + 4
      ),
      true
    );
    setPageItems(items);
  }, [currentPageCount, pageList]);

  const onLoadPages = () => {
    dispatch(pageActions.triggerPageList());
  };

  const previousPageHandler = (event) => {
    event.preventDefault();
    if (currentPageCount > 0) {
      let decPageCount = currentPageCount - 1;
      setCurrentPage(decPageCount);
    }
  };

  const nextPageHandler = (event) => {
    event.preventDefault();
    if (currentPageCount !== pageCount) {
      let incPageCount = currentPageCount + 1;
      setCurrentPage(incPageCount);
    }
  };

  return (
    <Modal
      width={{
        width: "850px",
        height: "620px",
        overflow: "hidden",
        padding: "0px",
        background: "#e6e7e3",
      }}
      contentCss={{ padding: "0px" }}
      onClose={onLoadPages}
    >
      <div className={classes["pageContainer"]}>
        <div className={classes["pageNavigator"]}>
          <a
            href=""
            className={`${classes["leftNavigator"]} ${
              currentPageCount === 0 ? classes["disabled"] : ""
            }`}
            onClick={previousPageHandler}
          >
            <i title="previous" className="fa fa-chevron-left"></i>
          </a>
          <a
            href=""
            className={`${classes["rightNavigator"]} ${
              currentPageCount === pageCount ? classes["disabled"] : ""
            }`}
            onClick={nextPageHandler}
          >
            <i title="next" className="fa fa-chevron-right"></i>
          </a>
        </div>
        <div className={classes["page-list-content"]}>
          {pageItems.map((item, i) => {
            if (item.Id == 0) {
              return <AddPageItem key={i} />;
            } else {
              return <PageItem key={item.Id} item={item} />;
            }
          })}
        </div>
      </div>
    </Modal>
  );
};

export default Page;
