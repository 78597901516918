const sessionIdName = "sessionId";

const setLocalStorageSessionId = function (sessionId) {
  localStorage.setItem(sessionIdName, JSON.stringify(sessionId));
};

const getLocalStorageSessionId = function () {
  return JSON.parse(localStorage.getItem(sessionIdName));
};

const removeLocalStorageSessionId = function (sessionId) {
  if (sessionId !== null && sessionId !== "") {
    //handle another sessionid generated without login in same browser
    if (getLocalStorageSessionId() === sessionId) {
      localStorage.removeItem(sessionIdName);
    }
  } else {
    localStorage.removeItem(sessionIdName);
  }
};

const checkSessionDetails = (data) => {
  // if (!isSessionAlertDisplayed) {
  //   if (isString(data)) {
  //     if (
  //       data.indexOf("Invalid Session Id") > -1 ||
  //       data.indexOf("Session has expired") > -1 ||
  //       data.indexOf("session not found or invalid") > -1
  //     ) {
  //       removeLocalStorageSessionId();
  //       alert("Session has expired. Please log in again.");
  //       isSessionAlertDisplayed = true;
  //     }
  //   }
  // }

  return true;
};

function isString(value) {
  return typeof value === "string" || value instanceof String;
}

const SharedService = {
  setLocalStorageSessionId,
  getLocalStorageSessionId,
  removeLocalStorageSessionId,
  checkSessionDetails,
};

export default SharedService;
