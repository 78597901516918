import * as moment from "moment";

const ConcateDateWithPageName = (itemList, isPages) => {
  let list = [...itemList];
  for (let i = 0; i < list.length; i++) {
    let pageName = list[i]["PageName"];
    if (pageName && pageName != undefined) {
      let pageNameArr = pageName.split("#");
      if (pageNameArr && pageNameArr.length > 1) {
        let getSharedUserName = pageNameArr[1].slice(28);
        let dateTime = list[i]["DeleteDateTime"];

        if (isPages) dateTime = new Date(pageNameArr[1].slice(0, 28));

        let localDate = convertUTCDateToLocalDate_Format(
          dateTime,
          "_DD MMM YYYY hh:mm A"
        );
        let item = { ...list[i] };
        item = {
          ...item,
          PageName: pageNameArr[0].concat(localDate).concat(getSharedUserName),
        };
        list[i] = item;
      }
    }
  }

  return list;
};

const convertUTCDateToLocalDate_Format = (nDate, format) => {
  if (nDate == "" || nDate == "NULL") {
    return nDate;
  }

  //let currd = new Date();
  //let offset = currd.getTimezoneOffset() / 60;
  var offset = moment().utcOffset();
  return moment.utc(nDate).utcOffset(offset).format(format);
};

const getWidth = (text, font) => {
  // re-use canvas object for better performance
  let canvas = document.createElement("canvas");
  let context = canvas.getContext("2d");
  context.font = font;
  let metrics = context.measureText(text);
  canvas = null;
  context = null;
  return metrics.width;
};

export const ConvertUTCDateToLocalDate_Format = ConcateDateWithPageName;
export const GetWidth = getWidth;
export default ConcateDateWithPageName;
