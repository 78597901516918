import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../UI/Loader";
import RecycleBinService from "./RecycleBinService";
import { ConvertToLocalDateTimeWithFormat } from "../../../../Helper/WidgetDataCommon";

const FilterRestore = ({ resetToMainMenu }) => {
  const [enableLoader, setEnableLoader] = useState(false);
  const [deleteRotations, setDeletedRotations] = useState([]);
  const allowDomain = useSelector(
    (state) => state.login.userDetail.allowDomain
  );
  const sessionId = useSelector((state) => state.login.sessionId);

  useEffect(() => {
    getDeletedFilters();
  }, []);

  const getDeletedFilters = () => {
    setEnableLoader(true);
    RecycleBinService.getDeletedFilter(
      "STAS Case Register",
      allowDomain,
      sessionId
    )
      .then((resp) => {
        if (resp) {
          const filters = [...resp];
          let _filters = [...filters].map((x) => {
            let item = { ...x };
            item["deletedOn"] = ConvertToLocalDateTimeWithFormat(
              item.deletedOn,
              "MMM DD YYYY, h:mm A"
            );
            return item;
          });

          setDeletedRotations(_filters);
        }
        setEnableLoader(false);
      })
      .catch((err) => {
        console.log("getDeletedFilters err:", err);
        setEnableLoader(false);
      });
  };

  const restoreFilter = (id) => {
    RecycleBinService.restoreFilter(id, sessionId)
      .then((res) => {
        getDeletedFilters();
      })
      .catch((err) => {
        console.log("restoreFilter err:", err);
      });
  };

  return (
    <div>
      {!enableLoader && (
        <div className="recycle-bin">
          <ul className="breadcrumb">
            <li>
              <a
                href=""
                onContextMenu={() => {
                  return false;
                }}
                onClick={(event) => {
                  event.preventDefault();
                  resetToMainMenu();
                }}
              >
                Rycycle Bin
              </a>
            </li>
            <li>Filters</li>
          </ul>
          <div className="main-container">
            <table
              className="table table-hover"
              style={{ tableLayout: "fixed", wordWrap: "break-word" }}
            >
              <thead>
                <tr>
                  <th>Filter Name</th>
                  <th>Last Modified</th>
                  <th className="action-col">Action</th>
                </tr>
              </thead>
              <tbody>
                {deleteRotations.map((item) => {
                  return (
                    <tr key={item.filterId}>
                      <td className="wrap-content" title={item.filterName}>
                        {item.filterName}
                      </td>
                      <td
                        className="wrap-content"
                        title={`${item.deletedBy}, ${item.deletedOn}`}
                      >{`${item.deletedBy}, ${item.deletedOn}`}</td>
                      <td style={{ textAlign: "center" }}>
                        <a
                          href=""
                          title="Restore Filter"
                          className="icons_cls"
                          style={{
                            fontSize: "18px",
                            color: "gray",
                            textDecoration: "none",
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            restoreFilter(item.filterId);
                          }}
                        >
                          <i title="Restore Filter" className="fa fa-undo"></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {enableLoader && <Loader styles={{ height: "80px" }} />}
    </div>
  );
};

export default FilterRestore;
