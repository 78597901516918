import React, {
  Fragment,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import WidgetTitleControl from "../../../../UI/TemplateControls/WidgetTitleControl";
import TrafficSignalTemplate from "./ConfigContent/TrafficSignal";
import { useSelector } from "react-redux";
import GetMetaData, {
  GridMetaData_Format,
} from "../../../../../Helper/Services";
import GetTrafficSignal, {
  GetFormattedColumns,
} from "../../../../../Helper/WidgetDataCommon";
import FormatColumns from "./ConfigContent/FormatColumns";
import { DataSectionObject } from "../../../../../Helper/CommonModel";
import DataSectionSettings from "../../../../UI/TemplateControls/DataSection";

const ReportTemplate = forwardRef((props, ref) => {
  const [showSettingsPanel, setShowSettingsPanel] = useState(true);
  const [widgetContentPanel, setWidgetContentPanel] = useState(false);
  const [titleContentPanel, setTitleContentPanel] = useState(false);
  const [formatColumnPanel, setFormatColumnPanel] = useState(false);
  const [title, setTitle] = useState("");
  const [titleIsValid, setTitleIsValid] = useState(true);
  const [shortTitle, setShortTitle] = useState("");
  const [shortTitleIsValid, setShortTitleIsValid] = useState(true);
  const [trafficSignalVal, setTrafficSignalVal] = useState("None");
  const [dtSourceItems, setDtSourceItems] = useState([]);
  const [trafficSignalItems, setTrafficSignalItems] = useState([]);
  const [formatColumns, setFormatColumns] = useState([]);
  const [blackIcon, setBlackIcon] = useState("1");
  const [redIcon, setRedIcon] = useState(".67");
  const [yellowIcon, setYellowIcon] = useState(".33");
  const [foramttedColumns, setForamttedColumns] = useState([]);
  const sessionId = useSelector((state) => state.login.sessionId);
  const [percentageValue, setPercentageValue] = useState(false);
  const dataSectionRef = useRef();
  const dsObjRef = useRef(DataSectionObject);

  useEffect(() => {
    if (
      props.dataSources != undefined &&
      Object.keys(props.dataSources.Reports).length !== 0
    ) {
      const _items = Object.entries(props.dataSources.Reports)
        .filter((item) => {
          if (item[1] === "Generic Table") {
            return true;
          }
        })
        .map((item) => item[0]);
      setDtSourceItems(_items);
    }

    if (
      props.dataSources != undefined &&
      props.isEdit === true &&
      props.settings !== undefined &&
      props.settings["reports"] != undefined
    ) {
      setTitle(props.settings["Title"]);
      setShortTitle(props.settings["ShortTitle"]);
      setBlackIcon(props.settings["blackIcon"]);
      if (
        props.settings["percentagevalue"] !== undefined &&
        props.settings["percentagevalue"] !== "" &&
        props.settings["percentagevalue"].toLowerCase() === "true"
      ) {
        setPercentageValue(true);
      } else {
        setPercentageValue(false);
      }
      setRedIcon(props.settings["redIcon"]);
      setTrafficSignalVal(props.settings["traffic"]);
      setYellowIcon(props.settings["yellowIcon"]);
      if (
        props.settings["finalcolumnlist"] !== undefined &&
        props.settings["finalcolumnlist"] !== ""
      ) {
        setFormatColumns(JSON.parse(props.settings["finalcolumnlist"]));
      }
      bindDropDownList(props.settings["reports"]);
    }
  }, [props.dataSources, props.settings]);

  useImperativeHandle(ref, () => ({
    GetValidateData() {
      const getVaildForm = validateform();
      if (getVaildForm) {
        return {
          formIsValid: getVaildForm,
          data: {
            ShortTitle: shortTitle,
            Title: title,
            blackIcon: blackIcon,
            connection: dsObjRef.current.connectionName,
            finalcolumnlist: JSON.stringify(foramttedColumns),
            percentagevalue: percentageValue.toString(),
            redIcon: redIcon,
            reports: dsObjRef.current.dataSourceName,
            traffic: trafficSignalVal,
            yellowIcon: yellowIcon,
            Filter: dsObjRef.current.filterName,
            FilterId: dsObjRef.current.filterDetails.filterid,
            IsSharedFilter: dsObjRef.current.filterDetails.issharedfilter,
            LastRefreshedTime: "",
            OriginalUserId: dsObjRef.current.filterDetails.originaluserid,
          },
        };
      } else {
        return {
          formIsValid: getVaildForm,
        };
      }
    },
  }));

  const onSettingsClicked = (val) => {
    if (val) {
      setShowSettingsPanel(val);
    } else {
      setShowSettingsPanel(!showSettingsPanel);
    }
    setWidgetContentPanel(false);
    setTitleContentPanel(false);
    setFormatColumnPanel(false);
  };

  const onWidgetContentClicked = () => {
    setWidgetContentPanel(!widgetContentPanel);
    setShowSettingsPanel(false);
    setTitleContentPanel(false);
    setFormatColumnPanel(false);
  };

  const onTitleContentPanelClicked = (val) => {
    setWidgetContentPanel(false);
    setShowSettingsPanel(false);
    setFormatColumnPanel(false);
    if (val) {
      setTitleContentPanel(val);
    } else {
      setTitleContentPanel(!titleContentPanel);
    }
  };

  const onFormatColumnClicked = () => {
    setWidgetContentPanel(false);
    setShowSettingsPanel(false);
    setTitleContentPanel(false);
    setFormatColumnPanel(!formatColumnPanel);
  };

  const dataSourceChangeHandler = (dataSourceVal) => {
    const _val = dataSourceVal;
    setTitle(_val);
    setShortTitle(_val);
    if (_val !== "") {
      // setIsShowFilter(true);
      // await getConnetionList(_val);
      bindDropDownList(_val);
    } else {
      setTrafficSignalItems([]);
      setFormatColumns([]);
      setForamttedColumns([]);
    }
  };

  const bindDropDownList = (dtSourceName) => {
    GetMetaData(dtSourceName, "", sessionId)
      .then((json) => {
        const metaData = GridMetaData_Format(json);
        const getTrafficSignals = GetTrafficSignal(metaData);
        setTrafficSignalItems(getTrafficSignals);
        const getFormattedColumns = GetFormattedColumns(metaData);
        setFormatColumns(getFormattedColumns);
        setForamttedColumns(getFormattedColumns);
        if (props.isEdit) {
          compareMetaDataWithSavedFormattedCols(getFormattedColumns);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const compareMetaDataWithSavedFormattedCols = (metaDataColList) => {
    if (props.settings["finalcolumnlist"] !== "") {
      const columnListFromWidgetSettings = JSON.parse(
        props.settings["finalcolumnlist"]
      );

      let newCols = [];
      let removeOldNoLongerAvailable = [];
      if (
        columnListFromWidgetSettings != undefined &&
        columnListFromWidgetSettings.length > 0
      ) {
        for (let index = 0; index < metaDataColList.length; index++) {
          const element = metaDataColList[index];
          const getColumns = columnListFromWidgetSettings.find(
            (x) => x.dataField === element.dataField
          );
          if (getColumns === undefined) {
            newCols.push(element);
          }
        }
      }

      //add new columns to list
      for (let index = 0; index < newCols.length; index++) {
        let element = newCols[index];
        columnListFromWidgetSettings.splice(element.index, 0, element);
      }

      //find to remove old column
      for (
        let index = 0;
        index < columnListFromWidgetSettings.length;
        index++
      ) {
        const element = columnListFromWidgetSettings[index];
        const getColumns = metaDataColList.find(
          (x) => x.dataField === element.dataField
        );
        if (getColumns === undefined) {
          removeOldNoLongerAvailable.push(element);
        }
      }

      //remove column list
      for (let index = 0; index < removeOldNoLongerAvailable.length; index++) {
        let element = removeOldNoLongerAvailable[index];
        const indexOfItem = columnListFromWidgetSettings.findIndex(
          (x) =>
            x.dataField.replace(/ /g, "_") ===
            element.dataField.replace(/ /g, "_")
        );
        console.log("element.index", element.index);
        columnListFromWidgetSettings.splice(indexOfItem, 1);
      }

      const updatedCols = columnListFromWidgetSettings.map((item, i) => {
        item.index = i;
        return item;
      });

      if (updatedCols.length > 0) {
        setForamttedColumns(updatedCols);
        setFormatColumns(updatedCols);
      }
    }
  };

  const OnTitleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const OnShortTitleChangeHandler = (event) => {
    setShortTitle(event.target.value);
  };

  const onTrafficLightHandler = (event) => {
    setTrafficSignalVal(event.target.value);
  };

  const OnChangeBlackIconHandler = (event) => {
    setBlackIcon(event.target.value);
  };
  const OnChangeYellowIconHandler = (event) => {
    setYellowIcon(event.target.value);
  };
  const OnChangeRedIconHandler = (event) => {
    setRedIcon(event.target.value);
  };

  const getFormattedColumns = (items) => {
    setForamttedColumns(items);
  };

  const percentageValueChange = (val) => {
    setPercentageValue(val);
  };

  const validateTitle = () => {
    if (title === "") {
      onTitleContentPanelClicked(true);
      setTitleIsValid(false);
      setTimeout(() => {
        setTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateShortTitle = () => {
    if (shortTitle === "") {
      onTitleContentPanelClicked(true);
      setShortTitleIsValid(false);
      setTimeout(() => {
        setShortTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateform = () => {
    const dataSectionVal = dataSectionRef.current.validateDataSection();
    dsObjRef.current = dataSectionVal.data;
    if (!dataSectionVal.isDataSectionValid) {
      onSettingsClicked(true);
      return false;
    }

    const isTitleValid = validateTitle();
    if (!isTitleValid) {
      return false;
    }

    const isShortTitleValid = validateShortTitle();
    if (!isShortTitleValid) {
      return false;
    }

    return true;
  };

  return (
    <Fragment>
      {!props.isEdit && <hr className="style-one" />}
      <div onClick={onSettingsClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {showSettingsPanel && "-"}
          {!showSettingsPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>DATA</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: showSettingsPanel ? "block" : "none",
        }}
      >
        <DataSectionSettings
          dataSourceList={dtSourceItems}
          dtSourceChange={dataSourceChangeHandler}
          ref={dataSectionRef}
          settings={props.settings}
          isEdit={props.isEdit}
        />
      </div>
      <div onClick={onTitleContentPanelClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {titleContentPanel && "-"}
          {!titleContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET TITLE</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: titleContentPanel ? "block" : "none",
        }}
      >
        <WidgetTitleControl
          titleVal={title}
          shortTitleVal={shortTitle}
          OnTitleChangeHandler={OnTitleChangeHandler}
          OnShortTitleChangeHandler={OnShortTitleChangeHandler}
          isTitleValid={titleIsValid}
          isShortTitleValid={shortTitleIsValid}
        />
      </div>
      <div onClick={onWidgetContentClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {widgetContentPanel && "-"}
          {!widgetContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET CONTENT</p>
      </div>
      <div
        style={{
          paddingLeft: "12px",
          display: widgetContentPanel ? "block" : "none",
        }}
      >
        <TrafficSignalTemplate
          onTrafficLightHandler={onTrafficLightHandler}
          trafficSignalVal={trafficSignalVal}
          OnChangeBlackIconHandler={OnChangeBlackIconHandler}
          blackIconVal={blackIcon}
          OnChangeRedIconHandler={OnChangeRedIconHandler}
          redIconVal={redIcon}
          OnChangeYellowIconHandler={OnChangeYellowIconHandler}
          onPercentageValueChange={percentageValueChange}
          percentageValue={percentageValue}
          yellowIconVal={yellowIcon}
          dtList={trafficSignalItems}
        />
      </div>
      <div onClick={onFormatColumnClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {formatColumnPanel && "-"}
          {!formatColumnPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>FORMAT COLUMNS</p>
      </div>
      <div
        style={{
          paddingLeft: "12px",
          display: formatColumnPanel ? "block" : "none",
        }}
      >
        <p>Drag to reorder. Double-click to hide/unhide.</p>
        <FormatColumns
          dtList={formatColumns}
          onFinalResult={getFormattedColumns}
        />
      </div>
    </Fragment>
  );
});

export default ReportTemplate;
