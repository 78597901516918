import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { pageActions } from "../../../../store/page-slice";
import editPageImage from "../../../../assets/edit_page.png";
import styles from "./EditImg.module.css";
import { useHistory } from "react-router-dom";
import { Get_Widget_List } from "../../../../Constants";

const EditImg = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sessionId = useSelector((state) => state.login.sessionId);
  const onEditPagehandler = async () => {
    const response = await fetch(`${Get_Widget_List}?pageId=${props.id}`);
    if (response.ok) {
      const getResponse = await response.json();
      if (getResponse.Success) {
        dispatch(pageActions.setEditWidgets(getResponse.Message.Widgets));
        dispatch(pageActions.closePageList());
        dispatch(pageActions.setEditPageId(props.id));
        setTimeout(() => {
          dispatch(pageActions.triggerEditPage());
        }, 1000);
      } else {
        alert(getResponse.ErrorMessage);
      }
    }
  };

  return (
    <React.Fragment>
      <img
        title="Edit Page"
        className={styles["edit-img"]}
        src={editPageImage}
        alt="No Image"
        onClick={onEditPagehandler}
      />
      <span
        title="Edit Page"
        className={styles["edit-spn"]}
        onClick={onEditPagehandler}
      >
        EDIT
      </span>
    </React.Fragment>
  );
};

export default EditImg;
