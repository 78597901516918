import { useEffect, useState, React } from "react";
import { useSelector } from "react-redux";
import { BarChartRefreshTime } from "../../../../Constants";
import {
  GetColorPallets,
  GetLastRefreshDateTime,
  GetWidgetData,
  GetWidgetSettings,
} from "../../../../Helper/Services";
import {
  GetFormattedGridData,
  IsSharedFilterAlert,
} from "../../../../Helper/WidgetDataCommon";
import NoShowDiv from "../../../UI/NoShowTemplate";
import BarchartD3Component from "./BarChartD3Component";
import { GetBarWidgetData } from "./BarChartWidgetServices";

const BarChartWidget = (props) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const isOtherUserPage = useSelector((state) => state.layout.IsOtherUserPage);
  const sharedUserEmail = useSelector((state) => state.layout.SharedUserEmail);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [widgetDt, setWidgetDt] = useState([]);
  const [chartDt, setcChartDt] = useState({
    barValues: [],
    colorsPallet: [],
    colorsCategoryPallet: [],
    mainStackCategories: [],
    categorySelected: [],
  });
  const [callout, setCallout] = useState(false);
  const [screenMsg, setScreenMsg] = useState("No Data");
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    const getWidgetSetting = () => {
      let isSharedFilter = false;
      let sharedFilterUserId = "";
      let filterId = "";

      GetWidgetSettings(props.widgetId)
        .then((res) => {
          if (
            res["IsSharedFilter"] !== undefined &&
            res["IsSharedFilter"] === "true"
          ) {
            isSharedFilter = true;
            sharedFilterUserId = res["OriginalUserId"];
            filterId = res["FilterId"];
          }

          setCallout(false);
          if (res["callouts"] !== "") {
            setCallout(true);
          }
          GetWidgetData(
            res["Filter"],
            res["reports"],
            res["connection"],
            sessionId,
            isOtherUserPage,
            sharedUserEmail,
            isSharedFilter,
            sharedFilterUserId,
            filterId
          )
            .then((widgetDataResponse) => {
              GetLastRefreshDateTime(res["reports"], "", sessionId)
                .then((lstReponseDt) => {
                  props.onGetNotification(lstReponseDt);
                  if (IsSharedFilterAlert(widgetDataResponse)) {
                    setScreenMsg(widgetDataResponse);
                    setShowNoData(true);
                    return false;
                  }
                  GetColorPallets(res["reports"], res["barname"], sessionId)
                    .then((colorsResp) => {
                      GetColorPallets(
                        res["reports"],
                        res["stackcategory"],
                        sessionId
                      )
                        .then((colorsResp1) => {
                          const getFormattedDt =
                            GetFormattedGridData(widgetDataResponse);
                          setWidgetDt(getFormattedDt);
                          const dt = GetBarWidgetData(
                            res,
                            getFormattedDt,
                            colorsResp1,
                            colorsResp
                          );
                          setcChartDt(dt);
                        })
                        .catch((err) => {
                          console.error("GetColorPallets2:-", err);
                        });
                    })
                    .catch((err) => {
                      console.error("GetColorPallets1:-", err);
                    });
                })
                .catch((err) => {
                  console.error("GetLastRefreshDateTime:-", err);
                });
            })
            .catch((err) => {
              console.error("GetWidgetData:-", err);
            });
        })
        .catch((err) => {});
    };

    if (sessionId !== "" && sessionId != null) {
      getWidgetSetting();
      GetWidgetSize();
    }

    window.addEventListener("resize", () => {
      GetWidgetSize();
    });

    const refreshWidget = setInterval(() => {
      getWidgetSetting();
    }, BarChartRefreshTime);

    return () => {
      //clear setinterval on unmount component
      clearInterval(refreshWidget);
    };
  }, [sessionId, props.RefreshVersion]);

  const GetWidgetSize = () => {
    const getParentContainer = document.getElementById(
      "content_" + props.widgetId
    );
    if (getParentContainer !== undefined && getParentContainer != null) {
      const getHeight = getParentContainer.clientHeight;
      const getWidth = getParentContainer.clientWidth;
      setHeight(getHeight);
      setWidth(getWidth);
    }
  };

  return (
    <div>
      {widgetDt.length > 0 && showNoData === false && (
        <BarchartD3Component
          height={height}
          width={width}
          data={chartDt}
          wid={props.wid}
          getWidgetSize={GetWidgetSize}
          widgetId={props.widgetId}
          callout={callout}
        />
      )}
      {widgetDt.length === 0 && showNoData === true && (
        <NoShowDiv screenMsg={screenMsg} />
      )}
    </div>
  );
};

export default BarChartWidget;
