import { DeleteButton } from "../Common/DeleteActionControl";
import { EditButton } from "../Common/EditActionControl";
import { React, useEffect, useState } from "react";
import { CancelButton } from "../Common/CancelActionControl";
import { UpdateButton } from "../Common/UpdateActionControl";
import RotationServices from "../RotationService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../../../../store/layout-slice";

const RotationItem = ({
  item,
  isSaveEnabled,
  loaderHandler,
  diabledAddEdit,
  isDisableAddEdit,
  displayPagesHandler,
}) => {
  const userId = useSelector((state) => state.layout.UserId);
  const activeRotationId = useSelector((state) => state.layout.PageRotation);
  const sessionId = useSelector((state) => state.login.sessionId);
  //const fullName = useSelector((state) => state.login.userDetail.userFullName);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [rotationName, setRotationName] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    setRotationName(item.RotationName);
  }, [item]);

  const editHandler = (event, id) => {
    event.preventDefault();
    if (
      isSaveEnabled ||
      isDisableAddEdit ||
      activeRotationId.toString() === item.Id.toString()
    )
      return false;
    setIsEdit(true);
    diabledAddEdit(true);
  };

  const deleteHandler = (event, id) => {
    event.preventDefault();
    if (
      isSaveEnabled ||
      isDisableAddEdit ||
      activeRotationId.toString() === item.Id.toString()
    )
      return false;

    var r = window.confirm("Are you sure you want to delete this rotation?");
    if (r === true) {
      loaderHandler(true);
      RotationServices.deleteRotation(id, sessionId)
        .then((res) => {
          if (res.Success) {
            getRotations();
          }
        })
        .catch((err) => {
          setIsEdit(true);
          diabledAddEdit(true);
          loaderHandler(false);
          console.log("deleteRotation:-", err);
        });
    }
  };

  const updateHandler = (event) => {
    event.preventDefault();
    if (rotationName === "") {
      setErrorMsg("Rotation name required.");
      setTimeout(() => {
        setErrorMsg("");
      }, 3000);
      return false;
    }
    loaderHandler(true);
    RotationServices.updateRotation(rotationName, item.Id, userId)
      .then((resp) => {
        if (resp.Success) {
          getRotations();
        } else {
          setErrorMsg(resp.ErrorMessage);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("updateRotation", err);
        diabledAddEdit(false);
        loaderHandler(false);
      });
  };

  const getRotations = () => {
    RotationServices.getRotation(userId)
      .then((res) => {
        if (res.Success) {
          dispatch(layoutActions.updateRotationList(res.Message.rotationlist));
          setIsEdit(false);
          loaderHandler(false);
          diabledAddEdit(false);
        }
      })
      .catch((err) => {
        loaderHandler(false);
        diabledAddEdit(false);
      });
  };

  return (
    <li
      className="list-group-item dark-background"
      style={{ display: "flex", flexWrap: "wrap", flexBasis: "100%" }}
    >
      <div style={{ justifyContent: "flex-start", flexBasis: "80%" }}>
        {!isEdit && (
          <a
            href=""
            onClick={(event) => {
              event.preventDefault();
              displayPagesHandler(item);
            }}
          >
            {activeRotationId.toString() === item.Id.toString() && (
              <span className="fa fa-check"></span>
            )}
            {item.PageList.length < 2 && (
              <>
                <span
                  title="Add two pages to enable rotation"
                  className="fa fa-info-circle"
                ></span>
                &nbsp;
              </>
            )}
            {item.RotationName}
          </a>
        )}
        {isEdit && (
          <>
            <input
              style={{ width: "100%" }}
              placeholder="Rotation Name"
              type="text"
              value={rotationName}
              onChange={(event) => setRotationName(event.target.value)}
            />
            {errorMsg && <span style={{ color: "red" }}>{errorMsg}</span>}
          </>
        )}
      </div>

      <div
        style={{
          justifyContent: "flex-end",
          flexBasis: "20%",
          display: "flex",
        }}
      >
        {!isEdit && (
          <>
            <EditButton
              isDisable={
                isSaveEnabled ||
                isDisableAddEdit ||
                activeRotationId.toString() === item.Id.toString()
              }
              editHandler={(event) => editHandler(event, item.Id)}
            />
            <DeleteButton
              isDisable={
                isSaveEnabled ||
                isDisableAddEdit ||
                activeRotationId.toString() === item.Id.toString()
              }
              onClickHandler={(event) => deleteHandler(event, item.Id)}
            />
          </>
        )}

        {isEdit && (
          <>
            <UpdateButton onClickHandler={updateHandler} />
            <CancelButton
              onClickHandler={(event) => {
                event.preventDefault();
                diabledAddEdit(false);
                setIsEdit(false);
              }}
            />
          </>
        )}
      </div>
    </li>
  );
};

export default RotationItem;
