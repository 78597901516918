import { useEffect, useState, React } from "react";
import classes from "./../Header.module.css";
import "./Rotation.css";
import { useSelector, useDispatch } from "react-redux";
import { layoutActions } from "../../../../store/layout-slice";
import { useParams, useHistory } from "react-router-dom";
import RotationServices from "./RotationService";
import { pageActions } from "../../../../store/page-slice";

const RotationMenuOption = ({ getTheme }) => {
  const dispatch = useDispatch();
  let { pageId } = useParams();
  const history = useHistory();
  const isUserLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const rotationList = useSelector((state) => state.layout.RotationList);
  const sessionId = useSelector((state) => state.login.sessionId);
  const userId = useSelector((state) => state.layout.UserId);
  const activeRotationId = useSelector((state) => state.layout.PageRotation);

  useEffect(() => {}, [rotationList]);

  const openSettingsHandler = (event) => {
    event.preventDefault();
    dispatch(layoutActions.setRotationPopup());
  };

  const pageRotationHandler = (event, id) => {
    event.preventDefault();
    let rotationPageList = [];
    let getRotationPageId = 0;
    let rotationTime = 0;
    const getRotationObj = rotationList.filter(function (res) {
      return res.Id.toString() == id.toString();
    });

    if (getRotationObj && getRotationObj.length > 0) {
      rotationPageList = getRotationObj[0]["PageList"];
    }

    if (rotationPageList.length > 1) {
      let getTime = 0;
      if (pageId !== undefined && pageId !== "") {
        let getPage = getRotationObj[0]["PageList"].filter(function (pageRes) {
          return pageRes.PageId.toString() == pageId.toString();
        });

        if (getPage && getPage.length > 0) {
          getRotationPageId = getPage[0]["PageId"];
          getTime = getPage[0]["TimeInterval"];
        } else {
          getTime = getRotationObj[0]["PageList"][0]["TimeInterval"];
          getRotationPageId = getRotationObj[0]["PageList"][0]["PageId"];
        }
      } else {
        getTime = getRotationObj[0]["PageList"][0]["TimeInterval"];
        getRotationPageId = getRotationObj[0]["PageList"][0]["PageId"];
      }

      if (getTime) {
        //converted minutes into second
        rotationTime = parseFloat(getTime) * 60;
      }

      if (
        pageId !== undefined &&
        pageId !== "" &&
        pageId.toString() !== getRotationPageId.toString()
      ) {
        updateRotationSettings(getRotationPageId, id, userId);
        setTimeout(() => {
          history.push("/" + sessionId + "/" + getRotationPageId);
        }, 1);
      } else {
        updateRotationSettings(getRotationPageId, id, userId, true);
      }
    } else if (id === "Off" || id === "false") {
      dispatch(layoutActions.setRotationTime(0));
      dispatch(layoutActions.setClockRotation(false));
      dispatch(layoutActions.setActiveRotationId("Off"));
      //dispatch(pageActions.setRotationPageId(""));
      RotationServices.updatePageRotationStatus(userId, id)
        .then(() => {
          dispatch(layoutActions.setRotationUpdateVersion());
          dispatch(pageActions.setRotationPageId(""));
        })
        .catch((err) => {
          console.log("updatePageRotationStatus:", err);
        });
    } else {
      alert("You cannot enable Auto Rotate until you add at least two pages.");
    }
  };

  const updateRotationSettings = (
    getRotationPageId,
    id,
    userId,
    isVersionUpdated = false
  ) => {
    // dispatch(pageActions.setRotationPageId(getRotationPageId));
    // dispatch(layoutActions.setActiveRotationId(id));
    // dispatch(layoutActions.setClockRotation(true));
    RotationServices.updatePageRotationStatus(userId, id)
      .then((x) => {
        if (isVersionUpdated === true) {
          dispatch(layoutActions.setRotationUpdateVersion());
        }
        dispatch(pageActions.setRotationPageId(getRotationPageId));
        dispatch(layoutActions.setActiveRotationId(id));
        dispatch(layoutActions.setClockRotation(true));
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  return (
    <div
      className={`${
        getTheme !== "Light" ? classes["button-dark"] : ""
      } pagerotate dx-button dx-widget dx-button-has-icon dx-button dx-button-normal`}
      style={{ marginRight: "4px", userSelect: "none" }}
    >
      <div className="dx-template-wrapper dropdown-toggle page-rotation-menu">
        <i
          className={`dx-icon page-rotation-icon ${
            getTheme !== "Light"
              ? classes["icon-clockIcon-dark"]
              : classes["icon-clockIcon"]
          }`}
          title="Auto Rotate"
        ></i>
        {isUserLoggedIn && (
          <ul
            role="menu"
            className="dropdown-menu page-rotation-menu-items ddl-menu-dark"
            style={{
              display: "none",
              textAlign: "center",
            }}
          >
            <li>
              <a
                href=""
                className="dropdown-item"
                style={{
                  color: "#abc43a",
                  width: "auto",
                }}
                onClick={(event) => pageRotationHandler(event, "Off")}
              >
                Auto Rotate: Off
              </a>
            </li>
            <li className="divider"></li>
            <li>
              <a
                href=""
                className="dropdown-item"
                style={{
                  color: "#abc43a",
                  width: "auto",
                }}
                onClick={openSettingsHandler}
              >
                Add/Edit Rotation
              </a>
            </li>
            {rotationList.length > 0 && (
              <li className="divider" key={Math.random(150, 500)}></li>
            )}
            {rotationList.map((item, index) => {
              return (
                <RotationItem
                  key={item.Id}
                  item={item}
                  index={index}
                  rotationLength={rotationList.length}
                  onClickHandler={pageRotationHandler}
                  activeRotationId={activeRotationId}
                />
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

const RotationItem = ({
  item,
  index,
  rotationLength,
  onClickHandler,
  activeRotationId,
}) => {
  return (
    <>
      <li>
        <a
          href=""
          className="dropdown-item"
          style={{
            color: "#abc43a",
            width: "auto",
          }}
          onClick={(event) => onClickHandler(event, item.Id)}
        >
          {activeRotationId.toString() === item.Id.toString() && (
            <span className="fa fa-check"></span>
          )}
          {item.RotationName}
        </a>
      </li>
      {index !== rotationLength - 1 && <li className="divider"></li>}
    </>
  );
};

export default RotationMenuOption;
