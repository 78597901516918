import React from "react";
import addButtonImg from "../../assets/AddWidgetButton.png";
import addButtonImgDark from "../../assets/AddWidgetButton-dark.png";
import classes from "./Widget.module.css";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../store/layout-slice";
import { useSelector } from "react-redux";

const EmptyWidget = (props) => {
  const dispatch = useDispatch();
  const getTheme = useSelector((state) => state.layout.theme);

  const onClickAddWidgetHandler = () => {
    dispatch(layoutActions.setAddWidgetPopup());
    dispatch(layoutActions.setSelectedWidgetId(props.widgetId));
  };

  return (
    <div className={classes["emptyWidget"]}>
      <div className={classes["content"]}>
        <img
          style={{ verticalAlign: "middle" }}
          className={`  ${
            getTheme !== "Light" ? classes["button-dark"] : ""
          } AddWidgetIcon dx-widget dx-state-hover-enabled dx-feedback dx-button dx-button-has-icon dx-button-normal`}
          title="Add Widget"
          src={getTheme !== "Light" ? addButtonImgDark : addButtonImg}
          onClick={onClickAddWidgetHandler}
        ></img>
      </div>
    </div>
  );
};

export default EmptyWidget;
