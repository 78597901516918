const ConfigurationConstant = {
  options: "options",
  Case_Aging_with_Promised_Date: "Case Aging (with Promised Date)",
  Total_Hours: "Total Hours",
  If_Promised_Date_Equals_Opened_Date: "If Promised Date = Opened Date",
  If_Promised_Date_Greater_Closed_Date: "If Promised Date > Closed Date",
  Calculation_Options: "Calculation Options",
  ASAP: "As Soon As Possible (ASAP)",
  ALAP: "As Late As Possible (ALAP)",
  Not_Applicable: "Not Applicable",
  Case_Owners: "Case Owners",
  Sales_Opp_Owners: "Sales Opp Owners",
  Sales_Opp_Owners_Capacity: "Sales Opp Owners Capacity",
  Protected_Calling_Times: "Protected Calling Times",
  Holidays: "Holidays",
  Global_Holidays: "Global Holidays",
  Teams: "Teams",
  Sales_Opportunity_Owner: "Sales Opportunity Owner",
  Case_Types: "Case Types",
  Case_Status_Values: "Case Status Values",
  Synchronize_Colors: "Synchronize Colors",
};

export default ConfigurationConstant;
