import { useEffect, useRef, React } from "react";
import * as d3 from "d3";
import $ from "jquery";

let rawText = "",
  textAlign = "start",
  rowSpacing = 0.4,
  fontColor = "#000",
  fontRT = 1;

const margin = {
  top: 10,
  bottom: 10,
  left: 10,
  right: 10,
};

const draw = (svg, width, height, rText, color, align) => {
  let text = svg.append("text");
  text = svg
    .append("text")
    .text("a")
    .style("font-size", "1000pt");
  // .style('font-family', 'Open Sans');
  fontRT = 1000 / text.node().getBBox().height;
  text.text("");
  fontColor = color;
  textAlign = align;
  rawText = rText;

  // Estimated row count
  let rowCount = rawText.split(".").length || 1;
  let rowSpaceCount = rawText.split(".").length - 1 || 0;

  let lines = [];

  let count_state = null;
  let first_match = true;
  let match_end = false;
  let paragraphPoint = "";

  while (!match_end) {
    let result = get_lines((height * fontRT) / rowCount, text, width);

    lines = result[0];
    let count = result[1];
    paragraphPoint = result[2];
    if (count == rowCount) {
      match_end = true;
    }
    if (count < rowCount) {
      match_end = true;
    }
    if (count > rowCount) {
      rowCount++;
    }
  }

  draw_text(lines, rowCount, paragraphPoint, text, width, height);
};

const get_lines = (font_size, text, width) => {
  let cur_row = 0,
    chars = rawText.split(""),
    lines = [""],
    paragraphPoint = [];

  text.style("font-size", font_size + "pt");

  $.each(chars, function(i, d) {
    text.selectAll("tspan").remove();
    text.append("tspan").text(lines[cur_row] + d);

    let line_width = text.node().getBBox().width;

    if (line_width >= width) {
      if (d == " ") {
        lines.push("");
        cur_row++;
      } else {
        let break_i = -1;
        for (let i = lines[cur_row].length - 1; i >= 0; i--) {
          if (lines[cur_row][i] == " ") {
            if (break_i == -1) {
              break_i = i;
              let new_line = lines[cur_row].slice(break_i + 1);
              let prev_line = lines[cur_row].slice(0, break_i);
              lines.push(new_line + d);
              lines[cur_row] = prev_line;
              cur_row++;
            }
          }
          if (lines[cur_row][i] == "-") {
            if (break_i == -1) {
              break_i = i;
              let new_line = lines[cur_row].slice(break_i + 1);
              let prev_line = lines[cur_row].slice(0, break_i + 1);
              lines.push(new_line + d);
              lines[cur_row] = prev_line;
              cur_row++;
            }
          }
        }
        if (break_i == -1) {
          lines[cur_row] += d;
        }
      }
    } else {
      lines[cur_row] += d;
    }
  });

  return [lines, cur_row + 1, paragraphPoint];
};

const draw_text = (lines, rowCount, paragraphPoint, text, width, height) => {
  let p = 0;
  let fontSize = (fontRT * height) / rowCount;
  let fontHeight = height / rowCount;
  text.selectAll("tspan").remove();
  text.style("font-size", fontSize + "pt");
  lines.forEach(function(d, i) {
    if (i == paragraphPoint[p]) {
      p++;
    }
    text
      .append("tspan")
      .text(d)
      .attr("dy", "0.88em")
      .attr("x", 0)
      .attr("y", i * fontHeight + p * fontSize * rowSpacing);
  });

  // Adjust font-size to fit height

  let size = text.node().getBBox();

  if (size.height > height) {
    p = 0;
    fontSize = (fontSize * height) / size.height;
    fontHeight = (fontHeight * height) / size.height;
    text.style("font-size", fontSize + "pt");
    text.selectAll("tspan").remove();
    lines.forEach(function(d, i) {
      if (i == paragraphPoint[p]) {
        p++;
      }
      text
        .append("tspan")
        .text(d)
        .attr("dy", "0.88em")
        .attr("x", 0)
        .attr("y", i * fontHeight + p * fontSize * rowSpacing);
    });
  }

  // Adjust font-size to fit width

  size = text.node().getBBox();

  if (size.width > width) {
    p = 0;
    fontSize = (fontSize * width) / size.width;
    fontHeight = (fontHeight * width) / size.width;
    text.style("font-size", fontSize + "pt");
    text.selectAll("tspan").remove();
    lines.forEach(function(d, i) {
      if (i == paragraphPoint[p]) {
        p++;
      }
      text
        .append("tspan")
        .text(d)
        .attr("dy", "0.88em")
        .attr("x", 0)
        .attr("y", i * fontHeight + p * fontSize * rowSpacing);
    });
  }

  // Positioning to middle

  size = text.node().getBBox();

  let w_off = margin.left + (width - size.width) / 2;
  let h_off = margin.top + (height - size.height) / 2;

  text.selectAll("tspan").remove();
  p = 0;
  lines.forEach(function(d, i) {
    if (i == paragraphPoint[p]) {
      p++;
    }
    text
      .append("tspan")
      .text(d)
      .attr("dy", "0.88em")
      .attr("x", w_off)
      .attr("y", i * fontHeight + p * fontSize * rowSpacing + h_off);
  });

  // Apply align

  switch (textAlign) {
    case "center":
      text
        .selectAll("tspan")
        .attr("x", margin.left + width / 2)
        .style("text-anchor", "middle");
      break;
    case "right":
      text
        .selectAll("tspan")
        .attr("x", width - margin.right)
        .style("text-anchor", "end");
      break;
  }

  // Apply color

  text.selectAll("tspan").style("fill", fontColor);
};

const TextNumericD3Component = (props) => {
  const d3Container = useRef(null);

  useEffect(() => {
    props.getWidgetSize();
    if (props.content !== undefined && d3Container.current) {
      const svg = d3.select(d3Container.current);
      svg.selectAll("*").remove();
      const w = props.width - margin.left - margin.right;
      const h = props.height - margin.top - margin.bottom;
      draw(svg, w, h, props.content, props.color, props.align);
    }
  }, [
    props.align,
    props.content,
    props.color,
    props.width,
    props.height,
    props.wid,
  ]);

  return (
    <svg
      ref={d3Container}
      style={{
        width: "100%",
        height: "100%",
        fontFamily: '"Open Sans", sans-serif',
      }}
    ></svg>
  );
};

export default TextNumericD3Component;
