import TableBody from "./TableBodyContent";
import TableHeaderTH from "./TableHeaderContent";
import styles from "./TableTemplate.module.css";
import { useEffect, useState, useRef, React } from "react";
import { GetGridSortSettings, UpdateGridSettings } from "../TableWidgetService";
import ContextMenu from "./ContextMenu";
import { Scrollbars } from "react-custom-scrollbars";
import LoaderPanel from "./LoaderPanel";
import SetColumnWidth from "./../ResizerService";

const offWidthDeduction = 9;
const TableContent = (props) => {
  const [sortColumn, setSortColumn] = useState("");
  const [sortColumnDir, setSortColumnDir] = useState("");
  const [lastSortColumn, setLastSortColumn] = useState("");
  const [metaData, setMetaData] = useState(props.metaData);
  const [height, setHeight] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const resizingPosX = useRef(0);
  const isColResizing = useRef(false);
  const isDisabledSort = useRef(false);
  const tableBodyRef = useRef();
  const scrollref = useRef();
  const isScrollInit = useRef(false);

  const onSortHandler = (dataField, sortDir) => {
    if (isDisabledSort.current == true) {
      return false;
    }
    let _sortDir = sortDir;
    if (lastSortColumn === dataField) {
      if (sortColumnDir === "") {
        _sortDir = "asc";
      }
      if (sortColumnDir === "desc") {
        _sortDir = "asc";
      }
      if (sortColumnDir === "asc") {
        _sortDir = "desc";
      }
    } else {
      _sortDir = "asc";
    }

    scrollref.current.scrollTop(0);

    setSortColumnDir(_sortDir);
    setSortColumn(dataField);
    setLastSortColumn(dataField);
    updateMetaData(metaData, dataField, _sortDir);
    UpdateGridSettings(props.widgetId, dataField, _sortDir);
  };

  const onClearSortHandler = (dataField) => {
    let _sortDir = "";
    setSortColumnDir(_sortDir);
    setSortColumn(dataField);
    setLastSortColumn(dataField);
    clearSortdt(metaData);
    UpdateGridSettings(props.widgetId, dataField, _sortDir);
  };

  useEffect(() => {
    var arrList = Object.assign([], props.metaData);
    const sortSettings = GetGridSortSettings(props.widgetId);
    if (sortSettings.sortProperty !== "" && sortSettings.sortOrder !== "") {
      setSortColumnDir(sortSettings.sortOrder);
      setSortColumn(sortSettings.sortProperty);
      setLastSortColumn(sortSettings.sortProperty);
      updateMetaData(
        arrList,
        sortSettings.sortProperty,
        sortSettings.sortOrder
      );
      // document.getElementById(`table${props.widgetId}`).myExtensionMethod();
    } else {
      setMetaData(arrList);
    }

    isScrollInit.current = false;

    document.addEventListener("mouseup", handleDocumentMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleDocumentMouseUp);
    };
  }, [props.metaData, props.widgetData]);

  const updateMetaData = (metaData, dataField, _sortDir) => {
    const updatedMetaData = metaData.map((item) => {
      if (item.dataField.toLowerCase() === dataField.toLowerCase()) {
        item["isSortColumn"] = true;
        item["sortColumnDir"] = _sortDir;
      } else {
        item["isSortColumn"] = false;
        item["sortColumnDir"] = "";
      }
      return item;
    });
    setMetaData(updatedMetaData);
  };

  const clearSortdt = (metaData) => {
    scrollref.current.scrollTop(0);
    const updatedMetaData = metaData.map((item) => {
      item["isSortColumn"] = false;
      item["sortColumnDir"] = "";
      return item;
    });
    setMetaData(updatedMetaData);
  };

  const updateHeight = (height) => {
    scrollref.current.scrollTop(0);
    setHeight(height);
  };

  const getDisplayedItem = (count) => {
    setItemCount(count);
  };

  const renderThumb = ({ style, ...props }) => {
    const { top } = 10;
    const thumbStyle = {
      backgroundColor: "rgb(0, 0, 0)",
      width: "75%",
      opacity: "0.4",
    };
    return (
      <div
        className="scroller-bg"
        style={{ ...style, ...thumbStyle }}
        {...props}
      />
    );
  };

  const onScrollEnd = (event) => {};
  const onScroll = (event) => {};
  const handleScrollFrame = (value) => {
    let scrollTop = 1;
    if (
      isScrollInit.current === false &&
      parseFloat(value.top).toFixed(2) > 0.901
    ) {
      scrollTop = 0.901;
      isScrollInit.current = true;
      console.log("scrollTop1", scrollTop);
    }

    if (parseFloat(value.top).toFixed(2) >= parseFloat(scrollTop).toFixed(2)) {
      if (props.widgetData.length > itemCount) {
        setShowLoader(true);
        setTimeout(() => {
          tableBodyRef.current.AddItemOnScroll();
          setShowLoader(false);
        }, 500);
      }
    }
  };

  const handleUpdate = (values) => {};

  const handleScrollStart = (event) => {};

  const mouseMoveHandler = (e) => {
    if (isColResizing.current == true) {
      const parentWidth = document.getElementById(`table${props.widgetId}`)
        .parentNode.parentElement.clientWidth;

      const _resizing = document
        .getElementById(`table${props.widgetId}`)
        .querySelectorAll("th.resizing .resizer");

      if (_resizing.length == 1) {
        const _nextCol = document
          .getElementById(`table${props.widgetId}`)
          .querySelectorAll("th.resizing + th");
        const _pageX = e.pageX || 0;
        const _widthDiff = _pageX - resizingPosX.current;
        const _setWidth =
          _resizing[0].closest("th").offsetWidth -
          offWidthDeduction +
          _widthDiff;
        const _nextColWidth =
          _nextCol[0].offsetWidth - offWidthDeduction - _widthDiff;

        if (
          resizingPosX != 0 &&
          _widthDiff != 0 &&
          _setWidth > 20 &&
          _nextColWidth > 20
        ) {
          const setWidthInPer = Math.round((100 * _setWidth) / parentWidth);
          SetColumnWidth(
            _resizing[0].closest("th").getAttribute("data-col"),
            props.widgetId,
            setWidthInPer
          );
          _resizing[0].closest("th").style.width = _setWidth + "px";
          resizingPosX.current = e.pageX;
          const _nextRowWidthInPer = Math.round(
            (100 * _nextColWidth) / parentWidth
          );
          _nextCol[0].style.width = _nextColWidth + "px";
          SetColumnWidth(
            _nextCol[0].closest("th").getAttribute("data-col"),
            props.widgetId,
            _nextRowWidthInPer
          );
        }
      }
    }
  };

  const handleDocumentMouseUp = (e) => {
    isColResizing.current = false;
    document
      .querySelectorAll(`#table${props.widgetId} > thead > tr > th`)
      .forEach((x) => x.classList.remove("resizing"));
    e.stopPropagation();
    setTimeout(() => {
      isDisabledSort.current = false;
    }, 500);
  };

  const resizerMousedown = (e) => {
    if (e.button == 0) {
      document
        .querySelector(`#table${props.widgetId} th`)
        .classList.remove("resizing");
      e.target.parentNode.classList.add("resizing");
      isColResizing.current = true;
      resizingPosX.current = e.pageX;
      isDisabledSort.current = true;
    }
  };

  return (
    <>
      <Scrollbars
        ref={scrollref}
        style={{ height: height }}
        autoHide
        renderThumbVertical={renderThumb}
        onScrollStop={onScrollEnd}
        onScroll={onScroll}
        onScrollFrame={handleScrollFrame}
        onUpdate={handleUpdate}
        onScrollStart={handleScrollStart}
      >
        <table
          id={`table${props.widgetId}`}
          className={`${styles["tbl-datagrid-table"]} ${styles["tbl-datagrid-table-fixed"]} resizable tblHeader_selector`}
          role="grid"
          onMouseMove={mouseMoveHandler}
        >
          <thead style={{ position: "sticky", top: "0" }}>
            <tr
              className={`${styles["tbl-first-row"]} ${styles["tbl-column-lines"]} ${styles["tbl-header-row"]}`}
            >
              {metaData.map((item, i) => {
                return (
                  <TableHeaderTH
                    colDetails={item}
                    key={i}
                    onSort={onSortHandler}
                    resizerMousedown={resizerMousedown}
                    islastItem={metaData.length - 1 === i}
                  />
                );
              })}
            </tr>
          </thead>
          <TableBody
            columnList={props.metaData}
            widgetData={props.widgetData}
            widgetId={props.widgetId}
            settings={props.settings}
            sortColumName={sortColumn}
            sortColumDir={sortColumnDir}
            getHeight={updateHeight}
            isScrollActive={showLoader}
            ref={tableBodyRef}
            getDisplayedItem={getDisplayedItem}
            wid={props.wid}
          />
        </table>
      </Scrollbars>
      {showLoader && <LoaderPanel />}
      <ContextMenu
        widgetId={props.widgetId}
        sortCol={sortColumn}
        sortDir={sortColumnDir}
        onSort={onSortHandler}
        clearSort={onClearSortHandler}
      />
    </>
  );
};

export default TableContent;
