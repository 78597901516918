import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Widgets from "../../Widgets/Widgets";
import classes from "./Dashboard.module.css";
import { layoutActions } from "../../../store/layout-slice";
import { pageActions } from "../../../store/page-slice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pageId } = useParams();

  const getTheme = useSelector((state) => state.layout.theme);
  const isUserLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const rotationList = useSelector((state) => state.layout.RotationList);
  const activeRotationId = useSelector((state) => state.layout.PageRotation);
  const sessionId = useSelector((state) => state.login.sessionId);
  const rotationPageId = useSelector((state) => state.page.rotationPageId);
  const isHeaderDisplayed = useSelector(
    (state) => state.layout.IsHeaderDisplayed
  );

  const [rotationInterval, setRotationInterval] = useState(null);

  const containerCss = getTheme !== "Light" ? "container-dark" : "container";

  useEffect(() => {
    checkRotationStatus(rotationList, activeRotationId);

    if (!isUserLoggedIn) {
      clearInterval(rotationInterval);
      dispatch(layoutActions.setClockRotation(true));
      dispatch(layoutActions.setRotationTime(0));
    }

    return () => clearInterval(rotationInterval);
  }, [activeRotationId, rotationPageId, isUserLoggedIn]);

  const checkRotationStatus = (rotationList, activeRotationId) => {
    if (activeRotationId === "Off") {
      clearInterval(rotationInterval);
      return;
    }
    clearInterval(rotationInterval);

    const getRotationObj = rotationList.find(
      (res) => res.Id.toString() === activeRotationId.toString()
    );

    if (!getRotationObj) return;

    const rotationPageList = getRotationObj.PageList;
    console.log("rotationPageList", rotationPageList);
    const currentPage = rotationPageList.find(
      (pageRes) => pageRes.PageId.toString() === rotationPageId?.toString()
    );
    console.log("currentPage", currentPage);
    const rotationTime =
      parseFloat(
        currentPage?.TimeInterval || rotationPageList[0].TimeInterval
      ) * 60 || 0;

    dispatch(layoutActions.setClockRotation(rotationTime > 0));
    dispatch(layoutActions.setRotationTime(rotationTime));

    if (rotationTime > 0) {
      startCustomPageRotation(
        rotationTime * 1000,
        rotationPageList,
        rotationPageId
      );
    }
  };

  const startCustomPageRotation = (time, rotationPageList, currentpageId) => {
    console.log("startCustomPageRotation", time, new Date());
    const interval = setInterval(() => {
      const nextPage = getNextPage(rotationPageList, currentpageId ?? pageId);
      if (nextPage) {
        console.log("nextPage", nextPage);
        dispatch(pageActions.setRotationPageId(nextPage.PageId));
        history.push(`/${sessionId}/${nextPage.PageId}`);
      }
    }, time);

    setRotationInterval(interval);
  };

  const getNextPage = (pageArr, currentPageId) => {
    if (!currentPageId) {
      const defaultPageIndex = pageArr.findIndex(
        (data) => data.PageName === "Default Page"
      );
      return pageArr[defaultPageIndex + 1] || pageArr[0];
    }

    const currentIndex = pageArr.findIndex(
      (data) => data.PageId.toString() === currentPageId.toString()
    );

    return (
      pageArr[currentIndex + 1] ||
      (pageArr[0].PageId.toString() !== currentPageId.toString() && pageArr[0])
    );
  };

  return (
    <div
      className={classes[containerCss]}
      style={{ top: isHeaderDisplayed ? "16px" : "" }}
    >
      <Widgets />
    </div>
  );
};

export default Dashboard;
