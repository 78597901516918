import React, { useState } from "react";
import { useSelector } from "react-redux";
import GetMetaData, {
  GetOOSWidgetData,
  GetSalesWidgetData,
  GetWidgetData,
  GridMetaData_Format,
} from "../../../Helper/Services";
import exportToExcelService from "../../../Helper/exportToExcel";
import {
  GetFormattedGridData,
  GetSalesWidgetFormattedData,
} from "../../../Helper/WidgetDataCommon";
import { BUBBLECHART, SALES, SALESV2 } from "../../../Constants";

const DatasourceControl = (props) => {
  const exportToExcelDetails = useSelector(
    (state) => state.page.exportExcelDetails
  );
  const widgetType = useSelector((state) => state.layout.selectedWidgetType);
  const isOtherUserPage = useSelector((state) => state.layout.IsOtherUserPage);
  const sharedUserEmail = useSelector((state) => state.layout.SharedUserEmail);
  const xAxisValBuubleChart = useSelector(
    (state) => state.page.xAxisValBubbleChart
  );
  const sessionId = useSelector((state) => state.login.sessionId);
  const [isExportingData, setIsExportingData] = useState(false);

  const exportToExcel = () => {
    if (isExportingData) return false;

    setIsExportingData(true);
    if (widgetType !== SALES && widgetType !== SALESV2) {
      console.log("widgetType", widgetType);

      if (widgetType === BUBBLECHART) {
        GetMetaData(exportToExcelDetails.dataSource, "", sessionId)
          .then((json) => {
            const metaData = GridMetaData_Format(json);
            GetOOSWidgetData(
              exportToExcelDetails.filterName,
              exportToExcelDetails.dataSource,
              exportToExcelDetails.connectionName,
              sessionId,
              isOtherUserPage,
              sharedUserEmail,
              exportToExcelDetails.isSharedFilter,
              exportToExcelDetails.sharedFilterUserId,
              exportToExcelDetails.filterId
            )
              .then((resp) => {
                const gridData = GetFormattedGridData(resp).filter(
                  (x) => parseInt(x.daysOpen) <= xAxisValBuubleChart
                );
                prepareExportToExcelfile(
                  gridData,
                  widgetType,
                  metaData["Grid Meta-Data"],
                  exportToExcelDetails.dataSource
                );
              })
              .catch((err) => {
                console.log("exportToExcel Err", err);
                setIsExportingData(false);
              });
          })
          .catch((err) => {
            setIsExportingData(false);
          });
      } else {
        GetMetaData(exportToExcelDetails.dataSource, "", sessionId)
          .then((json) => {
            const metaData = GridMetaData_Format(json);
            GetWidgetData(
              exportToExcelDetails.filterName,
              exportToExcelDetails.dataSource,
              exportToExcelDetails.connectionName,
              sessionId,
              isOtherUserPage,
              sharedUserEmail,
              exportToExcelDetails.isSharedFilter,
              exportToExcelDetails.sharedFilterUserId,
              exportToExcelDetails.filterId
            )
              .then((resp) => {
                const gridData = GetFormattedGridData(resp);
                prepareExportToExcelfile(
                  gridData,
                  widgetType,
                  metaData["Grid Meta-Data"],
                  exportToExcelDetails.dataSource
                );
              })
              .catch((err) => {
                console.log("exportToExcel Err", err);
                setIsExportingData(false);
              });
          })
          .catch((err) => {
            setIsExportingData(false);
          });
      }
    } else {
      var sawExportData = [];
      GetSalesWidgetData(
        exportToExcelDetails.filterName,
        exportToExcelDetails.dataSource,
        sessionId,
        isOtherUserPage,
        sharedUserEmail,
        exportToExcelDetails.isSharedFilter,
        exportToExcelDetails.sharedFilterUserId,
        exportToExcelDetails.filterId,
        "activity"
      )
        .then((activityData) => {
          const activity_dt = GetSalesWidgetFormattedData(activityData);
          if (activity_dt !== undefined && activity_dt.length > 0) {
            const activityMetaData = exportToExcelService.GenerateMetaData(
              activity_dt[0]
            );
            sawExportData.push({
              "meta-data": activityMetaData,
              data: activity_dt,
              sheetName: "Activity Data",
            });
          }

          //stage data call
          GetSalesWidgetData(
            exportToExcelDetails.filterName,
            exportToExcelDetails.dataSource,
            sessionId,
            isOtherUserPage,
            sharedUserEmail,
            exportToExcelDetails.isSharedFilter,
            exportToExcelDetails.sharedFilterUserId,
            exportToExcelDetails.filterId,
            "stage"
          )
            .then((stageData) => {
              const stage_dt = GetSalesWidgetFormattedData(stageData);
              if (stage_dt !== undefined && stage_dt.length > 0) {
                const stageMetaData = exportToExcelService.GenerateMetaData(
                  stage_dt[0]
                );
                sawExportData.push({
                  "meta-data": stageMetaData,
                  data: stage_dt,
                  sheetName: "Stage Data",
                });
              }

              //capacity data call
              GetSalesWidgetData(
                exportToExcelDetails.filterName,
                exportToExcelDetails.dataSource,
                sessionId,
                isOtherUserPage,
                sharedUserEmail,
                exportToExcelDetails.isSharedFilter,
                exportToExcelDetails.sharedFilterUserId,
                exportToExcelDetails.filterId,
                "capacity"
              )
                .then((capData) => {
                  const capacity_Dt = GetSalesWidgetFormattedData(capData);
                  if (capacity_Dt !== undefined && capacity_Dt.length > 0) {
                    for (var i = 0; i < capacity_Dt.length; i++) {
                      if (capacity_Dt.length - 1 !== i) {
                        capacity_Dt[i]["Y_Axis"] =
                          capacity_Dt[capacity_Dt.length - 1]["y_axis"];
                        capacity_Dt[i]["Blueline Value"] =
                          capacity_Dt[capacity_Dt.length - 1]["bluelineValue"];
                      }
                    }

                    const capacityMetaData =
                      exportToExcelService.GenerateMetaData(capacity_Dt[0]);
                    sawExportData.push({
                      "meta-data": capacityMetaData,
                      data: capacity_Dt,
                      sheetName: "Capacity Data",
                    });
                  }

                  //pct data call
                  GetSalesWidgetData(
                    exportToExcelDetails.filterName,
                    exportToExcelDetails.dataSource,
                    sessionId,
                    isOtherUserPage,
                    sharedUserEmail,
                    exportToExcelDetails.isSharedFilter,
                    exportToExcelDetails.sharedFilterUserId,
                    exportToExcelDetails.filterId,
                    "pct"
                  )
                    .then((pctData) => {
                      const pct_dt = GetSalesWidgetFormattedData(pctData);
                      if (pct_dt !== undefined && pct_dt.length > 0) {
                        var pctMetaData = [];
                        pctMetaData = exportToExcelService.GenerateMetaData(
                          pct_dt[0]
                        );
                        sawExportData.push({
                          "meta-data": pctMetaData,
                          data: pct_dt,
                          sheetName: "PCT Data",
                        });
                      }

                      prepareExportToExcelfile(
                        sawExportData,
                        widgetType,
                        "",
                        exportToExcelDetails.dataSource
                      );
                    })
                    .catch((err) => {
                      console.log("pct data call", err);
                      setIsExportingData(false);
                    });
                })
                .catch((err) => {
                  console.log("capacity data call:-", err);
                  setIsExportingData(false);
                });
            })
            .catch((err) => {
              console.log("stage data call:-", err);
              setIsExportingData(false);
            });
        })
        .catch((err) => {
          console.log("Activity data call:-", err);
          setIsExportingData(false);
        });
    }
  };

  const prepareExportToExcelfile = (dt, widgetType, headerData, dataSource) => {
    let getData = [];
    let sheetName = ["Sheet 1", "Sheet 2"];
    if (dt.length > 0) {
      if (widgetType === "SALES" || widgetType === "SALESV2") {
        sheetName = [];
        for (let i = 0; i < dt.length; i++) {
          let getRowData = {};
          getRowData["cells"] = dt[i]["meta-data"];
          getRowData["rows"] = dt[i]["data"];
          sheetName.push(dt[i]["sheetName"]);
          getData.push(getRowData);
        }
      } else {
        let getRowData = {};
        getRowData["cells"] = headerData;
        getRowData["rows"] = dt;
        getData.push(getRowData);
      }
    } else {
      getData.push({
        cells: headerData,
        rows: [{}],
      });
    }
    const result = exportToExcelService.ExportToExcelByExcelJs(
      getData,
      sheetName,
      dataSource.replace(/' '/g, "_") + ".xlsx"
    );

    if (result) {
      setIsExportingData(false);
    }
  };

  return (
    <React.Fragment>
      <p>
        Data Source
        {props.isEdit && (
          <span>
            &nbsp;(
            <span
              title={!isExportingData ? "Download" : "Please wait..."}
              onClick={exportToExcel}
              style={{
                color: "#9AB57E",
                cursor: !isExportingData ? "pointer" : "not-allowed",
              }}
            >
              Export to Excel
            </span>
            )
          </span>
        )}
      </p>
      <select
        value-key="Filter"
        onChange={props.onChangeHandler}
        value={props.value}
      >
        <option value="">Please Select</option>
        {props.dtList.map((item, index) => {
          return (
            <option key={index} value={item}>
              {item}
            </option>
          );
        })}
      </select>
      <span
        style={{
          display: props.isValid ? "none" : "block",
          color: "red",
          paddingLeft: "5px",
        }}
      >
        Required
      </span>
    </React.Fragment>
  );
};

export default DatasourceControl;
