import React from "react";
import { useDispatch } from "react-redux";
import { ReplaceTextToSpecialChar } from "../../../Helper/WidgetDataCommon";
import { layoutActions } from "../../../store/layout-slice";

const FilterControl = (props) => {
  const dispatch = useDispatch();

  const onChangeHandler = (e) => {
    if (e.target.value === "CreateNewFilter") {
      dispatch(layoutActions.setFilterPopup({ edit: false, clone: false }));
    }

    if (e.target.value === "EditExistingFilter") {
      dispatch(layoutActions.setFilterPopup({ edit: true, clone: false }));
    }

    if (e.target.value === "CloneFilter") {
      dispatch(layoutActions.setFilterPopup({ edit: false, clone: true }));
    }

    if (props.onChange !== undefined) {
      const issharedfilter = e.target[e.target.selectedIndex].getAttribute(
        "data-issharedfilter"
      );
      const filterid =
        e.target[e.target.selectedIndex].getAttribute("data-filterid");
      const originaluserid = e.target[e.target.selectedIndex].getAttribute(
        "data-originaluserid"
      );
      const filterValueName = e.target.value;
      props.onChange({
        issharedfilter: issharedfilter,
        filterid: filterid,
        originaluserid: originaluserid,
        filterValue: filterValueName,
      });
    }
  };

  return (
    <div id="filterDiv">
      <p>Filter</p>
      <select
        value-key="Filter"
        id="filterDdl"
        value={ReplaceTextToSpecialChar(props.value)}
        onChange={onChangeHandler}
      >
        <option value="0" disabled="disabled">
          Select Filter
        </option>
        {props.dtList.map((item) => {
          return (
            <option
              key={item.filterId}
              data-issharedfilter={item.isSharedFilter}
              data-filterid={item.filterId}
              data-originaluserid={item.originalUserId}
              value={ReplaceTextToSpecialChar(item.filterName)}
            >
              {ReplaceTextToSpecialChar(item.displayName)}
            </option>
          );
        })}
        <option value="null">None</option>
        <option disabled="disabled" value="Divider">
          -----------------------------------------
        </option>
        <option value="CreateNewFilter">Create New Filter</option>
        <option value="CloneFilter">Clone Filter</option>
        {props.dtList.length > 0 && (
          <option value="EditExistingFilter">Edit / Delete Filter</option>
        )}
        {props.dtList.length === 0 && (
          <option disabled="disabled" value="EditExistingFilter">
            Edit / Delete Filter
          </option>
        )}
      </select>
      <input type="hidden" value-key="IsSharedFilter" id="hdn_IsSharedFilter" />
      <input type="hidden" value-key="OriginalUserId" id="hdn_OriginalUserId" />
      <input type="hidden" value-key="FilterId" id="hdn_FilterId" />
      <span style={{ display: "none", color: "red", paddingLeft: "5px" }}>
        Please select filter name
      </span>
    </div>
  );
};

export default FilterControl;
