import { GetGridLocalStorage, SetGridLocalStorage } from "./TableWidgetService";

const SetColumnWidth = (columnName, widgetId, width) => {
  var getLocalStorage = GetGridLocalStorage(widgetId);
  if (getLocalStorage != undefined && getLocalStorage != null) {
    var objIndex = getLocalStorage.columns.findIndex(
      (obj) => obj.dataField == columnName
    );
    if (objIndex != -1) {
      getLocalStorage.columns[objIndex]["width"] = width + "%";
    }
    SetGridLocalStorage(widgetId, getLocalStorage);
  }
};

export default SetColumnWidth;
