import React from "react";
import useContextMenu from "../../../../../hooks/menu-context";
import styles from "./TableTemplate.module.css";

const ContextMenu = ({ widgetId, onSort, clearSort }) => {
  const { xPos, yPos, showMenu, sortCol, sortDir } = useContextMenu(widgetId);
  return (
    <>
      {showMenu ? (
        <ul
          id={`contextmenu_${widgetId}`}
          data-widgetid={widgetId}
          className={`dropdown-menu ${styles["menu-ddl"]} context_menu`}
          style={{
            top: yPos,
            left: xPos,
            position: "fixed",
            display: showMenu ? "block" : "block",
          }}
        >
          <li onClick={() => onSort(sortCol, sortDir)}>
            <a
              className="cols_sort_asc"
              style={{ opacity: sortDir === "asc" ? "0.5" : "1" }}
            >
              <i
                className={`fa fa-sort-alpha-asc ${styles["sorting-icon"]} `}
                aria-hidden="true"
              ></i>
              <span
                style={{
                  paddingLeft: "7px",
                }}
                className={styles["menu-item-text"]}
              >
                Sort Ascending
              </span>
            </a>
          </li>
          <li onClick={() => onSort(sortCol, sortDir)}>
            <a
              className="cols_sort_desc"
              style={{ opacity: sortDir === "desc" ? "0.5" : "1" }}
            >
              <i
                className={`fa fa-sort-alpha-desc ${styles["sorting-icon"]}`}
                aria-hidden="true"
              ></i>
              <span
                style={{ paddingLeft: "7px" }}
                className={styles["menu-item-text"]}
              >
                Sort Descending
              </span>
            </a>
          </li>
          <li onClick={() => clearSort(sortCol)}>
            <a
              className="cols_sort_clear"
              style={{
                opacity: sortDir === "desc" || sortDir === "asc" ? "1" : "0.5",
              }}
            >
              <span className={styles["menu-item-text"]}>Clear Sorting</span>
            </a>
          </li>
        </ul>
      ) : (
        <></>
      )}
    </>
  );
};

export default ContextMenu;
