import { useState, React } from "react";
import CloneImage from "../../../assets/clone.png";
import deletePageImage from "../../../assets/delete_page.png";

import openPageImage from "../../../assets/open_page.png";
import pageBackImage from "../../../assets/page_bg.png";
import EditImg from "./PageIcons/EditImg";
import OpenImg from "./PageIcons/OpenImg";
import CloneImg from "./PageIcons/CloneImg";
import classes from "./PageItem.module.css";
import DeleteImg from "./PageIcons/DeleteImg";

const PageItem = (props) => {
  const [displayIcons, setDisplayIcons] = useState();

  const onMouseOver = (event) => {
    setDisplayIcons(true);
  };

  const onMouseOut = (event) => {
    setDisplayIcons(false);
  };

  return (
    <div className={classes["widgetSize"]}>
      <div
        className={classes["mainwrapper"]}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <div title="Barchart" className={classes["title"]}>
          {props.item.PageName}
        </div>
        <div className={`${classes["box-content"]} ${classes["box"]}`}>
          <img className={classes["box-img"]} src={props.item.Image} />
          {displayIcons && (
            <span
              className={`${classes["caption"]} ${classes["slide-caption"]} EditDelete`}
            >
              <img
                style={{
                  height: "108%",
                  left: "-21.8px",
                  top: "3px",
                  width: "104.5%",
                  backgroundColor: "#C4D6A0",
                }}
                src={pageBackImage}
                alt="No Image"
              />
              <OpenImg id={props.item.Id} />
              <EditImg id={props.item.Id} />
              <CloneImg id={props.item.Id} />
              <DeleteImg
                id={props.item.Id}
                isSharedByOtherUser={props.item.IsSharedByOtherUser}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageItem;
