import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

const ExportToExcelByExcelJs = (tables, wsnames, wbname) => {
  let workbook = new ExcelJS.Workbook();
  for (let i = 0; i < tables.length; i++) {
    let worksheet = workbook.addWorksheet(wsnames[i]);

    for (let j = 0; j < tables[i].rows.length; j++) {
      // Create header
      if (j == 0) {
        let header = [];

        for (let p = 0; p < tables[i].cells.length; p++) {
          let getLengthStr = tables[i].cells[p]["caption"].length + 10;
          if (isNaN(getLengthStr)) {
            getLengthStr = 150;
          }

          if (tables[i].cells[p]["dataType"] == "date") {
            header.push({
              header: tables[i].cells[p]["caption"],
              key: tables[i].cells[p]["dataField"],
              width: getLengthStr,
              style: { numFmt: "dd/mm/yyyy hh:mm" },
            });
          } else {
            header.push({
              header: tables[i].cells[p]["caption"],
              key: tables[i].cells[p]["dataField"],
              width: getLengthStr,
            });
          }
        }

        worksheet.columns = header;
        let firstRow = worksheet.getRow(1);
        firstRow.eachCell(function (cell) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: "4F81BD",
            },
          };
          cell.font = {
            color: { argb: "FFFFFF" },
          };
        });
      }

      //create row
      let cellObj = {};
      for (let k = 0; k < tables[i].cells.length; k++) {
        let dataField = tables[i].cells[k]["dataField"];
        let dataValue = tables[i].rows[j][dataField];

        if (tables[i].cells[k]["dataType"] === "date") {
          if (dataValue !== "" && dataValue !== undefined) {
            let dataValue1 = convertTimeToLocal(dataValue);
            dataValue = new Date(dataValue1);
            let checkDate = new Date(1970, 12, 30);
            if (checkDate > dataValue) {
              dataValue = "";
            }
          }
        }

        if (tables[i].cells[k]["dataType"] === "time") {
          let dt = new Date("2020-01-21 " + dataValue);
          let dataValue1 = convertTimeToLocal(dt, true);
          dataValue = dataValue1;
        }

        if (
          tables[i].cells[k]["dataType"] === "Number" ||
          tables[i].cells[k]["dataType"] === "percentage"
        ) {
          if (
            !isNaN(dataValue) &&
            dataValue !== undefined &&
            dataValue !== ""
          ) {
            dataValue = parseFloat(dataValue).toFixed(2);
            dataValue = Number(dataValue);
          }
        }

        cellObj[dataField] = dataValue;
      }

      //Add and format row.
      if (j % 2 === 0) {
        let row = worksheet.addRow(cellObj);
        row.eachCell(function (cell) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: "DCE6F1",
            },
          };
        });
      } else {
        worksheet.addRow(cellObj);
      }
    }
  }

  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, wbname);
  });

  return true;
};

function GenerateMetaData(getFirstRow) {
  var metaData = [];
  var keys = Object.keys(getFirstRow);
  for (var i in keys) {
    if (keys[i] !== "SalesdataType") {
      var props = {};
      props["dataField"] = keys[i];
      if (keys[i] == "CurrentDayTime") {
        props["dataType"] = "date";
      } else if (keys[i] == "Time") {
        props["dataType"] = "time";
      } else {
        props["dataType"] = "string";
      }
      props["caption"] = keys[i];
      metaData.push(props);
    }
  }

  return metaData;
}

const convertTimeToLocal = (nDate, isTimeFormat) => {
  if (nDate == "" || nDate == "NULL") {
    return nDate;
  }

  var offset = moment().utcOffset();

  if (isTimeFormat)
    return moment
      .utc(nDate)
      .utcOffset(offset * 2)
      .format("hh:mm A");
  else
    return moment
      .utc(nDate)
      .utcOffset(offset * 2)
      .format("YYYY-MM-DD HH:mm");
};

const exportToExcelService = {
  ExportToExcelByExcelJs,
  GenerateMetaData,
};
export default exportToExcelService;
