import { useEffect, useState, React } from "react";
import Loader from "../UI/Loader";
import ModalOptional from "../UI/ModalOptional";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../store/layout-slice";
import QueryBuilderComponent from "./QueryBuilder";
import { useSelector } from "react-redux";
import GetMetaData, {
  GetAllFilterList,
  GetFilterDetail,
  GridMetaData_Format,
} from "../../Helper/Services";
import FilterServices from "./FilterServices";
import { ReplaceTextToSpecialChar } from "../../Helper/WidgetDataCommon";
import CloneFilter from "./CloneFilter";
import _ from "lodash";
import { REPORTS, SALES, SALESV2 } from "../../Constants";

const FilterComponent = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [isFilterListEnabled, setIsFilterListEnabled] = useState(false);
  const [hideQueryBuilder, setHideQueryBuilder] = useState(true);
  const [filterList, setFilterList] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [filterId, setFilterId] = useState("");
  const [filterText, setFilterText] = useState("");
  const [isSharedFilter, setIsSharedFilter] = useState(false);
  const selectedDataSource = useSelector(
    (state) => state.layout.selectedDataSource
  );
  const sessionId = useSelector((state) => state.login.sessionId);
  const email = useSelector((state) => state.login.userDetail.email);
  const widgetType = useSelector((state) => state.layout.selectedWidgetType);
  const allowDomain = useSelector(
    (state) => state.login.userDetail.allowDomain
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedDataSource !== "") {
      setIsLoading(true);

      if (props.isCloneFilter && props.isCloneFilter === true) {
        getFilterList(selectedDataSource, "", sessionId, allowDomain);
        setHideQueryBuilder(false);
        setIsFilterListEnabled(false);
      } else {
        if (widgetType !== SALESV2 && widgetType !== SALES) {
          GetMetaData(selectedDataSource, "", sessionId)
            .then((json) => {
              const isEnabledTeamColumn = widgetType === REPORTS ? true : false;
              const metaData = GridMetaData_Format(json, isEnabledTeamColumn);
              const getFields = FilterServices.getFields(metaData);
              setFields(getFields);
              //bind the filter list
              bindEditFilterList();
            })
            .catch((err) => {
              console.error(err);
              setIsLoading(false);
            });
        } else {
          const fields = FilterServices.getSalesFields();
          setFields(fields);
          //bind the filter list
          bindEditFilterList();
        }
      }
    }
  }, [props, selectedDataSource, sessionId]);

  const bindEditFilterList = () => {
    if (props.isEdit && props.isEdit === true) {
      getFilterList(selectedDataSource, "", sessionId, allowDomain);
      setHideQueryBuilder(false);
      setIsFilterListEnabled(true);
    } else {
      setIsLoading(false);
    }
  };

  const OnSaveHandler = () => {};

  const OnSetLoader = (val) => {
    setIsLoading(val);
  };

  const onCloseHandler = () => {
    dispatch(layoutActions.setFilterPopup({ edit: false, clone: false }));
  };

  const getFilterList = (dtSourceName, connectionName, sessionId, email) => {
    GetAllFilterList(dtSourceName, connectionName, sessionId, email)
      .then((json) => {
        setFilterList(json);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterChangeHandler = (e) => {
    setIsLoading(true);
    const filterValue = e.target.value;
    const filterid =
      e.target[e.target.selectedIndex].getAttribute("data-filterid");
    setFilterName(filterValue);
    setFilterId(filterid);
    setHideQueryBuilder(true);
    setIsFilterListEnabled(false);

    GetFilterDetail(filterid, sessionId)
      .then((json) => {
        if (_.isEmpty(json)) {
          alert("No Record Found");
        }
        if (!json) {
          alert("No Record Found");
        } else {
          setIsSharedFilter(json.isSharedFilter);
          setFilterText(json.filterText);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("GetFilterDetail=>", err);
      });
  };

  return (
    <ModalOptional
      title="Filter Builder"
      onClose={onCloseHandler}
      width={{ width: "710px" }}
    >
      {!isLoading && (
        <div>
          {isFilterListEnabled && (
            <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
              <p>Select Filter For Edit/ Delete</p>
              <select onChange={filterChangeHandler} defaultValue={filterName}>
                <option value="" disabled="disabled">
                  Select Filter
                </option>
                {filterList.map((item) => {
                  const filterName = ReplaceTextToSpecialChar(item.filterName);
                  const displayName = ReplaceTextToSpecialChar(
                    item.displayName
                  ).replace(/ /g, "\u00A0");
                  if (
                    item.isOwnFilter === true ||
                    item.isSharedFilter === false
                  ) {
                    return (
                      <option
                        value={filterName}
                        data-filterid={item.filterId}
                        key={item.filterId}
                      >
                        {displayName}
                      </option>
                    );
                  }
                  return "";
                })}
              </select>
            </div>
          )}
          {hideQueryBuilder && (
            <QueryBuilderComponent
              field={fields}
              OnSaveHandler={OnSaveHandler}
              onCloseHandler={onCloseHandler}
              filterName={filterName}
              isEdit={props.isEdit}
              dataSourceName={selectedDataSource}
              email={email}
              sessionId={sessionId}
              OnLoderEnable={OnSetLoader}
              isSharedFilter={isSharedFilter}
              filterText={filterText}
              filterId={filterId}
              widgetType={widgetType}
            />
          )}

          {props.isCloneFilter && (
            <CloneFilter
              filterList={filterList}
              onCloseHandler={onCloseHandler}
              email={email}
              sessionId={sessionId}
              dataSourceName={selectedDataSource}
            />
          )}
        </div>
      )}
      {isLoading && <Loader styles={{ height: "80px" }} />}
    </ModalOptional>
  );
};

export default FilterComponent;
