import { createSlice } from "@reduxjs/toolkit";

const initialGrid = {
  pageGrid: [
    {
      row1: [
        { x: 0, y: 0, isFill: false },
        { x: 1, y: 0, isFill: false },
        { x: 2, y: 0, isFill: false },
        { x: 3, y: 0, isFill: false },
      ],
      isOccupied: false,
    },
    {
      row2: [
        { x: 0, y: 1, isFill: false },
        { x: 1, y: 1, isFill: false },
        { x: 2, y: 1, isFill: false },
        { x: 3, y: 1, isFill: false },
      ],
      isOccupied: false,
    },
    {
      row3: [
        { x: 0, y: 2, isFill: false },
        { x: 1, y: 2, isFill: false },
        { x: 2, y: 2, isFill: false },
        { x: 3, y: 2, isFill: false },
      ],
      isOccupied: false,
    },
  ],
};

const pagecomtainerslice = createSlice({
  name: "pagecontainer",
  initialState: initialGrid,
  reducers: {
    resetAll(state) {
      state.pageGrid = initialGrid.pageGrid;
    },
    fillContainer(state, action) {
      state.pageGrid = action.payload;
    },
    updateFirstRow(state, action) {
      const getFillValues = action.payload.filter((x) => x.isFill);
      if (getFillValues.length === 4) {
        state.pageGrid[0].isOccupied = true;
      } else {
        state.pageGrid[0].isOccupied = false;
      }
      state.pageGrid[0].row1 = action.payload;
    },
    updateSecondRow(state, action) {
      const getFillValues = action.payload.filter((x) => x.isFill);
      if (getFillValues.length === 4) {
        state.pageGrid[1].isOccupied = true;
      } else {
        state.pageGrid[1].isOccupied = false;
      }
      state.pageGrid[1].row2 = action.payload;
    },
    updateThirdRow(state, action) {
      const getFillValues = action.payload.filter((x) => x.isFill);
      if (getFillValues.length === 4) {
        state.pageGrid[2].isOccupied = true;
      } else {
        state.pageGrid[2].isOccupied = false;
      }
      state.pageGrid[2].row3 = action.payload;
    },
    updateRow(state, action) {
      state.pageGrid[action.payload.rowId] = action.payload.row;
    },
  },
});

export const pageconataineractions = pagecomtainerslice.actions;

export default pagecomtainerslice;
