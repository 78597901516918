import {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  React,
} from "react";

const intialObject = {
  name: "",
  isValid: true,
  error: "Required",
};

const TwitterTemplate = forwardRef((props, ref) => {
  const [showSettingsPanel, setShowSettingsPanel] = useState(true);
  const [widgetContentPanel, setWidgetContentPanel] = useState(false);
  const [title, setTitle] = useState(intialObject);
  const [shortTitle, setShortTitle] = useState(intialObject);
  const [twitterVal, setTwitterVal] = useState(intialObject);
  const [feedType, setFeedType] = useState("@");

  useImperativeHandle(ref, () => ({
    GetValidateData() {
      const isFormValid = onValidateForm();
      if (isFormValid) {
        return {
          formIsValid: isFormValid,
          data: {
            LastRefreshedTime: "",
            Title: title.name,
            ShortTitle: shortTitle.name,
            TwitterId: twitterVal.name,
            feedType: feedType,
          },
        };
      } else {
        return {
          formIsValid: isFormValid,
        };
      }
    },
  }));

  useEffect(() => {
    if (props.settings !== undefined) {
      setTitle({ ...title, name: props.settings["Title"] });
      setShortTitle({ ...shortTitle, name: props.settings["ShortTitle"] });
      setTwitterVal({ ...twitterVal, name: props.settings["TwitterId"] });
      setFeedType(props.settings["feedType"]);
    }
  }, [props.settings]);

  const onSettingsClicked = () => {
    setShowSettingsPanel(!showSettingsPanel);
    setWidgetContentPanel(!widgetContentPanel);
  };

  const onWidgetContentClicked = () => {
    setWidgetContentPanel(!widgetContentPanel);
    setShowSettingsPanel(!showSettingsPanel);
  };

  const checkEventHandler = (event) => {
    let l_title, s_title;
    l_title = title.name;
    s_title = shortTitle.name || "";
    let value = twitterVal.name || "";

    validateTwitterVal();

    if (
      l_title !== "" ||
      l_title !== "Twitter:" ||
      l_title !== "Twitter: @" + value ||
      l_title !== "Twitter: #" + value
    ) {
      setTitle({ ...title, name: "Twitter: " + feedType + value });
    }

    if (
      s_title !== "" ||
      s_title !== "Twitter:" ||
      s_title !== "Twitter: @" + value ||
      s_title !== "Twitter: #" + value
    ) {
      setShortTitle({ ...title, name: "Twitter: " + feedType + value });
    }
  };

  const onChangeEventHandler = (event) => {
    setFeedType(event.target.value);
    let l_title, s_title;
    l_title = title.name;
    s_title = shortTitle.name || "";
    let value = twitterVal.name || "";

    if (
      l_title !== "" ||
      l_title !== "Twitter:" ||
      l_title !== "Twitter: @" + value ||
      l_title !== "Twitter: #" + value
    ) {
      setTitle({ ...title, name: "Twitter: " + event.target.value + value });
    }

    if (
      s_title !== "" ||
      s_title !== "Twitter:" ||
      s_title !== "Twitter: @" + value ||
      s_title !== "Twitter: #" + value
    ) {
      setShortTitle({
        ...title,
        name: "Twitter: " + event.target.value + value,
      });
    }

    validateTwitterVal();
  };

  const onTitleChange = (event) => {
    setTitle({ ...title, name: event.target.value });
  };

  const onShortTitleChange = (event) => {
    setShortTitle({ ...shortTitle, name: event.target.value });
  };

  const onValidateForm = () => {
    const titleIsValid = validateTitle();
    const shortTitleIsValid = validateShortTitle();
    if (!titleIsValid || !shortTitleIsValid) {
      setShowSettingsPanel(true);
      setWidgetContentPanel(false);
      return false;
    }

    const twitterValIsValid = validateTwitterVal();
    if (!twitterValIsValid) {
      setShowSettingsPanel(false);
      setWidgetContentPanel(true);
      return false;
    }

    return true;
  };

  const validateTitle = () => {
    if (title.name === "") {
      setTitle({ ...title, isValid: false, error: "Required" });
      setTimeout(() => {
        setTitle({ ...title, isValid: true });
      }, 3000);
      return false;
    }

    if (title.name.length <= 5) {
      setTitle({
        ...title,
        isValid: false,
        error: "Please enter at least 5 characters.",
      });
      setTimeout(() => {
        setTitle({ ...title, isValid: true });
      }, 3000);
      return false;
    }

    return true;
  };

  const validateShortTitle = () => {
    if (shortTitle.name === "") {
      setShortTitle({ ...shortTitle, isValid: false, error: "Required" });
      setTimeout(() => {
        setShortTitle({ ...shortTitle, isValid: true });
      }, 3000);
      return false;
    }

    if (shortTitle.name.length <= 5) {
      setShortTitle({
        ...shortTitle,
        isValid: false,
        error: "Please enter at least 5 characters.",
      });
      setTimeout(() => {
        setShortTitle({ ...shortTitle, isValid: true });
      }, 3000);
      return false;
    }

    return true;
  };

  const validateTwitterVal = () => {
    if (twitterVal.name === "") {
      setTwitterVal({ ...twitterVal, isValid: false, error: "Required" });
      setTimeout(() => {
        setTwitterVal({ ...twitterVal, isValid: true });
      }, 3000);
      return false;
    }
    return true;
  };

  const onTwitterValChange = (event) => {
    setTwitterVal({ ...twitterVal, name: event.target.value });
  };

  return (
    <div>
      {!props.isEdit && <hr className="style-one" />}
      <div onClick={onSettingsClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {showSettingsPanel && "-"}
          {!showSettingsPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET TITLE</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: showSettingsPanel ? "block" : "none",
        }}
      >
        <p>Widget Title (long)</p>
        <input
          type="text"
          name="Title"
          minLength="5"
          id="title"
          value-key="Title"
          value={title.name}
          onChange={onTitleChange}
          onBlur={validateTitle}
        />
        <span
          style={{
            display: title.isValid ? "none" : "block",
            color: "red",
            paddingLeft: "5px",
          }}
        >
          {title.error}
        </span>
        <p>Widget Title (short)</p>
        <input
          type="text"
          name="Short Title"
          id="short_title"
          style={{ width: "200px" }}
          minLength="5"
          value={shortTitle.name}
          onChange={onShortTitleChange}
          onBlur={validateShortTitle}
          value-key="ShortTitle"
        />
        <span
          style={{
            display: shortTitle.isValid ? "none" : "block",
            color: "red",
            paddingLeft: "5px",
          }}
        >
          {shortTitle.error}
        </span>
      </div>

      <div onClick={onWidgetContentClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {widgetContentPanel && "-"}
          {!widgetContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET CONTENTS</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: widgetContentPanel ? "block" : "none",
        }}
      >
        <p>Twitter Feed Parameter</p>
        <select
          className="selectParent"
          style={{ width: "45px", marginTop: "0px" }}
          onChange={onChangeEventHandler}
          value={feedType}
        >
          <option value="@">@</option>
          <option value="#">#</option>
        </select>
        <input
          type="text"
          id="twitterid"
          style={{
            width: "70%",
            marginTop: "1px",
            marginLeft: "4px",
            height: "21px",
          }}
          value={twitterVal.name}
          onBlur={checkEventHandler}
          onChange={onTwitterValChange}
        />
        <span
          style={{
            display: twitterVal.isValid ? "none" : "block",
            color: "red",
            paddingLeft: "5px",
          }}
        >
          {title.error}
        </span>
        <br />
      </div>
    </div>
  );
});

export default TwitterTemplate;
