import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Widget from "./Widget";

const Widgets = () => {
  const widgets = useSelector((state) => state.layout.Widgets);
  const [widgetItems, setWidgetItems] = useState([]);
  const [lastRow, setLastRow] = useState(0);

  useEffect(() => {
    setWidgetItems(widgets);
    if (widgets !== undefined) {
      const getLastRow = Math.max(...widgets.map((x) => x.Row));
      setLastRow(getLastRow);
    }
  }, [widgets]);

  let NoRows = 3,
    NoClo = 4,
    PerRowHeight = 0,
    PerColWidth = 0;
  PerRowHeight = parseFloat(100 / NoRows);
  PerColWidth = parseInt(100 / NoClo);

  return (
    <React.Fragment>
      {widgetItems.map((item, index) => {
        let Widgetheight = "",
          Htop = "",
          Wleft = "",
          WidgetWidth = "";

        if (item.SizeY === 1) {
          Widgetheight =
            "calc(" + parseFloat((item.SizeY / NoRows) * 100) + "%)";
          if (item.Row === lastRow) {
            Widgetheight =
              "calc(" + parseFloat((item.SizeY / NoRows) * 100) + "% - 16px)";
          }
        } else if (item.SizeY > 1) {
          Widgetheight =
            "calc(" + parseFloat((item.SizeY / NoRows) * 100) + "% + 8px)";
          if (item.Row === lastRow || item.SizeY === 3) {
            Widgetheight =
              "calc(" + parseFloat((item.SizeY / NoRows) * 100) + "%)";

            if (item.SizeY === 2) {
              Widgetheight =
                "calc(" + parseFloat((item.SizeY / NoRows) * 100) + "% - 8px)";
            }
          }
        }

        if (item.Row === 0) {
          Htop = item.Row * PerRowHeight + "px";
        } else {
          Htop =
            "calc(" + item.Row * PerRowHeight + "% + " + 8 * item.Row + "px)";
        }

        if (item.Col === 0) {
          Wleft = 8 + "px";
          WidgetWidth =
            "calc(" + parseInt((item.SizeX / NoClo) * 100) + "% - 16px)";
        } else {
          Wleft = item.Col * PerColWidth + "%";
          WidgetWidth =
            "calc(" + parseInt((item.SizeX / NoClo) * 100) + "% - 8px)";
        }

        let styles = {
          height: Widgetheight,
          width: WidgetWidth,
          left: Wleft,
          top: Htop,
        };

        return (
          <Widget
            key={`${item.Id}_${index}`}
            wid={item.SortOrder}
            styleCss={styles}
            widgetType={item.WidgetType}
            widgetTypeId={item.Type}
            widgetId={item.Id}
            title={item.Title}
            shortTitle={item.ShortTitle}
            pageId={item.PageId}
            RefreshVersion={item.UpdatedVersion}
          />
        );
      })}
    </React.Fragment>
  );
};

export default Widgets;
