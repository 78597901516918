// const validator = (r) => !!r.value;
import {
  BeforeToday,
  Last30days,
  Last7days,
  LastMonth,
  Operator_BeforeToday,
  Operator_Last30days,
  Operator_Last7days,
  Operator_LastMonth,
  Operator_ThisMonth,
  Operator_WorkingDaysOnly,
  Operator_Yesterday,
  ThisMonth,
  Today,
  WorkingDaysOnly,
  Yesterday,
  Operator_Today,
} from "./QueryBuilder/constants";
import {
  Clone_Filter_New,
  Delete_Filter_New,
  IS_TESTAPI_SERVICE_URL_Enabled,
  Insert_Update_Filter_New,
  IsFilterInUse,
  Update_Filter_Setting,
} from "../../Constants";

const getFields = (metaData) => {
  //Grid Meta-Data
  const fields = metaData["Grid Meta-Data"].map((item) => {
    const id = item.dataField.toString();
    const type = item.dataType.toString();
    const label = item.caption.toString();
    const name = id.replace(/ /g, "_");
    if (type === "Number" || type === "percentage") {
      return {
        name: name,
        label: label,
        inputType: "number",
        operators: [
          { name: "=", label: "equal" },
          { name: "!=", label: "not equal" },
          { name: "<", label: "less" },
          { name: "<=", label: "less or equal" },
          { name: ">", label: "greater" },
          { name: ">=", label: "greater or equal" },
          { name: "between", label: "between" },
          { name: "notBetween", label: "not between" },
          { name: "null", label: "is null" },
          { name: "notNull", label: "is not null" },
        ],
      };
    }

    if (type === "date") {
      return {
        name: name,
        label: label,
        inputType: "datetime-local",
        operators: [
          { name: "=", label: "equal" },
          { name: "!=", label: "not equal" },
          { name: "<", label: "less" },
          { name: "<=", label: "less or equal" },
          { name: ">", label: "greater" },
          { name: ">=", label: "greater or equal" },
          { name: "between", label: "between" },
          { name: "notBetween", label: "not between" },
          { name: "null", label: "is null" },
          { name: "notNull", label: "is not null" },
          { name: Operator_Today, label: "Today" },
          { name: Operator_Yesterday, label: "Yesterday" },
          { name: Operator_Last7days, label: "Last 7 days" },
          { name: Operator_Last30days, label: "Last 30 days" },
          // { name: Operator_WorkingDaysOnly, label: "Working days only" },
          // { name: Operator_LastMonth, label: "Last month" },
          // { name: Operator_ThisMonth, label: "This month" },
          // { name: Operator_BeforeToday, label: "Before today" },
        ],
      };
    }

    return {
      name: name,
      label: label,
      operators: [
        { name: "=", label: "equal" },
        { name: "!=", label: "not equal" },
        { name: "in", label: "in" },
        { name: "notIn", label: "not in" },
        { name: "beginsWith", label: "begins with" },
        { name: "doesNotBeginWith", label: "does not begin with" },
        { name: "contains", label: "contains" },
        { name: "doesNotContain", label: "does not contain" },
        { name: "endsWith", label: "ends with" },
        { name: "doesNotEndWith", label: "does not end with" },
        { name: "null", label: "is null" },
        { name: "notNull", label: "is not null" },
      ],
      //validator,
    };
  });

  return fields;
};

const getSalesFields = () => {
  const salesFields = [
    {
      name: "Salesperson",
      label: "SalesPerson",
      operators: [
        { name: "=", label: "equal" },
        { name: "!=", label: "not equal" },
        { name: "in", label: "in" },
        { name: "notIn", label: "not in" },
        { name: "beginsWith", label: "begins with" },
        { name: "doesNotBeginWith", label: "does not begin with" },
        { name: "contains", label: "contains" },
        { name: "doesNotContain", label: "does not contain" },
        { name: "endsWith", label: "ends with" },
        { name: "doesNotEndWith", label: "does not end with" },
        { name: "null", label: "is null" },
        { name: "notNull", label: "is not null" },
      ],
    },
  ];

  return salesFields;
};

const matchSpecialaCharcter = function (val) {
  var format = /[@#$^*()+\=\[\]{};':"\\|,.<>\/?]/;
  if (format.test(val)) {
    return true;
  } else {
    return false;
  }
};

const replaceSpecialCharToText = function (val) {
  if (val.indexOf("'") > -1) {
    val = val.replace(/'/g, "APPOSTOPIS");
  }
  if (val.indexOf("\\") > -1) {
    val = val.replace(/\\/g, "BACKSLASH");
  }
  if (val.indexOf("/") > -1) {
    val = val.replace(/\//g, "SLASH");
  }

  if (val.indexOf(":") > -1) {
    val = val.replace(/:/g, "COLON");
  }
  if (val.indexOf(".") > -1) {
    val = val.replace(/./g, "PERIODS");
  }
  if (val.indexOf("-") > -1) {
    val = val.replace(/-/g, "HYPHENS");
  }
  if (val.indexOf("&") > -1) {
    val = val.replace(/&/g, "AMPERSAND");
  }
  if (val.indexOf("%") > -1) {
    val = val.replace(/%/g, "PERCENTSIGN");
  }
  if (val.indexOf("*") > -1) {
    val = val.replace(/\*/g, "ASTERISK");
  }
  if (val.indexOf("!") > -1) {
    val = val.replace(/!/g, "EXCLAMATIOMMARK");
  }
  if (val.indexOf("$") > -1) {
    val = val.replace(/\$/g, "DOLLARSIGN");
  }
  if (val.indexOf("@") > -1) {
    val = val.replace(/@/g, "ATSYMBOL");
  }
  if (val.indexOf("(") > -1) {
    val = val.replace(/\(/g, "LEFTPARENTHESIS");
  }
  if (val.indexOf(")") > -1) {
    val = val.replace(/\)/g, "RIGHTPARENTHESIS");
  }
  if (val.indexOf(",") > -1) {
    val = val.replace(/,/g, "COMMA");
  }

  return val;
};

const generateCustomQuery = (q) => {
  let _rules = [...q.rules];
  const rules = _rules.map((t) => {
    if (t.operator === Operator_Today) {
      const obj = { ...t, operator: "=", value: Today };
      return obj;
    }
    if (t.operator === Operator_Yesterday) {
      const obj = { ...t, operator: "=", value: Yesterday };
      return obj;
    }
    if (t.operator === Operator_Last7days) {
      const obj = { ...t, operator: "=", value: Last7days };
      return obj;
    }
    if (t.operator === Operator_Last30days) {
      const obj = { ...t, operator: "=", value: Last30days };
      return obj;
    }

    if (t.operator === Operator_WorkingDaysOnly) {
      const obj = { ...t, operator: "=", value: WorkingDaysOnly };
      return obj;
    }

    if (t.operator === Operator_LastMonth) {
      const obj = { ...t, operator: "=", value: LastMonth };
      return obj;
    }

    if (t.operator === Operator_ThisMonth) {
      const obj = { ...t, operator: "=", value: ThisMonth };
      return obj;
    }

    if (t.operator === Operator_BeforeToday) {
      const obj = { ...t, operator: "=", value: BeforeToday };
      return obj;
    }

    if (t.rules !== undefined && t.rules.length > 0) {
      const customQuery = generateCustomQuery(t);
      const obj = { ...t, rules: customQuery.rules };
      return obj;
    }

    return t;
  });
  return { ...q, rules };
};

const reverseCustomQuery = (q) => {
  let _rules = [...q.rules];
  const rules = _rules.map((t) => {
    if (t.value === Today) {
      const obj = { ...t, operator: Operator_Today, value: "" };
      return obj;
    }
    if (t.value === Yesterday) {
      const obj = { ...t, operator: Operator_Yesterday, value: "" };
      return obj;
    }
    if (t.value === Last7days) {
      const obj = { ...t, operator: Operator_Last7days, value: "" };
      return obj;
    }
    if (t.value === Last30days) {
      const obj = { ...t, operator: Operator_Last30days, value: "" };
      return obj;
    }

    if (t.rules !== undefined && t.rules.length > 0) {
      const customQuery = reverseCustomQuery(t);
      const obj = { ...t, rules: customQuery.rules };
      return obj;
    }

    return t;
  });
  return { ...q, rules };
};

const trimQueryValue = (q) => {
  let _rules = [...q.rules];
  const rules = _rules.map((t) => {
    if (t.rules !== undefined && t.rules.length > 0) {
      const customQuery = trimQueryValue(t);
      const obj = { ...t, rules: customQuery.rules };
      return obj;
    }
    const value = t.value?.toString().trim();
    const obj = { ...t, value: value };
    return obj;
  });
  return { ...q, rules };
};

const addUpdateFilter = (formData, sessionId) => {
  let url = `${Insert_Update_Filter_New}`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(formData),
    headers: {
      sessionId: sessionId,
      "Content-Type": "application/json-patch+json",
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return response.text().then((text) => {
      if (text.indexOf("filter name already exists") > -1) {
        return text;
      }
      throw new Error(text);
    });
  });
};

const CheckIsFilterInUse = (sessionId, filtername, datasource, widgetId) => {
  let url = `${IsFilterInUse}/${datasource}/${filtername}/${sessionId}/${widgetId}/${IS_TESTAPI_SERVICE_URL_Enabled}`;
  return fetch(url, {
    method: "GET",
  }).then((response) => response.json());
};

const updateFilterSetting = (widgetId, filterName) => {
  let url = `${Update_Filter_Setting}/${filterName}/${widgetId}`;
  return fetch(url, {
    method: "PUT",
  }).then((response) => {
    if (response.ok) {
      return Promise.resolve();
    }

    return response.text().then((text) => {
      throw new Error(text);
    });
  });
};

const deleteFilter = (filterId, datasourceName, sessionId) => {
  //let url = `${Delete_Filter_New}/${datasourceName}/${filterName}`;
  let url = `${Delete_Filter_New}/${filterId}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: sessionId,
    },
  }).then((response) => {
    if (response.ok) {
      return Promise.resolve();
    }

    return response.text().then((text) => {
      throw new Error(text);
    });
  });
};

const cloneFilter = (
  datasourceName,
  oldFilterId,
  cloneFilterName,
  userEmail,
  sessionId
) => {
  let formdata = new URLSearchParams({
    oldFilterId: oldFilterId,
    userId: userEmail,
    dataSourceName: datasourceName,
    cloneFilterName: cloneFilterName,
  });

  let url = `${Clone_Filter_New}`;
  return fetch(url, {
    method: "POST",
    body: formdata.toString(),
    headers: {
      sessionId: sessionId,
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return response.text().then((text) => {
      if (
        text.indexOf("filter name already exists") > -1 ||
        text.indexOf("Special characters not allowed in Filter name") ||
        text.indexOf("Unsuccess") ||
        text.indexOf("Filter Already Exist")
      ) {
        return text;
      }
      throw new Error(text);
    });
  });
};

const handleInversionConditionForMongoDb = (filterText, mongoQuery) => {
  const mongoUpdatedQuery = Object.assign({}, mongoQuery);
  try {
    const indexOfDoesnotContain = filterText.indexOf('$not":{"$regex');
    const indexOfDoesnotStartWith = filterText.indexOf('"$not":{"$regex":"^');

    if (indexOfDoesnotContain > -1 || indexOfDoesnotStartWith > -1) {
      if (indexOfDoesnotContain < indexOfDoesnotStartWith) {
        //handle doesn't contains condition on edit
        handleDoesNotContain(indexOfDoesnotContain);

        //handle doesn't begin condition on edit
        handleDoesNotBeginWith(indexOfDoesnotContain);
      } else {
        //handle doesn't begin condition on edit
        handleDoesNotBeginWith(indexOfDoesnotStartWith);

        //handle doesn't contains condition on edit
        handleDoesNotContain(indexOfDoesnotContain);
      }
    }

    return mongoUpdatedQuery;
  } catch (error) {
    console.log("error", error);
    return mongoQuery;
  }

  function handleDoesNotBeginWith(indexOfDoesnotStartWith) {
    if (indexOfDoesnotStartWith > -1) {
      const jsonQuery = JSON.parse(filterText);
      for (let key in jsonQuery) {
        const res = createJsonObjectToPickInversionConditions(
          jsonQuery,
          key,
          null,
          "",
          "doesNotBeginWith"
        );
        insertRule(res, mongoUpdatedQuery);
      }
    }
  }

  function handleDoesNotContain(indexOfDoesnotContain) {
    if (indexOfDoesnotContain > -1) {
      const jsonQuery = JSON.parse(filterText);
      for (let key in jsonQuery) {
        const res = createJsonObjectToPickInversionConditions(
          jsonQuery,
          key,
          null,
          "",
          "doesNotContain"
        );
        insertRule(res, mongoUpdatedQuery);
      }
    }
  }

  function insertRule(res) {
    const query = { ...mongoUpdatedQuery };
    for (let index = 0; index < res.indexes.length; index++) {
      const indexObj = res.indexes[index].split("-");
      const jsonRule = res.jsonObj;
      let obj = { ...jsonRule };
      let obj1 = { ...query };
      if (indexObj.length > 0) {
        for (let i = 0; i < indexObj.length; i++) {
          const element = indexObj[i];
          obj = obj.rules[element];
          if (i === indexObj.length - 1) {
            if (indexObj.length > 1) {
              for (let j = 0; j < indexObj.length - 1; j++) {
                const element1 = indexObj[j];
                if (obj1.rules[element1] === undefined) {
                  const appendRule = jsonRule.rules[element1];
                  if (appendRule !== undefined) {
                    const rules = appendRule;
                    rules.rules = [];
                    obj1.rules.splice(element1, 0, rules);
                    obj1 = obj1.rules[obj1.rules.length - 1];
                  }
                } else {
                  obj1 = obj1.rules[element1];
                }
              }

              obj1.rules.splice(element, 0, obj);
            } else {
              const filterObj = mongoQuery.rules.filter(
                (x) =>
                  x.field === obj.field &&
                  x.value === obj.value &&
                  x.operator === obj.operator
              );
              if (filterObj.length === 0) {
                obj1.rules.splice(element, 0, obj);
              }
            }
          }
        }
      }
    }
    return query;
  }
};

function createJsonObjectToPickInversionConditions(
  jsonQuery,
  key,
  parentIndex,
  arrHis,
  queryIdentifier
) {
  const obj = {};
  let findDoesntconditionIndex = [];
  const pArr = jsonQuery[key];
  if (Array.isArray(pArr)) {
    if (key === "$and" || key == "$or") {
      obj["combinator"] = key.replace("$", "");
      obj["rules"] = [];

      for (let index = 0; index < pArr.length; index++) {
        const element = pArr[index];
        let qObj = {};
        for (let key1 in element) {
          const obj1 = element[key1];
          if (Array.isArray(obj1)) {
            if (parentIndex === null) {
              arrHis = `${parentIndex != null ? parentIndex : index}`;
            } else {
              arrHis = `${arrHis}-${index}`;
            }

            const res = createJsonObjectToPickInversionConditions(
              element,
              key1,
              index,
              arrHis,
              queryIdentifier
            );
            qObj = res.jsonObj;
            findDoesntconditionIndex = findDoesntconditionIndex.concat(
              res.indexes
            );
            arrHis = "";
          } else {
            const q_text = JSON.stringify(element[key1]);
            if (
              q_text.indexOf('$not":{"$regex') > -1 &&
              queryIdentifier === "doesNotContain" &&
              q_text.indexOf('$not":{"$regex":"^') === -1
            ) {
              qObj["operator"] = "doesNotContain";

              let currentItemIndex = arrHis;
              if (currentItemIndex !== "") {
                currentItemIndex = `${arrHis}-${index}`;
              } else {
                currentItemIndex = `${index}`;
              }
              findDoesntconditionIndex.push(currentItemIndex);
            }

            if (
              q_text.indexOf('$not":{"$regex":"^') > -1 &&
              queryIdentifier === "doesNotBeginWith"
            ) {
              qObj["operator"] = "doesNotBeginWith";

              let currentItemIndex = arrHis;
              if (currentItemIndex !== "") {
                currentItemIndex = `${arrHis}-${index}`;
              } else {
                currentItemIndex = `${index}`;
              }
              findDoesntconditionIndex.push(currentItemIndex);
            }

            if (typeof obj1 === "object") {
              qObj["field"] = key1;
              for (let key2 in obj1) {
                const obj2 = obj1[key2];
                qObj["value"] = obj2["$regex"];
                if (queryIdentifier === "doesNotContain") {
                  const val = obj2["$regex"];
                  if (val !== undefined) {
                    if (val.indexOf("$") > -1) {
                      qObj["operator"] = "doesNotEndWith";
                      qObj["value"] = val.replace("$", "");
                    }
                  }
                }
                if (queryIdentifier === "doesNotBeginWith") {
                  if (obj2["$regex"] !== undefined) {
                    qObj["value"] = obj2["$regex"].replace("^", "");
                  }
                }
              }
            } else {
              qObj["field"] = key1;
              qObj["value"] = obj1;
            }
          }
        }
        obj["rules"].push(qObj);
      }
    }
  } else {
    obj["rules"] = [];
    let qObj = {};
    qObj["field"] = key;
    const q_text = JSON.stringify(pArr);
    if (
      q_text.indexOf('$not":{"$regex') > -1 &&
      queryIdentifier === "doesNotContain" &&
      q_text.indexOf('$not":{"$regex":"^') === -1
    ) {
      qObj["operator"] = "doesNotContain";
      findDoesntconditionIndex.push("0");
    }

    if (
      q_text.indexOf('$not":{"$regex":"^') > -1 &&
      queryIdentifier === "doesNotBeginWith"
    ) {
      qObj["operator"] = "doesNotBeginWith";
      findDoesntconditionIndex.push("0");
    }

    for (let key1 in pArr) {
      if (typeof pArr[key1] === "object") {
        const obj2 = pArr[key1];
        if (queryIdentifier === "doesNotContain") {
          const val = obj2["$regex"];
          qObj["value"] = val;
          if (val !== undefined) {
            if (val.indexOf("$") > -1) {
              qObj["operator"] = "doesNotEndWith";
              qObj["value"] = val.replace("$", "");
            }
          }
        }
        if (queryIdentifier === "doesNotBeginWith") {
          if (obj2["$regex"] !== undefined) {
            qObj["value"] = obj2["$regex"].replace("^", "");
          }
        }
      }
    }
    if (Object.keys(qObj).length > 0) {
      obj["rules"].push(qObj);
    }
  }
  return { jsonObj: obj, indexes: findDoesntconditionIndex };
}

const FilterServices = {
  getFields,
  matchSpecialaCharcter,
  replaceSpecialCharToText,
  generateCustomQuery,
  addUpdateFilter,
  reverseCustomQuery,
  getSalesFields,
  CheckIsFilterInUse,
  deleteFilter,
  updateFilterSetting,
  cloneFilter,
  handleInversionConditionForMongoDb,
  trimQueryValue,
};

export default FilterServices;
