import * as moment from "moment";

const GetTrafficSignal = (list) => {
  let TrafficLights = list["Grid Meta-Data"]
    .filter((items) => {
      return (
        items.dataType === "Number" ||
        items.dataType === "percentage" ||
        items.dataType === "decimal"
      );
    })
    .map((items) => {
      return {
        id: items.dataField,
        text: items.caption,
      };
    });
  return TrafficLights;
};

const GetValues = (list) => {
  let getItems = list["Grid Meta-Data"]
    .filter((items) => {
      return (
        items.dataType === "Number" ||
        items.dataType === "percentage" ||
        items.dataType === "decimal" ||
        items.dataType === "number"
      );
    })
    .map((items) => {
      return {
        id: items.dataField,
        text: items.caption,
      };
    });
  return getItems;
};

const GetCategories = (list) => {
  let getItems = list["Grid Meta-Data"].map((items) => {
    return {
      id: items.dataField,
      text: items.caption,
    };
  });
  return getItems;
};

const GetFormattedColumnList = (list) => {
  let columns = list["Grid Meta-Data"].map((item, i) => {
    return {
      dataType: item.dataType,
      dataField: item.dataField.replace(/ /g, "_"),
      caption: item.caption,
      visible: true,
      index: i,
    };
  });

  return columns;
};

const replaceTextToSpecialChar = (val) => {
  if (val.indexOf("APPOSTOPIS") > -1) {
    val = val.replace(/APPOSTOPIS/g, "'");
  }
  if (val.indexOf("BACKSLASH") > -1) {
    val = val.replace(/BACKSLASH/g, "\\");
  }
  if (val.indexOf("SLASH") > -1) {
    val = val.replace(/SLASH/g, "/");
  }
  if (val.indexOf("COLON") > -1) {
    val = val.replace(/COLON/g, ":");
  }
  if (val.indexOf("PERIODS") > -1) {
    val = val.replace(/PERIODS/g, ".");
  }
  if (val.indexOf("HYPHENS") > -1) {
    val = val.replace(/HYPHENS/g, "-");
  }
  if (val.indexOf("AMPERSAND") > -1) {
    val = val.replace(/AMPERSAND/g, "&");
  }
  if (val.indexOf("PERCENTSIGN") > -1) {
    val = val.replace(/PERCENTSIGN/g, "%");
  }
  if (val.indexOf("ASTERISK") > -1) {
    val = val.replace(/ASTERISK/g, "*");
  }
  if (val.indexOf("EXCLAMATIOMMARK") > -1) {
    val = val.replace(/EXCLAMATIOMMARK/g, "!");
  }
  if (val.indexOf("DOLLARSIGN") > -1) {
    val = val.replace(/DOLLARSIGN/g, "$");
  }
  if (val.indexOf("ATSYMBOL") > -1) {
    val = val.replace(/ATSYMBOL/g, "@");
  }
  if (val.indexOf("LEFTPARENTHESIS") > -1) {
    val = val.replace(/LEFTPARENTHESIS/g, "(");
  }
  if (val.indexOf("RIGHTPARENTHESIS") > -1) {
    val = val.replace(/RIGHTPARENTHESIS/g, ")");
  }
  if (val.indexOf("COMMA") > -1) {
    val = val.replace(/COMMA/g, ",");
  }
  return val;
};

const replaceSpecialCharToText = function (val) {
  if (val.indexOf("'") > -1) {
    val = val.replace(/'/g, "APPOSTOPIS");
  }
  if (val.indexOf("\\") > -1) {
    val = val.replace(/\\/g, "BACKSLASH");
  }
  if (val.indexOf("/") > -1) {
    val = val.replace(/\//g, "SLASH");
  }

  if (val.indexOf(":") > -1) {
    val = val.replace(/:/g, "COLON");
  }
  if (val.indexOf(".") > -1) {
    val = val.replace(/./g, "PERIODS");
  }
  if (val.indexOf("-") > -1) {
    val = val.replace(/-/g, "HYPHENS");
  }
  if (val.indexOf("&") > -1) {
    val = val.replace(/&/g, "AMPERSAND");
  }
  if (val.indexOf("%") > -1) {
    val = val.replace(/%/g, "PERCENTSIGN");
  }
  if (val.indexOf("*") > -1) {
    val = val.replace(/\*/g, "ASTERISK");
  }
  if (val.indexOf("!") > -1) {
    val = val.replace(/!/g, "EXCLAMATIOMMARK");
  }
  if (val.indexOf("$") > -1) {
    val = val.replace(/\$/g, "DOLLARSIGN");
  }
  if (val.indexOf("@") > -1) {
    val = val.replace(/@/g, "ATSYMBOL");
  }
  if (val.indexOf("(") > -1) {
    val = val.replace(/\(/g, "LEFTPARENTHESIS");
  }
  if (val.indexOf(")") > -1) {
    val = val.replace(/\)/g, "RIGHTPARENTHESIS");
  }
  if (val.indexOf(",") > -1) {
    val = val.replace(/,/g, "COMMA");
  }

  return val;
};

const GetDatesTypeList = (list) => {
  let getItems = list["Grid Meta-Data"]
    .filter((items) => {
      return items.dataType === "date";
    })
    .map((items) => {
      return {
        id: items.dataField,
        text: items.caption,
      };
    });
  return getItems;
};

const GetGridData = (gridData) => {
  var gridDataModel = [];
  if (Array.isArray(gridData)) {
    for (var i = 0; i < gridData.length; i++) {
      var jsonObj = {};
      var gridObj = gridData[i];
      for (var j = 0; j < gridObj.length; j++) {
        var getValue = gridObj[j]["value"];
        var getName = gridObj[j]["name"];
        if (getName !== "_id") {
          jsonObj[getName] = getValue;
        }
      }
      gridDataModel.push(jsonObj);
    }
  }
  return gridDataModel;
};

const convertUTCDateToLocalDate = (nDate) => {
  if (nDate === "" || nDate === "NULL" || nDate === undefined) {
    return nDate;
  }

  var getDate = new Date(nDate);
  var checkDate = new Date(1970, 12, 30);
  if (checkDate > getDate) {
    return null;
  }

  //var currd = new Date();
  //var offset = currd.getTimezoneOffset() / 60;
  var offset = moment().utcOffset();
  //return new Date(moment.utc(nDate).zone(offset));
  return new Date(moment(nDate).utcOffset(offset));
};

const convertUTCDateToLocalDate_Format = (nDate, format) => {
  if (nDate === "" || nDate === "NULL" || nDate === undefined) {
    return nDate;
  }
  var getDate = new Date(nDate);
  var checkDate = new Date(1970, 12, 30);
  if (checkDate > getDate) {
    return null;
  }
  //var currd = new Date();
  //var offset = currd.getTimezoneOffset() / 60;
  var offset = moment().utcOffset();
  return moment.utc(nDate).utcOffset(+offset).format(format);
};

const convertUTCDateToLocalDate_SalesWidget = (nDate) => {
  if (nDate === "" || nDate === "NULL") {
    return nDate;
  }
  var offset = moment().utcOffset();
  return moment.utc(nDate).utcOffset(offset).format("MM/D/YYYY HH:mm:ss");
};

const getRefreshDataFormat = (gridData) => {
  var gridDataModel = [];
  var jsonObj = {};
  if (Array.isArray(gridData)) {
    for (var i = 0; i < gridData.length; i++) {
      var getValue = gridData[i]["value"];
      var getName = gridData[i]["name"];
      if (getName !== "_id") {
        jsonObj[getName] = getValue;
      }
    }

    gridDataModel.push(jsonObj);
  }
  return gridDataModel;
};

const alertForFilter = (data) => {
  if (isString(data)) {
    if (
      data.indexOf(
        "Filter unshared, select a different filter from settings and try again"
      ) > -1
    )
      return true;
  }
  return false;
};

const isString = (value) => {
  return typeof value === "string" || value instanceof String;
};

const getSalesWidgetData = (gridData) => {
  var gridDataModel = [];
  if (Array.isArray(gridData)) {
    for (var i = 0; i < gridData.length; i++) {
      var jsonObj = {};
      var gridObj = gridData[i];
      for (var j = 0; j < gridObj.length; j++) {
        var getValue = gridObj[j]["value"];
        var getName = gridObj[j]["name"];
        if (getName !== "_id") {
          jsonObj[getName] = getValue;
        }
      }
      gridDataModel.push(jsonObj);
    }
  }

  return gridDataModel;
};

const convertUTCDateToLocalDate_Format1 = (nDate, format) => {
  if (nDate === "" || nDate === "NULL" || nDate === undefined) {
    return nDate;
  }
  //var currd = new Date();
  //var offset = currd.getTimezoneOffset() / 60;
  var offset = moment().utcOffset();
  return moment(nDate).utcOffset(offset).format(format);
};

function formatDate(nDate) {
  if (nDate === "" || nDate === "NULL") {
    return nDate;
  }
  return moment(nDate).format("MMMM DD, YYYY");
}

export default GetTrafficSignal;

export const GetFormattedColumns = GetFormattedColumnList;
export const ReplaceTextToSpecialChar = replaceTextToSpecialChar;
export const GetCategoryList = GetCategories;
export const GetValueList = GetValues;
export const GetDateTypeList = GetDatesTypeList;
export const GetFormattedGridData = GetGridData;
export const ConvertToLocalDateTime = convertUTCDateToLocalDate;
export const GetRefreshDataFormat = getRefreshDataFormat;
export const ConvertToLocalDateTimeWithFormat =
  convertUTCDateToLocalDate_Format;
export const IsSharedFilterAlert = alertForFilter;
export const GetSalesWidgetFormattedData = getSalesWidgetData;

export const ConvertUTCDateToLocalDate_SalesWidget =
  convertUTCDateToLocalDate_SalesWidget;

export const ConvertUTCDateToLocalDate_Format1 =
  convertUTCDateToLocalDate_Format1;

export const FormatDate = formatDate;

export const ReplaceSpecialCharToText = replaceSpecialCharToText;
