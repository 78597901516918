const GetTextNumericData = (gridData, settings) => {
  let content = "",
    color = "",
    align = "";
  var rowData = [];
  if (gridData.length > 0) {
    rowData = gridData[0];
  }
  if (rowData != undefined) {
    var textValue = rowData[settings.tableheaderlist];
    if (textValue != undefined) {
      content = textValue.toString();
    } else {
      content = settings.subsectiontitle;
    }

    var textColor = rowData[settings.tableheaderlistcolor];

    if (textColor != undefined) {
      switch (textColor.toString().toLowerCase()) {
        case "red":
          color = "#E55253";
          break;
        case "green":
          color = "#9AB57E";
          break;
        case "yellow":
          color = "#E8C267";
          break;
        default:
          color = "#808080";
      }
    } else {
      color = settings.textcolor;
    }
  }
  align = settings.justification;

  return { color: color, content: content, align: align };
};

export default GetTextNumericData;
