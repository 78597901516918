import { format, isMatch } from "date-fns";
import ConfigurationConstant from "./Common/ConfigurationConstant";
import moment from "moment";

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

function getTwentyFourHourTime(amPmString) {
  const d = new Date("1/1/2013 " + amPmString);
  const hours = d.getHours();
  const minutes = d.getMinutes();
  //const seconds = d.getSeconds();
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  //let sSeconds = seconds.toString();
  if (hours < 10) sHours = "0" + sHours;
  if (minutes < 10) sMinutes = "0" + sMinutes;
  //if (seconds < 10) sSeconds = "0" + sSeconds;

  return sHours + ":" + sMinutes;
}

const validateSettings = (formControls, settingName, validationCounter) => {
  if (settingName === "Teams") {
    const teamNameValid = validateRequiredField(
      "team_name",
      "Team name required",
      formControls
    );
    if (!teamNameValid.isValid) {
      return teamNameValid;
    }

    const startTimeValid = validateRequiredField(
      "start_time",
      "Start time required",
      formControls
    );
    if (!startTimeValid.isValid) {
      return startTimeValid;
    }

    const finishTimeValid = validateRequiredField(
      "finish_time",
      "Finish time required",
      formControls
    );
    if (!finishTimeValid.isValid) {
      return finishTimeValid;
    }

    const validateStartFinishTime = validateStartAndFinishTime(
      formControls,
      validationCounter
    );
    if (!validateStartFinishTime.isValid) return validateStartFinishTime;

    const isValidTImeZone = validateRequiredField(
      "time_zone",
      "Timezone required",
      formControls
    );
    if (!isValidTImeZone.isValid) return isValidTImeZone;
  }

  //set validation for case owners
  if (settingName === ConfigurationConstant.Case_Owners) {
    const caseOwnersValid = validateRequiredField(
      "case_owners",
      "Case owners required",
      formControls
    );
    if (!caseOwnersValid.isValid) {
      return caseOwnersValid;
    }

    const teamNameValid = validateRequiredField(
      "team_name",
      "Team name required",
      formControls
    );
    if (!teamNameValid.isValid) {
      return teamNameValid;
    }

    const startTimeValid = validateRequiredField(
      "start_time",
      "Start time required",
      formControls
    );
    if (!startTimeValid.isValid) {
      return startTimeValid;
    }

    const finishTimeValid = validateRequiredField(
      "finish_time",
      "Finish time required",
      formControls
    );
    if (!finishTimeValid.isValid) {
      return finishTimeValid;
    }
  }

  //set validation for Case Types
  if (settingName === ConfigurationConstant.Case_Types) {
    const caseTypesValid = validateRequiredField(
      "case_types",
      "Case types required",
      formControls
    );
    if (!caseTypesValid.isValid) {
      return caseTypesValid;
    }

    const caseTypeDescValid = validateRequiredField(
      "case_type_desc",
      "Case types description required",
      formControls
    );
    if (!caseTypeDescValid.isValid) {
      return caseTypeDescValid;
    }

    const getWorkHrsValid = validateRequiredField(
      "get_work_hrs",
      "Target work hrs required",
      formControls
    );
    if (!getWorkHrsValid.isValid) {
      return getWorkHrsValid;
    }

    const getHrsValidate = validateGetWorkHrs(formControls, "get_work_hrs");
    if (!getHrsValidate.isValid) {
      return getHrsValidate;
    }
  }

  //set validation for Case Status Values
  if (settingName === ConfigurationConstant.Case_Status_Values) {
    const isValidStatus = validateRequiredField(
      "status",
      "Status required",
      formControls
    );
    if (!isValidStatus.isValid) return isValidStatus;
  }

  //set validation for Synchronize colors
  if (settingName === ConfigurationConstant.Synchronize_Colors) {
    const isValidColumn = validateRequiredField(
      "column",
      "Column required",
      formControls
    );
    if (!isValidColumn.isValid) return isValidColumn;
  }

  //set validation for Global Holidays
  if (settingName === "Global Holidays" || settingName === "Holidays") {
    const isValidDate = validateRequiredField(
      "holiday",
      "Date required",
      formControls
    );
    if (!isValidDate.isValid) return isValidDate;

    const isValidHoliday = validateDate(formControls, "holiday");
    if (!isValidHoliday.isValid) return isValidHoliday;

    const isValidDescription = validateRequiredField(
      "description",
      "Description required",
      formControls
    );
    if (!isValidDescription.isValid) return isValidDescription;

    const teamNameValid = validateRequiredField(
      "team_name",
      "Team name required",
      formControls
    );
    if (!teamNameValid.isValid) {
      return teamNameValid;
    }
  }

  //set validation for Sales Activity
  if (settingName === "Sales Activity" || settingName === "Sales Activities") {
    const isValidActivityCode = validateRequiredField(
      "activity_code",
      "Activity code required",
      formControls
    );
    if (!isValidActivityCode.isValid) return isValidActivityCode;

    const isValidMsiAttempt = validateRequiredField(
      "msi_attempt",
      "MSI Attempt required",
      formControls
    );
    if (!isValidMsiAttempt.isValid) return isValidMsiAttempt;

    const isValidSlotCount = validateRequiredField(
      "slot_count",
      "Slot count required",
      formControls
    );
    if (!isValidSlotCount.isValid) return isValidSlotCount;

    const isValidSlotCountNumber = validateSlotCountNumber(
      formControls,
      "slot_count"
    );
    if (!isValidSlotCountNumber.isValid) return isValidSlotCountNumber;
  }

  //set validation for Sales Stages
  if (settingName === "Sales Stages") {
    const isValidStage = validateRequiredField(
      "stage",
      "Stage required",
      formControls
    );
    if (!isValidStage.isValid) return isValidStage;
  }

  if (
    settingName === ConfigurationConstant.Sales_Opportunity_Owner ||
    settingName === "Sales Opp Owners"
  ) {
    const isValidSalesOppOwner = validateRequiredField(
      "sales_opp_owner",
      "Sales Opp Owner required",
      formControls
    );
    if (!isValidSalesOppOwner.isValid) return isValidSalesOppOwner;

    const isValidTeamName = validateRequiredField(
      "team_name",
      "Team required",
      formControls
    );
    if (!isValidTeamName.isValid) return isValidTeamName;

    const isValidTargetExpDollars = validateRequiredField(
      "target_exp_dollars",
      "Target exp dollars required",
      formControls
    );
    if (!isValidTargetExpDollars.isValid) return isValidTargetExpDollars;

    const isValidTargetExpDollarsNuns = validateTargetExpDollarsNums(
      formControls,
      "target_exp_dollars"
    );
    if (!isValidTargetExpDollarsNuns.isValid) {
      return isValidTargetExpDollarsNuns;
    }

    const isValidStartTime = validateRequiredField(
      "start_time",
      "Start time required",
      formControls
    );
    if (!isValidStartTime.isValid) return isValidStartTime;

    const isValidFinishTime = validateRequiredField(
      "finish_time",
      "End time required",
      formControls
    );
    if (!isValidFinishTime.isValid) return isValidFinishTime;
  }

  //set validation for Sales Opp Owners Capacity
  if (settingName === ConfigurationConstant.Sales_Opp_Owners_Capacity) {
    const isValidSalesPerson = validateRequiredField(
      "sales_person",
      "Sale person required",
      formControls
    );
    if (!isValidSalesPerson.isValid) return isValidSalesPerson;

    const isValidRecordType = validateRequiredField(
      "record_type",
      "Record type required",
      formControls
    );
    if (!isValidRecordType.isValid) return isValidRecordType;

    const isValidDay = validateRequiredField(
      "day",
      "Day required",
      formControls
    );
    if (!isValidDay.isValid) return isValidDay;

    const isValidDate = validateDate(formControls, "day");
    if (!isValidDate.isValid) return isValidDate;

    const isValidCapacity = validateRequiredField(
      "capacity",
      "Capacity required",
      formControls
    );
    if (!isValidCapacity.isValid) return isValidCapacity;
  }

  if (settingName === ConfigurationConstant.Protected_Calling_Times) {
    const isValidTeamName = validateRequiredField(
      "team_name",
      "Team required",
      formControls
    );
    if (!isValidTeamName.isValid) return isValidTeamName;

    const isValidPCTStart = validateRequiredField(
      "pct_start",
      "PCT start required",
      formControls
    );
    if (!isValidPCTStart.isValid) return isValidPCTStart;

    const isValidPCTFinish = validateRequiredField(
      "pct_finish",
      "PCT finish required",
      formControls
    );
    if (!isValidPCTFinish.isValid) return isValidPCTFinish;

    const isValidPCTDate = validatePCTDate(formControls);
    if (!isValidPCTDate.isValid) return isValidPCTDate;
  }

  return { isValid: true };
};

const validatePCTDate = (formControls) => {
  const pctStartIndex = formControls.findIndex(
    (x) => x["dataField"] === "pct_start"
  );

  const pctFinishIndex = formControls.findIndex(
    (x) => x["dataField"] === "pct_finish"
  );

  const startTime = formControls[pctStartIndex];
  let finishTime = formControls[pctFinishIndex];

  //get values
  const valuestart = startTime["value"];
  const valuestop = finishTime["value"];

  //create date format
  const timeStart = new Date("01/01/2019 " + valuestart);
  const timeEnd = new Date("01/01/2019 " + valuestop);
  if (timeStart > timeEnd) {
    finishTime["errorMessage"] =
      "PCT finish time must be greater than start time";

    let data = [...formControls];
    data[pctFinishIndex] = finishTime;

    return {
      isValid: false,
      result: data,
    };
  }

  return {
    isValid: true,
  };
};

const validateRequiredField = (name, erroMessage, formControls) => {
  console.log("name", name);
  console.log("formControls", formControls);
  const fieldIndex = formControls.findIndex((x) => x["dataField"] === name);
  let fieldName = formControls[fieldIndex];
  if (fieldName["value"] === "" || fieldName["value"] === undefined) {
    fieldName["errorMessage"] = erroMessage;
    let data = [...formControls];
    data[fieldIndex] = fieldName;
    return { isValid: false, result: data };
  }
  return { isValid: true, result: {} };
};

const validateGetWorkHrs = (formControls, name) => {
  const getWorkHrsIndex = formControls.findIndex(
    (x) => x["dataField"] === name
  );

  let getWorjHrs = formControls[getWorkHrsIndex];
  const getValues = parseFloat(getWorjHrs["value"]);
  if (!isNaN(getValues) && (getValues <= 0 || getValues <= -0)) {
    getWorjHrs["errorMessage"] = "Please enter value greater than 0";

    let data = [...formControls];
    data[getWorkHrsIndex] = getWorjHrs;

    return {
      isValid: false,
      result: data,
    };
  }

  if (!isNumeric(getValues)) {
    getWorjHrs["errorMessage"] = "Please enter numeric value";
    let data = [...formControls];
    data[getWorkHrsIndex] = getWorjHrs;

    return {
      isValid: false,
      result: data,
    };
  }

  return {
    isValid: true,
  };
};

const validateDate = (formControls, name) => {
  const getHolidayIndex = formControls.findIndex(
    (x) => x["dataField"] === name
  );

  let holiday = formControls[getHolidayIndex];
  const d = moment(holiday["value"], "DD-MMM-YYYY", true);
  if (!d.isValid()) {
    holiday["errorMessage"] = "Enter valid date";

    let data = [...formControls];
    data[getHolidayIndex] = holiday;

    return {
      isValid: false,
      result: data,
    };
  }

  return {
    isValid: true,
  };
};

const validateTargetExpDollarsNums = (formControls, name) => {
  const getTargetExpDollarIndex = formControls.findIndex(
    (x) => x["dataField"] === name
  );

  let targetExpDollar = formControls[getTargetExpDollarIndex];
  var checkInput = parseInt(targetExpDollar["value"]);
  if (!isNumeric(targetExpDollar["value"].replace(/,/g, ""))) {
    targetExpDollar["errorMessage"] = "Please enter numeric value";

    let data = [...formControls];
    data[getTargetExpDollarIndex] = targetExpDollar;

    return {
      isValid: false,
      result: data,
    };
  } else if (checkInput <= 0) {
    targetExpDollar["errorMessage"] =
      "Enter a number greater than or equal to 1";

    let data = [...formControls];
    data[getTargetExpDollarIndex] = targetExpDollar;

    return {
      isValid: false,
      result: data,
    };
  }

  return {
    isValid: true,
  };
};

const validateSlotCountNumber = (formControls, name) => {
  const slotCountIndex = formControls.findIndex((x) => x["dataField"] === name);

  let slotCount = formControls[slotCountIndex];
  const numbers = /^-?\d*$/;
  if (!numbers.test(slotCount["value"])) {
    slotCount["errorMessage"] = "Please enter numeric value";

    let data = [...formControls];
    data[slotCountIndex] = slotCount;

    return {
      isValid: false,
      result: data,
    };
  }

  return {
    isValid: true,
  };
};

const validateStartAndFinishTime = (formControls, validationCounterParam) => {
  console.log("validationCounterParam", validationCounterParam);
  let validationCounter = validationCounterParam;
  const startTimeIndex = formControls.findIndex(
    (x) => x["dataField"] === "start_time"
  );

  const finishTimeIndex = formControls.findIndex(
    (x) => x["dataField"] === "finish_time"
  );
  const startTime = formControls[startTimeIndex];
  let finishTime = formControls[finishTimeIndex];

  if (startTime["value"] !== "" && finishTime["value"] !== "") {
    //get values
    const valuestart = startTime["value"];
    const valuestop = finishTime["value"];

    //create date format
    const timeStart = new Date("01/01/2019 " + valuestart);
    const timeEnd = new Date("01/01/2019 " + valuestop);

    const diffMs = timeEnd - timeStart; // milliseconds
    //const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    if ((diffHrs === 10 && diffMins > 0) || (diffHrs > 10 && diffMins >= 0)) {
      if (validationCounter === 0) {
        finishTime["errorMessage"] =
          "Hours between Start and Finish time is greater than 10 hours. Do you want to continue?";
        let data = [...formControls];
        data[finishTimeIndex] = finishTime;

        validationCounter++;
        return {
          isValid: false,
          result: data,
          validationCounter: validationCounter,
        };
      }
    } else {
      validationCounter = 0;
      finishTime["errorMessage"] = "";
      let data = [...formControls];
      data[finishTimeIndex] = finishTime;
    }

    //validate the end time must be greater than start time
    if (timeStart >= timeEnd) {
      finishTime["errorMessage"] =
        "Finish time must be greater than start time";
      let data = [...formControls];
      data[finishTimeIndex] = finishTime;
      return {
        isValid: false,
        result: data,
        validationCounter: validationCounter,
      };
    }

    return {
      isValid: true,
    };
  }
};

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const bindValueOnChange = (
  settingName,
  formControls,
  index,
  value,
  name,
  isDate,
  teamList,
  salesOppOwnerList
) => {
  if (isDate) {
    const formattedDate = format(new Date(value), "dd-MMM-yyyy");
    const isValid = isMatch(formattedDate, "dd-MMM-yyyy");
    let data = [...formControls];
    data[index]["value"] = "";
    if (isValid) {
      data[index]["value"] = format(new Date(value), "dd-MMM-yyyy");
    }
    return data;
  } else {
    let data = [...formControls];
    data[index]["value"] = value;

    if (
      (settingName === ConfigurationConstant.Case_Owners ||
        settingName === ConfigurationConstant.Sales_Opp_Owners) &&
      name === "team_name"
    ) {
      const startTimeIndex = formControls.findIndex(
        (x) => x["dataField"] === "start_time"
      );
      const finishTimeIndex = formControls.findIndex(
        (x) => x["dataField"] === "finish_time"
      );

      if (value !== "") {
        const getTeamDetails = teamList.find(
          (item) => item["team_name"] === value
        );

        data[startTimeIndex]["value"] = SharedUtil.getTwentyFourHourTime(
          getTeamDetails["start_time"]
        );
        data[finishTimeIndex]["value"] = SharedUtil.getTwentyFourHourTime(
          getTeamDetails["finish_time"]
        );
      } else {
        if (settingName === ConfigurationConstant.Case_Owners) {
          data[startTimeIndex]["value"] = "09:00";
          data[finishTimeIndex]["value"] = "17:00";
        } else {
          data[startTimeIndex]["value"] = "";
          data[finishTimeIndex]["value"] = "";
        }
      }
    }

    if (
      settingName === ConfigurationConstant.Sales_Opp_Owners_Capacity &&
      name === "sales_person"
    ) {
      const teamIndex = formControls.findIndex(
        (x) => x["dataField"] === "team_name"
      );
      console.log("value", value);
      if (value !== "") {
        const getSalesOppOwner = salesOppOwnerList.find(
          (item) => item["sales_opp_owner"] === value
        );
        console.log("getSalesOppOwner", getSalesOppOwner);
        data[teamIndex]["value"] = getSalesOppOwner["team_name"];
      } else {
        data[teamIndex]["value"] = "";
      }
    }

    return data;
  }
};

const SharedUtil = {
  tConvert,
  getTwentyFourHourTime,
  validateSettings,
  bindValueOnChange,
};

export default SharedUtil;
