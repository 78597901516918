import {
  Fragment,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  React,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Connection_List_Url } from "../../../Constants";
import ConnectionControl from "./ConnectionControl";
import DatasourceControl from "./DatasourceControl";
import FilterControl from "./FilterControl";
import { GetAllFilterList } from "../../../Helper/Services";
import { layoutActions } from "../../../store/layout-slice";
import { pageActions } from "../../../store/page-slice";
import { ReplaceSpecialCharToText } from "../../../Helper/WidgetDataCommon";

const DataSectionSettings = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [dtSourceVal, setDtSourceVal] = useState("");
  const [dtSourceIsValid, setDtSourceIsValid] = useState(true);
  const [conSourceVal, setConSourceVal] = useState("");
  const [conSourceIsValid, setConSourceIsValid] = useState(true);
  const [conSourceItems, setConSourceItems] = useState([]);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const sessionId = useSelector((state) => state.login.sessionId);
  const encodedEmail = useSelector(
    (state) => state.login.userDetail.encodedEmail
  );
  const exportToExcelDetails = useSelector(
    (state) => state.page.exportExcelDetails
  );
  const email = useSelector((state) => state.login.userDetail.email);
  const [filterVal, setFilterVal] = useState("null");
  const [filterDetails, setFilterDetails] = useState({
    issharedfilter: false,
    filterid: "",
    originaluserid: "",
    filterValue: "null",
  });
  const filterObjFromContext = useSelector(
    (state) => state.layout.addUpdateFilterObj
  );

  useEffect(() => {
    if (
      props.isEdit === true &&
      props.settings !== undefined &&
      props.settings["reports"] !== undefined &&
      props.settings["reports"] !== ""
    ) {
      setDtSourceVal(props.settings["reports"]);
      setConSourceVal(props.settings["connection"]);

      let _filterVal = props.settings["Filter"];
      if (_filterVal === "") {
        _filterVal = "null";
      }

      setFilterVal(_filterVal);
      setFilterDetails({
        ...filterDetails,
        filterid: props.settings["FilterId"],
        filterValue: _filterVal,
        issharedfilter: props.settings["IsSharedFilter"],
        originaluserid: props.settings["OriginalUserId"],
      });

      getFilterList(
        props.settings["reports"],
        props.settings["connection"],
        sessionId,
        email
      );

      const exportToExcelData = {
        dataSource: props.settings["reports"],
        connectionName: props.settings["connection"],
        filterId: props.settings["FilterId"],
        filterName: props.settings["Filter"],
        isSharedFilter: props.settings["IsSharedFilter"],
        sharedFilterUserId: props.settings["OriginalUserId"],
      };
      dispatch(pageActions.setExportToExcelDetails(exportToExcelData));
      setIsShowFilter(true);
    }
  }, [props.settings]);

  useEffect(() => {
    if (filterObjFromContext.filterName !== "") {
      getFilterList(dtSourceVal, "", sessionId, email);
      setFilterVal(filterObjFromContext.filterName);
      setFilterDetails({
        ...filterDetails,
        filterid: filterObjFromContext.filterId,
        filterValue: filterObjFromContext.filterName,
        issharedfilter: filterObjFromContext.isSharedfilter,
        originaluserid: filterObjFromContext.userId,
      });
    }
  }, [filterObjFromContext]);

  useImperativeHandle(ref, () => ({
    validateDataSection() {
      const isValidForm = validateDataform();
      return {
        isDataSectionValid: isValidForm,
        data: {
          dataSourceName: dtSourceVal,
          connectionName: conSourceVal,
          filterName: filterVal,
          filterDetails: filterDetails,
        },
      };
    },
  }));

  const dataSourceChangeHandler = async (event) => {
    const _val = event.target.value;
    setIsShowFilter(false);
    setFilterVal("null");
    setConSourceVal("");
    setDtSourceVal(_val);
    if (_val !== "") {
      setFilterList([]);
      setIsShowFilter(true);
      await getConnetionList(_val);
      dispatch(layoutActions.setSelectedDatasource(_val));
    }
    props.dtSourceChange(_val);
  };

  const getConnetionList = async (dtSourceName) => {
    const url = `${Connection_List_Url}/${dtSourceName}/${encodedEmail}/${sessionId}`;
    const getList = await fetch(url);
    if (getList.ok) {
      const getResponse = await getList.json();
      const res = JSON.parse(getResponse);
      const _items = res.map((item) => {
        return {
          value: item.connectionName,
          text: item.connectionName,
        };
      });
      setConSourceItems(_items);
      if (_items.length > 0) {
        setConSourceVal(_items[0].value);
        getFilterList(
          dtSourceName,
          _items[0].value,
          sessionId,
          email,
          setFilterList
        );

        //update export to excel details
        const exportToExcelData = {
          ...exportToExcelDetails,
          dataSource: dtSourceName,
          connectionName: _items[0].value,
          filterId: "",
          filterName: "null",
          sharedFilterUserId: "",
          isSharedFilter: "",
        };
        dispatch(pageActions.setExportToExcelDetails(exportToExcelData));
      }
    }
  };

  const getFilterList = (dtSourceName, connectionName, sessionId, email) => {
    GetAllFilterList(dtSourceName, connectionName, sessionId, email)
      .then((json) => {
        setFilterList(json);

        if (props.settings !== undefined) {
          const _filterName = ReplaceSpecialCharToText(
            props.settings["Filter"]
          );

          //check the filtername not exists
          const filterExist = json.filter(
            (x) =>
              x.filterName === _filterName ||
              x.filterName ===
                ReplaceSpecialCharToText(filterObjFromContext.filterName)
          );
          if (filterExist.length === 0) {
            setFilterVal("null");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setFilterList([]);
      });
  };

  const connectionChangeHandler = (event) => {
    setConSourceVal(event.target.value);
  };

  const validateDtSource = () => {
    if (dtSourceVal === "") {
      setDtSourceIsValid(false);
      setTimeout(() => {
        setDtSourceIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateConnection = () => {
    if (conSourceVal === "") {
      setConSourceIsValid(false);
      setTimeout(() => {
        setConSourceIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateDataform = () => {
    if (props.isCheckedValidation === undefined || props.isCheckedValidation) {
      const dtSourceValid = validateDtSource();
      if (!dtSourceValid) {
        return false;
      }

      const isConnectionValid = validateConnection();
      if (!isConnectionValid) {
        return false;
      }
    }

    return true;
  };

  const OnFilterChangeHandler = (filter) => {
    setFilterVal(filter.filterValue);
    setFilterDetails(filter);

    //update export to excel details
    if (
      filter.filterValue !== "CreateNewFilter" &&
      filter.filterValue !== "EditExistingFilter" &&
      filter.filterValue !== "CloneFilter"
    ) {
      const exportToExcelData = {
        ...exportToExcelDetails,
        filterId: filter.filterid,
        filterName: filter.filterValue,
        sharedFilterUserId: filter.issharedfilter,
        isSharedFilter: filter.originaluserid,
      };
      dispatch(pageActions.setExportToExcelDetails(exportToExcelData));
    }
  };

  return (
    <Fragment>
      <DatasourceControl
        value={dtSourceVal}
        onChangeHandler={dataSourceChangeHandler}
        isValid={dtSourceIsValid}
        dtList={props.dataSourceList}
        isEdit={props.isEdit}
      />
      {!props.isEdit && (
        <ConnectionControl
          onChangeHandler={connectionChangeHandler}
          value={conSourceVal}
          isValid={conSourceIsValid}
          dtList={conSourceItems}
        />
      )}

      {isShowFilter && (
        <FilterControl
          dtList={filterList}
          value={filterVal}
          onChange={OnFilterChangeHandler}
        />
      )}
    </Fragment>
  );
});

export default DataSectionSettings;
