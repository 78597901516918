import { Fragment, React } from "react";
import styles from "./TableTemplate.module.css";

const TableHeaderTH = (props) => {
  const conClickHandler = (e) => {
    return false;
  };

  return (
    <Fragment>
      {props.colDetails.visible && (
        <th
          style={
            props.colDetails.width
              ? {
                  textAlign: "left",
                  position: "relative",
                  width: props.colDetails.width,
                }
              : { textAlign: "left", position: "relative" }
          }
          className={styles["tbl-datagrid-action"]}
          data-col={props.colDetails.dataField}
          data-dir={props.colDetails.sortColumnDir}
          onClick={() =>
            props.onSort(
              props.colDetails.dataField,
              props.colDetails.sortColumnDir
            )
          }
        >
          <div
            className={styles["tbl-column-indicators"]}
            data-col={props.colDetails.dataField}
            data-dir={props.colDetails.sortColumnDir}
            style={{
              float:
                props.colDetails.alignment &&
                props.colDetails.alignment === "right"
                  ? "left"
                  : "right",
            }}
          >
            {props.colDetails.isSortColumn && (
              <span
                style={{ color: "#767676d1", fontSize: "13px" }}
                data-col={props.colDetails.dataField}
                data-dir={props.colDetails.sortColumnDir}
                className={`tbl-sort fa ${
                  props.colDetails.sortColumnDir === "asc"
                    ? "fa-long-arrow-up"
                    : "fa-long-arrow-down"
                }`}
              ></span>
            )}
          </div>
          <div
            className={styles["tbl-datagrid-text-content"]}
            title={props.colDetails.caption}
            data-col={props.colDetails.dataField}
            data-dir={props.colDetails.sortColumnDir}
          >
            {props.colDetails.caption}
          </div>
          {!props.islastItem && (
            <div
              className="resizer"
              style={{
                position: "absolute",
                top: "0px",
                right: "-3px",
                bottom: "0px",
                width: "8px",
                zIndex: "999",
                background: "transparent",
                cursor: "col-resize",
              }}
              onMouseDown={props.resizerMousedown}
              onClick={conClickHandler}
            ></div>
          )}
        </th>
      )}
    </Fragment>
  );
};

export default TableHeaderTH;
