import Modal from "../../../UI/Modal";
import { useDispatch, useSelector } from "react-redux";
import { layoutActions } from "../../../../store/layout-slice";
import { useEffect, useState, useRef, React } from "react";
import {
  Get_DataSources,
  Get_Widget_Settings,
  IS_TESTAPI_SERVICE_URL_Enabled,
} from "../../../../Constants";
import { loginActions } from "../../../../store/login-slice";
import ButtonControls from "../../../UI/TemplateControls/ButtonControls";
import {
  GetLocalStorageLayout,
  GetWidgetDataById,
  RemoveWidget,
  SetLocalStorageLayout,
  SetWidgetDataById,
  UpdateWidgetSettings,
} from "../../../../Helper/SaveSettings";
import CircularGuageTemplate from "./../AddWidget/ConfigTemplate/CircularGuage";
import TwitterTemplate from "./../AddWidget/ConfigTemplate/Twitter";
import ReportTemplate from "./../AddWidget/ConfigTemplate/Report";
import OtccWidgetTemplate from "./../AddWidget/ConfigTemplate/Otcc";
import PieChartTemplate from "./../AddWidget/ConfigTemplate/PieChart";
import TextNumericTemplate from "./../AddWidget/ConfigTemplate/TextNumeric";
import QueueSizeGaugeTemplate from "./../AddWidget/ConfigTemplate/QueueSizeGuage";
import BarChartTemplate from "./../AddWidget/ConfigTemplate/BarChart";
import VideoFeedTemplate from "./../AddWidget/ConfigTemplate/VideoFeed";
import SAW2Template from "./../AddWidget/ConfigTemplate/SAWV2";
import ComboBarLineTemplate from "./../AddWidget/ConfigTemplate/ComboBarLine";
import Loader from "../../../UI/Loader";
import OpenOpportunitySummaryTemplate from "../AddWidget/ConfigTemplate/OOS";

const EditWidgetSettings = () => {
  const dispatch = useDispatch();
  const currentWidgetId = useSelector((state) => state.layout.selectedWidgetId);
  const ballistixUserId = useSelector((state) => state.layout.BallistixUserId);
  const userId = useSelector((state) => state.layout.UserId);
  const sessionId = useSelector((state) => state.login.sessionId);
  const email = useSelector((state) => state.login.userDetail.email);
  const fullName = useSelector((state) => state.login.userDetail.userFullName);
  const [dtSources, setDtSources] = useState();
  const [widgetSettings, setWidgetSettings] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const widgetType = useSelector((state) =>
    state.layout.selectedWidgetTypeId.toString()
  );
  const childRef = useRef();

  useEffect(() => {
    const getDatasources = async () => {
      const url = `${Get_DataSources}?SessionId=${sessionId}&email=${email}&isTestApi=${IS_TESTAPI_SERVICE_URL_Enabled}`;
      const getDataSourceList = await fetch(url);
      if (getDataSourceList.ok) {
        const getResponse = await getDataSourceList.json();
        setDtSources(getResponse);
        dispatch(loginActions.setEncodedEmail(getResponse.EncodeEmail));
        setIsLoading(false);
      }
    };
    const getWidgetSettings = async () => {
      const url = `${Get_Widget_Settings}?widgetId=${currentWidgetId}`;
      const getWidgetResponse = await fetch(url);
      if (getWidgetResponse.ok) {
        const getResponse = await getWidgetResponse.json();
        setWidgetSettings(getResponse);
        dispatch(layoutActions.setSelectedDatasource(getResponse["reports"]));
      }
    };

    if (widgetType.toString() !== "2") {
      getDatasources();
    } else {
      setIsLoading(false);
    }

    getWidgetSettings();
  }, [email, sessionId]);

  const onCloseHandler = () => {
    dispatch(layoutActions.setEditWidgetPopup());
  };

  const OnSaveHandler = () => {
    let postDt = childRef.current.GetValidateData();
    if (postDt.formIsValid) {
      setIsLoading(true);
      if (ballistixUserId === 0) {
        let localData = GetLocalStorageLayout();
        let res = GetWidgetDataById(currentWidgetId, localData.Widgets);
        res.WidgetType = "Twitter";
        res.Type = "2";
        res.Twitter = postDt.data["feedType"] + postDt.data["TwitterId"];
        res.Title = postDt.data["Title"];
        res.ShortTitle = postDt.data["ShortTitle"];
        localData = SetWidgetDataById(currentWidgetId, localData, res);
        SetLocalStorageLayout(localData);
        dispatch(
          layoutActions.updateWidgetSettings({
            title: postDt.data.Title,
            shortTitle: postDt.data.ShortTitle,
            WidgetId: currentWidgetId,
          })
        );
        dispatch(layoutActions.setEditWidgetPopup());
        setIsLoading(false);
      } else {
        UpdateWidgetSettings(currentWidgetId, widgetType, postDt.data)
          .then((json) => {
            dispatch(
              layoutActions.updateWidgetSettings({
                title: postDt.data.Title,
                shortTitle: postDt.data.ShortTitle,
                WidgetId: currentWidgetId,
              })
            );
            dispatch(layoutActions.setEditWidgetPopup());
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    }
  };

  const onRemoveWidgetHandler = () => {
    setIsLoading(true);
    if (ballistixUserId === 0) {
      var localData = GetLocalStorageLayout();
      var res = GetWidgetDataById(currentWidgetId, localData.Widgets);
      res.WidgetType = "Empty";
      res.Type = "0";
      res.Twitter = null;
      res.Title = "Empty";
      res.ShortTitle = "Empty";
      localData = SetWidgetDataById(currentWidgetId, localData, res);
      SetLocalStorageLayout(localData);
      dispatch(
        layoutActions.updateWidget({
          title: "",
          shortTitle: "",
          Type: 0,
          WidgetType: "Empty",
          WidgetId: currentWidgetId,
          widgetTypeId: 0,
        })
      );
      dispatch(layoutActions.setEditWidgetPopup());
      setIsLoading(false);
    } else {
      RemoveWidget(currentWidgetId, sessionId)
        .then((json) => {
          dispatch(
            layoutActions.updateWidget({
              title: "",
              shortTitle: "",
              Type: 0,
              WidgetType: "Empty",
              WidgetId: currentWidgetId,
              widgetTypeId: 0,
            })
          );
          dispatch(layoutActions.setEditWidgetPopup());
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <Modal title="Widget Settings" onClose={onCloseHandler}>
      {!isLoading && (
        <form className="settings">
          {widgetType === "1" && (
            <CircularGuageTemplate dataSources={dtSources} ref={childRef} />
          )}
          {widgetType === "2" && (
            <TwitterTemplate
              ref={childRef}
              isEdit={true}
              settings={widgetSettings}
            />
          )}

          {widgetType === "3" && (
            <ReportTemplate
              dataSources={dtSources}
              ref={childRef}
              settings={widgetSettings}
              isEdit={true}
            />
          )}
          {widgetType === "4" && (
            <OtccWidgetTemplate
              dataSources={dtSources}
              ref={childRef}
              settings={widgetSettings}
              isEdit={true}
            />
          )}
          {widgetType === "5" && (
            <PieChartTemplate
              dataSources={dtSources}
              ref={childRef}
              settings={widgetSettings}
              isEdit={true}
            />
          )}
          {widgetType === "7" && (
            <TextNumericTemplate
              dataSources={dtSources}
              ref={childRef}
              settings={widgetSettings}
              isEdit={true}
            />
          )}
          {widgetType === "8" && (
            <QueueSizeGaugeTemplate
              dataSources={dtSources}
              ref={childRef}
              settings={widgetSettings}
              isEdit={true}
            />
          )}
          {widgetType === "9" && (
            <BarChartTemplate
              dataSources={dtSources}
              ref={childRef}
              settings={widgetSettings}
              isEdit={true}
            />
          )}

          {widgetType === "10" && (
            <VideoFeedTemplate
              ref={childRef}
              sessionId={sessionId}
              settings={widgetSettings}
              isEdit={true}
              dataSources={dtSources}
            />
          )}
          {widgetType === "11" && (
            <SAW2Template
              dataSources={dtSources}
              ref={childRef}
              isEdit={true}
              settings={widgetSettings}
            />
          )}

          {widgetType === "12" && (
            <ComboBarLineTemplate
              dataSources={dtSources}
              ref={childRef}
              isEdit={true}
              settings={widgetSettings}
            />
          )}
          {widgetType === "13" && (
            <OpenOpportunitySummaryTemplate
              dataSources={dtSources}
              ref={childRef}
              isEdit={true}
              settings={widgetSettings}
            />
          )}
          <br />
          <ButtonControls
            OnSaveHandler={OnSaveHandler}
            onCloseModal={onCloseHandler}
            onRemoveWidget={onRemoveWidgetHandler}
            IsEditSetting={true}
          />
        </form>
      )}
      {isLoading && <Loader styles={{ height: "80px" }} />}
    </Modal>
  );
};

export default EditWidgetSettings;
