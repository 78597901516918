import $ from "jquery";
import {
  ConvertUTCDateToLocalDate_Format1,
  FormatDate,
} from "../../../../Helper/WidgetDataCommon";

const BarLineChartData = (settings, gridData) => {
  let json2 = [];
  let salesPersonArray = [];
  let categoriesArray = [];
  let linescategoriesArr = [];
  let filteredByCategoryName = [];
  let categorySelected = false;

  settings = barChartSettingColumnConfig(settings);
  let periodType = settings["periodtype"];
  if (periodType === undefined) {
    periodType = settings["periodType"];
  }
  const periodTypeNumber = settings["numberofperiodtype"];
  let PeriodTypeDate = new Date();
  PeriodTypeDate = excludeWeekdaysDecrement(PeriodTypeDate);
  let lastUpdatedDate = undefined;
  let periodList = [];
  const diffDays = periodTypeNumber;
  const endDate = new Date();

  if (periodType === "days") {
    PeriodTypeDate = excludeWeekdaysDecrement(PeriodTypeDate);
  }

  for (let i = 0; i < diffDays; i++) {
    if (lastUpdatedDate === undefined) {
      lastUpdatedDate = PeriodTypeDate;
    }

    if (periodType === "days") {
      if (i > 0) {
        lastUpdatedDate.setDate(lastUpdatedDate.getDate() - 1);
      }

      if (lastUpdatedDate < endDate && periodType !== "days") {
        break;
      }
    } else if (periodType === "month") {
      let dt = new Date();
      let increment = i + 1;
      dt = dt.setDate(dt.getDate() - increment * 30);
      const dt1 = new Date(dt);
      lastUpdatedDate = new Date(dt1.getFullYear(), dt1.getMonth(), 1);
    } else {
      let dt = new Date();
      const increment = i + 1;
      dt = dt.setDate(dt.getDate() - increment * 7);
      lastUpdatedDate = getMonday(dt);
    }

    PeriodTypeDate = lastUpdatedDate;
    const dt = new Date(PeriodTypeDate);
    periodList.push(dt);
  }

  //reverse the list
  periodList = periodList.reverse();

  if (periodType === "week") {
    if (periodList.length > 0) {
      periodList.splice(0, 1);
      const dt = new Date();
      const getDayOfWeek = getMonday(dt);
      periodList.push(getDayOfWeek);
    }
  } else if (periodType === "month") {
    if (periodList.length > 0) {
      periodList.splice(0, 1);
      const dt = new Date();
      const getFirstDayOfMonth = new Date(dt.getFullYear(), dt.getMonth(), 1);
      periodList.push(getFirstDayOfMonth);
    }
  }

  // time zone conversion
  if (settings.charttype !== "Bar") {
    for (let i = 0; i < gridData.length; i++) {
      gridData[i][settings.barname] = ConvertUTCDateToLocalDate_Format1(
        gridData[i][settings.barname],
        "DD MMM YYYY hh:mm A"
      );
    }
  }

  //filter by person name
  for (let i = 0; i < gridData.length; i++) {
    const barNameValue = gridData[i][settings.barname];

    if (i === 0) {
      salesPersonArray.push(barNameValue);
    }
    let filteredBySalesPerson = [];
    filteredBySalesPerson = salesPersonArray.filter(function (row) {
      let element = row;
      if (element === null || element === undefined) {
        element = "Others";
      }
      if (element.indexOf(barNameValue) !== -1) {
        return true;
      } else {
        return false;
      }
    });

    if (filteredBySalesPerson.length === 0) {
      salesPersonArray.push(barNameValue);
    }

    //categories

    if (
      settings.stackcategory !== "" &&
      (settings.charttype === "Bar" || settings.charttype === "BarLine")
    ) {
      const categoryName = gridData[i][settings.stackcategory];

      if (i === 0) categoriesArray.push(categoryName);

      filteredByCategoryName = categoriesArray.filter(function (row) {
        let element = row;
        if (element === null || element === undefined) {
          element = "Others";
        }
        if (element.indexOf(categoryName) !== -1) {
          return true;
        } else {
          return false;
        }
      });

      if (filteredByCategoryName.length === 0) {
        categoriesArray.push(categoryName);
      }

      //sort by alphabatically
      categoriesArray = categoriesArray.sort(function (a, b) {
        const nA = a.toLowerCase();
        const nB = b.toLowerCase();

        if (nA < nB) return -1;
        else if (nA > nB) return 1;
        return 0;
      });
    }

    //categories
    let lineCategoryName = [];
    if (
      settings.linecategory !== "" &&
      (settings.charttype === "Line" || settings.charttype === "BarLine")
    ) {
      const categoryName = gridData[i][settings.linecategory];

      if (i === 0) linescategoriesArr.push(categoryName);

      lineCategoryName = linescategoriesArr.filter(function (row) {
        let element = row;
        if (element === null || element === undefined) {
          element = "Others";
        }
        if (element.indexOf(categoryName) !== -1) {
          return true;
        } else {
          return false;
        }
      });

      if (lineCategoryName.length === 0) {
        linescategoriesArr.push(categoryName);
      }

      //sort by alphabatically
      linescategoriesArr = linescategoriesArr.sort(function (a, b) {
        var nA = a.toLowerCase();
        var nB = b.toLowerCase();

        if (nA < nB) return -1;
        else if (nA > nB) return 1;
        return 0;
      });
    }
  }

  if (settings.linecategory === "") {
    linescategoriesArr.push("others_hide");
  }

  if (settings.charttype === "Bar") {
    // generate json data
    for (let i = 0; i < salesPersonArray.length; i++) {
      let Values = [];
      let filteredBySalesPersonData = [];
      filteredBySalesPersonData = gridData.filter(function (row) {
        let element = row[settings.barname];
        if (element === null || element === undefined) {
          element = "Others";
        }
        if (element.indexOf(salesPersonArray[i]) !== -1) {
          return true;
        } else {
          return false;
        }
      });

      if (
        settings.barname !== "" &&
        settings.barvalues === "None" &&
        settings.stackcategory === ""
      ) {
        let slotCount = 0;
        $.each(filteredBySalesPersonData, function () {
          slotCount++;
        });

        Values.push({ label: salesPersonArray[i], value: slotCount });
      } else if (
        settings.barname !== "" &&
        settings.barvalues === "None" &&
        settings.stackcategory !== ""
      ) {
        categorySelected = true;
        //get activity name
        let activityNameArr = [];
        for (let j = 0; j < filteredBySalesPersonData.length; j++) {
          let activity = filteredBySalesPersonData[j][settings.stackcategory];
          if (activity == null) {
            activity = "Others";
          }

          if (j === 0 || activity === "") {
            activityNameArr.push(activity);
          }

          let filteredByAcitvity = [];
          filteredByAcitvity = activityNameArr.filter(function (row) {
            let element = row;
            if (element === null || element === undefined) {
              element = "Others";
            }
            if (element.indexOf(activity) !== -1) {
              return true;
            } else {
              return false;
            }
          });

          if (filteredByAcitvity.length === 0) {
            if (activity !== "") {
              activityNameArr.push(activity);
            }
          }
        }

        //get activity data and slots
        for (let k = 0; k < categoriesArray.length; k++) {
          let slotCount = 0;
          let activityData = [];
          activityData = filteredBySalesPersonData.filter(function (row) {
            let element = row[settings.stackcategory];
            if (element === null || element === undefined) {
              element = "Others";
            }
            if (element.indexOf(categoriesArray[k]) !== -1) {
              return true;
            } else {
              return false;
            }
          });

          $.each(activityData, function () {
            slotCount++;
          });

          Values.push({ label: categoriesArray[k], value: slotCount });
        }

        // added for remove empty array issue
        if (categoriesArray.length === 0) {
          Values.push({ label: filteredByCategoryName[0], value: 0 });
        }
      } else if (
        settings.barname !== "" &&
        settings.barvalues !== "None" &&
        settings.stackcategory === ""
      ) {
        let slotCount = 0;
        let j = 0;
        const barValToCheck = salesPersonArray[i];
        let valueArr = [];
        $.each(filteredBySalesPersonData, function () {
          if (barValToCheck === this[settings.barname]) {
            const val = this[settings.barvalues];
            //if (val != "" && val != null) {
            if (val != null) {
              slotCount = parseFloat(slotCount) + parseFloat(val);
              j++;
              valueArr.push(parseInt(val));
            }
          }
        });

        if (settings.operationperform === "Sum") {
          $.each(salesPersonArray, function () {
            if (this === salesPersonArray[i]) {
              Values.push({ label: this, value: parseInt(slotCount) });
            }
          });
        } else if (settings.operationperform === "Average") {
          let op_val = parseInt(slotCount / j);
          if (isNaN(op_val)) {
            op_val = 0;
          }
          $.each(salesPersonArray, function () {
            if (this === salesPersonArray[i]) {
              Values.push({ label: this, value: op_val });
            }
          });
        } else if (settings.operationperform === "Max") {
          let op_val = parseInt(Math.max.apply(Math, valueArr));
          if (isNaN(op_val)) {
            op_val = 0;
          }

          $.each(salesPersonArray, function () {
            if (this === salesPersonArray[i]) {
              Values.push({ label: this, value: op_val });
            }
          });
        } else if (settings.operationperform === "Min") {
          let op_val = parseInt(Math.min.apply(Math, valueArr));
          if (isNaN(op_val)) {
            op_val = 0;
          }
          $.each(salesPersonArray, function () {
            if (this === salesPersonArray[i]) {
              Values.push({ label: this, value: op_val });
            }
          });
        }
      } else {
        let activityNameArr = [];
        for (let j = 0; j < filteredBySalesPersonData.length; j++) {
          let activity = filteredBySalesPersonData[j][settings.stackcategory];
          if (activity === null) {
            activity = "Others";
          }

          if (j === 0 || activity === "") {
            activityNameArr.push(activity);
          }

          let filteredByAcitvity = [];
          filteredByAcitvity = activityNameArr.filter(function (row) {
            let element = row;
            if (element === null || element === undefined) {
              element = "Others";
            }
            if (element.indexOf(activity) !== -1) {
              return true;
            } else {
              return false;
            }
          });

          if (filteredByAcitvity.length === 0) {
            if (activity !== "") {
              activityNameArr.push(activity);
            }
          }
        }

        //get activity data and slots
        for (let k = 0; k < categoriesArray.length; k++) {
          let slotCount = 0;
          let j = 0;
          const barValToCheck = categoriesArray[k];
          let valueArr = [];
          $.each(filteredBySalesPersonData, function () {
            if (barValToCheck === this[settings.stackcategory]) {
              let val = this[settings.barvalues];
              if (val !== null) {
                slotCount = parseFloat(slotCount) + parseFloat(val);
                j++;
                valueArr.push(parseInt(val));
              }
            }
          });

          if (settings.operationperform === "Sum") {
            Values.push({ label: barValToCheck, value: parseInt(slotCount) });
          } else if (settings.operationperform === "Average") {
            let op_val = parseInt(slotCount / j);
            if (isNaN(op_val)) {
              op_val = 0;
            }
            Values.push({ label: barValToCheck, value: op_val });
          } else if (settings.operationperform === "Max") {
            let op_val = parseInt(Math.max.apply(Math, valueArr));
            if (isNaN(op_val)) {
              op_val = 0;
            }

            Values.push({ label: barValToCheck, value: op_val });
          } else if (settings.operationperform === "Min") {
            let op_val = parseInt(Math.min.apply(Math, valueArr));
            if (isNaN(op_val)) {
              op_val = 0;
            }
            Values.push({ label: barValToCheck, value: op_val });
          }
        }

        // added for remove empty array issue
        if (categoriesArray.length === 0) {
          Values.push({ label: filteredByCategoryName[0], value: 0 });
        }
      }

      const sortedArray = Values.sort(function (a, b) {
        const nA = a.label.toLowerCase();
        const nB = b.label.toLowerCase();

        if (nA < nB) return -1;
        else if (nA > nB) return 1;
        return 0;
      });

      const randomnumber = Math.floor(Math.random() * (100 - 10 + 1)) + 10;
      json2.push({
        name: salesPersonArray[i],
        states: sortedArray,
        target: 100,
        actual: 30,
        test: randomnumber,
        line: [],
        maxLineVal: 0,
      });
    }
    let fDt = [...json2];
    json2 = getSortedData(fDt, "name", 1);
  } else {
    // generate json data
    for (let i = 0; i < periodList.length; i++) {
      let Values = [];
      let filteredDataByDate = [];
      filteredDataByDate = gridData.filter(function (row) {
        const element = row[settings.barname];
        const start_date = new Date(periodList[i]).setHours(0, 0, 0, 0);
        let end_date = new Date().setHours(0, 0, 0, 0);
        if (element) {
          if (periodType === "month") {
            let dt1 = new Date(start_date);
            dt1 = new Date(dt1.getFullYear(), dt1.getMonth() + 1, 0);
            end_date = new Date(dt1).setHours(0, 0, 0, 0);
            const currentElementDate = new Date(element).setHours(0, 0, 0, 0);
            if (
              currentElementDate >= start_date &&
              currentElementDate <= end_date
            )
              return true;
          } else if (periodType === "week") {
            let dt1 = new Date(start_date);
            dt1 = dt1.setDate(dt1.getDate() + 4);
            end_date = new Date(dt1).setHours(0, 0, 0, 0);
            const currentElementDate = new Date(element).setHours(0, 0, 0, 0);

            if (
              currentElementDate >= start_date &&
              currentElementDate <= end_date
            )
              return true;
          } else {
            const dt = new Date(element).setHours(0, 0, 0, 0);
            const dt1 = new Date(periodList[i]).setHours(0, 0, 0, 0);
            if (dt === dt1) return true;
          }
        }

        return false;
      });

      if (
        settings.charttype === "Bar" ||
        settings.charttype === "BarLine" ||
        settings.charttype === "Line"
      ) {
        if (
          settings.barname !== "" &&
          settings.barvalues === "None" &&
          settings.stackcategory === ""
        ) {
          let slotCount = 0;

          $.each(filteredDataByDate, function () {
            slotCount++;
          });
          Values.push({ label: FormatDate(periodList[i]), value: slotCount });
        } else if (
          settings.barname !== "" &&
          settings.barvalues === "None" &&
          settings.stackcategory !== ""
        ) {
          categorySelected = true;
          //get activity name
          let activityNameArr = [];
          for (let j = 0; j < filteredDataByDate.length; j++) {
            let activity = filteredDataByDate[j][settings.stackcategory];
            if (activity === null) {
              activity = "Others";
            }

            if (j === 0 || activity === "") {
              activityNameArr.push(activity);
            }

            let filteredByAcitvity = [];
            filteredByAcitvity = activityNameArr.filter(function (row) {
              let element = row;
              if (element === null || element === undefined) {
                element = "Others";
              }
              if (element.indexOf(activity) !== -1) {
                return true;
              } else {
                return false;
              }
            });

            if (filteredByAcitvity.length === 0) {
              if (activity !== "") {
                activityNameArr.push(activity);
              }
            }
          }

          //get activity data and slots
          for (let k = 0; k < categoriesArray.length; k++) {
            let slotCount = 0;
            let activityData = [];
            activityData = filteredDataByDate.filter(function (row) {
              let element = row[settings.stackcategory];
              if (element === null || element === undefined) {
                element = "Others";
              }
              if (element.indexOf(categoriesArray[k]) !== -1) {
                return true;
              } else {
                return false;
              }
            });

            $.each(activityData, function () {
              slotCount++;
            });

            Values.push({ label: categoriesArray[k], value: slotCount });
          }

          // added for remove empty array issue
          if (categoriesArray.length === 0) {
            Values.push({ label: filteredByCategoryName[0], value: 0 });
          }
        } else if (
          settings.barname !== "" &&
          settings.barvalues !== "None" &&
          settings.stackcategory === ""
        ) {
          let slotCount = 0;
          let j = 0;
          const barValToCheck = periodList[i];
          let valueArr = [];

          if (periodType === "days") {
            $.each(filteredDataByDate, function () {
              const dt = new Date(barValToCheck).setHours(0, 0, 0, 0);
              const dt1 = new Date(this[settings.barname]).setHours(0, 0, 0, 0);
              if (dt === dt1) {
                const val = this[settings.barvalues];
                if (val !== null) {
                  slotCount = parseFloat(slotCount) + parseFloat(val);
                  j++;
                  valueArr.push(parseInt(val));
                }
              }
            });
          } else {
            $.each(filteredDataByDate, function () {
              const val = this[settings.barvalues];
              if (val !== null) {
                slotCount = parseFloat(slotCount) + parseFloat(val);
                j++;
                valueArr.push(parseInt(val));
              }
            });
          }

          if (settings.operationperform === "Sum") {
            $.each(periodList, function () {
              if (this === periodList[i]) {
                Values.push({ label: this, value: parseInt(slotCount) });
              }
            });
          } else if (settings.operationperform === "Average") {
            let op_val = parseInt(slotCount / j);
            if (isNaN(op_val)) {
              op_val = 0;
            }
            $.each(periodList, function () {
              if (this === periodList[i]) {
                Values.push({ label: this, value: op_val });
              }
            });
          } else if (settings.operationperform === "Max") {
            let op_val = parseInt(Math.max.apply(Math, valueArr));
            if (isNaN(op_val)) {
              op_val = 0;
            }

            $.each(periodList, function () {
              if (this === periodList[i]) {
                Values.push({ label: this, value: op_val });
              }
            });
          } else if (settings.operationperform === "Min") {
            let op_val = parseInt(Math.min.apply(Math, valueArr));
            if (isNaN(op_val)) {
              op_val = 0;
            }
            $.each(periodList, function () {
              if (this === periodList[i]) {
                Values.push({ label: this, value: op_val });
              }
            });
          }
        } else {
          categorySelected = true;
          //get activity name
          let activityNameArr = [];
          for (let j = 0; j < filteredDataByDate.length; j++) {
            let activity = filteredDataByDate[j][settings.stackcategory];
            if (activity === null) {
              activity = "Others";
            }

            if (j === 0 || activity === "") {
              activityNameArr.push(activity);
            }

            let filteredByAcitvity = [];
            filteredByAcitvity = activityNameArr.filter(function (row) {
              let element = row;
              if (element === null || element === undefined) {
                element = "Others";
              }
              if (element.indexOf(activity) !== -1) {
                return true;
              } else {
                return false;
              }
            });

            if (filteredByAcitvity.length === 0) {
              if (activity !== "") {
                activityNameArr.push(activity);
              }
            }
          }

          //get activity data and slots
          for (let k = 0; k < categoriesArray.length; k++) {
            let slotCount = 0;
            let j = 0;
            const barValToCheck = categoriesArray[k];
            let valueArr = [];

            $.each(filteredDataByDate, function () {
              if (barValToCheck === this[settings.stackcategory]) {
                const val = this[settings.barvalues];
                if (val !== null) {
                  slotCount = parseFloat(slotCount) + parseFloat(val);
                  j++;
                  valueArr.push(parseInt(val));
                }
              }
            });

            if (settings.operationperform === "Sum") {
              Values.push({ label: barValToCheck, value: parseInt(slotCount) });
            } else if (settings.operationperform === "Average") {
              let op_val = parseInt(slotCount / j);
              if (isNaN(op_val)) {
                op_val = 0;
              }
              Values.push({ label: barValToCheck, value: op_val });
            } else if (settings.operationperform === "Max") {
              let op_val = parseInt(Math.max.apply(Math, valueArr));
              if (isNaN(op_val)) {
                op_val = 0;
              }

              Values.push({ label: barValToCheck, value: op_val });
            } else if (settings.operationperform === "Min") {
              let op_val = parseInt(Math.min.apply(Math, valueArr));
              if (isNaN(op_val)) {
                op_val = 0;
              }
              Values.push({ label: barValToCheck, value: op_val });
            }
          }

          // added for remove empty array issue
          if (categoriesArray.length === 0) {
            Values.push({ label: filteredByCategoryName[0], value: 0 });
          }
        }
      }

      let linesArr = [];
      let maxLineVal = 0;
      if (settings.charttype === "Line" || settings.charttype === "BarLine") {
        //generate lines data
        if (
          settings.barname !== "" &&
          settings.linevalues === "None" &&
          settings.linecategory === ""
        ) {
          let slotCount = 0;
          $.each(filteredDataByDate, function () {
            slotCount++;
          });

          linesArr.push({ label: FormatDate(periodList[i]), value: slotCount });

          const maxVal = Math.max.apply(
            Math,
            linesArr.map(function (o) {
              return o.value;
            })
          );
          if (maxVal > maxLineVal) maxLineVal = maxVal;
        } else if (
          settings.barname !== "" &&
          settings.linevalues === "None" &&
          settings.linecategory !== ""
        ) {
          //get activity name
          let activityNameArr = [];
          for (let j = 0; j < filteredDataByDate.length; j++) {
            let activity = filteredDataByDate[j][settings.linecategory];
            if (activity === null) {
              activity = "Others";
            }

            if (j === 0 || activity === "") {
              activityNameArr.push(activity);
            }

            let filteredByAcitvity = [];
            filteredByAcitvity = activityNameArr.filter(function (row) {
              let element = row;
              if (element === null || element === undefined) {
                element = "Others";
              }
              if (element.indexOf(activity) !== -1) {
                return true;
              } else {
                return false;
              }
            });

            if (filteredByAcitvity.length === 0) {
              if (activity !== "") {
                activityNameArr.push(activity);
              }
            }
          }

          //get activity data and slots
          for (let k = 0; k < linescategoriesArr.length; k++) {
            let slotCount = 0;
            let activityData = [];
            activityData = filteredDataByDate.filter(function (row) {
              let element = row[settings.linecategory];
              if (element === null || element === undefined) {
                element = "Others";
              }
              if (element.indexOf(linescategoriesArr[k]) !== -1) {
                return true;
              } else {
                return false;
              }
            });

            $.each(activityData, function () {
              slotCount++;
            });

            linesArr.push({ label: linescategoriesArr[k], value: slotCount });
          }

          const maxVal = Math.max.apply(
            Math,
            linesArr.map(function (o) {
              return o.value;
            })
          );
          if (maxVal > maxLineVal) maxLineVal = maxVal;
        } else if (
          settings.barname !== "" &&
          settings.linevalues !== "None" &&
          settings.linecategory === ""
        ) {
          let slotCount = 0;
          let j = 0;
          const barValToCheck = salesPersonArray[i];
          let valueArr = [];

          if (periodType === "days") {
            $.each(filteredDataByDate, function () {
              const dt = new Date(barValToCheck).setHours(0, 0, 0, 0);
              const dt1 = new Date(this[settings.barname]).setHours(0, 0, 0, 0);
              if (dt === dt1) {
                const val = this[settings.linevalues];
                if (val !== null) {
                  slotCount = parseFloat(slotCount) + parseFloat(val);
                  j++;
                  valueArr.push(parseInt(val));
                }
              }
            });
          } else {
            $.each(filteredDataByDate, function () {
              const val = this[settings.linevalues];
              if (val !== null) {
                slotCount = parseFloat(slotCount) + parseFloat(val);
                j++;
                valueArr.push(parseInt(val));
              }
            });
          }

          if (settings.operationperform === "Sum") {
            $.each(periodList, function () {
              if (this === periodList[i]) {
                linesArr.push({ label: this, value: parseInt(slotCount) });
              }
            });
          } else if (settings.operationperform === "Average") {
            let op_val = parseInt(slotCount / j);
            if (isNaN(op_val)) {
              op_val = 0;
            }
            $.each(periodList, function () {
              if (this === periodList[i]) {
                linesArr.push({ label: this, value: op_val });
              }
            });
          } else if (settings.operationperform === "Max") {
            let op_val = parseInt(Math.max.apply(Math, valueArr));
            if (isNaN(op_val)) {
              op_val = 0;
            }

            $.each(periodList, function () {
              if (this === periodList[i]) {
                linesArr.push({ label: this, value: op_val });
              }
            });
          } else if (settings.operationperform === "Min") {
            let op_val = parseInt(Math.min.apply(Math, valueArr));
            if (isNaN(op_val)) {
              op_val = 0;
            }

            $.each(periodList, function () {
              if (this === periodList[i]) {
                linesArr.push({ label: this, value: op_val });
              }
            });
          }

          const maxVal = Math.max.apply(
            Math,
            linesArr.map(function (o) {
              return o.value;
            })
          );
          if (maxVal > maxLineVal) maxLineVal = maxVal;
        } else {
          //get activity name
          let activityNameArr = [];
          for (let j = 0; j < filteredDataByDate.length; j++) {
            let activity = filteredDataByDate[j][settings.linecategory];
            if (activity === null) {
              activity = "Others";
            }

            if (j === 0 || activity === "") {
              activityNameArr.push(activity);
            }

            let filteredByAcitvity = [];
            filteredByAcitvity = activityNameArr.filter(function (row) {
              let element = row;
              if (element === null || element === undefined) {
                element = "Others";
              }
              if (element.indexOf(activity) !== -1) {
                return true;
              } else {
                return false;
              }
            });

            if (filteredByAcitvity.length === 0) {
              if (activity !== "") {
                activityNameArr.push(activity);
              }
            }
          }

          //get activity data and slots
          for (let k = 0; k < linescategoriesArr.length; k++) {
            let slotCount = 0;
            let j = 0;
            let barValToCheck = linescategoriesArr[k];
            let valueArr = [];
            $.each(filteredDataByDate, function () {
              if (barValToCheck === this[settings.linecategory]) {
                let val = this[settings.linevalues];
                if (val !== null) {
                  slotCount = parseFloat(slotCount) + parseFloat(val);
                  j++;
                  valueArr.push(parseInt(val));
                }
              }
            });
            if (settings.operationLineperform === "Sum") {
              linesArr.push({
                label: barValToCheck,
                value: parseInt(slotCount),
              });
            } else if (settings.operationLineperform === "Average") {
              let op_val = parseInt(slotCount / j);
              if (isNaN(op_val)) {
                op_val = 0;
              }
              linesArr.push({ label: barValToCheck, value: op_val });
            } else if (settings.operationLineperform === "Max") {
              let op_val = parseInt(Math.max.apply(Math, valueArr));
              if (isNaN(op_val)) {
                op_val = 0;
              }

              linesArr.push({ label: barValToCheck, value: op_val });
            } else if (settings.operationLineperform === "Min") {
              let op_val = parseInt(Math.min.apply(Math, valueArr));
              if (isNaN(op_val)) {
                op_val = 0;
              }
              linesArr.push({ label: barValToCheck, value: op_val });
            }
          }

          let maxVal = Math.max.apply(
            Math,
            linesArr.map(function (o) {
              return o.value;
            })
          );
          if (maxVal > maxLineVal) maxLineVal = maxVal;
        }
      }

      let sortedArray = Values.sort(function (a, b) {
        let nA = a.label.toLowerCase();
        let nB = b.label.toLowerCase();

        if (nA < nB) return -1;
        else if (nA > nB) return 1;
        return 0;
      });
      let randomnumber = Math.floor(Math.random() * (100 - 10 + 1)) + 10;
      json2.push({
        name: FormatDate(periodList[i]),
        states: sortedArray,
        target: 100,
        actual: 30,
        test: randomnumber,
        line: linesArr,
        maxLineVal: maxLineVal,
      });
    }
  }
  // let fDt = [...json2];
  // json2 = getSortedData(fDt, "name", 1);
  return {
    lineCategories: linescategoriesArr,
    categories: categoriesArray,
    dataItems: json2,
  };
};

const getSortedData = (data, prop, isAsc) => {
  return data.sort((a, b) => {
    return (a[prop] < b[prop] ? -1 : 1) * (isAsc ? 1 : -1);
  });
};

const barChartSettingColumnConfig = function (data) {
  if (data.barname !== undefined && data.barname !== "") {
    data.barname = _removeSpaceAndUnderscore(data.barname);
  }

  if (data.stackcategory !== undefined && data.stackcategory !== "") {
    data.stackcategory = _removeSpaceAndUnderscore(data.stackcategory);
  }

  if (data.barvalues !== undefined && data.barvalues !== "") {
    data.barvalues = _removeSpaceAndUnderscore(data.barvalues);
  }
  return data;
};

function _removeSpaceAndUnderscore(val) {
  if (val.indexOf("_") > -1) {
    val = val.replace(/_/g, "");
  }

  if (val !== undefined && val !== "") {
    val = val.replace(/ /g, "");
  }

  return val;
}

function excludeWeekdaysDecrement(dateVal) {
  if (dateVal.getDay() === 6) {
    dateVal.setDate(dateVal.getDate() - 1);
  } else if (dateVal.getDay() === 0) {
    dateVal.setDate(dateVal.getDate() - 2);
  }
  return dateVal;
}

function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

const ChartSettings = (colorsLineResp, getFinalDt, colorsBarResp, settings) => {
  let colorsLinePallet = [];
  let colorsBarPallet = [];

  if (
    colorsLineResp !== undefined &&
    colorsLineResp !== null &&
    colorsLineResp.length > 0
  ) {
    colorsLinePallet = getFinalDt.lineCategories.map((item) => {
      let getColor = colorsLineResp.find((cls) => {
        return cls.Value === item;
      });
      if (getColor !== null) {
        return getColor.Colour;
      }
    });
  }

  if (
    colorsBarResp !== undefined &&
    colorsBarResp !== null &&
    colorsBarResp.length > 0
  ) {
    colorsBarPallet = getFinalDt.categories.map((item) => {
      let getColor = colorsBarResp.find((cls) => {
        return cls.Value === item;
      });
      if (getColor !== null) {
        return getColor.Colour;
      }
    });
  }

  let legends = {
    bar: getFinalDt.categories,
    line: getFinalDt.lineCategories,
  };

  //check the bar legend values
  let legendGenerated = true;
  if (settings.stackcategory === "") {
    legendGenerated = false;
  }

  //stacked settings
  let isStacked = false;
  if (settings.categorydisplay === "stacked") {
    isStacked = true;
  }

  //secondary axis settings
  let secondaryAxis = true;
  if (settings.lineyaxis === "Off") {
    secondaryAxis = false;
  }

  //chart settings
  let isBarVisible = false;
  let isLineVisible = false;
  if (settings.charttype === "Bar") {
    isBarVisible = true;
  } else if (settings.charttype === "Line") {
    isLineVisible = true;
  } else {
    isBarVisible = true;
    isLineVisible = true;
  }

  //bar value display settings
  let isBarValueVisible = true;
  if (settings.callouts === "Off") {
    isBarValueVisible = false;
  }

  //line value display settings
  let isLineValueVisible = true;
  if (settings.linecallouts === "Off") {
    isLineValueVisible = false;
  }

  let yAxisMinValue = 0;
  if (settings.yaxisminvalue) {
    yAxisMinValue = parseInt(settings.yaxisminvalue);
    if (isNaN(yAxisMinValue)) {
      yAxisMinValue = 0;
    }
  }

  let options = {
    bar: {
      visible: isBarVisible,
      values: isBarValueVisible,
      stacked: isStacked,
      legendGenerated: legendGenerated,
    },
    line: {
      visible: isLineVisible,
      values: isLineValueVisible,
      secondaryAxis: secondaryAxis,
      secondaryAxisMinValue: yAxisMinValue,
    },
    colorsPallet: {
      bar: colorsBarPallet,
      line: colorsLinePallet,
    },
  };

  legends["settings"] = options;

  return {
    legends,
    options,
  };
};

const BarLineChartDataService = {
  BarLineChartData,
  ChartSettings,
};

export default BarLineChartDataService;
