import React from "react";
import classes from "./TopBar.module.css";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

const TopBar = (props) => {
  const [rotationProgress, setRotationProgress] = useState(0);
  const isUserLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const rotationTime = useSelector((state) => state.layout.rotationTime);
  const isClockRotationActive = useSelector(
    (state) => state.layout.IsClockRotationActive
  );
  const rotationPageId = useSelector((state) => state.page.rotationPageId);
  //const [interval, setInterval] = useState(() => {});

  useEffect(() => {
    const start = new Date();
    const limit = rotationTime * 1000;
    setRotationProgress(0);

    //if (rotationTime > 0) {
    const interval = setInterval(function () {
      const elapsed = new Date() - start;
      setRotationProgress((elapsed / limit) * 100);
      if (elapsed >= limit) {
        clearInterval(interval);
      }
    }, 250);

    //setInterval(interval);
    //}

    // if (!isUserLoggedIn) {
    //   clearInterval(interval);
    //   setRotationProgress(0);
    // }

    return () => {
      clearInterval(interval);
    };
  }, [rotationTime, isClockRotationActive, rotationPageId]);

  return (
    <div className={classes["top-bar"]}>
      {isClockRotationActive &&
        rotationProgress <= 99.9888 &&
        isUserLoggedIn && (
          <div id="progressTimer">
            <div>
              <div id="progressTimer">
                <div className={classes["progress"]}>
                  <div
                    className={classes["progress-bar"]}
                    role="progressbar"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="120"
                    style={{ width: `${rotationProgress}%` }}
                  >
                    <i
                      className="fa fa-ellipsis-h fa-2"
                      style={{
                        color: "rgb(255, 255, 255)",
                        fontSize: "22px",
                        position: "relative",
                        top: "-9px",
                        zIndex: "100",
                      }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      <div
        className={classes["show-header"]}
        title="Show header"
        onClick={() => props.hideHeaderHandler(false)}
        style={{ display: !props.isHeaderDisplayed ? "none" : "" }}
      ></div>
      <div
        className={classes["hide-header"]}
        title="Hide header"
        onClick={() => props.hideHeaderHandler(true)}
        style={{ display: props.isHeaderDisplayed ? "none" : "" }}
      ></div>
    </div>
  );
};

export default TopBar;
