import { useCallback, useEffect, useState } from "react";

const useContextMenu = (id) => {
  const [xPos, setXPos] = useState("0px");
  const [yPos, setYPos] = useState("0px");
  const [showMenu, setShowMenu] = useState(false);
  const [sortCol, setSortCol] = useState("");
  const [sortDir, setSortDir] = useState("");

  const handleContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      document.querySelectorAll(".context_menu").forEach((e) => {
        const wId = e.getAttribute("data-widgetid");
        if (wId.toString() !== id.toString()) {
          e.style.display = "none";
        } else {
          e.style.display = "block";
        }
      });
      setXPos(`${e.pageX}px`);
      setYPos(`${e.pageY}px`);
      setShowMenu(true);
      setSortCol(e.target.getAttribute("data-col"));
      setSortDir(e.target.getAttribute("data-dir"));
    },
    [setXPos, setYPos]
  );

  const handleClick = useCallback(() => {
    showMenu && setShowMenu(false);
  }, [showMenu]);

  useEffect(() => {
    const selector = `#table${id} th`;
    document.querySelectorAll(selector).forEach((e) => {
      e.addEventListener("contextmenu", handleContextMenu);
    });
    document.addEventListener("click", handleClick);

    return () => {
      document.addEventListener("click", handleClick);
      document.querySelectorAll(selector).forEach((e) => {
        e.addEventListener("contextmenu", handleContextMenu);
      });
    };
  });

  return { xPos, yPos, showMenu, sortCol, sortDir };
};

export default useContextMenu;
