import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./login-slice";
import layoutSlice from "./layout-slice";
import pageSlice from "./page-slice";
import pagecomtainerslice from "./pagecontainer.slice";

const appStore = configureStore({
  reducer: {
    login: loginSlice.reducer,
    layout: layoutSlice.reducer,
    page: pageSlice.reducer,
    pagecontainer: pagecomtainerslice.reducer,
  },
});

export default appStore;
