import {
  Fragment,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  React,
} from "react";
import WidgetTitleControl from "../../../../UI/TemplateControls/WidgetTitleControl";
import DatasourceControl from "../../../../UI/TemplateControls/DatasourceControl";
import { GetVideoFeeds } from "../../../../../Helper/Services";
import { useSelector } from "react-redux";
import { pageActions } from "../../../../../store/page-slice";
import { useDispatch } from "react-redux";

const VideoFeedTemplate = forwardRef((props, ref) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const rtspId = useSelector((state) => state.page.rtspUrlId);
  const rtspUrlUpdated = useSelector((state) => state.page.rtspUrl);
  const [dtSourceItems, setDtSourceItems] = useState([]);
  const [dtSourceVal, setDtSourceVal] = useState("");
  const [dtSourceIsValid, setDtSourceIsValid] = useState(true);
  const [widgetContentPanel, setWidgetContentPanel] = useState(false);
  const [titleContentPanel, setTitleContentPanel] = useState(true);
  const [title, setTitle] = useState("Video Feed");
  const [shortTitle, setShortTitle] = useState("Video Feed");
  const [videoFeedOption, setVideoFeedOption] = useState("Nest Camera");
  const [videoId, setVideoId] = useState({ value: "", isValid: true });
  const [titleIsValid, setTitleIsValid] = useState(true);
  const [shortTitleIsValid, setShortTitleIsValid] = useState(true);
  const [videoFeedPassword, setVideoFeedPassword] = useState("");
  const [rtspUrl, setRtspUrl] = useState({ value: "", isValid: true });
  const [rtspUrlId, setRtspUrlId] = useState("");
  const [vFeedUrl, setvFeedUrl] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      props.dataSources !== undefined &&
      Object.keys(props.dataSources.Reports).length !== 0
    ) {
      const _items = Object.entries(props.dataSources.Reports)
        .filter((item) => {
          if (item[1] === "Generic Table") {
            return true;
          }
          return false;
        })
        .map((item) => item[0]);
      setDtSourceItems(_items);
    }

    if (
      props.isEdit === true &&
      props.settings !== undefined &&
      (rtspId === "" || rtspId === undefined)
    ) {
      setTitle(props.settings["Title"]);
      setShortTitle(props.settings["ShortTitle"]);
      setVideoFeedOption(props.settings["videooption"]);
      setVideoId({ ...videoId, value: props.settings["videofeedurl"] });
      setVideoFeedPassword(props.settings["videofeedpassword"]);
      setRtspUrlId(props.settings["rtspurlid"]);
      setRtspUrl({ ...rtspUrl, value: props.settings["rtspUrl"] });
      setDtSourceVal(props.settings["reports"]);
      if (props.settings["videooption"] === "Nsyteful Video Mixer V2") {
        getVideoFeeds(props.settings["reports"]);
      }
    }

    if (rtspId !== "" && rtspId !== undefined) {
      getVideoFeeds(dtSourceVal);
      setRtspUrlId(rtspId);
      setRtspUrl({ ...rtspUrl, value: rtspUrlUpdated, isValid: true });
    }

    return () => {
      console.log("clean up videofeed component");
      dispatch(pageActions.setRTSPUrlId({ id: "", url: "" }));
      dispatch(pageActions.setRtspDtName(""));
    };
  }, [props.settings, rtspId, rtspUrlUpdated]);

  useImperativeHandle(ref, () => ({
    GetValidateData() {
      const getVaildForm = validateForm();
      if (getVaildForm) {
        return {
          formIsValid: getVaildForm,
          data: {
            LastRefreshedTime: "",
            ShortTitle: shortTitle,
            Title: title,
            videofeedpassword: videoFeedPassword,
            videofeedsessionid: props.sessionId,
            videofeedurl: videoId.value,
            videooption: videoFeedOption,
            rtspUrl: rtspUrl.value,
            rtspurlid: rtspUrlId,
            reports: dtSourceVal,
          },
        };
      } else {
        return {
          formIsValid: getVaildForm,
        };
      }
    },
  }));

  const onWidgetContentClicked = (val) => {
    if (val) {
      setWidgetContentPanel(val);
    } else {
      setWidgetContentPanel(!widgetContentPanel);
    }
    setTitleContentPanel(false);
  };

  const onTitleContentPanelClicked = (val) => {
    if (val) {
      setTitleContentPanel(val);
    } else {
      setTitleContentPanel(!titleContentPanel);
    }
    setWidgetContentPanel(false);
  };

  const OnTitleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const OnShortTitleChangeHandler = (event) => {
    setShortTitle(event.target.value);
  };

  const onVideoOptionHandler = (event) => {
    setVideoFeedOption(event.target.value);
    if (event.target.value !== "Nsyteful Video Mixer V2") {
      setRtspUrl({ ...rtspUrl, value: "", isValid: true });
      setDtSourceVal("");
      setRtspUrlId("");
    } else {
      setVideoId({ ...videoId, value: "", isValid: true });
    }
  };

  const videoIdChangeHandler = (event) => {
    setVideoId({ ...videoId, value: event.target.value });
  };

  const validateTitle = () => {
    if (title === "") {
      onTitleContentPanelClicked(true);
      setTitleIsValid(false);
      setTimeout(() => {
        setTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateShortTitle = () => {
    if (shortTitle === "") {
      onTitleContentPanelClicked(true);
      setShortTitleIsValid(false);
      setTimeout(() => {
        setShortTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateVideoId = () => {
    if (videoId.value === "") {
      onWidgetContentClicked(true);
      setVideoId({ ...videoId, isValid: false });
      setTimeout(() => {
        setVideoId({ ...videoId, isValid: true });
      }, 3000);
      return false;
    }
    return true;
  };

  const videoFeedPasswordHandler = (event) => {
    setVideoFeedPassword(event.target.value);
  };

  const validateForm = () => {
    const isTitleValid = validateTitle();
    if (!isTitleValid) {
      return false;
    }

    const isShortTitleValid = validateShortTitle();
    if (!isShortTitleValid) {
      return false;
    }

    if (videoFeedOption === "Nsyteful Video Mixer V2") {
      if (dtSourceVal === "") {
        setDtSourceIsValid(false);
        setTimeout(() => {
          setDtSourceIsValid(true);
        }, 3000);
        return false;
      }
      if (
        rtspUrl.value === "" ||
        rtspUrl.value === "Create New RTSP Url" ||
        rtspUrl.value === "Select RTSP Url"
      ) {
        setRtspUrl({ ...rtspUrl, isValid: false });
        setTimeout(() => {
          setRtspUrl({ ...rtspUrl, isValid: true });
        }, 3000);
        return false;
      }
    } else {
      const isVideoValid = validateVideoId();
      if (!isVideoValid) {
        return false;
      }
    }

    return true;
  };

  const OnDatasourceChange = (event) => {
    const _val = event.target.value;
    setDtSourceVal(_val);
    setRtspUrl({ ...rtspUrl, value: "", isValid: true });
    setRtspUrlId("");
    if (_val !== "") {
      getVideoFeeds(_val);
    }
  };

  const onStreamUrlChange = (event) => {
    if (event.target.value !== "") {
      setRtspUrlId(event.target.value);
      setRtspUrl({ ...rtspUrl, value: event.target.selectedOptions[0].text });
    }

    if (event.target.value === "CreateNewRTSPUrl") {
      dispatch(pageActions.setRtspDtName(dtSourceVal));
      dispatch(pageActions.triggerCreateRstpUrl());
    }
  };

  const getVideoFeeds = (datasource) => {
    GetVideoFeeds(datasource, sessionId)
      .then((feeds) => {
        setvFeedUrl(feeds);
      })
      .catch((err) => {});
  };

  return (
    <Fragment>
      {!props.isEdit && <hr className="style-one" />}
      <div onClick={onTitleContentPanelClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {titleContentPanel && "-"}
          {!titleContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET TITLE</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: titleContentPanel ? "block" : "none",
        }}
      >
        <WidgetTitleControl
          titleVal={title}
          shortTitleVal={shortTitle}
          OnTitleChangeHandler={OnTitleChangeHandler}
          OnShortTitleChangeHandler={OnShortTitleChangeHandler}
          isTitleValid={titleIsValid}
          isShortTitleValid={shortTitleIsValid}
        />
      </div>
      <div onClick={onWidgetContentClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {widgetContentPanel && "-"}
          {!widgetContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET CONTENT</p>
      </div>
      <div
        style={{
          paddingLeft: "12px",
          display: widgetContentPanel ? "block" : "none",
        }}
      >
        <p>Video Option</p>
        <select
          value-key="videooption"
          onChange={onVideoOptionHandler}
          value={videoFeedOption}
        >
          <option value="Nest Camera">Nest Camera</option>
          <option value="Nsyteful Video Mixer"> Nsyteful Video Mixer</option>
          <option value="Nsyteful Video Mixer V2">
            Nsyteful Video Mixer V2
          </option>
        </select>
        {videoFeedOption !== "Nsyteful Video Mixer V2" && (
          <div>
            <p>Video ID</p>
            <input
              type="text"
              value-key="videofeedurl"
              style={{ marginTop: "1px" }}
              value={videoId.value}
              onChange={videoIdChangeHandler}
            />
            {!videoId.isValid && (
              <span
                id="validatevideofeedurl"
                style={{ color: "red", paddingLeft: "5px" }}
              >
                Required
              </span>
            )}
          </div>
        )}

        {videoFeedOption === "Nest Camera" && (
          <div id="pwd_cont">
            <p>Video Password (only if required)</p>
            <input
              type="password"
              value-key="videofeedpassword"
              id="videofeedpassword"
              style={{ marginTop: "1px" }}
              value={videoFeedPassword}
              onChange={videoFeedPasswordHandler}
            />
            <p>
              Note. In order for Nsyteful to automatically submit your password,
              you must be running the <b>Nsyteful Password Agent</b> Chrome
              Extension. Please
              <span style={{ color: "#9AB57E", cursor: "pointer" }}>
                <a
                  style={{ textDecoration: "none", color: "#9AB57E" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://chrome.google.com/webstore/detail/nsyteful/damdidmhcknblnahdeejdneajolieicb"
                >
                  follow this link
                </a>
              </span>
              to download and install. (This extension can only be used with the
              Chrome browser.)
            </p>
          </div>
        )}
        <input
          type="text"
          value-key="videofeedsessionid"
          id="videofeedsessionid"
          style={{ display: "none" }}
        />

        {videoFeedOption === "Nsyteful Video Mixer V2" && (
          <div>
            <DatasourceControl
              value={dtSourceVal}
              onChangeHandler={OnDatasourceChange}
              isValid={dtSourceIsValid}
              dtList={dtSourceItems}
            />
            <p>RTSP Url</p>
            <select
              disabled={dtSourceVal === "" || dtSourceVal === undefined}
              onChange={onStreamUrlChange}
              value={rtspUrlId}
            >
              <option>Select RTSP Url</option>
              {vFeedUrl.map((item) => {
                return (
                  <option
                    key={item.id}
                    data-feedurl={item.streamUrl}
                    value={item.id}
                  >
                    {item.streamUrl}
                  </option>
                );
              })}
              <option disabled="disabled" value="Divider">
                -----------------------------------------
              </option>
              <option value="CreateNewRTSPUrl">Create New RTSP Url</option>
            </select>
            {!rtspUrl.isValid && (
              <span style={{ color: "red", paddingLeft: "5px" }}>Required</span>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
});

export default VideoFeedTemplate;
