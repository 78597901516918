import React from "react";
const NoShowDiv = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "rgb(153, 153, 153)",
        fontSize: "17px",
      }}
    >
      {props.screenMsg}
    </div>
  );
};

export default NoShowDiv;
