import {
  Add_Rotation,
  Add_Rotation_Page,
  Delete_Rotation,
  Delete_Rotation_Page,
  Get_Rotations,
  IS_TESTAPI_SERVICE_URL_Enabled,
  Sort_Rotation_Page,
  Update_Page_Rotation_Status,
  Update_Rotation,
  Update_Rotation_Page,
} from "../../../../Constants";

const addRotation = (rotationName, userId) => {
  var obj = {};
  obj["RotationName"] = rotationName;
  obj["UserId"] = userId;

  let url = `${Add_Rotation}`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response.json());
};

const getRotation = (userId) => {
  let url = `${Get_Rotations}?userId=${userId}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response.json());
};

const updateRotation = (rotationName, rotationId, userId) => {
  var obj = {};
  obj["Id"] = rotationId;
  obj["RotationName"] = rotationName;
  obj["UserId"] = userId;

  let url = `${Update_Rotation}`;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response.json());
};

const deleteRotation = (id, sessionId) => {
  let url = `${Delete_Rotation}/${sessionId}/${id}/${IS_TESTAPI_SERVICE_URL_Enabled}`;
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response.json());
};

const addRotationPage = (pageId, rotationId, timeInterval) => {
  var obj = {};
  obj["PageId"] = pageId;
  obj["RotationId"] = rotationId;
  obj["TimeInterval"] = timeInterval;

  let url = `${Add_Rotation_Page}`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response.json());
};

const updateRotationPage = (
  rotationPageId,
  pageId,
  rotationId,
  timeInterval
) => {
  var obj = {};
  obj["Id"] = rotationPageId;
  obj["PageId"] = pageId;
  obj["RotationId"] = rotationId;
  obj["TimeInterval"] = timeInterval;

  let url = `${Update_Rotation_Page}`;
  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response.json());
};

const deleteRotationPage = (id) => {
  let url = `${Delete_Rotation_Page}?id=${id}`;
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response.json());
};

const updatePageRotationStatus = (userId, value) => {
  let url = `${Update_Page_Rotation_Status}?layoutId=${userId}&val=${value}`;
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => {
    return response.ok;
  });
};

const sortRotationPage = (rotationPageId, rotationId, newSortOrderId) => {
  var obj = {};
  obj["Id"] = rotationPageId;
  obj["RotationId"] = rotationId;
  obj["NewSortOrderId"] = newSortOrderId;

  let url = `${Sort_Rotation_Page}`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((response) => response.json());
};

const RotationServices = {
  addRotation,
  getRotation,
  updateRotation,
  deleteRotation,
  addRotationPage,
  updateRotationPage,
  deleteRotationPage,
  updatePageRotationStatus,
  sortRotationPage,
};

export default RotationServices;
