import React from "react";
export const ConfiguratorCancelButton = ({ onClickHandler }) => {
  return (
    <a
      href=""
      className="icons_cls"
      onClick={onClickHandler}
      title="Cancel"
      style={{ fontSize: "18px", color: "gray", textDecoration: "none" }}
    >
      &nbsp;<i className="fa fa-times" aria-hidden="true"></i>
    </a>
  );
};
