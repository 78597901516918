import { useEffect, useState, React } from "react";
import { QueryBuilder, formatQuery } from "react-querybuilder";
import { parseMongoDB } from "react-querybuilder/parseMongoDB";
import "react-querybuilder/dist/query-builder.scss";
import "./queryBuilder.css";
import { QueryBuilderBootstrap } from "@react-querybuilder/bootstrap";
import "bootstrap-icons/font/bootstrap-icons.scss";
import FilterServices from "../FilterServices";
import { CustomValueEditor } from "./customValueEditor";
import {
  Operator_NotNull,
  Operator_Null,
  Operator_Today,
  Operator_Yesterday,
} from "./constants";
import { Operator_Last7days } from "./constants";
import { Operator_Last30days } from "./constants";
import { Operator_WorkingDaysOnly } from "./constants";
import { Operator_LastMonth } from "./constants";
import { Operator_ThisMonth } from "./constants";
import { Operator_BeforeToday } from "./constants";
import { useSelector, useDispatch } from "react-redux";
import { layoutActions } from "../../../store/layout-slice";
import { BUBBLECHART, SALESV2 } from "../../../Constants";
import Loader from "../../UI/Loader";
import WidgetButtonControl from "../../UI/Buttons/WidgetButton";
import SaveWidgetButton from "../../UI/Buttons/SaveWidget";
import { ReplaceTextToSpecialChar } from "../../../Helper/WidgetDataCommon";
//import "bootstrap/scss/bootstrap.scss";

const initialQuery = {
  combinator: "and",
  rules: [{ field: "CaseNo", operator: "=", value: "" }],
};
const QueryBuilderComponent = (props) => {
  const currentWidgetId = useSelector((state) => state.layout.selectedWidgetId);
  const [query, setQuery] = useState(initialQuery);
  const [validateRules, setValidateRules] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [filterName, setFilterName] = useState("");
  const [customQuery, setCustomQuery] = useState(initialQuery);
  const [isSharedFilter, setIsSharedFilter] = useState(false);
  const [enabledLoader, setEnabledLoader] = useState(false);
  const allowDomain = useSelector(
    (state) => state.login.userDetail.allowDomain
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setFilterName(props.filterName);
    setIsSharedFilter(props.isSharedFilter);
    if (props.widgetType !== undefined && props.widgetType === SALESV2) {
      const salesQuery = {
        ...initialQuery,
        rules: [{ field: "Salesperson", operator: "=", value: "" }],
      };
      setQuery(salesQuery);
    }

    if (props.widgetType !== undefined && props.widgetType === BUBBLECHART) {
      const salesQuery = {
        ...initialQuery,
        rules: [{ field: "opportunity", operator: "=", value: "" }],
      };
      setQuery(salesQuery);
    }

    if (props.filterText !== "" && props.filterText !== undefined) {
      //remove tab from query globally
      const filterText = props.filterText.replace(/\t/g, "");
      const mongoQuery = parseMongoDB(filterText);
      console.log("mongoQuery", mongoQuery);
      //   const mongoQuery1 = FilterServices.handleInversionConditionForMongoDb(
      //     props.filterText,
      //     { ...mongoQuery }
      //   );
      //   const convertQueryToDefault = JSON.parse(
      //     formatQuery(mongoQuery1, "json")
      //   );
      //   query = FilterServices.reverseCustomQuery(convertQueryToDefault);
      const query = FilterServices.reverseCustomQuery(mongoQuery);
      setQuery(query);
      setCustomQuery(query);
    }
    return () => {
      //setQuery(initialQuery);
      //setCustomQuery(initialQuery);
    };
  }, [
    props.field,
    props.filterName,
    props.isSharedFilter,
    props.filterText,
    props.filterId,
  ]);

  const onValidateQuery = (q) => validateRules;

  const saveHandler = () => {
    const getDateFields = props.field
      .filter((x) => x.inputType === "datetime-local")
      .map((x) => x.name)
      .join(",")
      .toString();
    let isEmptyCount = 0;
    const invalidFields = query.rules.map((items) => {
      const id = items.id;

      if (
        items.value === "" &&
        items.operator !== Operator_Today &&
        items.operator !== Operator_Yesterday &&
        items.operator !== Operator_Last7days &&
        items.operator !== Operator_Last30days &&
        items.operator !== Operator_WorkingDaysOnly &&
        items.operator !== Operator_LastMonth &&
        items.operator !== Operator_ThisMonth &&
        items.operator !== Operator_BeforeToday &&
        items.operator !== Operator_Null &&
        items.operator !== Operator_NotNull
      ) {
        isEmptyCount++;
        return { [id]: false };
      }

      if (
        items.operator === "=" &&
        getDateFields.indexOf(items.field) > -1 &&
        items.value.indexOf(",") > -1
      ) {
        isEmptyCount++;
        return { [id]: false };
      }

      return { [id]: true };
    });
    const obj = Object.assign({}, ...invalidFields);
    setValidateRules(obj);
    const isValid = validateFilters(isEmptyCount);
    if (isValid) {
      //props.OnLoderEnable(true);
      setEnabledLoader(true);
      const _customQuery = FilterServices.generateCustomQuery(query);
      const trimValuesQuery = FilterServices.trimQueryValue(_customQuery);
      console.log("trimValuesQuery", trimValuesQuery);
      console.log("customQuery", _customQuery);
      const filterText = formatQuery(trimValuesQuery, {
        format: "mongodb",
        parseNumbers: true,
      });

      console.log("filterText", filterText);
      const id = props.isEdit === true ? props.filterId : null;
      let filterNameObj = FilterServices.replaceSpecialCharToText(filterName);
      filterNameObj = filterNameObj.trim();

      const settings = {
        id: id,
        filterText: filterText,
        datasourceName: props.dataSourceName,
        filterName: filterNameObj,
        isSharedFilter: isSharedFilter,
        domainName: allowDomain,
        userId: props.email,
      };

      FilterServices.addUpdateFilter(settings, props.sessionId)
        .then((data) => {
          if (data) {
            if (
              data === "Special characters not allowed in Filter name" ||
              data === "Filter Already Exist" ||
              data === "Unsuccess" ||
              data === "filter name already exists"
            ) {
              displayError(data);
              setEnabledLoader(false);
              return false;
            } else {
              dispatch(
                layoutActions.setAddUpdateFilter({
                  filterName: ReplaceTextToSpecialChar(data["filterName"]),
                  filterId: data["id"],
                  isSharedfilter: data["isSharedFilter"],
                  userId: data["userId"],
                })
              );
              props.onCloseHandler();
              setEnabledLoader(false);
            }
          }
        })
        .catch((err) => {
          console.log("addUpdateFilter-err", err);
          setEnabledLoader(false);
        });
      // In case of google sheet
      // if (dataManager.IsGoogleSheet(dataSourceName)) {
      //     settings["DatasourceName"] = dataSourceName + " " + connectionName;
      // }
    }
  };

  const deleteFilter = () => {
    const result = window.confirm(
      "Are you sure you want to delete this filter?"
    );
    if (result) {
      setEnabledLoader(true);
      const _filterName = FilterServices.replaceSpecialCharToText(filterName);
      FilterServices.CheckIsFilterInUse(
        props.sessionId,
        props.filterId,
        props.dataSourceName,
        currentWidgetId
      )
        .then((resp) => {
          if (resp === true) {
            setEnabledLoader(false);
            setTimeout(() => {
              alert(
                "This filter is in use on other widgets and cannot be deleted"
              );
            }, 500);
          } else {
            if (_filterName.length > 0) {
              // google generic sheet
              // var dtSource = dataSourceName;
              // if (dataManager.IsGoogleSheet(dataSourceName)) {
              //   dtSource = dataSourceName + " " + connectionName;
              // }
              FilterServices.deleteFilter(
                props.filterId,
                props.dataSourceName,
                props.sessionId
              )
                .then((res) => {
                  dispatch(
                    layoutActions.setAddUpdateFilter({
                      filterName: "null",
                      filterId: "",
                      isSharedfilter: "",
                      userId: "",
                    })
                  );
                  props.onCloseHandler();
                  setEnabledLoader(false);
                  try {
                    FilterServices.updateFilterSetting(
                      currentWidgetId,
                      _filterName
                    )
                      .then((res) => {})
                      .catch((err) => {
                        console.log("updateFilterSetting API", err);
                      });
                  } catch (error) {}
                })
                .catch((err) => {
                  console.log("deleteFilter API", err);
                  setEnabledLoader(false);
                });
            } else {
              setEnabledLoader(false);
            }
          }
        })
        .catch((err) => {
          setEnabledLoader(false);
          console.log("CheckIsFilterInUse", err);
        });
    }
  };

  const setQueryHandler = (q) => {
    const query = FilterServices.generateCustomQuery(q);
    setCustomQuery(query);
    setQuery(q);
  };

  const onFilterNameChangeHandler = (event) => {
    setFilterName(event.target.value);
  };

  const validateFilters = (emptyCount) => {
    if (query.rules.length === 0) {
      displayError("Filter condition cannot be empty.");
      return false;
    }

    if (emptyCount > 0) {
      displayError("Filter condition cannot be empty.");
      return false;
    }

    if (filterName.length > 100) {
      displayError("Filter name is too long. Please enter a shorter name");
      return false;
    }

    if (filterName.length === 0 || filterName.trim() === "") {
      displayError("Please Enter Filter Name");
      return false;
    }

    const getSpecialCharacterMatched =
      FilterServices.matchSpecialaCharcter(filterName);

    if (getSpecialCharacterMatched) {
      displayError("Allow only following special character &!_-%");
      return false;
    }

    return true;
  };

  const displayError = (message) => {
    setErrorMsg(message);
    setTimeout(() => {
      setErrorMsg("");
    }, 3000);
  };

  const sharedFilterHandler = () => {
    setIsSharedFilter(!isSharedFilter);
  };

  return (
    <div className="custom-style-query-builder validateQuery">
      <form style={{ display: enabledLoader ? "none" : "" }}>
        <QueryBuilderBootstrap>
          <QueryBuilder
            fields={props.field}
            query={query}
            onQueryChange={setQueryHandler}
            controlClassnames={{
              queryBuilder: "queryBuilder-branches",
              addRule: "add-rule-btn",
              addGroup: "add-group-btn",
              removeRule: "remove-rule-btn",
              removeGroup: "remove-group-btn",
            }}
            validator={onValidateQuery}
            translations={{
              addGroup: {
                label: "Add group",
                title: "Add group",
              },
              addRule: {
                label: "Add rule",
                title: "Add rule",
                className: "btn btn-success btn-sm",
              },
              removeRule: {
                label: "Delete",
                title: "Remove rule",
              },
              removeGroup: {
                label: "Delete",
                title: "Remove group",
              },
            }}
            controlElements={{ valueEditor: CustomValueEditor }}
          />
        </QueryBuilderBootstrap>
        <div style={{ width: "250px" }}>
          <p>Filter Name</p>
          <input
            type="text"
            id="filterNameTxt"
            style={{
              width: "300px",
              border: "1px solid #cfcfcf",
              padding: "3px",
            }}
            value={filterName}
            onChange={onFilterNameChangeHandler}
          />
          {errorMsg && <span style={{ color: "red" }}>{errorMsg}</span>}
          <div style={{ width: "250px" }}>
            <input
              style={{ top: "14px", position: "relative" }}
              type="checkbox"
              checked={isSharedFilter}
              onChange={sharedFilterHandler}
            />
            <label
              style={{
                left: "4px",
                top: "12px",
                fontWeight: "400",
                position: "relative",
                paddingBottom: "10px",
              }}
            >
              Share with all domain members
            </label>
          </div>
        </div>
        <br />
        {/* <ButtonControls
          OnSaveHandler={saveHandler}
          onCloseModal={props.onCloseHandler}
          SaveBtnText={props.isEdit === true ? "Update" : "Save"}
        /> */}

        <div id="settings-buttons">
          <SaveWidgetButton
            onClick={saveHandler}
            text={props.isEdit === true ? "Update" : "Save"}
          />
          <WidgetButtonControl
            title="Close"
            cssStyle={{ marginLeft: "3px" }}
            onClick={props.onCloseHandler}
          />

          <WidgetButtonControl
            title="Delete Filter"
            cssStyle={{ marginLeft: "3px", width: "80px" }}
            onClick={deleteFilter}
          />
        </div>
      </form>
      {enabledLoader && <Loader styles={{ height: "80px" }} />}
    </div>
  );
};

export default QueryBuilderComponent;
