import React from "react";
import { useSelector } from "react-redux";
import TableContent from "./TableContent";
import styles from "./TableTemplate.module.css";

const TableTemplate = (props) => {
  const getTheme = useSelector((state) => state.layout.theme);
  return (
    <div
      className={`${styles["tbl-datagrid"]} ${
        getTheme !== "Light" ? styles["dark-datagrid"] : ""
      } `}
    >
      <div
        className={`${styles["tbl-datagrid-rowsview"]} ${styles["tbl-datagrid-nowrap"]}`}
      >
        <div
          id="body_scroll_{{tblId}}"
          className={`${styles["tbl-scrollable-content"]} ${styles["tbl-col-headers"]} body_scroll_selector`}
        >
          <div className={styles["tbl-datagrid-content"]}>
            <TableContent
              metaData={props.metaData}
              widgetData={props.widgetData}
              widgetId={props.widgetId}
              settings={props.settings}
              wid={props.wid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableTemplate;
