import React from "react";
export const UpdateButton = ({ onClickHandler }) => {
  return (
    <a
      href=""
      className="icons_cls"
      title="Update"
      style={{ fontSize: "18px", color: "gray", textDecoration: "none" }}
      onClick={onClickHandler}
    >
      <i className="fa fa-check-square-o" aria-hidden="true"></i>
    </a>
  );
};
