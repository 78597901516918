import TwitterTemplate from "./Template";
import { useEffect, useState, React } from "react";
import { GetWidgetSettings } from "../../../../Helper/Services";
import GetTwitterFeed, { GetTweets, GetWidgetDataById } from "./TwitterService";
import { TwitterWidgetRefreshTime } from "../../../../Constants";
import { useSelector } from "react-redux";

const TwitterWidget = (props) => {
  const ballistixUserId = useSelector((state) => state.layout.BallistixUserId);
  const [tweetList, setTweetList] = useState([]);
  const [isUserNotFound, setIsUserNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let tweeterParam = "";

  useEffect(() => {
    const getWidgetSetting = () => {
      if (ballistixUserId === 0) {
        const getWidgetDetail = GetWidgetDataById(props.widgetId);
        if (getWidgetDetail["Twitter"] != undefined) {
          tweeterParam = getWidgetDetail["Twitter"];
        } else {
          tweeterParam = getWidgetDetail["Configuration"]
            .replace("<root><feedType>", "")
            .replace("</feedType><TwitterId>", "")
            .replace("</TwitterId></root>", "");
        }

        loadTweets(tweeterParam);
      } else {
        GetWidgetSettings(props.widgetId)
          .then((res) => {
            tweeterParam = res["feedType"] + "" + res["TwitterId"];
            loadTweets(tweeterParam);
          })
          .catch((err) => {});
      }
    };

    getWidgetSetting();

    const refreshWidget = setInterval(() => {
      console.log("set Timout..", new Date().toLocaleTimeString());
      getWidgetSetting();
    }, TwitterWidgetRefreshTime);

    return () => {
      //clear setinterval on unmount component
      clearInterval(refreshWidget);
    };
  }, [props.title]);

  const loadTweets = (tweeterParam) => {
    var query = escape(tweeterParam.trim().replace(/\u200B/g, ""));
    GetTwitterFeed(query)
      .then((data) => {
        setIsLoading(true);
        if (data == "") {
          setIsUserNotFound(true);
        }

        if (query.indexOf("%23") != -1) {
          if (data.statuses == "") {
            setTweetList([]);
          }
        }

        let tweets = data;
        try {
          if (data.statuses !== undefined) {
            tweets = data.statuses;
          }

          const res = GetTweets(tweets);
          setTweetList(res);
          setIsLoading(false);
        } catch (e) {
          setTweetList([]);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setTweetList([]);
        setIsLoading(false);
      });
  };

  return (
    <div>
      {isUserNotFound && <center> User not found. </center>}
      {tweetList.length > 0 &&
        tweetList.map((item, i) => {
          return <TwitterTemplate key={i} item={item} />;
        })}
      {tweetList.length === 0 && !isLoading && (
        <center> No feeds found. </center>
      )}
    </div>
  );
};

export default TwitterWidget;
