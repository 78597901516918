import { createSlice } from "@reduxjs/toolkit";
import {
  GetLocalStorageLayout,
  SetLocalStorageLayout,
  SwapLayoutInLocalStorage,
} from "../Helper/SaveSettings";

const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    PageList: [],
    RotationList: [],
    Widgets: [],
    Page: null,
    theme: "Light",
    AccountName: "",
    layoutId: "",
    pageName: "",
    UserId: "",
    PageRotation: "Off",
    BallistixUserId: 0,
    IsSharedPage: false,
    IsOtherUserPage: false,
    SharedUserEmail: null,
    IsAddWidgetPopup: false,
    IsEditWidgetPopup: false,
    selectedWidgetId: "",
    selectedWidgetTypeId: "",
    selectedWidgetType: "",
    dragItemOrderId: "",
    IsFilterPopupOpen: false,
    IsEditFilter: false,
    IsCloneFilter: false,
    selectedDataSource: "",
    addUpdateFilterObj: {
      filterName: "",
      filterId: "",
      isSharedfilter: "",
      userId: "",
    },
    IsRotationPopup: false,
    IsClockRotationActive: false,
    rotationTime: 0,
    IsConfiguratorPopup: false,
    IsHeaderDisplayed: false,
    IsCloneWidgetPopup: false,
    IsChangePasswordPopup: false,
    IsRecycleBinPopup: false,
    IsWidgetRestored: false,
    IsRotationUpdateVersion: 0,
  },
  reducers: {
    setIntialLayout(state, action) {
      let getLayout = GetLocalStorageLayout();
      if (getLayout !== undefined && getLayout !== null) {
        SetLocalStorageLayout(getLayout);
      } else {
        SetLocalStorageLayout(action.payload);
      }

      if (action.payload.BallistixUserId === 0) {
        getLayout = GetLocalStorageLayout();
        state.Widgets = getLayout.Widgets;
      } else {
        state.Widgets = action.payload.Widgets.map((x) => {
          x.UpdatedVersion = 0;
          return x;
        });
      }

      state.PageList = action.payload.PageList;
      state.RotationList = action.payload.RotationList;
      //state.Widgets = action.payload.Widgets;
      state.Page = action.payload.Page;
      state.theme = action.payload.theme;
      state.layoutId = action.payload.layoutId;
      state.UserId = action.payload.UserId;
      state.PageRotation = action.payload.PageRotation;
      if (action.payload.PageRotation === "Off") {
        state.IsClockRotationActive = false;
        state.rotationTime = 0;
      }
      state.BallistixUserId = action.payload.BallistixUserId;
      state.IsSharedPage = action.payload.IsSharedPage;
      state.IsOtherUserPage = action.payload.IsOtherUserPage;
      state.SharedUserEmail = action.payload.SharedUserEmail;
    },
    setPageList(state, action) {
      state.PageList = action.payload;
    },
    setAddWidgetPopup(state, action) {
      state.IsAddWidgetPopup = !state.IsAddWidgetPopup;
    },
    setWidgetRestore(state, action) {
      state.IsWidgetRestored = !state.IsWidgetRestored;
    },
    setRotationPopup(state, action) {
      state.IsRotationPopup = !state.IsRotationPopup;
    },
    setCloneWidgetPopup(state, action) {
      state.IsCloneWidgetPopup = !state.IsCloneWidgetPopup;
    },
    setConfiguratorPopup(state, action) {
      state.IsConfiguratorPopup = !state.IsConfiguratorPopup;
    },
    setChangePasswordPopup(state, action) {
      state.IsChangePasswordPopup = !state.IsChangePasswordPopup;
    },
    setHeaderDisplay(state, action) {
      state.IsHeaderDisplayed = action.payload;
    },
    setClockRotation(state, action) {
      state.IsClockRotationActive = action.payload;
      // if (action.payload === false) {
      //   state.PageRotation = "Off";
      // }
    },
    setActiveRotationId(state, action) {
      state.PageRotation = action.payload;
    },
    setRotationTime(state, action) {
      state.rotationTime = action.payload;
    },
    setRotationUpdateVersion(state, action) {
      state.IsRotationUpdateVersion += 1;
    },
    setFilterPopup(state, action) {
      state.IsFilterPopupOpen = !state.IsFilterPopupOpen;
      state.IsEditFilter = action.payload.edit;
      state.IsCloneFilter = action.payload.clone;
    },
    setAddUpdateFilter(state, action) {
      state.addUpdateFilterObj = action.payload;
    },

    setRecycleBinPopup(state, action) {
      state.IsRecycleBinPopup = !state.IsRecycleBinPopup;
    },
    setEditWidgetPopup(state, action) {
      state.IsEditWidgetPopup = !state.IsEditWidgetPopup;
      state.addUpdateFilterObj = {
        filterName: "",
        filterId: "",
        isSharedfilter: "",
        userId: "",
      };
    },
    setSelectedWidgetId(state, action) {
      state.selectedWidgetId = action.payload;
    },
    setSelectedDatasource(state, action) {
      state.selectedDataSource = action.payload;
    },
    setSelectedWidgetTypeId(state, action) {
      state.selectedWidgetTypeId = action.payload;
    },
    setSelectedWidgetType(state, action) {
      state.selectedWidgetType = action.payload;
    },
    setDragItemOrder(state, action) {
      state.dragItemOrderId = action.payload;
    },
    updateWidget(state, action) {
      const getItemIndex = state.Widgets.findIndex(
        (x) => x.Id === action.payload.WidgetId
      );
      let widgetDetails = { ...state.Widgets[getItemIndex] };
      widgetDetails.Title = action.payload.title;
      widgetDetails.ShortTitle = action.payload.shortTitle;
      widgetDetails.Type = action.payload.widgetTypeId;
      widgetDetails.WidgetType = action.payload.WidgetType;
      state.Widgets[getItemIndex] = widgetDetails;
    },
    updateWidgetSettings(state, action) {
      const getItemIndex = state.Widgets.findIndex(
        (x) => x.Id === action.payload.WidgetId
      );
      let widgetDetails = { ...state.Widgets[getItemIndex] };
      widgetDetails.Title = action.payload.title;
      widgetDetails.ShortTitle = action.payload.shortTitle;
      widgetDetails.UpdatedVersion = widgetDetails.UpdatedVersion + 1;
      state.Widgets[getItemIndex] = widgetDetails;
    },
    swapWidget(state, action) {
      if (state.dragItemOrderId !== action.payload.dropWidgetSortOrder) {
        const getIndexofWidget1 = state.Widgets.findIndex(
          (x) => x.SortOrder === parseInt(state.dragItemOrderId)
        );
        const getIndexofWidget2 = state.Widgets.findIndex(
          (x) => x.SortOrder === action.payload.dropWidgetSortOrder
        );

        const widgetdt1 = { ...state.Widgets[getIndexofWidget1] };
        const widgetdt2 = { ...state.Widgets[getIndexofWidget2] };

        let widget1 = { ...state.Widgets[getIndexofWidget1] };
        let widget2 = { ...state.Widgets[getIndexofWidget2] };

        //swap item for local storage
        if (state.BallistixUserId === 0) {
          SwapLayoutInLocalStorage(widget1, widget2);
        }
        widget1.Title = widgetdt2.Title;
        widget1.Id = widgetdt2.Id;
        widget1.Type = widgetdt2.Type;
        widget1.ShortTitle = widgetdt2.ShortTitle;
        //widget1.SortOrder = widgetdt2.SortOrder;

        widget2.Title = widgetdt1.Title;
        widget2.Id = widgetdt1.Id;
        widget2.Type = widgetdt1.Type;
        widget2.ShortTitle = widgetdt1.ShortTitle;
        //widget2.SortOrder = widgetdt1.SortOrder;

        state.Widgets[getIndexofWidget1] = widget1;
        state.Widgets[getIndexofWidget2] = widget2;
      }
    },
    updateTheme(state, action) {
      state.theme = action.payload;
    },
    updateRotationList(state, action) {
      state.RotationList = action.payload;
    },
  },
});

export const layoutActions = layoutSlice.actions;

export default layoutSlice;
