import TableTemplate from "./Template/TableTemplate";
import { useEffect, useState, React } from "react";
import GetMetaData, {
  GetLastRefreshDateTime,
  GetWidgetData,
  GetWidgetSettings,
  GridMetaData_Format,
} from "../../../../Helper/Services";
import { useSelector } from "react-redux";
import GetColumnList, {
  GetUpdatedColSettings,
  UpdatedWidgetData,
} from "./TableWidgetService";
import { GetFormattedGridData } from "../../../../Helper/WidgetDataCommon";
import { IsSharedFilterAlert } from "./../../../../Helper/WidgetDataCommon";
import { TableWidgetRefreshTime } from "../../../../Constants";

const TableWidget = (props) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const isOtherUserPage = useSelector((state) => state.layout.IsOtherUserPage);
  const isSharedPage = useSelector((state) => state.layout.IsSharedPage);
  const sharedUserEmail = useSelector((state) => state.layout.SharedUserEmail);
  const [metaDt, setMetaDt] = useState([]);
  const [widgetDt, setWidgetDt] = useState([]);
  const [widgetSettings, setWidgetSettings] = useState("");
  const [screenMsg, setScreenMsg] = useState("No Data");
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    const getWidgetSetting = () => {
      let isSharedFilter = false;
      let sharedFilterUserId = "";
      let filterId = "";

      GetWidgetSettings(props.widgetId)
        .then((res) => {
          setShowNoData(false);
          setWidgetSettings(res);
          if (
            res["IsSharedFilter"] !== undefined &&
            res["IsSharedFilter"] == "true"
          ) {
            isSharedFilter = true;
            sharedFilterUserId = res["OriginalUserId"];
            filterId = res["FilterId"];
          }

          GetMetaData(res["reports"], "", sessionId)
            .then((json) => {
              GetWidgetData(
                res["Filter"],
                res["reports"],
                res["connection"],
                sessionId,
                isOtherUserPage,
                sharedUserEmail,
                isSharedFilter,
                sharedFilterUserId,
                filterId
              )
                .then((widgetDataResponse) => {
                  GetLastRefreshDateTime(res["reports"], "", sessionId)
                    .then((lstReponseDt) => {
                      props.onGetNotification(lstReponseDt);
                      const metaData = GridMetaData_Format(json);
                      const updatedMetadata = GetUpdatedColSettings(
                        metaData,
                        res
                      );
                      const colList = GetColumnList(
                        updatedMetadata,
                        res["finalcolumnlist"],
                        props.widgetId
                      );
                      setMetaDt(colList);

                      if (IsSharedFilterAlert(widgetDataResponse)) {
                        setScreenMsg(widgetDataResponse);
                        setShowNoData(true);
                        return false;
                      }

                      const getFormattedDt =
                        GetFormattedGridData(widgetDataResponse);
                      const getDateTimeConvertedDt = UpdatedWidgetData(
                        metaData,
                        getFormattedDt
                      );
                      setWidgetDt(getDateTimeConvertedDt);
                      if (getDateTimeConvertedDt.length === 0) {
                        setShowNoData(true);
                      }
                    })
                    .catch((err) => {
                      console.error("GetLastRefreshDateTime:-", err);
                    });
                })
                .catch((err) => {
                  console.error("GetWidgetData:-", err);
                });
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {});
    };

    if (sessionId !== "" && sessionId != null) {
      getWidgetSetting();
    }

    const refreshWidget = setInterval(() => {
      console.log("set Timout..", new Date().toLocaleTimeString());
      getWidgetSetting();
    }, TableWidgetRefreshTime);

    return () => {
      //clear setinterval on unmount component
      clearInterval(refreshWidget);
    };
  }, [sessionId, props.RefreshVersion]);

  return (
    <div>
      <TableTemplate
        metaData={metaDt}
        widgetData={widgetDt}
        widgetId={props.widgetId}
        settings={widgetSettings}
        wid={props.wid}
        displayMessage={screenMsg}
      />

      {widgetDt.length === 0 && showNoData === true && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "rgb(153, 153, 153)",
            fontSize: "17px",
          }}
        >
          {screenMsg}
        </div>
      )}
    </div>
  );
};

export default TableWidget;
