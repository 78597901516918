import { createSlice } from "@reduxjs/toolkit";

const initialPageDetails = {
  isPageListOpen: false,
  isAddPageOpen: false,
  isEditPageOpen: false,
  isClonePageOpen: false,
  editPageId: false,
  clonePageId: "",
  EditWidgets: [],
  isLoadPage: false,
  loadPageId: "",
  isCreateVideoFeedUrlFormOpen: false,
  rtspUrlId: "",
  rtspUrl: "",
  rtspDtName: "",
  rotationPageId: "",
  xAxisValBubbleChart: 0,
  exportExcelDetails: {
    dataSource: "",
    connectionName: "",
    filterId: "",
    filterName: "",
    isSharedFilter: "",
    sharedFilterUserId: "",
  },
};
const pageSlice = createSlice({
  name: "page",
  initialState: initialPageDetails,
  reducers: {
    triggerPageList(state) {
      state.isPageListOpen = !state.isPageListOpen;
    },
    closePageList(state) {
      state.isPageListOpen = false;
    },
    triggerAddPage(state) {
      state.isAddPageOpen = !state.isAddPageOpen;
    },
    triggerEditPage(state) {
      state.isEditPageOpen = !state.isEditPageOpen;
    },
    setEditPageId(state, action) {
      state.editPageId = action.payload;
    },
    setEditWidgets(state, action) {
      state.EditWidgets = action.payload;
    },
    triggerClonePage(state) {
      state.isClonePageOpen = !state.isClonePageOpen;
    },
    setClonePageId(state, action) {
      state.clonePageId = action.payload;
    },
    setLoadPageId(state, action) {
      state.loadPageId = action.payload;
    },
    setPageLoadEvent(state, action) {
      state.isLoadPage = !state.isLoadPage;
    },
    triggerCreateRstpUrl(state) {
      state.isCreateVideoFeedUrlFormOpen = !state.isCreateVideoFeedUrlFormOpen;
    },
    setRTSPUrlId(state, action) {
      state.rtspUrlId = action.payload.id;
      state.rtspUrl = action.payload.url;
    },
    setRtspDtName(state, action) {
      state.rtspDtName = action.payload;
    },
    setRotationPageId(state, action) {
      state.rotationPageId = action.payload;
    },
    setExportToExcelDetails(state, action) {
      state.exportExcelDetails = action.payload;
    },
    setXAxisValBubbleChart(state, action) {
      state.xAxisValBubbleChart = action.payload;
    },
  },
});

export const pageActions = pageSlice.actions;

export default pageSlice;
