import { useEffect, useRef, React } from "react";
import * as d3 from "d3";

const gauge = (h, w, configuration, number, actualVal, optimalVal, svgD3) => {
  //   let height = $(container).height(); //- 40;
  //   let width = $(container).width();
  let height = h; //- 40;
  let width = w;
  let circleRadius = Math.min(width, height) / 1.65;
  let that = {};
  let config = {
    minValue: 0,
    maxValue: 200,

    minAngle: -90,
    maxAngle: 90,

    transitionMs: 100,

    majorTicks: 3,
    labelInset: circleRadius / 5.3488,
    color: d3.scaleOrdinal().range(["#E55253", "#9AB57E", "#E8C267"]),
  };

  let tickdatas = [0, 67, 133, 200];
  function color2(value) {
    if (value <= tickdatas[1]) {
      return "#E55253";
    } else if (value <= tickdatas[2]) {
      return "#9AB57E";
    } else {
      return "#E8C267";
    }
  }
  let range, r;

  let value = 0;

  let svg, arc, scale, ticks, tickData, pointer, pointer2, pointer3;

  function deg2rad(deg) {
    return (deg * Math.PI) / 180;
  }

  function configure(configuration) {
    let prop;
    for (prop in configuration) {
      config[prop] = configuration[prop];
    }

    range = config.maxAngle - config.minAngle;
    r = circleRadius;

    scale = d3
      .scaleLinear()
      .range([0, 1])
      .domain([config.minValue, config.maxValue]);

    tickData = d3.range(config.majorTicks).map(function() {
      return 1 / config.majorTicks;
    });
    arc = d3
      .arc()
      .innerRadius(r * 0.75)
      .outerRadius(r * 0.8)
      .startAngle(function(d, i) {
        let ratio = d * i;
        return deg2rad(config.minAngle + ratio * range) * 1.5;
      })
      .endAngle(function(d, i) {
        let ratio = d * (i + 1);
        return deg2rad(config.minAngle + ratio * range) * 1.5;
      });
  }
  that.configure = configure;

  function centerTranslation() {
    return "translate(" + width / 2 + "," + height / 1.84 + ")";
  }

  function render(newValue) {
    //let elementId = "#" + container.attr("id");
    // svg = d3
    //   .select(elementId)
    //   .append("svg")
    //   .attr("class", "gauge")
    //   .attr("width", width)
    //   .attr("height", height);

    svg = svgD3;

    let centerTx = centerTranslation();

    let arcs = svg
      .append("g")
      .attr("class", "arc")
      .attr("transform", centerTx);

    arcs
      .selectAll("path")
      .data(tickData)
      .attr("id", "yyy")
      .enter()
      .append("path")
      .attr("fill", function(d, i) {
        return config.color(i);
      })
      .attr("d", arc);

    let lg = svg
      .append("g")
      .attr("class", "label")
      .attr("transform", centerTx);

    lg.append("text")
      .attr("x", -circleRadius / 1.4375)
      .attr("y", circleRadius / 1.5353)
      .text(tickdatas[0] + "%")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#767676")
      .style(
        "font-family",
        "'Segoe UI','Helvetica Neue','Trebuchet MS',Verdana"
      )
      .style("font-weight", "400")
      .style("cursor", "default");

    lg.append("text")
      .attr("x", -circleRadius / 1.4375)
      .attr("y", -circleRadius / 1.5353)
      .text(tickdatas[1] + "%")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#767676")
      .style(
        "font-family",
        "'Segoe UI','Helvetica Neue','Trebuchet MS',Verdana"
      )
      .style("font-weight", "400")
      .style("cursor", "default");

    lg.append("text")
      .attr("x", circleRadius / 1.4375)
      .attr("y", -circleRadius / 1.5353)
      .text(tickdatas[2] + "%") //-248 210
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#767676")
      .style(
        "font-family",
        "'Segoe UI','Helvetica Neue','Trebuchet MS',Verdana"
      )
      .style("font-weight", "400")
      .style("cursor", "default");

    lg.append("text")
      .attr("x", circleRadius / 1.4375)
      .attr("y", circleRadius / 1.5353)
      .text(tickdatas[3] + "%")
      .style("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "#767676")
      .style(
        "font-family",
        "'Segoe UI','Helvetica Neue','Trebuchet MS',Verdana"
      )
      .style("font-weight", "400")
      .style("cursor", "default");
    //-248 210

    lg = svg
      .append("g")
      .attr("class", "labl")
      .attr("transform", centerTx);
    lg.selectAll("lne")
      .data(tickdatas)
      .enter()
      .append("line")
      .attr("x1", circleRadius / 145.93)
      .attr("y1", circleRadius / 47.5) //-262 264
      .attr("x2", circleRadius / 145.93)
      .attr("y2", -circleRadius / 23.75)
      .attr("transform", function(d) {
        let ratio = scale(d);
        let newAngle = config.minAngle + ratio * range;
        return (
          "rotate(" +
          (Math.round(newAngle * 1.5) - 0.5) +
          ") translate(0," +
          (config.labelInset - r + circleRadius / 23) +
          ")"
        ); //13
      })
      .attr("fill", "#ffffff")
      .style("stroke", "white")
      .style("stroke-width", "1.5")
      .style("stroke-opacity", "1");

    let txt = "Optimal: 80";
    let y = circleRadius / 2.52886;
    lg = svg.append("g").attr("class", "labl");

    lg.append("text")
      .attr("x", -circleRadius / 68.35)
      .attr("y", function(d) {
        return y + circleRadius / 16;
      }) //-10 40
      .text(actualVal)
      .attr("transform", centerTx)
      .attr("fill", color2(number))
      .style("text-anchor", "middle")
      .style("font-size", circleRadius / 4.6 + "px")
      .style(
        "font-family",
        "'Segoe UI','Helvetica Neue','Trebuchet MS',Verdana"
      )
      .style("font-weight", "400")
      .style("cursor", "default");

    lg.append("text")
      .attr("x", -circleRadius / 115)
      .attr("y", function(d) {
        return y + circleRadius / 6;
      }) //-2 20  //15px
      .text(optimalVal)
      .attr("transform", centerTx)
      .attr("fill", "#9AB57E")
      .style("text-anchor", "middle")
      .style("font-size", circleRadius / 13 + "px") // circleRadius / 9
      .style(
        "font-family",
        "'Segoe UI','Helvetica Neue','Trebuchet MS',Verdana"
      )
      .style("font-weight", "400")
      .style("cursor", "default");

    let number2 = number;
    if (number2 < config.minValue) {
      number2 = config.minValue;
    }
    if (number2 > config.maxValue) {
      number2 = config.maxValue;
    }
    let ratio = scale(parseInt(number2) + 2) + 0.015;
    let newAngle = config.minAngle + ratio * range;
    let pg = svg
      .append("g")
      .attr("class", "pointer")
      .attr("transform", centerTx);

    pointer = pg
      .append("line")
      .style("stroke-width", "4")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", -circleRadius / 38.33)
      .attr("y2", -circleRadius / 2.875)
      .attr("transform", "rotate(" + newAngle * 1.499 + ")")
      .attr("stroke", "#c2c2c2")
      .style("opacity", 0.8);

    let pg2 = svg
      .append("g")
      .attr("class", "pointer2")
      .attr("transform", centerTx);
    pointer2 = pg2
      .append("line")
      .style("stroke-width", "4")
      .attr("x1", -circleRadius / 38.33)
      .attr("y1", -circleRadius / 2.875)
      .attr("x2", -circleRadius / 20.72)
      .attr("y2", -circleRadius / 1.4375)
      .attr("transform", "rotate(" + newAngle * 1.499 + ")")
      .attr("stroke", color2(number)); //.style("opacity", 0.56);

    let pg3 = svg
      .append("g")
      .attr("class", "pointer3")
      .attr("transform", centerTx);
    pointer3 = pg3
      .append("line")
      .style("stroke-width", "4")
      .attr("x1", -circleRadius / 38.33)
      .attr("y1", -circleRadius / 2.875)
      .attr("x2", -circleRadius / 37.76)
      .attr("y2", -circleRadius / 2.82)
      .attr("transform", "rotate(" + newAngle * 1.499 + ")")
      .attr("stroke", "white")
      .style("opacity", 1);

    svg
      .append("circle")
      .attr("class", "pointer")
      .attr("r", 6)
      .attr("stroke", "#c2c2c2")
      .attr("fill", "none")
      .attr("stroke-width", "2")
      .attr("transform", centerTx);
    svg
      .append("circle")
      .attr("class", "pointer")
      .attr("r", 5)
      .attr("fill", "#ffffff")
      .attr("transform", centerTx);
  }
  that.render = render;

  function update(newValue, newConfiguration) {
    if (newConfiguration !== undefined) {
      configure(newConfiguration);
    }
    if (newValue >= config.minValue && newValue <= config.maxValue) {
      let ratio = scale(newValue) + 0.015;
      let newAngle = config.minAngle + ratio * range;
      pointer
        .transition()
        .duration(config.transitionMs)
        .ease(d3.easeElastic)
        .attr("transform", "rotate(" + newAngle * 1.499 + ")");
      pointer2
        .transition()
        .duration(config.transitionMs)
        .ease(d3.easeElastic)
        .attr("transform", "rotate(" + newAngle * 1.499 + ")");
      pointer3
        .transition()
        .duration(config.transitionMs)
        .ease(d3.easeElastic)
        .attr("transform", "rotate(" + newAngle * 1.499 + ")");
    }
    if (newValue < config.minValue) {
      newValue = config.minValue;
      let ratio = scale(newValue) + 0.015;
      let newAngle = config.minAngle + ratio * range;
      pointer
        .transition()
        .duration(config.transitionMs)
        .ease(d3.easeElastic)
        .attr("transform", "rotate(" + newAngle * 1.499 + ")");
      pointer2
        .transition()
        .duration(config.transitionMs)
        .ease(d3.easeElastic)
        .attr("transform", "rotate(" + newAngle * 1.499 + ")");
      pointer3
        .transition()
        .duration(config.transitionMs)
        .ease(d3.easeElastic)
        .attr("transform", "rotate(" + newAngle * 1.499 + ")");
    }
    if (newValue > config.maxValue) {
      newValue = config.maxValue;
      let ratio = scale(newValue) + 0.015;
      let newAngle = config.minAngle + ratio * range;
      pointer
        .transition()
        .duration(config.transitionMs)
        .ease(d3.easeElastic)
        .attr("transform", "rotate(" + newAngle * 1.499 + ")");
      pointer2
        .transition()
        .duration(config.transitionMs)
        .ease(d3.easeElastic)
        .attr("transform", "rotate(" + newAngle * 1.499 + ")");
      pointer3
        .transition()
        .duration(config.transitionMs)
        .ease(d3.easeElastic)
        .attr("transform", "rotate(" + newAngle * 1.499 + ")");
    }
  }
  that.update = update;
  configure(configuration);
  return that;
};

const QueueSizeGaugeD3Component = (props) => {
  const d3Container = useRef(null);
  useEffect(() => {
    props.getWidgetSize();
    if (
      props.data !== undefined &&
      d3Container.current &&
      props.data.needleVal >= 0
    ) {
      const svg = d3.select(d3Container.current);
      svg.selectAll("*").remove();
      const w = props.width;
      const h = props.height;
      const guageD3 = gauge(
        h,
        w,
        { transitionMs: 4000 },
        props.data.needleVal,
        props.data.actual,
        "Optimal: " + props.data.optimal,
        svg
      );
      guageD3.render();
      guageD3.update(props.data.needleVal);
    }
  }, [props.data, props.width, props.height, props.wid]);

  return (
    <svg ref={d3Container} width={props.width} height={props.height}></svg>
  );
};

export default QueueSizeGaugeD3Component;
