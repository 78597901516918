import { Layout_Key, Twitter_Api } from "../../../../Constants";

const GetTwitterFeed = (query) => {
  let url = `${Twitter_Api}?q=${query}&count=10`;
  return fetch(url).then((response) => response.json());
};

const twitterLinks = (text) => {
  var base_url = "https://twitter.com/"; // identica: 'http://identi.ca/'
  var hashtag_part = "search?q=#"; // identica: 'tag/'
  // convert URLs into links
  text = text.replace(
    /(>|<a[^<>]+href=['"])?(https?:\/\/([-a-z0-9]+\.)+[a-z]{2,5}(\/[-a-z0-9!#()\/?&.,]*[^ !#?().,])?)/gi,
    function ($0, $1, $2) {
      return $1 ? $0 : '<a href="' + $2 + '" target="_blank">' + $2 + "</a>";
    }
  );
  // convert protocol-less URLs into links
  text = text.replace(
    /(:\/\/|>)?\b(([-a-z0-9]+\.)+[a-z]{2,5}(\/[-a-z0-9!#()\/?&.]*[^ !#?().,])?)/gi,
    function ($0, $1, $2) {
      return $1 ? $0 : '<a href="https://' + $2 + '">' + $2 + "</a>";
    }
  );
  // convert @mentions into follow links
  text = text.replace(
    /(:\/\/|>)?(@([_a-z0-9\-]+))/gi,
    function ($0, $1, $2, $3) {
      return $1
        ? $0
        : '<a href="' +
            base_url +
            $3 +
            '" title="Follow ' +
            $3 +
            '" target="_blank">@' +
            $3 +
            "</a>";
    }
  );
  // convert #hashtags into tag search links
  text = text.replace(
    /(:\/\/[^ <]*|>)?(\#([_a-z0-9\-]+))/gi,
    function ($0, $1, $2, $3) {
      return $1
        ? $0
        : '<a href="' +
            base_url +
            hashtag_part +
            $3 +
            '" title="Search tag: ' +
            $3 +
            '" target="_blank">#' +
            $3 +
            "</a>";
    }
  );
  return text;
};

const GetDateFormat = (tweetDate) => {
  tweetDate = new Date(tweetDate);
  let date_now = new Date();
  let seconds = Math.floor((date_now - tweetDate) / 1000);

  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  let days = Math.floor(hours / 24);

  hours = hours - days * 24;

  minutes = minutes - days * 24 * 60 - hours * 60;

  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  if (days > 0) {
    let month = new Array();
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";

    return tweetDate.getDate() + month[tweetDate.getMonth()];
  } else if (hours > 0) {
    return hours + "h";
  } else {
    return minutes + "m";
  }
};

const GetTweetData = (items) => {
  let id_str;
  let screenName;
  let text;
  let profileImage;
  let createdDate;

  let tweetDt = [];

  for (let index = 0; index < items.length; index++) {
    const tweetJson = items[index];
    if (tweetJson.retweeted_status == null) {
      id_str = tweetJson.id_str;
      screenName = tweetJson.user.screen_name;
      text = twitterLinks(tweetJson.text);
      profileImage = tweetJson.user.profile_image_url;

      createdDate = GetDateFormat(tweetJson.created_at);
    } else {
      id_str = tweetJson.retweeted_status.id_str;
      screenName = tweetJson.retweeted_status.user.screen_name;
      text = twitterLinks(tweetJson.retweeted_status.text);
      profileImage = tweetJson.user.profile_image_url;

      createdDate = GetDateFormat(tweetJson.created_at);
    }

    profileImage = profileImage.replace("http://", "https://");
    tweetDt.push({
      id_str: id_str,
      screenName: screenName,
      text: text,
      createdDate: createdDate.toLocaleString(),
      profileImage: profileImage,
    });
  }
  return tweetDt;
};

const getWidgetDataById = (widgetId) => {
  let data = getLocalStorageLayout().Widgets;
  for (var i = 0; i < data.length; i++) {
    if (data[i].Id === widgetId) {
      return data[i];
    }
  }
  return null;
};

const getLocalStorageLayout = () => {
  return JSON.parse(localStorage.getItem(Layout_Key));
};

export default GetTwitterFeed;
export const GetTweets = GetTweetData;
export const GetWidgetDataById = getWidgetDataById;
