import * as d3 from "d3";
import { useEffect, useRef, React } from "react";
import { useSelector } from "react-redux";

const BubbleAnimation = (svgElement, theme, h, w) => {
  let svg = svgElement;
  let width = w; //document.getElementById(id).clientWidth;
  let height = h; //document.getElementById(id).clientHeight;
  let max = Math.max(width, height);

  // Random Position Data
  let data = [];
  for (let i = 0; i < 50; i++) {
    data.push({
      cx: Math.random() * width + 1,
      cy: Math.random() * height + 1,
      r: (Math.random() * max) / 40 + max / 20,
      opacity: 0.6,
    });
  }

  // Background
  let background = svg
    .append("rect")
    .attr("width", width)
    .attr("height", height);

  //for set buuble color for theme
  let bubblecolor = "#A0A0A0";
  let bgcolor1 = "#E7E9ED";
  let bgcolor2 = "#CFD1D4";
  let bgcolor3 = "#E7E9ED";
  if (theme == "Light") {
    //bubblecolor = "#A0A0A0";
    bubblecolor = "#e7e7e7";
    bgcolor1 = "#FFFFFF";
    bgcolor2 = "#FFFFFF";
    bgcolor3 = "#FFFFFF";
  } else {
    //8a8888
    // bubblecolor = "#6C6A6A";
    bubblecolor = "#383838";
    bgcolor1 = "#262626";
    bgcolor2 = "#262626";
    bgcolor3 = "#262626";
  }

  // Draw Circle
  let bubbles = svg
    .selectAll(".bubble")
    .data(data)
    .enter()
    .append("circle")
    .attr("r", function(d) {
      return d.r;
    })
    .style("fill", bubblecolor)
    .style("opacity", function(d) {
      return d.opacity;
    });

  const animateBackground = () => {
    background
      //.style('fill', '#626567')
      .style("fill", bgcolor1)
      .transition()
      .duration(4000)
      .style("fill", bgcolor2)
      .transition()
      .duration(4000)
      .style("fill", bgcolor3)
      .on("end", animateBackground);
  };

  setInterval(() => {
    animateBubbles();
  }, 10000);

  const animateBubbles = () => {
    bubbles
      .attr("cx", function(d) {
        return d.cx;
      })
      .attr("cy", function(d) {
        return d.cy;
      })
      .transition()
      .ease(d3.easeLinear)
      .duration(10000)
      .attr("cx", function(d) {
        let dx = Math.random() > 0.5 ? 1 : -1;
        let speed = 40 + Math.random() * 40;
        if (d.cx > width) {
          dx = -1;
        }
        if (d.cx < 0) {
          dx = 1;
        }
        d.cx += speed * dx;
        return d.cx;
      })
      .attr("cy", function(d) {
        let dy = Math.random() > 0.5 ? 1 : -1;
        let speed = 40 + Math.random() * 100;
        if (d.cy > height) {
          dy = -1;
        }
        if (d.cy < 0) {
          dy = 1;
        }
        d.cy += speed * dy;
        return d.cy;
      });
  };

  animateBackground();
  animateBubbles();
};

const BubbleD3Component = (props) => {
  const d3Container = useRef(null);
  const getTheme = useSelector((state) => state.layout.theme);
  useEffect(() => {
    const svg = d3.select(d3Container.current);
    svg.selectAll("*").remove();
    BubbleAnimation(svg, getTheme, props.height, props.width);
  }, [props.width, props.height, getTheme]);

  return (
    <svg
      ref={d3Container}
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: "10",
        top: "0",
      }}
    ></svg>
  );
};

export default BubbleD3Component;
