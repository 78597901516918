import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextNumericRefreshTime } from "../../../../Constants";
import {
  GetLastRefreshDateTime,
  GetWidgetData,
  GetWidgetSettings,
} from "../../../../Helper/Services";
import {
  GetFormattedGridData,
  IsSharedFilterAlert,
} from "../../../../Helper/WidgetDataCommon";
import NoShowDiv from "../../../UI/NoShowTemplate";
import GetResult from "../PieChartWidget/PieChartWidgetService";
import TextNumericD3Component from "./TextNumericD3Component";
import GetTextNumericData from "./TextNumericWidgetService";

const TextWidget = (props) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const isOtherUserPage = useSelector((state) => state.layout.IsOtherUserPage);
  const sharedUserEmail = useSelector((state) => state.layout.SharedUserEmail);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [widgetDt, setWidgetDt] = useState({});
  const [screenMsg, setScreenMsg] = useState("No Data");
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    const getWidgetSetting = () => {
      let isSharedFilter = false;
      let sharedFilterUserId = "";
      let filterId = "";

      GetWidgetSettings(props.widgetId)
        .then((res) => {
          setShowNoData(false);
          if (
            res["IsSharedFilter"] !== undefined &&
            res["IsSharedFilter"] == "true"
          ) {
            isSharedFilter = true;
            sharedFilterUserId = res["OriginalUserId"];
            filterId = res["FilterId"];
          }

          if (res["reports"] !== "") {
            GetWidgetData(
              res["Filter"],
              res["reports"],
              res["connection"],
              sessionId,
              isOtherUserPage,
              sharedUserEmail,
              isSharedFilter,
              sharedFilterUserId,
              filterId
            )
              .then((widgetDataResponse) => {
                GetLastRefreshDateTime(res["reports"], "", sessionId)
                  .then((lstReponseDt) => {
                    props.onGetNotification(lstReponseDt);
                    if (IsSharedFilterAlert(widgetDataResponse)) {
                      setScreenMsg(widgetDataResponse);
                      setShowNoData(true);
                      return false;
                    }
                    const getFormattedDt =
                      GetFormattedGridData(widgetDataResponse);

                    const getWidgetDt = GetTextNumericData(getFormattedDt, res);
                    setWidgetDt(getWidgetDt);
                  })
                  .catch((err) => {
                    console.error("GetLastRefreshDateTime:-", err);
                  });
              })
              .catch((err) => {
                console.error("GetWidgetData:-", err);
              });
          } else {
            setWidgetDt({
              color: res.textcolor,
              content: res.subsectiontitle,
              align: res.justification,
            });
          }
        })
        .catch((err) => {
          console.log("getWidget settings:-", err);
        });
    };

    if (sessionId !== "" && sessionId != null) {
      getWidgetSetting();
      GetWidgetSize();
    }

    window.addEventListener("resize", () => {
      GetWidgetSize();
    });

    const refreshWidget = setInterval(() => {
      console.log("text set Timout..", new Date().toLocaleTimeString());
      getWidgetSetting();
    }, TextNumericRefreshTime);

    return () => {
      //clear setinterval on unmount component
      clearInterval(refreshWidget);
    };
  }, [sessionId, props.RefreshVersion]);

  const GetWidgetSize = () => {
    const getParentContainer = document.getElementById(
      "content_" + props.widgetId
    );
    if (getParentContainer !== undefined && getParentContainer != null) {
      const getHeight = getParentContainer.clientHeight;
      const getWidth = getParentContainer.clientWidth;
      setHeight(getHeight);
      setWidth(getWidth);
    }
  };

  return (
    <React.Fragment>
      {showNoData === false && (
        <div style={{ width: "100%", height: "100%" }}>
          <TextNumericD3Component
            height={height}
            width={width}
            getWidgetSize={GetWidgetSize}
            align={widgetDt.align}
            content={widgetDt.content}
            color={widgetDt.color}
            widgetId={props.widgetId}
            wid={props.wid}
          />
        </div>
      )}

      {showNoData === true && <NoShowDiv screenMsg={screenMsg} />}
    </React.Fragment>
  );
};

export default TextWidget;
