import { React } from "react";
export const DeleteButton = ({ isDisable, onClickHandler }) => {
  let stylesCss = { fontSize: "18px", color: "gray", textDecoration: "none" };
  if (isDisable) {
    stylesCss = {
      fontSize: "18px",
      color: "gray",
      textDecoration: "none",
      cursor: "not-allowed",
    };
  }
  return (
    <a
      href=""
      className="icons_cls"
      title="Delete"
      onClick={onClickHandler}
      style={stylesCss}
    >
      &nbsp;<i className="fa fa-trash-o" aria-hidden="true"></i>
    </a>
  );
};
