import { useState, React } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  GetColorPallets,
  GetLastRefreshDateTime,
  GetWidgetData,
  GetWidgetSettings,
} from "../../../../Helper/Services";
import GetResult from "./PieChartWidgetService";
import {
  GetFormattedGridData,
  IsSharedFilterAlert,
} from "./../../../../Helper/WidgetDataCommon";
import PieChartComponent from "./PieChartComponent";
import NoShowDiv from "../../../UI/NoShowTemplate";
import { PieChartWidgetRefreshTime } from "../../../../Constants";

const PieChartWidget = (props) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const isOtherUserPage = useSelector((state) => state.layout.IsOtherUserPage);
  const sharedUserEmail = useSelector((state) => state.layout.SharedUserEmail);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [widgetDt, setWidgetDt] = useState([]);
  const [colorPallets, setColorPallets] = useState([]);
  const [showCallOut, setShowCallOut] = useState(true);
  const [showPercentage, setShowPercentage] = useState(false);
  const [showAbsolute, setShowAbsolute] = useState(false);
  const [screenMsg, setScreenMsg] = useState("No Data");
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    const getWidgetSetting = () => {
      let isSharedFilter = false;
      let sharedFilterUserId = "";
      let filterId = "";

      GetWidgetSettings(props.widgetId)
        .then((res) => {
          setShowNoData(false);
          if (
            res["IsSharedFilter"] !== undefined &&
            res["IsSharedFilter"] == "true"
          ) {
            isSharedFilter = true;
            sharedFilterUserId = res["OriginalUserId"];
            filterId = res["FilterId"];
          }

          let callout = "";
          if (res.callout != undefined) {
            callout = res.callout;
          }
          if (callout == 2) {
            setShowPercentage(false);
            setShowCallOut(true);
          } else if (callout == 3) {
            setShowPercentage(true);
            setShowCallOut(true);
          } else {
            setShowCallOut(false);
          }

          // GetMetaData(res["reports"], "", sessionId)
          //   .then((json) => {
          GetWidgetData(
            res["Filter"],
            res["reports"],
            res["connection"],
            sessionId,
            isOtherUserPage,
            sharedUserEmail,
            isSharedFilter,
            sharedFilterUserId,
            filterId
          )
            .then((widgetDataResponse) => {
              GetLastRefreshDateTime(res["reports"], "", sessionId)
                .then((lstReponseDt) => {
                  GetColorPallets(res["reports"], res["category"], sessionId)
                    .then((colorsResp) => {
                      props.onGetNotification(lstReponseDt);
                      if (IsSharedFilterAlert(widgetDataResponse)) {
                        setScreenMsg(widgetDataResponse);
                        setShowNoData(true);
                        return false;
                      }
                      const getFormattedDt = GetFormattedGridData(
                        widgetDataResponse
                      );

                      const widgetData = GetResult(res, getFormattedDt);
                      setWidgetDt(widgetData);

                      var colorsPallet = [];
                      if (colorsResp != null && colorsResp.length > 0) {
                        colorsPallet = widgetData.map((item) => {
                          var getColor = colorsResp.find((cls) => {
                            return cls.Value === item.name;
                          });
                          if (getColor != null) {
                            return getColor.Colour;
                          }
                        });
                        setColorPallets(colorsPallet);
                      }

                      if (widgetData.length === 0) {
                        setShowNoData(true);
                      }
                    })
                    .catch((err) => {
                      console.error("GetColorPallets:-", err);
                    });
                })
                .catch((err) => {
                  console.error("GetLastRefreshDateTime:-", err);
                });
            })
            .catch((err) => {
              console.error("GetWidgetData:-", err);
            });
          // })
          // .catch((err) => {
          //   console.error(err);
          // });
        })
        .catch((err) => {});
    };

    if (sessionId !== "" && sessionId != null) {
      getWidgetSetting();
      GetWidgetSize();
    }

    window.addEventListener("resize", () => {
      GetWidgetSize();
    });

    const refreshWidget = setInterval(() => {
      console.log("Pie set Timout..", new Date().toLocaleTimeString());
      getWidgetSetting();
    }, PieChartWidgetRefreshTime);

    return () => {
      //clear setinterval on unmount component
      clearInterval(refreshWidget);
    };
  }, [sessionId, props.RefreshVersion]);

  const GetWidgetSize = () => {
    const getParentContainer = document.getElementById(
      "content_" + props.widgetId
    );
    if (getParentContainer !== undefined && getParentContainer != null) {
      const getHeight = getParentContainer.clientHeight;
      const getWidth = getParentContainer.clientWidth;
      setHeight(getHeight);
      setWidth(getWidth);
    }
  };

  return (
    <div>
      {widgetDt.length > 0 && showNoData === false && (
        <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
          <PieChartComponent
            data={widgetDt}
            colorPallets={colorPallets}
            height={height}
            width={width}
            getWidgetSize={GetWidgetSize}
            showCallOut={showCallOut}
            showPercentage={showPercentage}
            widgetId={props.widgetId}
            wid={props.wid}
          />
        </div>
      )}

      {widgetDt.length === 0 && showNoData === true && (
        <NoShowDiv screenMsg={screenMsg} />
      )}
    </div>
  );
};

export default PieChartWidget;
