import {
  Get_Deleted_Pages,
  Get_Deleted_Rotation,
  Get_Deleted_Widgets,
  Get_New_Filters_Deleted_List,
  IS_TESTAPI_SERVICE_URL_Enabled,
  Is_Deleted_Widget_Exists,
  LOGIN_Detail_URL,
  Restore_Delete_Page,
  Restore_Deleted_Rotation,
  Restore_Deleted_Widget,
  Restore_New_Filter,
} from "../../../../Constants";

const getDeletedPages = (sessionId) => {
  const url = `${Get_Deleted_Pages}/${sessionId}/${IS_TESTAPI_SERVICE_URL_Enabled}`;
  return fetch(url).then((response) => response.json());
};

const restoreDeletePage = (pageId) => {
  let url = `${Restore_Delete_Page}?pageId=${pageId}`;
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response.text();
  });
};

const getPages = (sessionId) => {
  let url = `${LOGIN_Detail_URL}?SessionId=${sessionId}&PageId=&edit=0&isTestApi=${IS_TESTAPI_SERVICE_URL_Enabled}`;
  return fetch(url, {
    method: "GET",
  }).then((response) => {
    return response.json();
  });
};

const getDeletedWidgets = (sessionId) => {
  let url = `${Get_Deleted_Widgets}/${sessionId}/${IS_TESTAPI_SERVICE_URL_Enabled}`;
  return fetch(url, {
    method: "GET",
  }).then((response) => {
    return response.json();
  });
};

const getDeletedRotations = (sessionId) => {
  let url = `${Get_Deleted_Rotation}/${sessionId}/${IS_TESTAPI_SERVICE_URL_Enabled}`;
  return fetch(url, {
    method: "GET",
  }).then((response) => {
    return response.json();
  });
};

const restoreWidget = (id) => {
  let url = `${Restore_Deleted_Widget}/${id}`;
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response.json();
  });
};

const restoreRotation = (id) => {
  let url = `${Restore_Deleted_Rotation}/${id}`;
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response.json();
  });
};

const isAlreadyWidgetExists = (id) => {
  let url = `${Is_Deleted_Widget_Exists}/${id}`;
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    return response.text();
  });
};

const getDeletedFilter = (
  datasourceName = "STAS Case Register",
  domain,
  sessionId
) => {
  let url = `${Get_New_Filters_Deleted_List}/${domain}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: sessionId,
    },
  }).then((response) => response.json());
};

const restoreFilter = (id, sessionId) => {
  let url = `${Restore_New_Filter}/${id}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: sessionId,
    },
  }).then((response) => {
    if (response.ok) {
      return Promise.resolve();
    }

    return response.text().then((text) => {
      throw new Error(text);
    });
  });
};

const RecycleBinService = {
  getDeletedPages,
  restoreDeletePage,
  getPages,
  getDeletedWidgets,
  restoreWidget,
  isAlreadyWidgetExists,
  getDeletedRotations,
  restoreRotation,
  getDeletedFilter,
  restoreFilter,
};

export default RecycleBinService;
