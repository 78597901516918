import WidgetButtonControl from "../../../../../UI/Buttons/WidgetButton";
import { pageActions } from "../../../../../../store/page-slice";
import ModalOptional from "../../../../../UI/ModalOptional";
import { useDispatch } from "react-redux";
import { useState, React } from "react";
import { SaveVideoFeedUrl } from "../../../../../../Helper/Services";
import { useSelector } from "react-redux";

const CreateVideoFeedRTSP = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.login.userDetail.email);
  const sessionId = useSelector((state) => state.login.sessionId);
  const datasource = useSelector((state) => state.page.rtspDtName);
  const [rtspUrl, setRtspUrl] = useState("");
  const [isRtspUrlValid, setIsRtspUrlValid] = useState(true);
  const closePopupHandler = () => {
    dispatch(pageActions.triggerCreateRstpUrl());
  };

  const onSaveHandler = () => {
    if (rtspUrl === "") {
      setIsRtspUrlValid(false);
      setTimeout(() => {
        setIsRtspUrlValid(true);
      }, 5000);
    }

    if (rtspUrl !== "") {
      SaveVideoFeedUrl(datasource, rtspUrl, email, sessionId)
        .then((response) => {
          if (response) {
            dispatch(
              pageActions.setRTSPUrlId({
                id: response.id,
                url: response.streamUrl,
              })
            );
            dispatch(pageActions.triggerCreateRstpUrl());
          }
        })
        .catch((err) => {});
    }
  };

  const onChangeRtspUrl = (event) => {
    setRtspUrl(event.target.value);
  };

  return (
    <ModalOptional
      title="Add RTSP URL"
      onClose={closePopupHandler}
      width={{ width: "510px" }}
    >
      <div style={{ width: "300px" }}>
        <p>RTSP Url</p>
        <input
          type="text"
          style={{ width: "400px" }}
          value={rtspUrl}
          onChange={onChangeRtspUrl}
        />
        {!isRtspUrlValid && (
          <span style={{ color: "red" }}>Please enter RTSP url</span>
        )}
      </div>
      <div style={{ paddingTop: "15px", paddingBottom: "10px" }}>
        <WidgetButtonControl
          title="Save"
          cssStyle={{ marginLeft: "3px" }}
          onClick={onSaveHandler}
        />
        <WidgetButtonControl
          title="Close"
          cssStyle={{ marginLeft: "3px" }}
          onClick={closePopupHandler}
        />
      </div>
    </ModalOptional>
  );
};

export default CreateVideoFeedRTSP;
