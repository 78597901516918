import React from "react";
import loadImg from "./../../assets/Loading_icon.gif";

const Loader = ({ styles }) => {
  return (
    <center>
      <img src={loadImg} style={styles} />
    </center>
  );
};

export default Loader;
