import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LOGOUT_URL } from "../../../../Constants";
import { loginActions } from "../../../../store/login-slice";
import classes from "./UserInfo.module.css";
import SharedService from "../../../../Helper/SharedService";
import { layoutActions } from "../../../../store/layout-slice";
import RotationServices from "../Rotation/RotationService";
import { pageActions } from "../../../../store/page-slice";

const UserInfo = (props) => {
  const user = useSelector((state) => state.login.userDetail);
  const isUserLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const sessionId = useSelector((state) => state.login.sessionId);
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = async (event) => {
    event.preventDefault();
    const resp = await fetch(`${LOGOUT_URL}/${user.email}/${sessionId}`);
    if (resp.ok) {
      SharedService.removeLocalStorageSessionId(sessionId);
    } else if (!resp.ok) {
      SharedService.removeLocalStorageSessionId(sessionId);
    }
    setTimeout(() => {
      dispatch(loginActions.logOut());
    }, 350);
    history.replace("/");
  };

  const configuratorHandler = (event) => {
    event.preventDefault();
    dispatch(layoutActions.setConfiguratorPopup());
  };

  const userName = isUserLoggedIn
    ? `${user.email}`
    : "Guest (thank you for visiting!)";
  return (
    <div
      className={`${classes["log-in-text"]} ${classes["dropdown"]}`}
      style={{ position: "absolute" }}
    >
      <span id="usenameDiv" className="">
        {userName}
      </span>
      {isUserLoggedIn && <b style={{ color: "#abc43a" }} className="caret"></b>}
      &nbsp;
      {!isUserLoggedIn && (
        <a
          id="lnk"
          data-toggle="dropdown"
          onClick={props.onShowLoginHandler}
          href=""
          style={{ color: "#abc43a", textDecoration: "none" }}
        >
          Login
        </a>
      )}
      {isUserLoggedIn && (
        <ul
          role="menu"
          className={`${classes["dropdown-menu"]} ${classes["dropdown-menu-right"]} ddl-menu-dark`}
        >
          <li>
            <a
              style={{ color: "#abc43a !important" }}
              href=""
              onClick={configuratorHandler}
            >
              Configurator
            </a>
          </li>
          <li className={classes["divider"]}></li>
          <li>
            <a
              style={{ color: "#abc43a !important" }}
              href=""
              onClick={(event) => {
                event.preventDefault();
                dispatch(layoutActions.setChangePasswordPopup());
              }}
            >
              Change Password
            </a>
          </li>
          <li className={classes["divider"]}></li>
          <li>
            <a
              style={{ color: "#abc43a !important" }}
              href=""
              onClick={(event) => {
                event.preventDefault();
                dispatch(layoutActions.setRecycleBinPopup());
              }}
            >
              Recycle Bin
            </a>
          </li>
          <li className={classes["divider"]}></li>
          <li>
            <a style={{ color: "#abc43a !important" }} href="" onClick={logout}>
              Logout
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default UserInfo;
