import Modal from "../../../UI/Modal";
import { useDispatch, useSelector } from "react-redux";
import { layoutActions } from "../../../../store/layout-slice";
import { useEffect, useState, useRef, React, useCallback } from "react";
import {
  Get_DataSources,
  Widget_Types,
  IS_TESTAPI_SERVICE_URL_Enabled,
} from "../../../../Constants";
import TwitterTemplate from "./ConfigTemplate/Twitter";
import CircularGuageTemplate from "./ConfigTemplate/CircularGuage";
import ReportTemplate from "./ConfigTemplate/Report";
import { loginActions } from "../../../../store/login-slice";
import OtccWidgetTemplate from "./ConfigTemplate/Otcc";
import PieChartTemplate from "./ConfigTemplate/PieChart";
import TextNumericTemplate from "./ConfigTemplate/TextNumeric";
import QueueSizeGaugeTemplate from "./ConfigTemplate/QueueSizeGuage";
import BarChartTemplate from "./ConfigTemplate/BarChart";
import VideoFeedTemplate from "./ConfigTemplate/VideoFeed";
import SAW2Template from "./ConfigTemplate/SAWV2";
import ComboBarLineTemplate from "./ConfigTemplate/ComboBarLine";
import ButtonControls from "../../../UI/TemplateControls/ButtonControls";
import {
  AddNewWidgetSettings,
  GetLocalStorageLayout,
  GetWidgetDataById,
  SetLocalStorageLayout,
  SetWidgetDataById,
} from "../../../../Helper/SaveSettings";
import Loader from "../../../UI/Loader";
import OpenOpportunitySummaryTemplate from "./ConfigTemplate/OOS";

const AddWidgetSettings = (props) => {
  const dispatch = useDispatch();
  const [widgetType, setWidgetType] = useState([]);
  const [widgetTypeSelection, setWidgetTypeSelection] = useState(0);
  const [widgetTypeName, setWidgetTypeName] = useState("");
  const sessionId = useSelector((state) => state.login.sessionId);
  const email = useSelector((state) => state.login.userDetail.email);
  const currentWidgetId = useSelector((state) => state.layout.selectedWidgetId);
  const [dtSources, setDtSources] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const ballistixUserId = useSelector((state) => state.layout.BallistixUserId);
  const childRef = useRef();

  const onCloseHandler = () => {
    dispatch(layoutActions.setAddWidgetPopup());
  };

  useEffect(() => {
    const getWidgetType = async () => {
      //set group type 1 when user loggedin else set 2
      let url = `${Widget_Types}?groupId=${1}`;
      if (ballistixUserId === 0) {
        url = `${Widget_Types}?groupId=${2}`;
      }
      const getWidgetTypes = await fetch(url);
      if (getWidgetTypes.ok) {
        const getResponse = await getWidgetTypes.json();
        setWidgetType(getResponse.Message.WidgetTypes);
        setIsLoading(false);
      }

      if (!getWidgetTypes.ok) setIsLoading(false);
    };

    const getDatasources = async () => {
      const url = `${Get_DataSources}?SessionId=${sessionId}&email=${email}&isTestApi=${IS_TESTAPI_SERVICE_URL_Enabled}`;
      const getDataSourceList = await fetch(url);
      if (getDataSourceList.ok) {
        const getResponse = await getDataSourceList.json();
        setDtSources(getResponse);
        dispatch(loginActions.setEncodedEmail(getResponse.EncodeEmail));
      }
    };
    getWidgetType();
    getDatasources();
  }, [email, sessionId, dispatch]);

  const onWidgetTypeHandler = (event) => {
    setWidgetTypeSelection(event.target.value);
    setWidgetTypeName(event.target.options[event.target.selectedIndex].text);
  };

  const OnSaveHandler = () => {
    const postDt = childRef.current.GetValidateData();
    if (postDt.formIsValid) {
      setIsLoading(true);
      if (ballistixUserId === 0) {
        let localData = GetLocalStorageLayout();
        let res = GetWidgetDataById(currentWidgetId, localData.Widgets);
        res.WidgetType = "Twitter";
        res.Type = "2";
        res.Twitter = postDt.data["feedType"] + postDt.data["TwitterId"];
        res.Title = postDt.data["Title"];
        res.ShortTitle = postDt.data["ShortTitle"];
        localData = SetWidgetDataById(currentWidgetId, localData, res);
        SetLocalStorageLayout(localData);
        dispatch(
          layoutActions.updateWidget({
            title: postDt.data.Title,
            shortTitle: postDt.data.ShortTitle,
            Type: parseInt(widgetTypeSelection),
            WidgetType: widgetTypeName,
            WidgetId: currentWidgetId,
            widgetTypeId: parseInt(widgetTypeSelection),
          })
        );
        dispatch(layoutActions.setAddWidgetPopup());
        setIsLoading(false);
      } else {
        AddNewWidgetSettings(currentWidgetId, widgetTypeSelection, postDt.data)
          .then((json) => {
            dispatch(
              layoutActions.updateWidget({
                title: postDt.data.Title,
                shortTitle: postDt.data.ShortTitle,
                Type: parseInt(widgetTypeSelection),
                WidgetType: widgetTypeName,
                WidgetId: currentWidgetId,
                widgetTypeId: parseInt(widgetTypeSelection),
              })
            );
            dispatch(layoutActions.setAddWidgetPopup());
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      }
    }
  };

  const onCloneWidgetHandler = useCallback(() => {
    dispatch(layoutActions.setCloneWidgetPopup());
  }, []);

  return (
    <Modal title="Widget Settings" onClose={onCloseHandler}>
      {!isLoading && (
        <div>
          <p>Widget Type</p>
          <select
            id="widgetSelect"
            onChange={onWidgetTypeHandler}
            value={widgetTypeSelection}
          >
            {widgetType.map((item) => {
              return (
                item.WidgetTypeId !== 6 && (
                  <option key={item.WidgetTypeId} value={item.WidgetTypeId}>
                    {item.Name}
                  </option>
                )
              );
            })}
          </select>
          <form className="settings">
            {widgetTypeSelection === "1" && (
              <CircularGuageTemplate dataSources={dtSources} ref={childRef} />
            )}
            {widgetTypeSelection === "2" && <TwitterTemplate ref={childRef} />}

            {widgetTypeSelection === "3" && (
              <ReportTemplate dataSources={dtSources} ref={childRef} />
            )}
            {widgetTypeSelection === "4" && (
              <OtccWidgetTemplate dataSources={dtSources} ref={childRef} />
            )}
            {widgetTypeSelection === "5" && (
              <PieChartTemplate dataSources={dtSources} ref={childRef} />
            )}
            {widgetTypeSelection === "7" && (
              <TextNumericTemplate dataSources={dtSources} ref={childRef} />
            )}
            {widgetTypeSelection === "8" && (
              <QueueSizeGaugeTemplate dataSources={dtSources} ref={childRef} />
            )}
            {widgetTypeSelection === "9" && (
              <BarChartTemplate dataSources={dtSources} ref={childRef} />
            )}

            {widgetTypeSelection === "10" && (
              <VideoFeedTemplate
                ref={childRef}
                sessionId={sessionId}
                dataSources={dtSources}
              />
            )}
            {widgetTypeSelection === "11" && (
              <SAW2Template dataSources={dtSources} ref={childRef} />
            )}

            {widgetTypeSelection === "12" && (
              <ComboBarLineTemplate dataSources={dtSources} ref={childRef} />
            )}

            {widgetTypeSelection === "13" && (
              <OpenOpportunitySummaryTemplate
                dataSources={dtSources}
                ref={childRef}
              />
            )}
            <br />
            <ButtonControls
              OnSaveHandler={OnSaveHandler}
              onCloseModal={onCloseHandler}
              onCloneWidgetModal={onCloneWidgetHandler}
            />
          </form>
        </div>
      )}

      {isLoading && <Loader styles={{ height: "80px" }} />}
    </Modal>
  );
};

export default AddWidgetSettings;
