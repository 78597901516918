import { ConvertToLocalDateTime } from "../../../../Helper/WidgetDataCommon";
import yellowImg from "../../../../assets/bullet_yellow.png";
import blackImg from "../../../../assets/bullet_black.png";
import greenImg from "../../../../assets/bullet_green.png";
import redImg from "../../../../assets/bullet_red.png";

const GetColumnList = (metaData, formattedColumns, widgetId) => {
  let gridColumnList = metaData;
  const getLocalStorageColumns = [];

  if (formattedColumns != undefined && formattedColumns != "") {
    const filterColumnList = JSON.parse(formattedColumns);
    const gridColumn = metaData;
    if (
      filterColumnList.length > 0 &&
      filterColumnList != null &&
      filterColumnList != undefined
    ) {
      gridColumnList = [];
      let sortedArr = filterColumnList.sort(function (a, b) {
        return a.index - b.index;
      });

      if (gridColumn.length > sortedArr.length) {
        var incremrentIdx = 0;
        for (var i = 0; i < gridColumn.length; i++) {
          const element = gridColumn[i];
          const getIndexOfItem = sortedArr.findIndex(
            (x) =>
              x.dataField.replace(/ /g, "_") ===
              element.dataField.replace(/ /g, "_")
          );

          //add new column if found
          if (getIndexOfItem === -1) {
            const newItem = { ...element, visible: true, index: i };
            sortedArr.splice(i, 0, newItem);
            sortedArr = sortedArr.map((x) => {
              if (x.index > i - 1) {
                x.index = x.index + incremrentIdx;
                incremrentIdx = 1;
              }
              return x;
            });
          }
        }
      }

      let storageItem = JSON.parse(
        localStorage.getItem("gridStorage" + widgetId)
      );

      if (
        storageItem != null &&
        storageItem != undefined &&
        storageItem != ""
      ) {
        for (let i = 0; i < sortedArr.length; i++) {
          for (let j = 0; j < storageItem.columns.length; j++) {
            if (
              sortedArr[i].dataField.replace(/_/g, " ") ===
              storageItem.columns[j].dataField
            ) {
              storageItem.columns[j].visibleIndex = sortedArr[i].index;
              storageItem.columns[j].visible = sortedArr[i].visible;

              if (storageItem.columns[j].width !== undefined) {
                sortedArr[i].width = storageItem.columns[j].width;
              }

              if (storageItem.columns[j].sortOrder !== undefined) {
                sortedArr[i].sortOrder = storageItem.columns[j].sortOrder;
              }
            }
          }
        }

        if (sortedArr.length !== storageItem.columns.length) {
          var arrList = Object.assign([], sortedArr);
          for (var j = 0; j < storageItem.columns.length; j++) {
            for (var i = 0; i < arrList.length; i++) {
              if (
                arrList[i].dataField.replace(/_/g, " ") ===
                storageItem.columns[j].dataField
              ) {
                if (storageItem.columns[j].sortOrder !== undefined) {
                  sortedArr[i].sortOrder = storageItem.columns[j].sortOrder;
                }

                if (storageItem.columns[j].width !== undefined) {
                  sortedArr[i].width = storageItem.columns[j].width;
                }
              }
            }
          }
          storageItem = { columns: arrList };
        }

        localStorage.setItem(
          "gridStorage" + widgetId,
          JSON.stringify(storageItem)
        );
      } else {
        var colList = { columns: filterColumnList };
        localStorage.setItem("gridStorage" + widgetId, JSON.stringify(colList));
      }

      for (var i = 0; i < sortedArr.length; i++) {
        for (var j = 0; j < gridColumn.length; j++) {
          if (sortedArr[i].caption == gridColumn[j].caption) {
            var getObj = gridColumn[j];
            getObj.visible = sortedArr[i].visible;
            if (sortedArr[i].width != undefined) {
              getObj.width = sortedArr[i].width;
            }
            gridColumnList.push(getObj);
          }
        }
      }
    } else {
      gridColumnList = metaData;
      if (gridColumnList != null && gridColumnList != undefined) {
        for (var i = 0; i < gridColumnList.length; i++) {
          gridColumnList[i].visible = true;
        }
        var colList = { columns: gridColumnList };
        localStorage.setItem("gridStorage" + widgetId, JSON.stringify(colList));
      }
    }
  } else {
    gridColumnList = metaData;
    if (gridColumnList != null && gridColumnList != undefined) {
      for (var i = 0; i < gridColumnList.length; i++) {
        gridColumnList[i].visible = true;
      }
      var colList = { columns: gridColumnList };
      localStorage.setItem("gridStorage" + widgetId, JSON.stringify(colList));
    }
  }

  return gridColumnList;
};

const ColSettings = (metaData, settings) => {
  let trafficField = "";
  if (settings.traffic != undefined) {
    trafficField = settings.traffic;
  }
  const updatedMetadata = metaData["Grid Meta-Data"].map((item) => {
    if (
      item.dataType == "Number" ||
      item.dataType == "Date" ||
      item.dataType == "percentage"
    ) {
      item.alignment = "right";
    }

    if (item.dataType == "Date" || item.dataType == "date") {
      item.dataType = "date";
      item.format = function (value) {
        return formatDate(value, true);
      };
    }

    if (
      item.dataType == "decimal" ||
      item.dataType == "Number" ||
      item.dataType == "number"
    ) {
      item.dataType = "number";
      item.alignment = "right";
      item.precision = 2;
      item.format = "fixedPoint";
    }

    if (item.dataField == "time") {
      item.alignment = "right";
    }

    if (
      item["dataType"] == "DateTime" ||
      item["dataType"] == "Date" ||
      item["dataType"] == "date"
    ) {
      if (settings.reports == "Ballistix Opportunity Case Register") {
        if (item.dataField == "Closed") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }

        if (item.dataField == "Opened") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
        if (item.dataField == "ExpectedDeliveryMonth") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
        if (item.dataField == "LastUpdate") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
        if (item.dataField == "NextActionDate") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }

        if (item.dataField == "LastUpdate") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
      }

      if (settings.reports == "Prentice Activities Case Register") {
        if (item.dataField == "StartDate") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }

        if (item.dataField == "DueDate") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
      }

      if (settings.reports == "Hightech Signs Opportunities Case Register") {
        if (item.dataField == "Closed") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }

        if (item.dataField == "Opened") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
        if (item.dataField == "ExpectedDeliveryMonth") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
        if (item.dataField == "LastUpdate") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
      }

      if (settings.reports == "Willbanks Opportunity Case Register") {
        if (item.dataField == "EstimatedClosePromiseDate") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }

        if (item.dataField == "CreatedDate") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
        if (item.dataField == "LastModifiedClosedDate") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
      }

      if (
        (settings.reports === "GSC Activity Case Register" ||
          settings.reports === "TQS Activity Case Register" ||
          settings.reports === "TQS Activity Data Case Register") &&
        (item.dataField === "DateSet" ||
          item.dataField === "SetFor" ||
          item.dataField === "DateDone" ||
          item.dataField === "Date Done" ||
          item.dataField === "Set For" ||
          item.dataField === "Date Set")
      ) {
        var col = item["dataField"];
        if (col === "DateSet") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
        if (col === "SetFor") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
        if (col === "DateDone") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
        if (col === "Date Done") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
        if (col === "Set For") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
        if (col === "Date Set") {
          item["format"] = function (value) {
            return formatDate(value, false);
          };
        }
      }

      if (
        item["dataField"] == "FRS Scheduled Date" ||
        item["dataField"] == "SubmissionDue" ||
        item["dataField"] == "ForecastClosedDate"
      ) {
        item["format"] = function (value) {
          return formatDate(value, false);
        };
      }
    }

    if (item.dataField == trafficField) {
      item["template"] = true;
    }

    if (item["dataType"] === "percentage" && item.dataField != trafficField) {
      item["template"] = true;
    }

    return item;
  });

  return updatedMetadata;
};

const formatDate = (dateVal, isDisplayDateTime) => {
  if (dateVal == "" || dateVal == "NULL" || dateVal == undefined) {
    return "";
  }

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const newDate = new Date(dateVal);

  const checkDate = new Date(1970, 12, 30);
  if (checkDate > newDate) {
    return "";
  }

  let sMonth = padValue(newDate.getMonth() + 1);
  let sDay = padValue(newDate.getDate());
  let sYear = newDate.getFullYear();
  let sHour = newDate.getHours();
  let sMinute = padValue(newDate.getMinutes());
  let sAMPM = "AM";

  const iHourCheck = parseInt(sHour);
  if (iHourCheck >= 12) {
    sAMPM = "PM";
    sHour = iHourCheck - 12;
  } else if (iHourCheck === 0) {
    sHour = "12";
  }

  sHour = padValue(sHour);

  if (sHour === "00") {
    sHour = "12";
  }

  if (isDisplayDateTime) {
    return (
      sDay +
      " " +
      monthNames[sMonth - 1] +
      " " +
      sYear +
      " > " +
      sHour +
      ":" +
      sMinute +
      " " +
      sAMPM
    );
  } else {
    return sDay + " " + monthNames[sMonth - 1] + " " + sYear;
  }
};

const padValue = (value) => {
  return value < 10 ? "0" + value : value;
};

const updateDateTimeToLocatDateTime = (metaData, widgetData) => {
  let updatedWidgetData = widgetData;

  metaData["Grid Meta-Data"].map((columnDt) => {
    if (
      columnDt["dataType"] == "DateTime" ||
      columnDt["dataType"] == "Date" ||
      columnDt["dataType"] == "date"
    ) {
      const colName = columnDt["dataField"];
      updatedWidgetData = widgetData.map((item) => {
        item[colName] = ConvertToLocalDateTime(item[colName]);
        if (item[colName] === "" || item[colName] === "Invalid date") {
          item[colName] = null;
        }
        return item;
      });
    }
  });

  return updatedWidgetData;
};

const findDynamicTargatedBullet = (val, blackVal, redVal, yellowVal) => {
  val = parseFloat(val);
  blackVal = parseFloat(blackVal);
  redVal = parseFloat(redVal);
  yellowVal = parseFloat(yellowVal);
  if (val < yellowVal) {
    return greenImg;
  } else if (val < redVal && val >= yellowVal) {
    return yellowImg;
  } else if (val < blackVal && val >= redVal) {
    return redImg;
  } else if (val >= blackVal) {
    return blackImg;
  }
};

const updateWidgetSettingsInLocalStorage = (
  widgetId,
  propertyName,
  sortOrder
) => {
  var getLocalStorage = getGridLocalStorage(widgetId);
  if (getLocalStorage != undefined && getLocalStorage != null) {
    var objIndex = getLocalStorage.columns.findIndex(
      (obj) =>
        obj.dataField.toLowerCase() === (propertyName || "").toLowerCase()
    );
    var getFreshObject = getLocalStorage.columns.filter(function (data) {
      if (data.hasOwnProperty("sortOrder")) delete data["sortOrder"];
      return data;
    });
    getLocalStorage.columns = getFreshObject;
    if (objIndex != -1) {
      getLocalStorage.columns[objIndex]["sortOrder"] = sortOrder;
    }

    setGridLocalStorage(widgetId, getLocalStorage);
  }
};

function getGridLocalStorage(widgetId) {
  return JSON.parse(localStorage.getItem("gridStorage" + widgetId));
}

function setGridLocalStorage(widgetId, obj) {
  localStorage.setItem("gridStorage" + widgetId, JSON.stringify(obj));
}

const getSortOrderDetailsFromLocalStorage = (widgetId) => {
  let propName = "",
    sortOrder = "";
  var getLocalStorage = getGridLocalStorage(widgetId);
  if (getLocalStorage != undefined && getLocalStorage != null) {
    var getFreshObject = getLocalStorage.columns.filter(function (data) {
      if (data.hasOwnProperty("sortOrder")) return data;
    });

    if (getFreshObject.length > 0) {
      propName = getFreshObject[0]["dataField"];
      sortOrder = getFreshObject[0]["sortOrder"];
    }
  }
  return { sortProperty: propName, sortOrder: sortOrder };
};

export default GetColumnList;
export const GetUpdatedColSettings = ColSettings;
export const UpdatedWidgetData = updateDateTimeToLocatDateTime;
export const GetTargetBullets = findDynamicTargatedBullet;
export const UpdateGridSettings = updateWidgetSettingsInLocalStorage;
export const GetGridSortSettings = getSortOrderDetailsFromLocalStorage;
export const GetGridLocalStorage = getGridLocalStorage;
export const SetGridLocalStorage = setGridLocalStorage;
