import {
  Fragment,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  React,
} from "react";
import GetMetaData from "../../../../../Helper/Services";
import { useSelector } from "react-redux";
import { GridMetaData_Format } from "../../../../../Helper/Services";
import DataSectionSettings from "../../../../UI/TemplateControls/DataSection";
import WidgetTitleControl from "../../../../UI/TemplateControls/WidgetTitleControl";
import TextContent from "./ConfigContent/TextContent";
import { GetCategoryList } from "../../../../../Helper/WidgetDataCommon";
import { DataSectionObject } from "./../../../../../Helper/CommonModel";

const TextNumericTemplate = forwardRef((props, ref) => {
  const [showSettingsPanel, setShowSettingsPanel] = useState(true);
  const [widgetContentPanel, setWidgetContentPanel] = useState(false);
  const [titleContentPanel, setTitleContentPanel] = useState(false);
  const [dtSourceItems, setDtSourceItems] = useState([]);
  const [title, setTitle] = useState("Text/Numeric Activity Tracker");
  const [shortTitle, setShortTitle] = useState("Text/Numeric");
  const sessionId = useSelector((state) => state.login.sessionId);
  const [tableHeaderList, setTableHeaderList] = useState([]);
  const [tableHeaderVal, setTableHeaderVal] = useState("");
  const [subSectionTitleVal, setSubSectionTitleVal] = useState({
    value: "",
    isValid: true,
  });
  const [tableHeaderListColorVal, setTableHeaderListColorVal] = useState("");
  const [textColorVal, setTextColorVal] = useState("#808080");
  const [justificationVal, setJustificationVal] = useState("left");
  const [titleIsValid, setTitleIsValid] = useState(true);
  const [shortTitleIsValid, setShortTitleIsValid] = useState(true);
  const dataSectionRef = useRef();
  const dsObjRef = useRef(DataSectionObject);

  useEffect(() => {
    if (
      props.dataSources !== undefined &&
      Object.keys(props.dataSources.Reports).length !== 0
    ) {
      const _items = Object.entries(props.dataSources.Reports)
        .filter((item) => {
          if (item[1] === "Generic Table") {
            return true;
          }
          return false;
        })
        .map((item) => item[0]);
      setDtSourceItems(_items);
    }

    if (
      props.dataSources !== undefined &&
      props.isEdit === true &&
      props.settings !== undefined &&
      props.settings["reports"] != undefined
    ) {
      getMetaData(props.settings["reports"]);
      setTitle(props.settings["Title"]);
      setShortTitle(props.settings["ShortTitle"]);
      setTableHeaderVal(props.settings["tableheaderlist"]);
      setSubSectionTitleVal({
        ...subSectionTitleVal,
        value: props.settings["subsectiontitle"],
      });
      setTableHeaderListColorVal(props.settings["tableheaderlistcolor"]);
      setTextColorVal(props.settings["textcolor"]);
      setJustificationVal(props.settings["justification"]);
      setJustificationVal(props.settings["justification"]);
    }
  }, [props.dataSources, props.settings]);

  useImperativeHandle(ref, () => ({
    GetValidateData() {
      const getVaildForm = validateForm();
      if (getVaildForm) {
        return {
          formIsValid: getVaildForm,
          data: {
            Filter: dsObjRef.current.filterName || "null",
            FilterId: dsObjRef.current.filterDetails.filterid,
            IsSharedFilter: dsObjRef.current.filterDetails.issharedfilter,
            LastRefreshedTime: "",
            OriginalUserId: dsObjRef.current.filterDetails.originaluserid,
            ShortTitle: shortTitle,
            Title: title,
            connection: dsObjRef.current.connectionName,
            justification: justificationVal,
            reports: dsObjRef.current.dataSourceName,
            subsectiontitle: subSectionTitleVal.value,
            tableheaderlist: tableHeaderVal,
            tableheaderlistcolor: tableHeaderListColorVal,
            textcolor: textColorVal,
          },
        };
      } else {
        return {
          formIsValid: getVaildForm,
        };
      }
    },
  }));

  const onSettingsClicked = (val) => {
    if (val) {
      setShowSettingsPanel(val);
    } else {
      setShowSettingsPanel(!showSettingsPanel);
    }
    setWidgetContentPanel(false);
    setTitleContentPanel(false);
  };

  const onWidgetContentClicked = (val) => {
    if (val) {
      setWidgetContentPanel(val);
    } else {
      setWidgetContentPanel(!widgetContentPanel);
    }
    setShowSettingsPanel(false);
    setTitleContentPanel(false);
  };

  const onTitleContentPanelClicked = (val) => {
    if (val) {
      setTitleContentPanel(val);
    } else {
      setTitleContentPanel(!titleContentPanel);
    }
    setWidgetContentPanel(false);
    setShowSettingsPanel(false);
  };

  const OnTitleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const OnShortTitleChangeHandler = (event) => {
    setShortTitle(event.target.value);
  };

  const onChangeHeadList = (event) => {
    setTableHeaderVal(event.target.value);
  };

  const onChangeSubsectiontitle = (event) => {
    setSubSectionTitleVal({ ...subSectionTitleVal, value: event.target.value });
  };

  const onChangeTableHeaderListcolor = (event) => {
    setTableHeaderListColorVal(event.target.value);
  };

  const onChangeTextColor = (event) => {
    setTextColorVal(event.target.value);
  };

  const onChangeJustification = (event) => {
    setJustificationVal(event.target.value);
  };

  const onDatasourceChangeHandler = (dataSourceVal) => {
    if (dataSourceVal !== "") {
      getMetaData(dataSourceVal);
    } else {
      setTableHeaderList([]);
    }
  };

  const getMetaData = (dataSourceVal) => {
    if (dataSourceVal !== "") {
      GetMetaData(dataSourceVal, "", sessionId)
        .then((json) => {
          const metaData = GridMetaData_Format(json);
          const getCategories = GetCategoryList(metaData);
          setTableHeaderList(getCategories);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const validateTitle = () => {
    if (title === "") {
      onTitleContentPanelClicked(true);
      setTitleIsValid(false);
      setTimeout(() => {
        setTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateShortTitle = () => {
    if (shortTitle === "") {
      onTitleContentPanelClicked(true);
      setShortTitleIsValid(false);
      setTimeout(() => {
        setShortTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateSubSectionTitle = () => {
    if (subSectionTitleVal.value === "") {
      onWidgetContentClicked(true);
      setSubSectionTitleVal({ ...subSectionTitleVal, isValid: false });
      setTimeout(() => {
        setSubSectionTitleVal({ ...subSectionTitleVal, isValid: true });
      }, 3000);
      return false;
    }

    return true;
  };

  const validateForm = () => {
    const dataSectionVal = dataSectionRef.current.validateDataSection();
    dsObjRef.current = dataSectionVal.data;
    if (!dataSectionVal.isDataSectionValid) {
      onSettingsClicked(true);
      return false;
    }

    const isTitleValid = validateTitle();
    if (!isTitleValid) {
      return false;
    }

    const isShortTitleValid = validateShortTitle();
    if (!isShortTitleValid) {
      return false;
    }

    if (dataSectionVal.data.dataSourceName === "") {
      const isSubSectionValid = validateSubSectionTitle();
      if (!isSubSectionValid) {
        return false;
      }
    }

    return true;
  };

  return (
    <Fragment>
      {!props.isEdit && <hr className="style-one" />}
      <div onClick={onSettingsClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {showSettingsPanel && "-"}
          {!showSettingsPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>DATA</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: showSettingsPanel ? "block" : "none",
        }}
      >
        <DataSectionSettings
          dataSourceList={dtSourceItems}
          dtSourceChange={onDatasourceChangeHandler}
          ref={dataSectionRef}
          settings={props.settings}
          isEdit={props.isEdit}
          isCheckedValidation={false}
        />
      </div>
      <div onClick={onTitleContentPanelClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {titleContentPanel && "-"}
          {!titleContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET TITLE</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: titleContentPanel ? "block" : "none",
        }}
      >
        <WidgetTitleControl
          titleVal={title}
          shortTitleVal={shortTitle}
          OnTitleChangeHandler={OnTitleChangeHandler}
          OnShortTitleChangeHandler={OnShortTitleChangeHandler}
          isTitleValid={titleIsValid}
          isShortTitleValid={shortTitleIsValid}
        />
      </div>
      <div onClick={onWidgetContentClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {widgetContentPanel && "-"}
          {!widgetContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET CONTENT</p>
      </div>
      <div
        style={{
          paddingLeft: "12px",
          display: widgetContentPanel ? "block" : "none",
        }}
      >
        <TextContent
          tableHeaderList={tableHeaderList}
          tableHeaderVal={tableHeaderVal}
          subSectionTitleVal={subSectionTitleVal}
          tableHeaderListColorVal={tableHeaderListColorVal}
          textColorVal={textColorVal}
          justificationVal={justificationVal}
          onChangeHeadList={onChangeHeadList}
          onChangeSubsectiontitle={onChangeSubsectiontitle}
          onChangeTableHeaderListcolor={onChangeTableHeaderListcolor}
          onChangeTextColor={onChangeTextColor}
          onChangeJustification={onChangeJustification}
        />
      </div>
    </Fragment>
  );
});

export default TextNumericTemplate;
