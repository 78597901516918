import { createSlice } from "@reduxjs/toolkit";

const initialUserDetail = {
  accountId: -1,
  accountName: "",
  allowDomain: "",
  email: "",
  userFullName: "",
  encodedEmail: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoggedIn: false,
    sessionId: "",
    userDetail: initialUserDetail,
    isLogoutEvent: false,
    isNewPageCreated: false,
    newPageId: "",
    editPageId: "",
    clonePageId: "",
    isEditPageEvent: false,
    isClonePageEvent: false,
    isPageLoaded: false,
  },
  reducers: {
    login(state, action) {
      state.sessionId = action.payload;
      state.isLoggedIn = true;
    },
    setUserDetail(state, action) {
      state.userDetail = action.payload;
    },
    setPageLoaded(state, action) {
      state.isPageLoaded = action.payload;
    },
    logOut(state) {
      state.sessionId = "";
      state.isLoggedIn = false;
      state.userDetail = initialUserDetail;
      state.isLogoutEvent = true;
    },
    isNewPageCreated(state, action) {
      state.newPageId = action.payload;
      state.isNewPageCreated = !state.isNewPageCreated;
    },

    isEditPageEvent(state, action) {
      state.isEditPageEvent = !state.isEditPageEvent;
      state.editPageId = action.payload;
    },
    resetEditPageId(state) {
      state.editPageId = "";
    },
    resetNewPageId(state) {
      state.newPageId = "";
    },
    isClonePageEvent(state, action) {
      state.isClonePageEvent = !state.isClonePageEvent;
      state.clonePageId = action.payload;
    },
    resetClonePageId(state) {
      state.clonePageId = "";
    },
    setEncodedEmail(state, action) {
      state.userDetail.encodedEmail = action.payload;
    },
  },
});

export const loginActions = loginSlice.actions;

export default loginSlice;
