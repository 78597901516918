import {
  Fragment,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  React,
} from "react";
import { useSelector } from "react-redux";
import GetMetaData from "../../../../../Helper/Services";
import { GridMetaData_Format } from "../../../../../Helper/Services";
import DataSectionSettings from "../../../../UI/TemplateControls/DataSection";
import WidgetTitleControl from "../../../../UI/TemplateControls/WidgetTitleControl";
import { GetCategoryList } from "../../../../../Helper/WidgetDataCommon";
import { GetValueList } from "../../../../../Helper/WidgetDataCommon";
import BarLineContent from "./ConfigContent/BarLineContent";
import { DataSectionObject } from "../../../../../Helper/CommonModel";
import { GetDateTypeList } from "../../../../../Helper/WidgetDataCommon";

const ComboBarLineTemplate = forwardRef((props, ref) => {
  const [showSettingsPanel, setShowSettingsPanel] = useState(true);
  const [widgetContentPanel, setWidgetContentPanel] = useState(false);
  const [titleContentPanel, setTitleContentPanel] = useState(false);
  const [dtSourceItems, setDtSourceItems] = useState([]);
  const [title, setTitle] = useState("Combo Bar Line Chart");
  const [shortTitle, setShortTitle] = useState("Combo Bar Line Chart");
  const sessionId = useSelector((state) => state.login.sessionId);
  const [ddlList, setDdlList] = useState([]);
  const [ddlDateTypeList, setDdlDateTypeList] = useState([]);
  const [getValues, setGetValues] = useState([]);
  const [chartType, setChartType] = useState("Bar");
  const [barvalue, setBarvalue] = useState("None");
  const [lineValues, setLineValues] = useState("None");
  const [periodType, setPeriodType] = useState("days");
  const [periodTypeNumber, setPeriodTypeNumber] = useState("1");
  const [operationPerform, setOperationPerform] = useState("");
  const [stackCategory, setStackCategory] = useState("");
  const [category, setCategory] = useState("stacked");
  const [callout, setCallout] = useState("");
  const [operationLineperform, setOperationLineperform] = useState("");
  const [lineCategory, setLineCategory] = useState("");
  const [yaxisMinValue, setYaxisMinValue] = useState("");
  const [lineCallout, setLineCallout] = useState("");
  const [lineyaxis, setLineyaxis] = useState("");
  const [barname, setBarname] = useState({ value: "", isValid: true });

  const [titleIsValid, setTitleIsValid] = useState(true);
  const [shortTitleIsValid, setShortTitleIsValid] = useState(true);
  const dataSection = useRef(DataSectionObject);
  const dataSectionRef = useRef();

  useEffect(() => {
    if (
      props.dataSources != undefined &&
      Object.keys(props.dataSources.Reports).length !== 0
    ) {
      const _items = Object.entries(props.dataSources.Reports)
        .filter((item) => {
          if (item[1] === "Generic Table") {
            return true;
          }
        })
        .map((item) => item[0]);
      setDtSourceItems(_items);
    }

    if (
      props.dataSources !== undefined &&
      props.isEdit === true &&
      props.settings !== undefined &&
      props.settings["reports"] !== undefined
    ) {
      getMetaData(props.settings["reports"]);
      setTitle(props.settings["Title"]);
      setShortTitle(props.settings["ShortTitle"]);
      setBarname({
        value: props.settings["barname"],
        isValid: true,
      });
      setBarvalue(props.settings["barvalues"]);
      setCallout(props.settings["callouts"]);
      setCategory(props.settings["categorydisplay"]);
      setChartType(props.settings["charttype"]);
      setLineCallout(props.settings["linecallouts"]);
      setLineCategory(props.settings["linecategory"]);
      setLineValues(props.settings["linevalues"]);
      setLineyaxis(props.settings["lineyaxis"]);
      setPeriodTypeNumber(props.settings["numberofperiodtype"]);
      setOperationLineperform(props.settings["operationLineperform"]);
      setOperationPerform(props.settings["operationperform"]);
      setStackCategory(props.settings["stackcategory"]);
      setYaxisMinValue(props.settings["yaxisminvalue"]);
      if (props.settings["periodtype"] === undefined) {
        setPeriodType(props.settings["periodType"]);
      } else {
        setPeriodType(props.settings["periodtype"]);
      }
    }
  }, [props.dataSources, props.settings]);

  useImperativeHandle(ref, () => ({
    GetValidateData() {
      const getVaildForm = validateForm();
      if (getVaildForm) {
        return {
          formIsValid: getVaildForm,
          data: {
            Filter: dataSection.current.filterName,
            FilterId: dataSection.current.filterDetails.filterid,
            IsSharedFilter: dataSection.current.filterDetails.issharedfilter,
            OriginalUserId: dataSection.current.filterDetails.originaluserid,
            ShortTitle: shortTitle,
            Title: title,
            barname: barname.value,
            barvalues: barvalue,
            callouts: callout,
            categorydisplay: category,
            charttype: chartType,
            connection: dataSection.current.connectionName,
            linecallouts: lineCallout,
            linecategory: lineCategory,
            linevalues: lineValues,
            lineyaxis: lineyaxis,
            numberofperiodtype: periodTypeNumber,
            operationLineperform: operationLineperform,
            operationperform: operationPerform,
            reports: dataSection.current.dataSourceName,
            stackcategory: stackCategory,
            yaxisminvalue: yaxisMinValue,
            periodType: periodType,
          },
        };
      } else {
        return {
          formIsValid: getVaildForm,
        };
      }
    },
  }));

  const onSettingsClicked = (val) => {
    if (val) {
      setShowSettingsPanel(val);
    } else {
      setShowSettingsPanel(!showSettingsPanel);
    }
    setWidgetContentPanel(false);
    setTitleContentPanel(false);
  };

  const onWidgetContentClicked = (val) => {
    if (val) {
      setWidgetContentPanel(val);
    } else {
      setWidgetContentPanel(!widgetContentPanel);
    }
    setShowSettingsPanel(false);
    setTitleContentPanel(false);
  };

  const onTitleContentPanelClicked = (val) => {
    if (val) {
      setTitleContentPanel(val);
    } else {
      setTitleContentPanel(!titleContentPanel);
    }
    setWidgetContentPanel(false);
    setShowSettingsPanel(false);
  };

  const OnTitleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const OnShortTitleChangeHandler = (event) => {
    setShortTitle(event.target.value);
  };

  const onDatasourceChangeHandler = (dataSourceVal) => {
    getMetaData(dataSourceVal);
  };

  const getMetaData = (dataSourceVal) => {
    if (dataSourceVal !== "") {
      GetMetaData(dataSourceVal, "", sessionId)
        .then((json) => {
          const metaData = GridMetaData_Format(json);
          const getCategories = GetCategoryList(metaData);
          const getNumericValues = GetValueList(metaData);
          const getDateTypeList = GetDateTypeList(metaData);
          setGetValues(getNumericValues);
          setDdlList(getCategories);
          setDdlDateTypeList(getDateTypeList);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const onChangeChartType = (event) => {
    setChartType(event.target.value);
    resetSettings();
  };

  const resetSettings = () => {
    setBarname({
      value: "",
      isValid: true,
    });
    setBarvalue("None");
    setCallout("");
    setCategory("stacked");
    setLineCallout("");
    setLineCategory("");
    setLineValues("None");
    setLineyaxis("");
    setPeriodTypeNumber("1");
    setOperationLineperform("N/A");
    setOperationPerform("N/A");
    setStackCategory("");
    setYaxisMinValue("");
    setPeriodType("days");
  };

  const periodTypeChangeHandler = (event) => {
    setPeriodType(event.target.value);
  };

  const periodTypeNumberChangeHandler = (event) => {
    setPeriodTypeNumber(event.target.value);
  };

  const valueChange = (event) => {
    setBarvalue(event.target.value);
    if (event.target.value == "None") {
      setOperationPerform("N/A");
    }
    if (event.target.value !== "None") {
      setOperationPerform("Sum");
    }
  };

  const onOperationPerformSelect = (event) => {
    setOperationPerform(event.target.value);
  };

  const onStackCategorySelect = (event) => {
    setStackCategory(event.target.value);
  };

  const onCategoryDisplaySelect = (event) => {
    setCategory(event.target.value);
  };

  const onCalloutSelect = (event) => {
    setCallout(event.target.value);
  };

  const onLineChartHandler = (event) => {
    setLineValues(event.target.value);
    if (event.target.value == "None") {
      setOperationLineperform("N/A");
    }
    if (event.target.value !== "None") {
      setOperationLineperform("Sum");
    }
  };

  const onOperationLineperform = (event) => {
    setOperationLineperform(event.target.value);
  };

  const onLineCategoryChange = (event) => {
    setLineCategory(event.target.value);
  };

  const onYaxisMinValue = (event) => {
    if (event.target.value > 0) {
      setYaxisMinValue("");
    } else {
      setYaxisMinValue(event.target.value);
    }
  };

  const onLineCalloutsChange = (event) => {
    setLineCallout(event.target.value);
  };

  const onChangeLineyaxis = (event) => {
    setLineyaxis(event.target.value);
  };

  const barnameChangeHandler = (event) => {
    setBarname({ ...barname, value: event.target.value });
  };

  const onChangeYaxisMinValue = (event) => {
    setYaxisMinValue(event.target.value);
  };

  const validateTitle = () => {
    if (title === "") {
      onTitleContentPanelClicked(true);
      setTitleIsValid(false);
      setTimeout(() => {
        setTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateShortTitle = () => {
    if (shortTitle === "") {
      onTitleContentPanelClicked(true);
      setShortTitleIsValid(false);
      setTimeout(() => {
        setShortTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateBarname = () => {
    if (barname.value === "") {
      onWidgetContentClicked(true);
      setBarname({ ...barname, isValid: false });
      setTimeout(() => {
        setBarname({ ...barname, isValid: true });
      }, 3000);
      return false;
    }
    return true;
  };

  const validateForm = () => {
    const dataSectionVal = dataSectionRef.current.validateDataSection();
    dataSection.current = dataSectionVal.data;
    if (!dataSectionVal.isDataSectionValid) {
      onSettingsClicked(true);
      return false;
    }

    const isTitleValid = validateTitle();
    if (!isTitleValid) {
      return false;
    }

    const isShortTitleValid = validateShortTitle();
    if (!isShortTitleValid) {
      return false;
    }
    const isBarnameValid = validateBarname();
    if (!isBarnameValid) {
      return false;
    }

    return true;
  };

  return (
    <Fragment>
      {!props.isEdit && <hr className="style-one" />}
      <div onClick={onSettingsClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {showSettingsPanel && "-"}
          {!showSettingsPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>DATA</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: showSettingsPanel ? "block" : "none",
        }}
      >
        <DataSectionSettings
          dataSourceList={dtSourceItems}
          dtSourceChange={onDatasourceChangeHandler}
          ref={dataSectionRef}
          settings={props.settings}
          isEdit={props.isEdit}
        />
      </div>
      <div onClick={onTitleContentPanelClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {titleContentPanel && "-"}
          {!titleContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET TITLE</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: titleContentPanel ? "block" : "none",
        }}
      >
        <WidgetTitleControl
          titleVal={title}
          shortTitleVal={shortTitle}
          OnTitleChangeHandler={OnTitleChangeHandler}
          OnShortTitleChangeHandler={OnShortTitleChangeHandler}
          isTitleValid={titleIsValid}
          isShortTitleValid={shortTitleIsValid}
        />
      </div>
      <div onClick={onWidgetContentClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {widgetContentPanel && "-"}
          {!widgetContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET CONTENT</p>
      </div>
      <div
        style={{
          paddingLeft: "12px",
          display: widgetContentPanel ? "block" : "none",
        }}
      >
        <BarLineContent
          ddlList={ddlList}
          ddlDateTypeList={ddlDateTypeList}
          valueList={getValues}
          onChangeChartType={onChangeChartType}
          periodTypeChangeHandler={periodTypeChangeHandler}
          periodTypeNumberChangeHandler={periodTypeNumberChangeHandler}
          valueChange={valueChange}
          onOperationPerformSelect={onOperationPerformSelect}
          onStackCategorySelect={onStackCategorySelect}
          onCategoryDisplaySelect={onCategoryDisplaySelect}
          onCalloutSelect={onCalloutSelect}
          onLineChartHandler={onLineChartHandler}
          onOperationLineperform={onOperationLineperform}
          onLineCategoryChange={onLineCategoryChange}
          onYaxisMinValue={onYaxisMinValue}
          onLineCalloutsChange={onLineCalloutsChange}
          onChangeLineyaxis={onChangeLineyaxis}
          barnameChangeHandler={barnameChangeHandler}
          onChangeYaxisMinValue={onChangeYaxisMinValue}
          periodType={periodType}
          periodTypeNumber={periodTypeNumber}
          operationPerform={operationPerform}
          stackCategory={stackCategory}
          category={category}
          callout={callout}
          operationLineperform={operationLineperform}
          lineCategory={lineCategory}
          yaxisMinValue={yaxisMinValue}
          lineCallout={lineCallout}
          lineyaxis={lineyaxis}
          barname={barname}
          chartType={chartType}
          barvalue={barvalue}
          lineValues={lineValues}
        />
      </div>
    </Fragment>
  );
});

export default ComboBarLineTemplate;
