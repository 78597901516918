import { useEffect, useState, React } from "react";
import {
  GetColorPallets,
  GetLastRefreshDateTime,
  GetWidgetData,
  GetWidgetSettings,
} from "./../../../../Helper/Services";
import { useSelector } from "react-redux";
import {
  GetFormattedGridData,
  IsSharedFilterAlert,
} from "../../../../Helper/WidgetDataCommon";
import BarLineChartDataService from "./BarLineChartWidgetService";
import BarLinechartD3Component from "./BarLineChartD3Component";
import NoShowDiv from "../../../UI/NoShowTemplate";
import { BarLineChartRefreshTime } from "../../../../Constants";

const BarLineChart = (props) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const isOtherUserPage = useSelector((state) => state.layout.IsOtherUserPage);
  const sharedUserEmail = useSelector((state) => state.layout.SharedUserEmail);
  const [widgetDt, setWidgetDt] = useState([]);
  const [chartSettings, setChartSettings] = useState([]);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [screenMsg, setScreenMsg] = useState("No Data");
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    const getWidgetSetting = () => {
      let isSharedFilter = false;
      let sharedFilterUserId = "";
      let filterId = "";

      GetWidgetSettings(props.widgetId)
        .then((settings) => {
          if (
            settings["IsSharedFilter"] !== undefined &&
            settings["IsSharedFilter"] === "true"
          ) {
            isSharedFilter = true;
            sharedFilterUserId = settings["OriginalUserId"];
            filterId = settings["FilterId"];
          }

          GetWidgetData(
            settings["Filter"],
            settings["reports"],
            settings["connection"],
            sessionId,
            isOtherUserPage,
            sharedUserEmail,
            isSharedFilter,
            sharedFilterUserId,
            filterId
          )
            .then((widgetDataResponse) => {
              GetLastRefreshDateTime(settings["reports"], "", sessionId)
                .then((lstReponseDt) => {
                  props.onGetNotification(lstReponseDt);
                  if (IsSharedFilterAlert(widgetDataResponse)) {
                    setScreenMsg(widgetDataResponse);
                    setShowNoData(true);
                    return false;
                  }

                  GetColorPallets(
                    settings["reports"],
                    settings["linecategory"],
                    sessionId
                  )
                    .then((colorsLineResp) => {
                      GetColorPallets(
                        settings["reports"],
                        settings["stackcategory"],
                        sessionId
                      )
                        .then((colorsBarResp) => {
                          //proceess data
                          const getFormattedDt =
                            GetFormattedGridData(widgetDataResponse);

                          const getFinalDt =
                            BarLineChartDataService.BarLineChartData(
                              settings,
                              getFormattedDt
                            );

                          ///setup the color pallets
                          const chartSettings =
                            BarLineChartDataService.ChartSettings(
                              colorsLineResp,
                              getFinalDt,
                              colorsBarResp,
                              settings
                            );
                          setChartSettings(chartSettings);
                          setWidgetDt(getFinalDt.dataItems);
                        })
                        .catch((err) => {
                          console.error("GetColorPallets2:-", err);
                        });
                    })
                    .catch((err) => {
                      console.error("GetColorPallets1:-", err);
                    });
                })
                .catch((err) => {
                  console.error("GetLastRefreshDateTime:-", err);
                });
            })
            .catch((err) => {
              console.error("GetWidgetData:-", err);
            });
        })
        .catch((err) => {});
    };

    if (sessionId !== "" && sessionId != null) {
      getWidgetSetting();
      GetWidgetSize();
    }

    window.addEventListener("resize", () => {
      GetWidgetSize();
    });

    const refreshWidget = setInterval(() => {
      console.log(
        "Comobo Bar chart line set Timout..",
        new Date().toLocaleTimeString()
      );
      getWidgetSetting();
    }, BarLineChartRefreshTime);

    return () => {
      //clear setinterval on unmount component
      clearInterval(refreshWidget);
    };
  }, [sessionId, props.RefreshVersion]);

  const GetWidgetSize = () => {
    const getParentContainer = document.getElementById(
      "content_" + props.widgetId
    );
    if (getParentContainer !== undefined && getParentContainer != null) {
      const getHeight = getParentContainer.clientHeight;
      const getWidth = getParentContainer.clientWidth;
      setHeight(getHeight);
      setWidth(getWidth);
    }
  };

  return (
    <>
      {showNoData === false && (
        <BarLinechartD3Component
          data={widgetDt}
          chartSettings={chartSettings}
          height={height}
          width={width}
          widgetId={props.widgetId}
          wid={props.wid}
          getWidgetSize={GetWidgetSize}
        />
      )}

      {showNoData === true && <NoShowDiv screenMsg={screenMsg} />}
    </>
  );
};

export default BarLineChart;
