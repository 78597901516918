import { useRef, useState, useEffect, React } from "react";
import { pageActions } from "../../../store/page-slice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import * as htmlToImage from "html-to-image";

import Modal from "../../UI/Modal";
import styles from "./EditPage.module.css";
import PageLayout from "../PageLayout/PageLayout";
import useInput from "../../../hooks/use-input";
import {
  Check_Page_Name_Exists,
  Save_Template,
  Update_Shared_Page,
} from "../../../Constants";
import { loginActions } from "../../../store/login-slice";

const isNotEmpty = (value) => value.trim() !== "";
const EditPage = () => {
  const userId = useSelector((state) => state.layout.UserId);
  const widgets = useSelector((state) => state.page.EditWidgets);
  const pageList = useSelector((state) => state.layout.PageList);
  const pageId = useSelector((state) => state.page.editPageId);
  const sessionId = useSelector((state) => state.login.sessionId);
  const userEmail = useSelector((state) => state.login.userDetail.email);
  const allowDomain = useSelector(
    (state) => state.login.userDetail.allowDomain
  );
  const [hideCloseBtn, setHideCloseBtn] = useState(false);
  const [isSharedPage, setIsSharedPage] = useState(false);
  const [isBoxShadow, setIsBoxShadow] = useState(true);
  let history = useHistory();
  const initialGrid = [
    {
      row1: [
        { x: 0, y: 0, isFill: false },
        { x: 1, y: 0, isFill: false },
        { x: 2, y: 0, isFill: false },
        { x: 3, y: 0, isFill: false },
      ],
      isOccupied: false,
    },
    {
      row2: [
        { x: 0, y: 1, isFill: false },
        { x: 1, y: 1, isFill: false },
        { x: 2, y: 1, isFill: false },
        { x: 3, y: 1, isFill: false },
      ],
      isOccupied: false,
    },
    {
      row3: [
        { x: 0, y: 2, isFill: false },
        { x: 1, y: 2, isFill: false },
        { x: 2, y: 2, isFill: false },
        { x: 3, y: 2, isFill: false },
      ],
      isOccupied: false,
    },
  ];

  const pageContainerRef = useRef(initialGrid);
  let itemsArr = [];

  const dispatch = useDispatch();
  const [items, setItems] = useState(itemsArr);
  const [newCounter, setNewCounter] = useState(0);
  const [cols, setCols] = useState({ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 });
  const [rowHeight, setRowHeight] = useState(180);
  const [layout, setLayout] = useState(itemsArr);
  const [addBtnDisabled, setaAdBtnDisabled] = useState(false);
  const {
    value: pagename,
    isValid: pagenameIsValid,
    hasError: pagenameHasError,
    valueChangeHandler: pagenameChangeHandler,
    inputBlurHandler: pagenameBlurHandler,
    reset: resetpagename,
    setDefaultValue: setDefaultName,
  } = useInput(isNotEmpty);
  let disabledCss = "";
  let disabledCssForAddBtn = "disabled";

  useEffect(() => {
    let getPageDetails = pageList.filter((item) => item.Id === pageId);
    if (getPageDetails.length > 0) {
      setDefaultName(getPageDetails[0].PageName || "");
      setIsSharedPage(getPageDetails[0].IsShared || false);
    }
    itemsArr = widgets.map((item) => {
      return {
        i: item.Id.toString(),
        x: item.Col,
        y: item.Row,
        w: item.SizeX,
        h: item.SizeY,
        minW: 1,
        minH: 1,
        maxW: 4,
        maxH: 3,
        resizeHandles: ["s", "w", "e", "n", "se"],
        type: item.Type,
      };
    });

    setItems(itemsArr);
  }, [widgets]);

  const createElementHandler = (el) => {
    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer",
    };
    const i = el.add ? "+" : el.i;
    const boxCss = isBoxShadow
      ? {
          boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
          backgroundColor: "rgb(196, 214, 160)",
          width: "190px",
        }
      : { backgroundColor: "rgb(196, 214, 160)", width: "190px" };
    return (
      <div className={styles["box"]} style={boxCss} key={i} data-grid={el}>
        <div className={styles["box-header"]}>
          <div
            className={`${styles["box-header-btns"]} ${styles["pull-right"]}`}
          >
            {!hideCloseBtn && (
              <button
                aria-hidden="true"
                className={`${styles["close"]} closeBtn`}
                type="button"
                onClick={onRemoveItem.bind(this, i, el.type)}
              >
                x
              </button>
            )}
          </div>
        </div>
        <div className="box-content">
          {el.type > 0 && !hideCloseBtn && (
            <div
              id="lockDiv"
              style={{
                position: "absolute",
                textAlign: "center",
                top: "calc(100% / 2 - 40px)",
                left: "calc(100% / 2 - 40px)",
              }}
            >
              <i
                className="fa fa-lock lockDiv"
                alt="locked"
                style={{
                  width: "80px",
                  height: "80px",
                  fontSize: "6em",
                  color: "rgb(215, 227, 191)",
                }}
              ></i>
            </div>
          )}
        </div>
      </div>
    );
  };

  const onLayoutChange = (layout) => {
    setaAdBtnDisabled(false);
    setLayout(layout);
    if (layout.length > 0) {
      pageContainerRef.current = initialGrid;
      layout.forEach((element) => {
        fillContainer(element, true);
      });

      let getOccupiedRows = pageContainerRef.current.filter(
        (x) => x.isOccupied
      );

      if (getOccupiedRows.length == 3) {
        setaAdBtnDisabled(true);
      } else {
        setaAdBtnDisabled(false);
      }
    }
  };

  // We're using the cols coming back from this to calculate where to add new items.
  const onBreakpointChange = (breakpoint, cols) => {
    setCols(cols);
  };

  const onRemoveItem = (i, type) => {
    if (type > 0) {
      if (
        window.confirm(
          "This container has a widget associated with it. If you delete the container, you delete the associated widget too. Are you sure you want to proceed?"
        )
      ) {
        var removeItem = _.find(items, { i: i });
        setItems(_.reject(items, { i: i }));
      } else {
        return false;
      }
    } else {
      var removeItem = _.find(items, { i: i });
      setItems(_.reject(items, { i: i }));
    }
  };

  const onAddItem = () => {
    if (addBtnDisabled) return;

    /*eslint no-console: 0*/
    const t1 = _.groupBy(layout, "y");
    if (_.isEmpty(t1) || layout.length == 0) {
      const updatedItems = items.concat({
        i: "n" + newCounter,
        x: 0,
        y: 0, // puts it at the bottom
        w: 1,
        h: 1,
        resizeHandles: ["s", "w", "e", "n", "se"],
      });
      const updatedNewCounter = newCounter + 1;
      setItems(updatedItems);
      setNewCounter(updatedNewCounter);
      return false;
    }

    let _gridItems = pageContainerRef.current.slice();
    for (let step = 0; step < _gridItems.length; step++) {
      let getItem = _gridItems[step];
      const getRow = "row" + parseInt(step + 1);
      if (!getItem.isOccupied) {
        let getRowItem = [...getItem[getRow.toString()]];
        let getEmptyContainer = getRowItem.filter((x) => !x.isFill);
        if (getEmptyContainer.length > 0) {
          const updatedItems = items.concat({
            i: "n" + newCounter,
            x: getEmptyContainer[0].x,
            y: getEmptyContainer[0].y, // puts it at the bottom
            w: 1,
            h: 1,
            resizeHandles: ["s", "w", "e", "n", "se"],
          });
          const updatedNewCounter = newCounter + 1;
          setItems(updatedItems);
          setNewCounter(updatedNewCounter);
          break;
        }
      }
    }
  };

  const updateCountainer = (rowId, colId, isTrue) => {
    let _items = pageContainerRef.current.slice();
    const rowName = "row" + parseInt(rowId + 1);
    let getRow = [..._items[rowId][rowName]];
    let getItem = { ...getRow[colId], isFill: isTrue };
    getRow[colId] = getItem;
    _items[rowId][rowName] = getRow;

    let getEmptyContainer = _items[rowId][rowName].filter((x) => x.isFill);
    if (getEmptyContainer.length == 4) {
      _items[rowId] = { ..._items[rowId], isOccupied: true };
    }
    const updatedItems = [..._items];
    pageContainerRef.current = updatedItems;
  };

  const fillContainer = (layoutItems, isTrue) => {
    let getLastContainer = layoutItems; // layoutItems[layoutItems.length - 1];

    if (getLastContainer.w === 1 && getLastContainer.h === 1) {
      if (getLastContainer.x === 0 && getLastContainer.y === 0) {
        updateCountainer(getLastContainer.y, getLastContainer.x, isTrue);
      }
      if (getLastContainer.x === 1 && getLastContainer.y === 0) {
        updateCountainer(getLastContainer.y, getLastContainer.x, isTrue);
      }
      if (getLastContainer.x === 2 && getLastContainer.y === 0) {
        updateCountainer(getLastContainer.y, getLastContainer.x, isTrue);
      }
      if (getLastContainer.x === 3 && getLastContainer.y === 0) {
        updateCountainer(getLastContainer.y, getLastContainer.x, isTrue);
      }

      if (getLastContainer.x === 0 && getLastContainer.y === 1) {
        updateCountainer(getLastContainer.y, getLastContainer.x, isTrue);
      }
      if (getLastContainer.x === 1 && getLastContainer.y === 1) {
        updateCountainer(getLastContainer.y, getLastContainer.x, isTrue);
      }
      if (getLastContainer.x === 2 && getLastContainer.y === 1) {
        updateCountainer(getLastContainer.y, getLastContainer.x, isTrue);
      }
      if (getLastContainer.x === 3 && getLastContainer.y === 1) {
        updateCountainer(getLastContainer.y, getLastContainer.x, isTrue);
      }

      if (getLastContainer.x === 0 && getLastContainer.y === 2) {
        updateCountainer(getLastContainer.y, getLastContainer.x, isTrue);
      }
      if (getLastContainer.x === 1 && getLastContainer.y === 2) {
        updateCountainer(getLastContainer.y, getLastContainer.x, isTrue);
      }
      if (getLastContainer.x === 2 && getLastContainer.y === 2) {
        updateCountainer(getLastContainer.y, getLastContainer.x, isTrue);
      }
      if (getLastContainer.x === 3 && getLastContainer.y === 2) {
        updateCountainer(getLastContainer.y, getLastContainer.x, isTrue);
      }
    }

    if (getLastContainer.w === 2 && getLastContainer.h === 1) {
      if (getLastContainer.x === 0 && getLastContainer.y === 0) {
        updateCountainer(0, 0, isTrue);
        updateCountainer(0, 1, isTrue);
      }

      if (getLastContainer.x === 1 && getLastContainer.y === 0) {
        updateCountainer(0, 1, isTrue);
        updateCountainer(0, 2, isTrue);
      }

      if (getLastContainer.x === 2 && getLastContainer.y === 0) {
        updateCountainer(0, 2, isTrue);
        updateCountainer(0, 3, isTrue);
      }

      if (getLastContainer.x === 0 && getLastContainer.y === 1) {
        updateCountainer(1, 0, isTrue);
        updateCountainer(1, 1, isTrue);
      }

      if (getLastContainer.x === 1 && getLastContainer.y === 1) {
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
      }

      if (getLastContainer.x === 2 && getLastContainer.y === 1) {
        updateCountainer(1, 2, isTrue);
        updateCountainer(1, 3, isTrue);
      }

      if (getLastContainer.x === 0 && getLastContainer.y === 2) {
        updateCountainer(2, 0, isTrue);
        updateCountainer(2, 1, isTrue);
      }

      if (getLastContainer.x === 1 && getLastContainer.y === 2) {
        updateCountainer(2, 1, isTrue);
        updateCountainer(2, 2, isTrue);
      }

      if (getLastContainer.x === 2 && getLastContainer.y === 2) {
        updateCountainer(2, 2, isTrue);
        updateCountainer(2, 3, isTrue);
      }
    }

    if (getLastContainer.w === 3 && getLastContainer.h === 1) {
      if (getLastContainer.x === 0 && getLastContainer.y === 0) {
        updateCountainer(0, 0, isTrue);
        updateCountainer(0, 1, isTrue);
        updateCountainer(0, 2, isTrue);
      }
      if (getLastContainer.x === 1 && getLastContainer.y === 0) {
        updateCountainer(0, 1, isTrue);
        updateCountainer(0, 2, isTrue);
        updateCountainer(0, 3, isTrue);
      }

      if (getLastContainer.x === 0 && getLastContainer.y === 1) {
        updateCountainer(1, 0, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
      }
      if (getLastContainer.x === 1 && getLastContainer.y === 1) {
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(1, 3, isTrue);
      }

      if (getLastContainer.x === 0 && getLastContainer.y === 2) {
        updateCountainer(2, 0, isTrue);
        updateCountainer(2, 1, isTrue);
        updateCountainer(2, 2, isTrue);
      }
      if (getLastContainer.x === 1 && getLastContainer.y === 2) {
        updateCountainer(2, 1, isTrue);
        updateCountainer(2, 2, isTrue);
        updateCountainer(2, 3, isTrue);
      }
    }

    if (getLastContainer.w === 4 && getLastContainer.h === 1) {
      if (getLastContainer.x === 0 && getLastContainer.y === 0) {
        updateCountainer(0, 0, isTrue);
        updateCountainer(0, 1, isTrue);
        updateCountainer(0, 2, isTrue);
        updateCountainer(0, 3, isTrue);
      }

      if (getLastContainer.x === 0 && getLastContainer.y === 1) {
        updateCountainer(1, 0, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(1, 3, isTrue);
      }

      if (getLastContainer.x === 0 && getLastContainer.y === 2) {
        updateCountainer(2, 0, isTrue);
        updateCountainer(2, 1, isTrue);
        updateCountainer(2, 2, isTrue);
        updateCountainer(2, 3, isTrue);
      }
    }

    if (getLastContainer.w === 1 && getLastContainer.h === 2) {
      if (getLastContainer.x === 0 && getLastContainer.y === 0) {
        updateCountainer(0, 0, isTrue);
        updateCountainer(1, 0, isTrue);
      }

      if (getLastContainer.x === 1 && getLastContainer.y === 0) {
        updateCountainer(0, 1, isTrue);
        updateCountainer(1, 1, isTrue);
      }

      if (getLastContainer.x === 2 && getLastContainer.y === 0) {
        updateCountainer(0, 2, isTrue);
        updateCountainer(1, 2, isTrue);
      }

      if (getLastContainer.x === 3 && getLastContainer.y === 0) {
        updateCountainer(0, 3, isTrue);
        updateCountainer(1, 3, isTrue);
      }

      if (getLastContainer.x === 0 && getLastContainer.y === 1) {
        updateCountainer(1, 0, isTrue);
        updateCountainer(2, 0, isTrue);
      }

      if (getLastContainer.x === 1 && getLastContainer.y === 1) {
        updateCountainer(1, 1, isTrue);
        updateCountainer(2, 1, isTrue);
      }

      if (getLastContainer.x === 2 && getLastContainer.y === 1) {
        updateCountainer(1, 2, isTrue);
        updateCountainer(2, 2, isTrue);
      }

      if (getLastContainer.x === 3 && getLastContainer.y === 1) {
        updateCountainer(1, 3, isTrue);
        updateCountainer(2, 3, isTrue);
      }
    }

    if (getLastContainer.w === 2 && getLastContainer.h === 2) {
      if (getLastContainer.x === 0 && getLastContainer.y === 0) {
        updateCountainer(0, 0, isTrue);
        updateCountainer(0, 1, isTrue);
        updateCountainer(1, 0, isTrue);
        updateCountainer(1, 1, isTrue);
      }

      if (getLastContainer.x === 1 && getLastContainer.y === 0) {
        updateCountainer(0, 1, isTrue);
        updateCountainer(0, 2, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
      }

      if (getLastContainer.x === 2 && getLastContainer.y === 0) {
        updateCountainer(0, 2, isTrue);
        updateCountainer(0, 3, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(1, 3, isTrue);
      }

      if (getLastContainer.x === 0 && getLastContainer.y === 1) {
        updateCountainer(1, 0, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(2, 0, isTrue);
        updateCountainer(2, 1, isTrue);
      }

      if (getLastContainer.x === 1 && getLastContainer.y === 1) {
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(2, 1, isTrue);
        updateCountainer(2, 2, isTrue);
      }

      if (getLastContainer.x === 2 && getLastContainer.y === 1) {
        updateCountainer(1, 2, isTrue);
        updateCountainer(1, 3, isTrue);
        updateCountainer(2, 2, isTrue);
        updateCountainer(2, 3, isTrue);
      }
    }

    if (getLastContainer.w === 3 && getLastContainer.h === 2) {
      if (getLastContainer.x === 0 && getLastContainer.y === 0) {
        updateCountainer(0, 0, isTrue);
        updateCountainer(0, 1, isTrue);
        updateCountainer(0, 2, isTrue);
        updateCountainer(1, 0, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
      }

      if (getLastContainer.x === 1 && getLastContainer.y === 0) {
        updateCountainer(0, 1, isTrue);
        updateCountainer(0, 2, isTrue);
        updateCountainer(0, 3, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(1, 3, isTrue);
      }

      if (getLastContainer.x === 0 && getLastContainer.y === 1) {
        updateCountainer(1, 0, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(2, 0, isTrue);
        updateCountainer(2, 1, isTrue);
        updateCountainer(2, 2, isTrue);
      }

      if (getLastContainer.x === 1 && getLastContainer.y === 1) {
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(1, 3, isTrue);
        updateCountainer(2, 1, isTrue);
        updateCountainer(2, 2, isTrue);
        updateCountainer(2, 3, isTrue);
      }
    }

    if (getLastContainer.w === 4 && getLastContainer.h === 2) {
      if (getLastContainer.x === 0 && getLastContainer.y === 0) {
        updateCountainer(0, 0, isTrue);
        updateCountainer(0, 1, isTrue);
        updateCountainer(0, 2, isTrue);
        updateCountainer(0, 3, isTrue);
        updateCountainer(1, 0, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(1, 3, isTrue);
      }

      if (getLastContainer.x === 0 && getLastContainer.y === 1) {
        updateCountainer(1, 0, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(1, 3, isTrue);
        updateCountainer(2, 0, isTrue);
        updateCountainer(2, 1, isTrue);
        updateCountainer(2, 2, isTrue);
        updateCountainer(2, 3, isTrue);
      }
    }

    if (getLastContainer.w === 1 && getLastContainer.h === 3) {
      if (getLastContainer.x === 0 && getLastContainer.y === 0) {
        updateCountainer(0, 0, isTrue);
        updateCountainer(1, 0, isTrue);
        updateCountainer(2, 0, isTrue);
      }

      if (getLastContainer.x === 1 && getLastContainer.y === 0) {
        updateCountainer(0, 1, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(2, 1, isTrue);
      }

      if (getLastContainer.x === 2 && getLastContainer.y === 0) {
        updateCountainer(0, 2, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(2, 2, isTrue);
      }

      if (getLastContainer.x === 3 && getLastContainer.y === 0) {
        updateCountainer(0, 3, isTrue);
        updateCountainer(1, 3, isTrue);
        updateCountainer(2, 3, isTrue);
      }
    }

    if (getLastContainer.w === 2 && getLastContainer.h === 3) {
      if (getLastContainer.x === 0 && getLastContainer.y === 0) {
        updateCountainer(0, 0, isTrue);
        updateCountainer(0, 1, isTrue);
        updateCountainer(1, 0, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(2, 0, isTrue);
        updateCountainer(2, 1, isTrue);
      }
      if (getLastContainer.x === 1 && getLastContainer.y === 0) {
        updateCountainer(0, 1, isTrue);
        updateCountainer(0, 2, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(2, 1, isTrue);
        updateCountainer(2, 2, isTrue);
      }

      if (getLastContainer.x === 2 && getLastContainer.y === 0) {
        updateCountainer(0, 2, isTrue);
        updateCountainer(0, 3, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(1, 3, isTrue);
        updateCountainer(2, 2, isTrue);
        updateCountainer(2, 3, isTrue);
      }
    }

    if (getLastContainer.w === 3 && getLastContainer.h === 3) {
      if (getLastContainer.x === 0 && getLastContainer.y === 0) {
        updateCountainer(0, 0, isTrue);
        updateCountainer(0, 1, isTrue);
        updateCountainer(0, 2, isTrue);
        updateCountainer(1, 0, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(2, 0, isTrue);
        updateCountainer(2, 1, isTrue);
        updateCountainer(2, 2, isTrue);
      }

      if (getLastContainer.x === 1 && getLastContainer.y === 0) {
        updateCountainer(0, 1, isTrue);
        updateCountainer(0, 2, isTrue);
        updateCountainer(0, 3, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(1, 3, isTrue);
        updateCountainer(2, 1, isTrue);
        updateCountainer(2, 2, isTrue);
        updateCountainer(2, 3, isTrue);
      }
    }

    if (getLastContainer.w === 4 && getLastContainer.h === 3) {
      if (getLastContainer.x === 0 && getLastContainer.y === 0) {
        updateCountainer(0, 0, isTrue);
        updateCountainer(0, 1, isTrue);
        updateCountainer(0, 2, isTrue);
        updateCountainer(0, 3, isTrue);
        updateCountainer(1, 0, isTrue);
        updateCountainer(1, 1, isTrue);
        updateCountainer(1, 2, isTrue);
        updateCountainer(1, 3, isTrue);
        updateCountainer(2, 0, isTrue);
        updateCountainer(2, 1, isTrue);
        updateCountainer(2, 2, isTrue);
        updateCountainer(2, 3, isTrue);
      }
    }
  };

  const onResizeStop = (layout) => {};

  const onClosePopup = () => {
    dispatch(pageActions.triggerEditPage());
  };

  const onSaveHandler = async () => {
    if (addBtnDisabled === false) {
      return false;
    }

    if (pagename === "") {
      pagenameBlurHandler();
      return false;
    }

    setaAdBtnDisabled(false);
    const IsPageExists = await fetch(
      `${Check_Page_Name_Exists}?pageName=${pagename}&userId=${userId}&pageId=${pageId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    if (IsPageExists.ok) {
      setHideCloseBtn(true);
      setIsBoxShadow(false);
      const response = await IsPageExists.json();
      if (response === true) {
        alert("That page name already exists. Please choose another!");
        setaAdBtnDisabled(true);
        return false;
      } else {
        htmlToImage
          .toPng(document.getElementById("NewTemplate"))
          .then(async (dataUrl) => {
            const img = dataUrl;
            let layoutOutput = layout.map((element) => {
              let getWidgetDetails = widgets.filter((x) => x.Id == element.i);
              if (getWidgetDetails.length > 0) {
                return {
                  id: getWidgetDetails[0].Id,
                  col: element.x,
                  row: element.y,
                  sizeX: element.w,
                  sizeY: element.h,
                  type: getWidgetDetails[0].Type,
                  title: getWidgetDetails[0].Title,
                  shorttitle: getWidgetDetails[0].ShortTitle,
                  configuration: getWidgetDetails[0].Configuration,
                };
              } else {
                return {
                  name: "New Widget",
                  sizeX: element.w,
                  sizeY: element.h,
                  type: 0,
                  row: element.y,
                  col: element.x,
                };
              }
            });

            if (isSharedPage) {
              let isConfirm = window.confirm(
                "Are you sure you want to share this page with all domain members?"
              );
              setaAdBtnDisabled(true);
              if (!isConfirm) {
                setHideCloseBtn(false);
                setIsBoxShadow(true);
                return false;
              }
            }

            layoutOutput[0].Image = img;
            layoutOutput[0].pageId = pageId;

            const saveTemplate = await fetch(
              `${Save_Template}?pageName=${pagename}&userId=${userId}`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(layoutOutput),
              }
            );

            if (saveTemplate.ok) {
              setaAdBtnDisabled(true);
              const resp = await saveTemplate.json();
              updatePageShared(resp);
              dispatch(pageActions.triggerEditPage());
              setHideCloseBtn(false);
              setIsBoxShadow(true);
              dispatch(loginActions.isEditPageEvent(resp));
              history.push("/" + sessionId + "/" + resp);
            }
          });
      }
    }
  };

  const onPageSharedHandler = () => {
    setIsSharedPage(!isSharedPage);
  };

  const updatePageShared = async (pageId) => {
    let username = "";
    const _pageId = parseInt(pageId);
    if (userEmail !== "") {
      username = userEmail.split("@")[0];
    }
    const obj = {
      pageId: _pageId,
      userName: username,
      domainName: allowDomain,
      isShared: isSharedPage,
      userId: userId,
      userEmail: userEmail,
    };

    await fetch(`${Update_Shared_Page}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    });
  };

  disabledCss = addBtnDisabled ? "disabled" : "";
  disabledCssForAddBtn = addBtnDisabled ? "" : "disabled";

  const requiredError = pagenameHasError ? "required-error" : "";

  if (disabledCssForAddBtn === "") {
    disabledCssForAddBtn = pagenameHasError ? "disabled" : "";
  }

  return (
    <Modal
      width={{
        width: "828px",
        overflow: "hidden",
        padding: "0px",
        background: "#e6e7e3",
      }}
      onClose={onClosePopup}
      contentCss={{ padding: "0px", background: "none" }}
    >
      <div style={{ marginTop: "10px", textAlign: "center", height: "65px" }}>
        <div
          title="Add Container"
          id="addWidget"
          className={`dx-button dx-button-has-icon ${styles[disabledCss]}`}
          onClick={onAddItem}
          style={{
            left: "248px",
            top: "11px",
            cursor: "pointer",
            position: "absolute",
          }}
        >
          <i
            className="fa fa-plus dx-icon"
            style={{ fontSize: "27px", color: "gray" }}
          ></i>
        </div>
        <input
          id="pageName"
          type="text"
          className={requiredError}
          placeholder="Give your page a (unique) name"
          value={pagename}
          onBlur={pagenameBlurHandler}
          onChange={pagenameChangeHandler}
          style={{
            height: "22px",
            position: "relative",
            top: "1.5px",
            marginLeft: "10px",
            width: "245px",
            padding: "5px",
          }}
        />
        <input type="hidden" value="" id="ImageData" />
        <div
          title="Save Page"
          id="btn_saveTemplate"
          className={`dx-button dx-button-has-icon ${styles[disabledCssForAddBtn]}`}
          style={{
            position: "absolute",
            left: "553px",
            top: "11px",
            cursor: "pointer",
          }}
          onClick={onSaveHandler}
        >
          <i
            className="fa fa-floppy-o dx-icon"
            style={{ fontSize: "28px", color: "gray", height: "25px" }}
          ></i>
        </div>
        <br />
        <input
          style={{ left: "-1px", top: "7px", position: "relative" }}
          type="checkbox"
          checked={isSharedPage}
          onChange={onPageSharedHandler}
        />
        <label
          style={{
            left: "2px",
            top: "6px",
            fontWeight: "400",
            position: "relative",
            color: "#212529",
          }}
        >
          Share with all domain members
        </label>
      </div>
      <div
        style={{
          width: "800px !important",
          overflow: "hidden",
          backgroundColor: "white",
          border: "none",
          height: "590px",
          margin: "12px",
        }}
      >
        <PageLayout
          onLayoutChange={onLayoutChange}
          layout={layout}
          rowHeight={rowHeight}
          items={items}
          createElementHandler={createElementHandler}
          onResizeStop={onResizeStop}
        />
      </div>
    </Modal>
  );
};

export default EditPage;
