import { Configurator_URL } from "../../../../Constants";

const GetDatasources = (sessionId) => {
  let url = `${Configurator_URL}/configurator/api/datasources`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: sessionId,
    },
  }).then((response) => response.json());
};

const GetConfigurationType = (sessionId, id) => {
  let url = `${Configurator_URL}/configurator/api/columnsettings/${id}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: sessionId,
    },
  }).then((response) => response.json());
};

const GetClientSetting = (sessionId, clientId, settingId) => {
  let url = `${Configurator_URL}/configurator/api/getclientsetting/${clientId}/${settingId}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: sessionId,
    },
  }).then((response) => response.json());
};

const GetTimeZoneList = (sessionId) => {
  let url = `${Configurator_URL}/configurator/api/gettimezones`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: sessionId,
    },
  }).then((response) => response.json());
};

const InsertUpdateSettings = (settings, sessionId) => {
  let url = `${Configurator_URL}/configurator/api/insertupdatesetting`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(settings),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: sessionId,
    },
  }).then((response) => {
    if (response.ok) {
      return response.text();
    }
  });
};

const DeleteSettings = (
  dataSourceId,
  sessionId,
  columnSettingId,
  settingId,
  dataSourceType,
  userName,
  userEmail
) => {
  let url = `${Configurator_URL}/configurator/api/deletesetting/${dataSourceId}/${columnSettingId}/${settingId}/${dataSourceType}?userName=${userName}&userEmail=${userEmail}`;
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: sessionId,
    },
  }).then((response) => {
    if (response.ok) {
      return response.text();
    }
  });
};

const SortClientSetting = (settings, sessionId) => {
  let url = `${Configurator_URL}/configurator/api/sortclientsetting`;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(settings),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      sessionId: sessionId,
    },
  }).then((response) => {
    if (response.ok) {
      return response.text();
    }
  });
};

const ConfiguratorServices = {
  GetDatasources,
  GetConfigurationType,
  GetClientSetting,
  GetTimeZoneList,
  InsertUpdateSettings,
  DeleteSettings,
  SortClientSetting,
};

export default ConfiguratorServices;
