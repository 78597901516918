import $ from "jquery";
import { ConvertUTCDateToLocalDate_Format } from "./../../../../Helper/PageOperations";

const barChartSettingColumnConfig = (data) => {
  if (data.barname != undefined && data.barname != "") {
    data.barname = _removeSpaceAndUnderscore(data.barname);
  }

  if (data.stackcategory != undefined && data.stackcategory != "") {
    data.stackcategory = _removeSpaceAndUnderscore(data.stackcategory);
  }

  if (data.barvalues != undefined && data.barvalues != "") {
    data.barvalues = _removeSpaceAndUnderscore(data.barvalues);
  }
  return data;
};

const _removeSpaceAndUnderscore = (val) => {
  if (val.indexOf("_") > -1) {
    val = val.replace(/_/g, "");
  }

  if (val != undefined && val != "") {
    val = val.replace(/ /g, "");
  }

  return val;
};

const getSortedData = (data, prop, isAsc) => {
  return data.sort((a, b) => {
    return (a[prop] < b[prop] ? -1 : 1) * (isAsc ? 1 : -1);
  });
};

const getBarWidgetDt = (data, gridData, categoriesColorsResp, colorsResp) => {
  let json2 = [];
  let salesPersonArray = [];
  let mainStackCategories = [];
  let categorySelected = false;
  let isDateField = false;

  //configure data
  data = barChartSettingColumnConfig(data);

  ///conversion
  //for (let i = 0; i < griddata1["Grid Meta-Data"].length; i++) {
  //	if (griddata1["Grid Meta-Data"][i]["dataField"] == [data.barname]) {
  //		if (griddata1["Grid Meta-Data"][i]["dataType"] == "date")
  //			isDateField = true;
  //	}
  //}

  if (isDateField) {
    for (let i = 0; i < gridData.length; i++) {
      let getDate = gridData[i][data.barname];
      let locDate = ConvertUTCDateToLocalDate_Format(getDate, "MM/D/YYYY");
      gridData[i][data.barname] = locDate;
    }
  }

  //filter by person name
  for (let i = 0; i < gridData.length; i++) {
    let salesPerson = gridData[i][data.barname];
    if (i == 0) {
      salesPersonArray.push(salesPerson);
    }
    let filteredBySalesPerson = [];
    filteredBySalesPerson = salesPersonArray.filter(function (row) {
      let element = row;
      if (element == null || element == undefined) {
        element = "Others";
      }
      if (element.indexOf(salesPerson) != -1) {
        return true;
      } else {
        return false;
      }
    });

    if (filteredBySalesPerson.length == 0) {
      salesPersonArray.push(salesPerson);
    }
  }

  // generate json data
  for (let i = 0; i < salesPersonArray.length; i++) {
    let Values = [];
    let filteredBySalesPersonData = [];
    filteredBySalesPersonData = gridData.filter(function (row) {
      let element = row[data.barname];
      if (element == null || element == undefined) {
        element = "Others";
      }
      if (element.indexOf(salesPersonArray[i]) != -1) {
        return true;
      } else {
        return false;
      }
    });

    if (
      data.barname != "" &&
      data.barvalues == "None" &&
      data.stackcategory == ""
    ) {
      let slotCount = 0;
      $.each(filteredBySalesPersonData, function () {
        slotCount++;
      });

      $.each(salesPersonArray, function () {
        if (this == salesPersonArray[i]) {
          Values.push({ label: this, value: slotCount });
        }
        //else {
        //	Values.push({ "label": this, "value": 0 });
        //}
      });
    } else if (
      data.barname != "" &&
      data.barvalues == "None" &&
      data.stackcategory != ""
    ) {
      categorySelected = true;
      //get activity name
      let activityNameArr = [];
      for (let j = 0; j < filteredBySalesPersonData.length; j++) {
        let activity = filteredBySalesPersonData[j][data.stackcategory];
        if (activity == null) {
          activity = "Others";
        }

        if (j == 0 || activity == "") {
          activityNameArr.push(activity);
        }

        let filteredByAcitvity = [];
        filteredByAcitvity = activityNameArr.filter(function (row) {
          let element = row;
          if (element == null || element == undefined) {
            element = "Others";
          }
          if (element.indexOf(activity) != -1) {
            return true;
          } else {
            return false;
          }
        });

        if (filteredByAcitvity.length == 0) {
          if (activity != "") {
            activityNameArr.push(activity);
          }
        }

        let isCategoryExist = mainStackCategories.filter(function (dt) {
          if (dt.indexOf(activity) > -1) return true;
          return false;
        });
        if (isCategoryExist.length == 0) {
          mainStackCategories.push(activity);
        }
      }

      let finalActivities = activityNameArr.sort();

      //get activity data and slots
      for (let k = 0; k < finalActivities.length; k++) {
        let slotCount = 0;
        let activityData = [];
        activityData = filteredBySalesPersonData.filter(function (row) {
          let element = row[data.stackcategory];
          if (element == null || element == undefined) {
            element = "Others";
          }
          if (element.indexOf(finalActivities[k]) != -1) {
            return true;
          } else {
            return false;
          }
        });

        $.each(activityData, function () {
          slotCount++;
        });

        Values.push({ label: finalActivities[k], value: slotCount });
      }
    } else if (
      data.barname != "" &&
      data.barvalues != "None" &&
      data.stackcategory == ""
    ) {
      let slotCount = 0;
      let j = 0;
      let barValToCheck = salesPersonArray[i];
      let valueArr = [];
      $.each(filteredBySalesPersonData, function () {
        if (barValToCheck == this[data.barname]) {
          let val = this[data.barvalues];
          //if (val != "" && val != null) {
          if (val != null) {
            slotCount = parseFloat(slotCount) + parseFloat(val);
            j++;
            valueArr.push(parseInt(val));
          }
        }
      });

      if (data.operationperform == "Sum") {
        $.each(salesPersonArray, function () {
          if (this == salesPersonArray[i]) {
            Values.push({ label: this, value: parseInt(slotCount) });
          }
          //else {
          //	Values.push({ "label": this, "value": 0 });
          //}
        });
      } else if (data.operationperform == "Average") {
        let op_val = parseInt(slotCount / j);
        if (isNaN(op_val)) {
          op_val = 0;
        }
        $.each(salesPersonArray, function () {
          if (this == salesPersonArray[i]) {
            Values.push({ label: this, value: op_val });
          }
          //else {
          //	Values.push({ "label": this, "value": 0 });
          //}
        });
      } else if (data.operationperform == "Max") {
        let op_val = parseInt(Math.max.apply(Math, valueArr));
        if (isNaN(op_val)) {
          op_val = 0;
        }

        $.each(salesPersonArray, function () {
          if (this == salesPersonArray[i]) {
            Values.push({ label: this, value: op_val });
          }
          //else {
          //	Values.push({ "label": this, "value": 0 });
          //}
        });
      } else if (data.operationperform == "Min") {
        let op_val = parseInt(Math.min.apply(Math, valueArr));
        if (isNaN(op_val)) {
          op_val = 0;
        }

        $.each(salesPersonArray, function () {
          if (this == salesPersonArray[i]) {
            Values.push({ label: this, value: op_val });
          }
          //else {
          //	Values.push({ "label": this, "value": 0 });
          //}
        });
      }
    } else {
      categorySelected = true;
      //get activity name
      let activityNameArr = [];
      for (let j = 0; j < filteredBySalesPersonData.length; j++) {
        let activity = filteredBySalesPersonData[j][data.stackcategory];
        if (activity == null) {
          activity = "Others";
        }

        if (j == 0 || activity == "") {
          activityNameArr.push(activity);
        }

        let filteredByAcitvity = [];
        filteredByAcitvity = activityNameArr.filter(function (row) {
          let element = row;
          if (element == null || element == undefined) {
            element = "Others";
          }
          if (element.indexOf(activity) != -1) {
            return true;
          } else {
            return false;
          }
        });

        //get the stack categories
        let isCategoryExist = mainStackCategories.filter(function (dt) {
          if (dt.indexOf(activity) > -1) return true;
          return false;
        });
        if (isCategoryExist.length == 0) {
          mainStackCategories.push(activity);
        }

        if (filteredByAcitvity.length == 0) {
          if (activity != "") {
            activityNameArr.push(activity);
          }
        }
      }

      let finalActivities = activityNameArr.sort();
      //get activity data and slots
      for (let k = 0; k < finalActivities.length; k++) {
        let slotCount = 0;
        let j = 0;
        let barValToCheck = finalActivities[k];
        let valueArr = [];
        $.each(filteredBySalesPersonData, function () {
          if (barValToCheck == this[data.stackcategory]) {
            let val = this[data.barvalues];
            if (val != null) {
              slotCount = parseFloat(slotCount) + parseFloat(val);
              j++;
              valueArr.push(parseInt(val));
            }
          }
        });
        if (data.operationperform == "Sum") {
          Values.push({ label: barValToCheck, value: parseInt(slotCount) });
        } else if (data.operationperform == "Average") {
          let op_val = parseInt(slotCount / j);
          if (isNaN(op_val)) {
            op_val = 0;
          }
          Values.push({ label: barValToCheck, value: op_val });
        } else if (data.operationperform == "Max") {
          let op_val = parseInt(Math.max.apply(Math, valueArr));
          if (isNaN(op_val)) {
            op_val = 0;
          }

          Values.push({ label: barValToCheck, value: op_val });
        } else if (data.operationperform == "Min") {
          let op_val = parseInt(Math.min.apply(Math, valueArr));
          if (isNaN(op_val)) {
            op_val = 0;
          }
          Values.push({ label: barValToCheck, value: op_val });
        }
      }
    }

    json2.push({ name: salesPersonArray[i], values: Values });
  }

  let fDt = [...json2];
  let getSortedDt = getSortedData(fDt, "name", 1);
  json2 = [...fDt];

  let colorsPallet = [];
  let colorsCategoryPallet = [];
  if (colorsResp != null && colorsResp.length > 0) {
    colorsPallet = json2.map((item) => {
      let getColor = colorsResp.find((cls) => {
        return cls.Value === item.name;
      });
      if (getColor != null) {
        return getColor.Colour;
      }
    });
  }

  let sortedArray = mainStackCategories.sort();
  if (categoriesColorsResp != null && categoriesColorsResp.length > 0) {
    colorsCategoryPallet = sortedArray.map((item) => {
      let getColor = categoriesColorsResp.find((cls) => {
        return cls.Value === item;
      });
      if (getColor != null) {
        return getColor.Colour;
      }
    });
  }

  return {
    barValues: json2,
    colorsPallet: colorsPallet,
    colorsCategoryPallet: colorsCategoryPallet,
    mainStackCategories: mainStackCategories,
    categorySelected: categorySelected,
  };
};

export const GetBarWidgetData = getBarWidgetDt;
