import { Fragment, React, useRef, useState } from "react";
import { useEffect } from "react";
import * as d3 from "d3";
import _ from "lodash";
// import { OOSDt } from "./OOSData";
import "./OOS.css";

const Margin = { LEFT: 30, RIGHT: 0, TOP: 0, BOTTOM: 30 };
const fontSize = "16px";

const OOSWidget = (
  svgElement,
  widgetId,
  _Height,
  Width,
  slots,
  xAxisMax,
  wonDollarValue,
  salesPersons,
  collorPallet
) => {
  let Height = _Height;
  let colors = [
    "#5f8b95",
    "#ba4d51",
    "#af8a53",
    "#955f71",
    "#859666",
    "#7e688c",
    "#91bdc7",
    "#ec7f83",
    "#e1bc85",
    "#c791a3",
    "#b7c898",
    "#B09ABE",
    "#2D5963",
    "#881B1F",
    "#7D5821",
    "#632D3F",
    "#536434",
    "#4C365A",
  ];

  colors.forEach(function (d) {
    colors.push(d);
  });

  if (collorPallet.length > 0) {
    colors = collorPallet;
  }

  let legends = {
    text: wonDollarValue,
    salesPerson: [...salesPersons],
  };

  //generate legend
  const getLegend = legendGenerator(colors, legends);
  document.getElementById(`${widgetId}-dom-legend`).innerHTML = `${getLegend}`;

  const legend = document.getElementById(`${widgetId}-dom-legend`);

  Height = _Height - legend.clientHeight - 1;

  const svg = svgElement;
  const x1Scale = d3.scaleLinear().domain([0, xAxisMax]).range([0, Width]);
  const yScale = d3.scaleBand(); //.paddingInner(0.1); //.paddingOuter(0.1);
  const xScale = d3.scaleBand(); //.paddingInner(0.1).paddingOuter(0.1);
  const y1Scale = d3.scaleLinear().domain([0, Height]).range([Height, 0]);
  let maxDollarValue_op = 0;

  // Remove Old Graph
  svg.selectAll("g").remove();
  svg.selectAll("text").remove();

  const dollarValueScale = d3
    .scaleLinear()
    .domain([4500, 13000])
    .range([37, 55]);

  const barGroup = svg
    .append("g")
    .attr("class", "bar")
    .attr("transform", `translate(${Margin.LEFT},${Margin.TOP})`);

  //draw x-axis back ground color
  const xAxisLowerPart = svg
    .append("g")
    .attr("class", "x-axis-lower")
    .attr("transform", `translate(${Margin.LEFT},${Margin.TOP})`);

  const yAxisGroup = svg
    .append("g")
    .attr("class", "y-axis-oos")
    .attr("transform", `translate(${Margin.LEFT},${Margin.TOP})`);

  const xAxisGroup = svg
    .append("g")
    .attr("class", "x-axis-oos")
    .attr("transform", `translate(${Margin.LEFT},${Height + Margin.TOP})`);

  const x1AxisGroup = svg
    .append("g")
    .attr("class", "x1-axis")
    .attr("transform", `translate(0,${Height})`);

  const y1AxisGroup = svg
    .append("g")
    .attr("class", "y1-axis")
    .attr("transform", `translate(${Margin.LEFT},${Margin.TOP})`);

  const activityBarGroup = svg
    .append("g")
    .attr("class", "activity-bar")
    .attr("transform", `translate(${Margin.LEFT},${Margin.TOP})`);

  const bubbleGroup = svg
    .append("g")
    .attr("class", "bubble-entity")
    .attr("transform", `translate(${Margin.LEFT},${Margin.TOP})`);

  const text = svg
    .append("text")
    .attr("x", 0)
    .attr("y", Height / 2)
    .attr("dy", ".35em");

  function drawChart(data, stages) {
    const minDollarValue = d3.min(
      data.map((x) => {
        return d3.min(x.bubbles.map((x) => x.dollarValue));
      })
    );

    const maxDollarValue = d3.max(
      data.map((x) => {
        return d3.max(x.bubbles.map((x) => x.dollarValue));
      })
    );

    maxDollarValue_op = maxDollarValue;

    //yScale.domain(data.map((x) => x.stageName)).range([0, Height]);
    yScale.domain(stages).range([0, Height]);

    dollarValueScale
      .domain([minDollarValue, maxDollarValue])
      .range([yScale.bandwidth() / 2, (yScale.bandwidth() / 2) * 1.5]);

    xScale
      //.domain(["0-30 days", "31-60 days", "61-90 days", ">90 days"])
      .domain(slots)
      .range([0, Width]);

    const yAxisCaller = yAxisGroup.call(
      d3.axisLeft(yScale).tickSizeInner(0).tickSizeOuter(0)
    );
    const xAxisCaller = xAxisGroup.call(
      d3.axisBottom(xScale).tickSizeInner(0).tickSizeOuter(0).tickPadding(10)
    );

    //const x1AxisCaller = x1AxisGroup.call(d3.axisBottom(x1Scale));

    //const y1AxisCaller = y1AxisGroup.call(d3.axisLeft(y1Scale));

    //

    let getMaxTspan = 0;

    yAxisCaller
      .selectAll("text")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "middle")
      .style("color", "#ffffff")
      .style("font-size", "14px")
      .style("font-weight", "500")
      .call(wrap, yScale.bandwidth());

    yAxisCaller.selectAll(".tick").attr("transform", (d, i, el) => {
      const tspanLength = d3.select(el[i]).selectAll("text tspan")
        ._groups[0].length;

      if (tspanLength > getMaxTspan) {
        getMaxTspan = tspanLength;
      }

      //calculation depend on the font-size of label e.g. 14
      return `translate(${tspanLength > 1 ? -14 * tspanLength : -14},${
        yScale(d) + yScale.bandwidth() / 2 - 4
      })`;
    });

    yAxisCaller.attr(
      "transform",
      `translate(${getMaxTspan * 14 + 18},${Margin.TOP})`
    );

    var element = d3.select(".y-axis-oos").node();
    const yAxisWidth = element.getBoundingClientRect().width + 5;

    //update x-axis
    xScale
      //.domain(["0-30 days", "31-60 days", "61-90 days", ">90 days"])
      .domain(slots)
      .range([0, Width - Math.abs(Margin.LEFT - yAxisWidth)]);

    const xAxisCaller1 = xAxisGroup.call(
      d3.axisBottom(xScale).tickSizeInner(0).tickSizeOuter(0).tickPadding(10)
    );

    xAxisCaller1.attr(
      "transform",
      `translate(${Margin.LEFT + Math.abs(Margin.LEFT - yAxisWidth)},${
        Height + Margin.TOP
      })`
    );

    //horizontal lines
    const _xArea = d3
      .select(".x-axis-oos")
      .selectAll("vertical-line")
      //.data(["0-30 days", "31-60 days", "61-90 days", ">90 days"]);
      .data(slots);
    _xArea.exit().remove();

    _xArea
      .enter()
      .append("line")
      .attr("class", "vertical-line")
      .attr("x1", (d, i) => {
        return xScale(d) + xScale.bandwidth();
      })
      .attr("x2", (d, i) => xScale(d) + xScale.bandwidth())
      .attr("y1", (d) => 0)
      .attr("y2", (d) => -Height)
      .attr("stroke", "#BFBFBF")
      .attr("fill", "#BFBFBF")
      .attr("stroke-width", "3")
      .style("stroke-dasharray", "10,5,1,5")
      .attr("shape-rendering", "crispEdges");

    //update x-axis linear scale
    const range_width = Width + Margin.LEFT;
    x1Scale.range([
      Margin.LEFT + Math.abs(Margin.LEFT - yAxisWidth),
      range_width,
    ]);

    //const x1AxisCaller1 = x1AxisGroup.call(d3.axisBottom(x1Scale));

    //console.log("x1Scale(10)", x1Scale(10) - Margin.LEFT);

    //xAxisLowerPart
    const lowerRect = xAxisLowerPart;

    //remove
    lowerRect.exit().remove();

    //update
    lowerRect
      .attr("y", Height)
      .attr("x", (d) => {
        return -yAxisWidth;
      })
      .attr("height", (d) => {
        return "30";
      })
      .attr("width", Width + yAxisWidth)
      .attr("fill", "#BFBFBF")
      .attr("shape-rendering", "crispEdges");

    //create
    lowerRect
      .append("rect")
      .attr("y", Height)
      .attr("x", (d) => {
        return -Margin.LEFT;
      })
      .attr("height", (d) => {
        return "30";
      })
      .attr("width", Width + Margin.LEFT + 1)
      .attr("fill", "#BFBFBF")
      .attr("shape-rendering", "crispEdges");

    lowerRect
      .append("text")
      .attr("x", -yAxisWidth / 2 + Math.abs(Margin.LEFT - yAxisWidth))
      .attr("y", Height + 20)
      .text("AGE")
      .attr("font-size", "14px")
      .style("font-weight", "bold")
      .attr("text-anchor", "middle")
      .attr("fill", "black");

    const rect = barGroup.selectAll("rect").data(data);

    //remove elaments
    rect.exit().remove();

    //update elements
    rect
      .attr("y", (d) => {
        return yScale(d["stageName"]);
      })
      .attr("x", (d) => {
        return -yAxisWidth;
      })
      .attr("height", (d) => {
        return yScale.bandwidth();
      })
      .attr("width", Width + yAxisWidth)
      .attr("fill", (d, i) => {
        if (d["stageName"].toLowerCase().indexOf("won") > -1) {
          return "#789440";
        }
        return i % 2 === 0 ? "#3F3F3F" : "#595959";
      })
      .attr("stroke", (d, i) => {
        if (d["stageName"].toLowerCase().indexOf("won") > -1) {
          return "#789440";
        }
        return i % 2 === 0 ? "#3F3F3F" : "#595959";
      });

    //add new elemets
    rect
      .enter()
      .append("rect")
      .attr("y", (d) => {
        return yScale(d["stageName"]);
      })
      .attr("x", (d) => {
        return -yAxisWidth;
      })
      .attr("height", (d) => {
        return yScale.bandwidth();
      })
      .attr("width", Width + yAxisWidth)
      .attr("fill", (d, i) => {
        if (d["stageName"].toLowerCase().indexOf("won") > -1) {
          return "#789440";
        }
        return i % 2 === 0 ? "#3F3F3F" : "#595959";
      })
      .attr("stroke", (d, i) => {
        if (d["stageName"].toLowerCase().indexOf("won") > -1) {
          return "#789440";
        }
        return i % 2 === 0 ? "#3F3F3F" : "#595959";
      });

    //draw bar lines
    const activityBar = activityBarGroup.selectAll("bargroup").data(data);

    //remove elaments
    activityBar.exit().remove();

    //create bar element
    activityBar.enter().append("g").attr("class", "bargroup").call(appendLine);

    // draw bubble element
    const bubbles = bubbleGroup.selectAll("bubblegroup").data(data);

    //remove bubble element
    bubbles.exit().remove();

    //create bubble element
    bubbles
      .enter()
      .append("g")
      .attr("class", "bubblegroup")
      .call(appendBubbles);

    d3.selectAll(".text-item").each(function (dt, i) {
      const rd = dollarValueScale(dt.dollarValue);
      const element = d3.select(this);
      const widthOfEle = element.node().getComputedTextLength();
      if (widthOfEle > rd * 2) {
        if (dt.isText) {
          const acronym = convertTextToAcronym(dt.text);
          element.text(acronym);
        } else {
          const dollarValueText = `$${d3.format(".2s")(dt.dollarValue)} (${
            dt.slots
          } slots)`;
          element.text(dollarValueText);
        }
      }
    });
  }

  function appendBubbles(element) {
    element.each(function () {
      let xgroup = 0;
      let trackIndex = 1;
      var getElement = d3.select(this).data()[0];

      const circle = d3
        .select(this)
        .selectAll("circle.bubble")
        .data(getElement["bubbles"]);

      circle
        .enter()
        .append("circle")
        .attr("class", "bubble")
        .attr("r", (d) => {
          const rd = dollarValueScale(d.dollarValue); //yScale.bandwidth() / 4 + 1;
          return rd;
        })
        .attr("cx", (d) => {
          const openDay = getAdjustedDayToAvoidCroppedBubble(d);
          return x1Scale(openDay) - Margin.LEFT;
        })
        .attr("cy", (d, i) => {
          const xgroup1 = xScale(d["x-axis"]);
          const index = Number(d["groupIndex"]) + 1; //Number(i) + 1;
          const maxHeight = yScale.bandwidth() + yScale.bandwidth() / 2;
          let activity = d.activityCount;
          if (activity > maxHeight / 2) {
            activity = maxHeight / 2;
          }

          const val =
            yScale(getElement["stageName"]) + yScale.bandwidth() / 2 + activity;

          const yVal = y1Scale(val);

          if (xgroup === xgroup1) {
            trackIndex++;
          }

          //handle multiple entities
          let incement = 0;
          if (index % 2 === 0) {
            incement = -yScale.bandwidth() / 3;
          }

          let yval1 =
            yScale(getElement["stageName"]) + yScale.bandwidth() / 2 + incement;
          if (yVal < 0) {
            yval1 += yVal;
          }
          return yval1;
        })
        .attr("stroke", (d) => {
          const salesPersonIndex = legends.salesPerson.findIndex(
            (x) => x === d.salesPerson
          );
          return colors[salesPersonIndex];

          if (getElement["stageName"].toLowerCase().indexOf("won") > -1) {
            return "#FFD100";
          }
          return "#a491ba";
        })
        .attr("fill", (d) => {
          const salesPersonIndex = legends.salesPerson.findIndex(
            (x) => x === d.salesPerson
          );
          return colors[salesPersonIndex];

          if (getElement["stageName"].toLowerCase().indexOf("won") > -1) {
            return "#FFD100";
          }
          return "#a491ba";
        })
        .attr("fill-opacity", 0.5)
        .attr("stroke-width", "1");

      //append text
      circle.enter().append("text").call(appendText, getElement["stageName"]);
    });

    function appendText(element, stageName) {
      element.each(function (dt, i) {
        var getElement = d3.select(this).data()[0];
        const index = Number(getElement["groupIndex"]) + 1;
        const nfObject = new Intl.NumberFormat("en-US");
        const formattedNumber = nfObject.format(getElement["dollarValue"]);
        const dollarValueText = `$${formattedNumber} (${getElement["activityCount"]} slots)`;
        const companyName = getElement["text"];
        const text = d3
          .select(this)
          .selectAll("bubble.text-item")
          .data([
            {
              text: companyName,
              dollarValue: getElement["dollarValue"],
              isText: true,
            },
            {
              text: dollarValueText,
              dollarValue: getElement["dollarValue"],
              slots: getElement["activityCount"],
              isText: false,
            },
          ]);

        text.remove().exit();

        text
          .enter()
          .append("tspan")
          .attr("class", "text-item")
          .attr("x", (d, i) => {
            const openDay = getAdjustedDayToAvoidCroppedBubble(getElement);
            //return x1Scale(getElement["day"]) - Margin.LEFT;
            return x1Scale(openDay) - Margin.LEFT;
          })
          .attr("y", (d, i) => {
            //adjust the vertical gap between company text and dollar value
            let yadj = 13;
            if (i == 0) {
              yadj = -5;
            }

            const maxHeight = yScale.bandwidth() + yScale.bandwidth() / 2;
            let activity = getElement["activityCount"];
            if (activity > maxHeight / 2) {
              activity = maxHeight / 2;
            }

            //check the height overlap the x-axis
            const val = yScale(stageName) + yScale.bandwidth() / 2 + activity;
            const yVal = y1Scale(val);

            //handle multiple entities
            let incement = 0;
            if (index % 2 === 0) {
              incement = -yScale.bandwidth() / 3;
            }

            let yval1 = yScale(stageName) + yScale.bandwidth() / 2 + incement;
            if (yVal < 0) {
              yval1 += yVal;
            }
            return yval1 + yadj;
          })
          .attr("fill", "#FFFFFF")
          .attr("text-anchor", "middle")
          .style("font-size", fontSize)
          .text((d) => d.text);

        //console.log("text length", text);
      });
    }
  }

  function getAdjustedDayToAvoidCroppedBubble(d) {
    const rd = dollarValueScale(d.dollarValue);
    const axisPart = Math.round(xAxisMax / 4);
    const conditionPart = parseInt(xAxisMax - parseInt(axisPart / 4));
    const day = parseInt(d.day);
    let openDay = day;
    if (day > conditionPart && day <= xAxisMax) {
      const slotArea = xScale.bandwidth() / axisPart;
      const adjustDayNumber = parseInt(rd / slotArea);
      openDay = xAxisMax - adjustDayNumber;
    }
    return openDay;
  }

  function appendLine(element) {
    element.each(function () {
      var getElement = d3.select(this).data()[0];
      const bar = d3
        .select(this)
        .selectAll("line.barline")
        .data(getElement["bubbles"]);
      bar
        .enter()
        .append("line")
        .attr("class", "barline")
        .attr("x1", (d) => {
          const openDay = getAdjustedDayToAvoidCroppedBubble(d);
          return x1Scale(openDay) - Margin.LEFT;
          //return x1Scale(d.day) - Margin.LEFT;
        })
        .attr("x2", (d) => {
          const openDay = getAdjustedDayToAvoidCroppedBubble(d);
          return x1Scale(openDay) - Margin.LEFT;
          //return x1Scale(d.day) - Margin.LEFT;
        })
        .attr("y1", (d, i) => {
          const index = Number(d["groupIndex"]) + 1;
          const maxHeight = yScale.bandwidth() + yScale.bandwidth() / 2;
          let activity = d.activityCount;
          if (activity > maxHeight / 2) {
            activity = maxHeight / 2;
          }

          //handle multiple entities
          let incement = 0;
          if (index % 2 === 0) {
            incement = -yScale.bandwidth() / 3;
          }

          let val =
            yScale(getElement["stageName"]) +
            yScale.bandwidth() / 2 -
            activity +
            incement;

          const val1 =
            yScale(getElement["stageName"]) + yScale.bandwidth() / 2 + activity;

          const yVal = y1Scale(val1);

          if (yVal < 0) {
            val += yVal;
          }

          return val;
        })
        .attr("y2", (d, i) => {
          const index = Number(d["groupIndex"]) + 1;
          const maxHeight = yScale.bandwidth() + yScale.bandwidth() / 2;
          let activity = d.activityCount;
          if (activity > maxHeight / 2) {
            activity = maxHeight / 2;
          }

          //handle multiple entities
          let incement = 0;
          if (index % 2 === 0) {
            incement = -yScale.bandwidth() / 3;
          }

          let val =
            yScale(getElement["stageName"]) +
            yScale.bandwidth() / 2 +
            activity +
            incement;

          const yVal = y1Scale(val);
          if (yVal < 0) {
            val += yVal;
          }

          return val;
        })
        .attr("stroke", "#BFBFBF")
        .attr("stroke-width", "4");

      //line upper connector
      bar
        .enter()
        .append("line")
        .attr("class", "line-connector")
        .attr("x1", (d) => {
          const openDay = getAdjustedDayToAvoidCroppedBubble(d);
          return x1Scale(openDay) - Margin.LEFT - 3.5;
          //return x1Scale(d.day) - Margin.LEFT - 3.5;
        })
        .attr("x2", (d) => {
          const openDay = getAdjustedDayToAvoidCroppedBubble(d);
          return x1Scale(openDay) - Margin.LEFT + 3.5;
          //return x1Scale(d.day) - Margin.LEFT + 3.5;
        })
        .attr("y1", (d, i) => {
          const index = Number(d["groupIndex"]) + 1;

          const maxHeight = yScale.bandwidth() + yScale.bandwidth() / 2;
          let activity = d.activityCount;
          if (activity > maxHeight / 2) {
            activity = maxHeight / 2;
          }

          //handle multiple entities
          let incement = 0;
          if (index % 2 === 0) {
            incement = -yScale.bandwidth() / 3;
          }

          let val =
            yScale(getElement["stageName"]) +
            yScale.bandwidth() / 2 -
            activity +
            incement;

          const val1 =
            yScale(getElement["stageName"]) + yScale.bandwidth() / 2 + activity;

          const yVal = y1Scale(val1);

          if (yVal < 0) {
            val += yVal;
          }

          return val;
        })
        .attr("y2", (d, i) => {
          const index = Number(d["groupIndex"]) + 1;

          const maxHeight = yScale.bandwidth() + yScale.bandwidth() / 2;
          let activity = d.activityCount;
          if (activity > maxHeight / 2) {
            activity = maxHeight / 2;
          }

          //handle multiple entities
          let incement = 0;
          if (index % 2 === 0) {
            incement = -yScale.bandwidth() / 3;
          }

          let val =
            yScale(getElement["stageName"]) +
            yScale.bandwidth() / 2 -
            activity +
            incement;

          const val1 =
            yScale(getElement["stageName"]) + yScale.bandwidth() / 2 + activity;

          const yVal = y1Scale(val1);

          if (yVal < 0) {
            val += yVal;
          }

          return val;
        })
        .attr("stroke", "#BFBFBF")
        .attr("stroke-width", "4")
        .attr("stroke-linecap", "round");

      //line lower connector
      bar
        .enter()
        .append("line")
        .attr("class", "line-connector")
        .attr("x1", (d) => {
          const openDay = getAdjustedDayToAvoidCroppedBubble(d);
          return x1Scale(openDay) - Margin.LEFT - 3.5;
          //return x1Scale(d.day) - Margin.LEFT - 3.5;
        })
        .attr("x2", (d) => {
          const openDay = getAdjustedDayToAvoidCroppedBubble(d);
          return x1Scale(openDay) - Margin.LEFT + 3.5;
          //return x1Scale(d.day) - Margin.LEFT + 3.5;
        })
        .attr("y1", (d, i) => {
          const index = Number(d["groupIndex"]) + 1;

          //handle multiple entities
          let incement = 0;
          if (index % 2 === 0) {
            incement = -yScale.bandwidth() / 3;
          }

          let val =
            yScale(getElement["stageName"]) +
            yScale.bandwidth() / 2 +
            d.activityCount +
            incement;

          const yVal = y1Scale(val);
          if (yVal < 0) {
            val += yVal;
          }
          return val;
        })
        .attr("y2", (d, i) => {
          const index = Number(d["groupIndex"]) + 1;

          //handle multiple entities
          let incement = 0;
          if (index % 2 === 0) {
            incement = -yScale.bandwidth() / 3;
          }

          let val =
            yScale(getElement["stageName"]) +
            yScale.bandwidth() / 2 +
            d.activityCount +
            incement;

          const yVal = y1Scale(val);
          if (yVal < 0) {
            val += yVal;
          }
          return val;
        })
        .attr("stroke", "#BFBFBF")
        .attr("stroke-width", "4")
        .attr("stroke-linecap", "round");
    });
  }

  function wrap(text, width) {
    text.each(function () {
      let text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        //lineNumber = 0,
        lineHeight = 1.2, // ems
        x = text.attr("x"),
        y = text.attr("y"),
        dy = 0; // parseFloat(text.attr("dy")),
      let tspan = text
        .text(null)
        .append("tspan")
        .style(
          "font-family",
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji'
        )
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em");
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .style(
              "font-family",
              '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji'
            )
            .attr("x", x)
            .attr("y", y)
            //.attr("dy", ++lineNumber * lineHeight + dy + "em")
            .attr("dy", lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }

  function convertTextToAcronym(text) {
    const words = text.split(" ");
    let acronyms = "";

    for (const word of words) {
      acronyms += word[0];
    }

    return acronyms.toLocaleUpperCase();
  }

  function legendGenerator(_colors, legends) {
    let colorsObj = {};

    colorsObj = _colors;

    let barLegendDOM = "";
    let lineLegendDOM = "";
    const nfObject = new Intl.NumberFormat("en-US");
    const formattedNumber = nfObject.format(legends.text);

    const labelDOM =
      "<div style=\"display:inline-block;margin-left:4px;color:#FFFFFF;font-family: 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;font-weight: 500;font-size: 14px;\">Closed Won Value: ".concat(
        `$${formattedNumber}`,
        "</div>"
      );
    barLegendDOM += "<div class='legendBoxContent'>".concat(labelDOM, "</div>");

    barLegendDOM =
      "<div class='barLegendBox' style=\"flex-basis: 50% !important;justify-content: flex-start;\">".concat(
        barLegendDOM,
        "</div>"
      );

    if (legends.salesPerson.length > 0) {
      for (let j = 0; j < legends.salesPerson.length; j++) {
        let _color = colorsObj[j];

        const _boxDOM =
          '<div style="display:inline-block;width:14px;height:14px;border-radius:6px;background:'.concat(
            _color,
            '"></div>'
          );
        const _labelDOM =
          "<div style=\"display:inline-block;margin-left:4px;color:#FFFFFF;font-family: 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;font-weight: 400;font-size: 14px;\">".concat(
            legends.salesPerson[j],
            "</div>"
          );
        lineLegendDOM +=
          '<div style="display:flex;align-items:center;margin-right:10px;padding-bottom:5px;">'
            .concat(_boxDOM)
            .concat(_labelDOM, "</div>");
      }
    }

    lineLegendDOM =
      '<div style="display:flex;flex-wrap: wrap;flex-basis:50%;justify-content:flex-end;">'.concat(
        lineLegendDOM,
        "</div>"
      );

    let legendHtml =
      '<div style="display:flex;flex-wrap: wrap;box-sizing: border-box;">'
        .concat(barLegendDOM)
        .concat(lineLegendDOM, "</div>");

    return legendHtml;
  }

  return { draw: drawChart };
};

const OOSD3Component = ({
  widgetId,
  widgetDt,
  height,
  width,
  getWidgetSize,
  wid,
  slots,
  xAxisMax,
  wonDollarValue,
  salesPersons,
  stages,
  collorPallet,
}) => {
  const d3Container = useRef(null);
  const [backgroundColor, setBackgroundColor] = useState("");
  const adjustedHeight = height - Margin.TOP - Margin.BOTTOM;
  const adjustedWidth = width - Margin.RIGHT - Margin.LEFT;

  useEffect(() => {
    getWidgetSize();

    if (!_.isEmpty(widgetDt) && height > 0 && width > 0) {
      const svg = d3.select(d3Container.current);
      const chart = OOSWidget(
        svg,
        widgetId,
        adjustedHeight,
        adjustedWidth,
        slots,
        xAxisMax,
        wonDollarValue,
        salesPersons,
        collorPallet
      );

      chart.draw(widgetDt, stages);
      const backgroundColor1 = slots.length % 2 === 0 ? "#3F3F3F" : "#595959";
      setBackgroundColor(backgroundColor1);
      //chart.draw(OOSDt);
    }
  }, [
    widgetDt,
    height,
    width,
    wid,
    getWidgetSize,
    slots,
    xAxisMax,
    wonDollarValue,
    salesPersons,
    stages,
    collorPallet,
  ]);

  return (
    <Fragment>
      <div id={widgetId} className="ooswidget">
        <div
          id={`${widgetId}-dom-legend`}
          data-elementid={widgetId}
          style={{
            padding: "5px 0",
            background: backgroundColor,
          }}
        ></div>
        <svg ref={d3Container} height={height} width={width}></svg>
      </div>
    </Fragment>
  );
};

export default OOSD3Component;
