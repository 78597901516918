import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../UI/Loader";
import { useEffect } from "react";
import { ConvertToLocalDateTimeWithFormat } from "../../../../Helper/WidgetDataCommon";
import RecycleBinService from "./RecycleBinService";
import { layoutActions } from "../../../../store/layout-slice";
import RotationServices from "../Rotation/RotationService";

const RestoreRotation = ({ resetToMainMenu }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.layout.UserId);
  const sessionId = useSelector((state) => state.login.sessionId);
  const [enableLoader, setEnableLoader] = useState(false);
  const [deleteRotations, setDeletedRotations] = useState([]);

  useEffect(() => {
    getDeletedRotations();
  }, []);

  const getDeletedRotations = () => {
    setEnableLoader(true);
    RecycleBinService.getDeletedRotations(sessionId)
      .then((resp) => {
        if (resp) {
          const rotation = [...resp.Message.Rotations];
          let _rotation = [...rotation].map((x) => {
            let item = { ...x };
            item["DeletedOn"] = ConvertToLocalDateTimeWithFormat(
              item.DeletedOn,
              "MMM DD YYYY, h:mm A"
            );
            return item;
          });

          setDeletedRotations(_rotation);
        }
        setEnableLoader(false);
      })
      .catch((err) => {
        console.log("getDeletedRotations err:", err);
        setEnableLoader(false);
      });
  };

  const restoreRotation = (id) => {
    RecycleBinService.restoreRotation(id)
      .then((res) => {
        getDeletedRotations();
        getRotations();
      })
      .catch((err) => {
        console.log("restoreRotation err:", err);
      });
  };

  const getRotations = () => {
    RotationServices.getRotation(userId)
      .then((res) => {
        if (res.Success) {
          dispatch(layoutActions.updateRotationList(res.Message.rotationlist));
        }
      })
      .catch((err) => {});
  };

  return (
    <div>
      {!enableLoader && (
        <div className="recycle-bin">
          <ul className="breadcrumb">
            <li>
              <a
                href=""
                onContextMenu={() => {
                  return false;
                }}
                onClick={(event) => {
                  event.preventDefault();
                  resetToMainMenu();
                }}
              >
                Rycycle Bin
              </a>
            </li>
            <li>Auto Rotations</li>
          </ul>
          <div className="main-container">
            <table
              className="table table-hover"
              style={{ tableLayout: "fixed", wordWrap: "break-word" }}
            >
              <thead>
                <tr>
                  <th>Rotation Name</th>
                  <th>Last Modified</th>
                  <th className="action-col">Action</th>
                </tr>
              </thead>
              <tbody>
                {deleteRotations.map((item) => {
                  return (
                    <tr key={item.Id}>
                      <td className="wrap-content" title={item.RotationName}>
                        {item.RotationName}
                      </td>
                      <td
                        className="wrap-content"
                        title={`${item.DeletedByUserName}, ${item.DeletedOn}`}
                      >{`${item.DeletedByUserName}, ${item.DeletedOn}`}</td>
                      <td style={{ textAlign: "center" }}>
                        <a
                          href=""
                          title="Restore Auto Rotation"
                          className="icons_cls"
                          style={{
                            fontSize: "18px",
                            color: "gray",
                            textDecoration: "none",
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            restoreRotation(item.Id);
                          }}
                        >
                          <i
                            title="Restore Auto Rotation"
                            className="fa fa-undo"
                          ></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {enableLoader && <Loader styles={{ height: "80px" }} />}
    </div>
  );
};

export default RestoreRotation;
