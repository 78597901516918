import { memo } from "react";
import { Fragment } from "react";
import ConfigrationConstant from "./ConfigurationConstant";
import ConfigurationConstant from "./ConfigurationConstant";
import DatePicker from "react-datepicker";
import { parse } from "date-fns";

const FormControl = ({
  column,
  timeZoneList,
  handleFormChange,
  index,
  isReadonly,
  teamList,
  columnList,
  salesOppOwnerList,
}) => {
  return (
    <Fragment>
      {column["type"] === "Textbox" && (
        <>
          <input
            className="form-control"
            type="text"
            value={column.value}
            name={column["dataField"]}
            onChange={(event) => handleFormChange(index, event)}
            disabled={isReadonly}
          />
          {column["errorMessage"] && (
            <span style={{ color: "red" }}>{column["errorMessage"]}</span>
          )}
        </>
      )}
      {column["type"] === "Clock" && (
        <>
          <input
            className="form-control"
            type="time"
            placeholder="HH:mm"
            title="Please enter valid value. The field is incomplete or has an invalid time."
            value={column.value}
            name={column["dataField"]}
            onChange={(event) => handleFormChange(index, event)}
            disabled={isReadonly}
          />
          {column["errorMessage"] && (
            <span style={{ color: "red" }}>{column["errorMessage"]}</span>
          )}
        </>
      )}
      {column["type"] === "Date" && (
        <>
          <DatePicker
            dateFormat="dd-MMM-yyyy"
            placeholderText="dd-mmm-yyyy"
            className="form-control"
            name={column["dataField"]}
            selected={
              !column.value
                ? null
                : parse(column.value, "dd-MMM-yyyy", new Date())
            }
            onChange={(event) => handleFormChange(index, event, true)}
          />
          {column["errorMessage"] && (
            <span style={{ color: "red" }}>{column["errorMessage"]}</span>
          )}
        </>
      )}
      {column["type"] === "Dropdown" && (
        <>
          <select
            className="form-control"
            onChange={(event) => handleFormChange(index, event)}
            value={column.value}
            name={column["dataField"]}
            disabled={isReadonly}
          >
            {column["dataField"] !== ConfigurationConstant.options && (
              <option value="">Select</option>
            )}

            {column["dataField"] === "msi_attempt" && (
              <>
                <option value="MSI">MSI</option>
                <option value="Attempt">Attempt</option>
              </>
            )}

            {column["dataField"] === "record_type" && (
              <>
                <option value="Set default capacity">
                  Set default capacity
                </option>
                <option value="Log variation">Log variation</option>
              </>
            )}

            {column["dataField"] === "sales_person" &&
              salesOppOwnerList.map((item, i) => {
                return (
                  <option key={i} value={item.sales_opp_owner}>
                    {item.sales_opp_owner}
                  </option>
                );
              })}

            {column["dataField"] === "time_zone" &&
              timeZoneList.map((item) => {
                return (
                  <option key={item.timezoneID} value={item.timezoneID}>
                    {item.timezone}
                  </option>
                );
              })}
            {(column["dataField"] === "team" ||
              column["dataField"] === "team_name") &&
              teamList.map((item) => {
                return (
                  <option key={item["settingsId"]} value={item["team_name"]}>
                    {item["team_name"]}
                  </option>
                );
              })}

            {column["dataField"] === "column" &&
              columnList.map((item) => {
                return (
                  <option key={item["dataField"]} value={item["dataField"]}>
                    {item["caption"]}
                  </option>
                );
              })}

            {column["dataField"] === ConfigrationConstant.options &&
              column["parameter"] ===
                ConfigrationConstant.Case_Aging_with_Promised_Date && (
                <>
                  <option value="As Late As Possible (ALAP)">
                    As Late As Possible (ALAP)
                  </option>
                  <option value="As Soon As Possible (ASAP)">
                    As Soon As Possible (ASAP)
                  </option>
                </>
              )}

            {column["dataField"] === ConfigrationConstant.options &&
              column["parameter"] === ConfigrationConstant.Total_Hours && (
                <>
                  <option value="Include Working Hours only">
                    Include Working Hours only
                  </option>
                  <option value="Include Non-Working Hours">
                    Include Non-Working Hours
                  </option>
                </>
              )}

            {column["dataField"] === ConfigrationConstant.options &&
              column["parameter"] ===
                ConfigrationConstant.If_Promised_Date_Equals_Opened_Date && (
                <>
                  <option value="Ignore Promised Date and calculate on Opened Date">
                    Ignore Promised Date and calculate on Opened Date
                  </option>
                  <option value="Include Promised Date">
                    Include Promised Date
                  </option>
                </>
              )}

            {column["dataField"] === ConfigrationConstant.options &&
              column["parameter"] ===
                ConfigrationConstant.If_Promised_Date_Greater_Closed_Date && (
                <>
                  <option value="Display 0%">Display 0%</option>
                  <option value="Display Negative %">Display Negative %</option>
                </>
              )}
          </select>
          {column["errorMessage"] && (
            <span style={{ color: "red" }}>{column["errorMessage"]}</span>
          )}
        </>
      )}
    </Fragment>
  );
};

export default memo(FormControl);
