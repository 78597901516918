import { Fragment, useEffect, useRef, useState, React } from "react";
import bulletBlackImg from "../../../../../../assets/bullet_black.png";
import bulletGreenImg from "../../../../../../assets/bullet_green.png";
import bulletRedImg from "../../../../../../assets/bullet_red.png";
import bulletYellowImg from "../../../../../../assets/bullet_yellow.png";

const TrafficSignalTemplate = (props) => {
  const specialText = "when value is <";
  const [percentageVal, setPercentageVal] = useState(false);

  useEffect(() => {
    setPercentageVal(props.percentageValue);
  }, [props]);

  const onPercentageValueChange = (event) => {
    setPercentageVal(event.target.checked);
    props.onPercentageValueChange(event.target.checked);
  };

  return (
    <Fragment>
      <p>Column for Traffic Lights</p>
      <select
        value-key="traffic"
        onChange={props.onTrafficLightHandler}
        value={props.trafficSignalVal}
      >
        <option value="None">None</option>
        {props.dtList.map((x, index) => {
          return (
            <option key={index} value={x.id}>
              {x.text}
            </option>
          );
        })}
      </select>
      <p>Icon rules</p>
      <input id="IsValid" type="hidden" value="0" />
      <div>
        <span>
          <img
            style={{ float: "left", paddingTop: "4px", marginRight: "5px" }}
            width="20"
            src={bulletBlackImg}
          />
        </span>
        <span style={{ paddingRight: "20px" }}>when value is</span>
        <span style={{ marginLeft: "82px" }}>
          ≥&nbsp;
          <input
            style={{ width: "50px" }}
            type="text"
            name="blackIcon"
            id="blackIcon"
            maxLength="5"
            onChange={props.OnChangeBlackIconHandler}
            onKeyUp={props.OnChangeBlackIconHandler}
            value-key="blackIcon"
            value={props.blackIconVal}
          />
        </span>
      </div>
      <div style={{ marginTop: "7px" }}>
        <span>
          <img
            style={{ float: "left", paddingTop: "4px", marginRight: "5px" }}
            width="20"
            src={bulletRedImg}
          />
        </span>
        <div style={{ float: "left", width: "190px" }}>
          {specialText} <span id="blackValue">{props.blackIconVal}</span> and
        </div>
        <span>
          ≥&nbsp;
          <input
            type="text"
            name="redIcon"
            id="redIcon"
            onChange={props.OnChangeRedIconHandler}
            maxLength="5"
            onKeyUp={props.OnChangeRedIconHandler}
            style={{ width: "50px" }}
            value-key="redIcon"
            value={props.redIconVal}
          />
        </span>
      </div>
      <div style={{ marginTop: "7px" }}>
        <span>
          <img
            style={{ float: "left", paddingTop: "4px", marginRight: "5px" }}
            width="20"
            src={bulletYellowImg}
          />
        </span>
        <div style={{ float: "left", width: "190px" }}>
          {specialText} <span id="redValue">{props.redIconVal}</span> and
        </div>
        <span>
          ≥&nbsp;
          <input
            type="text"
            name="yellowIcon"
            id="yellowIcon"
            onChange={props.OnChangeYellowIconHandler}
            maxLength="5"
            onKeyUp={props.OnChangeYellowIconHandler}
            style={{ width: "50px" }}
            value-key="yellowIcon"
            value={props.yellowIconVal}
          />
        </span>
      </div>
      <div style={{ marginTop: "7px" }}>
        <span>
          <img
            style={{ float: "left", paddingTop: "4px", marginRight: "5px" }}
            width="20"
            src={bulletGreenImg}
          />
        </span>
        <span style={{ paddingRight: "20px" }}>
          {specialText} <span id="yellowValue">{props.yellowIconVal}</span>
        </span>
      </div>
      <div
        id="IconRuleError"
        style={{
          color: "red",
          fontSize: "14px",
          paddingTop: "10px",
          display: "none",
        }}
      >
        Each value must be LESS THAN the value above{" "}
      </div>
      <div style={{ marginTop: "18px", marginLeft: "6px" }} id="percentageChk">
        <label
          style={{
            wordWrap: "break-word",
            fontWeight: "normal",
            cursor: "pointer",
          }}
        >
          <input
            type="checkbox"
            style={{
              verticalAlign: "text-top",
              height: "auto",
              width: "auto",
              margin: "3px",
            }}
            // defaultChecked={percentageVal}
            checked={percentageVal}
            onChange={onPercentageValueChange}
          />
          Hide Values
        </label>
      </div>
      <br />
    </Fragment>
  );
};

export default TrafficSignalTemplate;
