import React from "react";
import styles from "./WidgetButton.module.css";

const SaveWidgetButton = (props) => {
  return (
    <div
      className={`${styles["save-button"]} settings-btn-dark`}
      onClick={props.onClick}
      tabIndex="0"
    >
      <div className={styles["save-button-content"]}>
        <span className={styles["save"]}>{props.text || "Save"}</span>
      </div>
    </div>
  );
};

export default SaveWidgetButton;
