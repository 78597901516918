import React, { useState } from "react";
import Modal from "../../../UI/Modal";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../../../store/layout-slice";
import { DatasourceList } from "./Datasources";
import "./index.css";
import { ConfigurationType } from "./ClientSettings/ConfigurationType";

export const ConfiguratorSetting = () => {
  const dispatch = useDispatch();
  const onCloseHandler = () => {
    dispatch(layoutActions.setConfiguratorPopup());
  };
  const [showDatasources, setShowDatasources] = useState(true);
  const [datasourceDetails, setDatasourceDetails] = useState({
    id: "",
    name: "",
    type: "",
  });

  const selectedDatatsourceHandler = (item, isEnabledDatasource) => {
    setDatasourceDetails(item);
    setShowDatasources(isEnabledDatasource);
  };

  return (
    <Modal
      title="Datasource Configuration"
      width={{ width: "850px" }}
      onClose={onCloseHandler}
      contentCss={{ padding: "0px" }}
    >
      <div style={{ display: showDatasources ? "block" : "none" }}>
        <DatasourceList onSelectDatasource={selectedDatatsourceHandler} />
      </div>

      {!showDatasources && (
        <ConfigurationType
          dataSourceTitle={datasourceDetails.name}
          datasourceId={datasourceDetails.id}
          dataSourceType={datasourceDetails.type}
          onDatasourceClick={selectedDatatsourceHandler}
        />
      )}
    </Modal>
  );
};
