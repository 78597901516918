import { Fragment, useState, React } from "react";

const BarChartContent = (props) => {
  return (
    <Fragment>
      <p>Bar Name</p>

      <select
        value-key="barname"
        onChange={props.onChangeBarname}
        value={props.barname.value}
      >
        <option value="">Please Select</option>
        {props.ddlList.map((item, i) => {
          return (
            <option key={i} value={item.id}>
              {item.text}
            </option>
          );
        })}
      </select>
      {!props.barname.isValid && (
        <span style={{ color: "red", paddingLeft: "5px" }}>Required</span>
      )}

      <p>Bar Values</p>
      <select
        value-key="barvalues"
        onChange={props.onChangeBarValues}
        value={props.barvalue}
      >
        <option value="None">None (Category Items will be Counted)</option>
        {props.valueList.map((item, i) => {
          return (
            <option key={i} value={item.id}>
              {item.text}
            </option>
          );
        })}
      </select>

      <p>Operation To Perform</p>

      <select
        value-key="operationperform"
        onChange={props.onChangeOperationperform}
        value={props.operationperform}
      >
        {props.barvalue === "None" && (
          <option value="N/A">Not Applicable</option>
        )}
        {props.barvalue !== "None" && (
          <>
            <option value="Sum">Sum</option>
            <option value="Average">Average</option>
            <option value="Max">Max</option>
            <option value="Min">Min</option>
          </>
        )}
      </select>

      <p>Stack Category</p>

      <select
        value-key="stackcategory"
        onChange={props.onChangeStackcategory}
        value={props.stackcategory}
      >
        <option value="">None</option>
        {props.ddlList.map((item, i) => {
          return (
            <option key={i} value={item.id}>
              {item.text}
            </option>
          );
        })}
      </select>

      <p>Show Values (Callouts)</p>
      <select
        value-key="callouts"
        onChange={props.onChangeCallouts}
        value={props.callout}
      >
        <option value="">Off</option>
        <option value="On">On</option>
      </select>
    </Fragment>
  );
};

export default BarChartContent;
