import { Fragment, React } from "react";

const TextContent = (props) => {
  return (
    <Fragment>
      <p>
        <strong>Text Value</strong>
      </p>

      <select
        value-key="tableheaderlist"
        onChange={props.onChangeHeadList}
        value={props.tableHeaderVal}
      >
        <option value="">Please Select</option>
        {props.tableHeaderList.map((item, i) => {
          return (
            <option key={i} value={item.id}>
              {item.text}
            </option>
          );
        })}
      </select>
      <br />
      <br />
      <textarea
        onChange={props.onChangeSubsectiontitle}
        value-key="subsectiontitle"
        style={{ width: "300px" }}
        value={props.subSectionTitleVal.value}
      ></textarea>
      {!props.subSectionTitleVal.isValid && (
        <span style={{ color: "red", paddingLeft: "5px" }}>Required</span>
      )}

      <p>
        <strong>Text Color</strong>
      </p>

      <select
        onChange={props.onChangeTableHeaderListcolor}
        value-key="tableheaderlistcolor"
        value={props.tableHeaderListColorVal}
      >
        <option value="">Please Select</option>
        {props.tableHeaderList.map((item, i) => {
          return (
            <option key={i} value={item.id}>
              {item.text}
            </option>
          );
        })}
      </select>
      <br />
      <br />
      <select
        value-key="textcolor"
        onChange={props.onChangeTextColor}
        value={props.textColorVal}
      >
        <option value="#808080">Grey</option>
        <option value="#E55253">Red</option>
        <option value="#9AB57E">Green</option>
        <option value="#E8C267">Yellow</option>
      </select>
      <p>
        <strong>Text Justification</strong>
      </p>
      <select
        value-key="justification"
        onChange={props.onChangeJustification}
        value={props.justificationVal}
      >
        <option value="left">Left</option>
        <option value="right">Right</option>
        <option value="center">Center</option>
      </select>
    </Fragment>
  );
};

export default TextContent;
