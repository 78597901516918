import { useEffect, useRef, React } from "react";
import * as d3 from "d3";

const pieChartObject = (
  mainSvg,
  elementId,
  finalDt,
  showCallOut,
  showPercentage,
  colorsPallet,
  width,
  height
) => {
  let columnObject = {};
  // set the dimensions and margins of the graph
  //let width = document.getElementById(elementId).offsetWidth - 30; //450
  //let height = document.getElementById(elementId).offsetHeight - 30; //450
  let margin = 30;
  let calcRadius = 0;
  //let marginRight = 10;
  //let marginTop = 10;

  // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
  let radius = Math.min(width, height) / 2 - margin;

  // legend dimensions
  let legendRectSize = 12; // defines the size of the colored squares in legend
  let legendSpacing = 10; // defines spacing between squares

  let svg = mainSvg
    .append("g")
    .attr("class", `pie_series_${elementId}`)
    .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

  let defs = mainSvg.append("defs");
  let data = finalDt;

  let tots = d3.sum(data, function(d) {
    return d.count;
  });

  data.forEach(function(d) {
    d.count = +d.count; // calculate count as we iterate through the data
    d.enabled = true; // add enabled property to track which entries are checked
  });

  data.forEach(function(d) {
    d.percentage = d.count / tots;
  });

  let maxCharacter = 0;
  let namesArr = [];
  for (let index = 0; index < data.length; index++) {
    namesArr.push(data[index]["name"]);
  }
  maxCharacter = arrayItemMax(namesArr);

  //calculate colums
  let w = parseFloat(maxCharacter) * 6.27 + legendRectSize;
  let calcColumn = parseInt(width / w);
  if (calcColumn > 4) {
    calcColumn = 4;
  }

  if (calcColumn < 0 || calcColumn === 0) {
    calcColumn = 1;
  }

  // set the color scale
  let color = d3
    .scaleOrdinal()
    //.domain(data)
    .range([
      "#5f8b95",
      "#ba4d51",
      "#af8a53",
      "#955f71",
      "#859666",
      "#7e688c",
      "#91bdc7",
      "#ec7f83",
      "#e1bc85",
      "#c791a3",
      "#b7c898",
      "#B09ABE",
      "#2D5963",
      "#881B1F",
      "#7D5821",
      "#632D3F",
      "#536434",
      "#4C365A",
    ]);

  if (colorsPallet != undefined && colorsPallet.length > 0) {
    //mainSvg.attr("colorsPallets", JSON.stringify(colorsPallet));
    color = d3.scaleOrdinal().range(colorsPallet);
  }

  // Compute the position of each group on the pie:
  const pie = d3
    .pie()
    //.startAngle(Math.PI / 2) // <-- Setting startAngle of the layout
    //.endAngle(Math.PI * 2 + Math.PI / 2) // <-- and endAngle
    .value(function(d) {
      return d.count;
    })
    .sort(null);
  //let data_ready = pie(d3.entries(data))
  let data_ready = pie(data);

  // The arc generator
  let arc = d3
    .arc()
    .innerRadius(radius * 1) // This is the size of the donut hole
    .outerRadius(radius);

  // Another arc that won't be drawn. Just for labels positioning
  let outerArc = d3
    .arc()
    .innerRadius(radius * 1.2)
    .outerRadius(radius * 0.9);

  let nodeWidth = (d) => d.getBBox().width;

  // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
  let legend = mainSvg
    .append("g")
    .attr("class", `legend_${elementId}`)
    .style("opacity", 0)
    .attr("transform", "translate(0,0)");
  let lg = legend
    .selectAll("g")
    .data(data_ready)
    .enter()
    .append("g")
    .attr("transform", position);
  lg.append("rect") // append rectangle squares to legend
    .attr("width", legendRectSize) // width of rect size is defined above
    .attr("height", legendRectSize) // height of rect size is defined above
    .style("fill", function(d) {
      return color(d.data.name);
    }) // each fill is passed a color
    //.style('stroke', function(d){ return(color(d.data.key)) }) // each stroke is passed a color
    .on("click", function(label) {});

  lg.append("text")
    .attr("x", legendRectSize + legendSpacing - 2)
    .attr("y", legendRectSize - 2)
    .style("fill", "#767676")
    .style(
      "font-family",
      "'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana"
    )
    .style("font-weight", "400")
    .style("font-size", "12px")
    .style("cursor", "default")
    .text(function(d) {
      return d.data.name;
    }); // return label

  let getHeightOfLegends = d3
    .select(`.legend_${elementId}`)
    .node()
    .getBBox();
  let getHeightOfLegend = getHeightOfLegends.height;
  let calcHeightOfPie = height - (getHeightOfLegend + margin);

  //if (getHeightOfLegend > calcHeightOfPie)
  let getPercentageOfLegendHeight = (getHeightOfLegend / height) * 100;
  if (getPercentageOfLegendHeight > 65 || w > width) {
    calcHeightOfPie = height;
  }

  if (height > 238) {
    radius = Math.min(width, calcHeightOfPie) / 2 - margin;
  } else {
    if (showCallOut) radius = Math.min(width, calcHeightOfPie) / 2 - 20;
    else radius = Math.min(width, calcHeightOfPie) / 2;
  }

  calcRadius = radius;
  if (showCallOut) {
    // radius = radius - (radius / 9.075);// 40;
    radius = radius - radius / 8.075; // 40;
  }

  //d3.selectAll(`pie_series_${elementId}`).attr("transform", "translate(" + ((width / 2) - 14) + "," + calcHeightOfPie / 2 + ")");
  svg.attr(
    "transform",
    "translate(" + width / 2 + "," + calcHeightOfPie / 2 + ")"
  );

  d3.select(`.legend_${elementId}`).remove();

  let arc1 = d3
    .arc()
    .innerRadius(0)
    .outerRadius(radius);

  arc = d3
    .arc()
    .innerRadius(radius * 1)
    .outerRadius(radius);

  outerArc = d3
    .arc()
    .innerRadius(radius * 1.2)
    .outerRadius(radius * 1.2);

  let path = svg
    .selectAll("path")
    .data(data_ready)
    .enter()
    .append("path")
    .attr("class", `${elementId}_pie_slice`)
    .attr(
      "d",
      d3
        .arc()
        .innerRadius(0)
        .outerRadius(radius)
    )
    .attr("fill", function(d) {
      return color(d.data.name);
    })
    .attr("stroke", "#ffffff")
    .style("stroke-width", "0")
    //.style('shape-rendering', 'crispedges')
    .each(function(d) {
      return this._current - d;
    });

  path
    .transition()
    .duration(1000)
    .attrTween("d", function(d) {
      // 'd' specifies the d attribute that we'll be animating
      let interpolate = d3.interpolate(this._current, d); // this = current path element
      this._current = interpolate(0); // interpolate between current value and the new value of 'd'
      return function(t) {
        this._current = interpolate(t);
        let arc = d3
          .arc()
          .innerRadius(0)
          .outerRadius(radius);
        return arc(this._current);
      };
    });

  path
    .on("mouseover", function(event, dt) {
      let getColorCode = d3.select(this).style("fill");
      createPiePattern(defs, getColorCode);
      createLegendPattern(defs, getColorCode);
      d3.select(this).style("fill", "url(#test1)");

      mainSvg
        .selectAll(`.legend_${elementId}`)
        .selectAll("rect")
        .style("fill", function(d) {
          if (dt.data.name === d.data.name) {
            return "url(#legend_pattern)";
          } else {
            return color(d.data.name);
          }
        });
    })
    .on("mouseleave", function() {
      defs.selectAll("pattern").remove();
      d3.select(this).style("fill", function(d) {
        return color(d.data.name);
      });
      mainSvg
        .selectAll(`.legend_${elementId}`)
        .selectAll("rect")
        .style("fill", function(d) {
          return color(d.data.name);
        });
    })
    .on("click", function(event, label) {
      let rect = d3.select(this); // this refers to the colored squared just clicked
      let enabled = false; // set enabled true to default

      mainSvg
        .selectAll(`.legend_${elementId}`)
        .selectAll("g")
        .filter(function(d) {
          return d.data.name == label.data.name;
        })
        .attr("class", "disabled");

      pie.value(function(d) {
        if (d.name === label.data.name) d.enabled = enabled; // if entry label matches legend label
        return d.enabled ? d.count : 0; // update enabled property and return count or 0 based on the entry's status
      });

      data_ready = pie(data);
      path = path.data(data_ready); // update pie with new data
      path
        .transition() // transition of redrawn pie
        .duration(1000) //
        .attrTween("d", function(d) {
          // 'd' specifies the d attribute that we'll be animating
          let interpolate = d3.interpolate(this._current, d); // this = current path element
          this._current = interpolate(0); // interpolate between current value and the new value of 'd'
          return function(t) {
            this._current = interpolate(t);
            let arc = d3
              .arc()
              .innerRadius(0)
              .outerRadius(radius);
            return arc(this._current);
          };
        });

      if (showCallOut) {
        makePolyLines();
        labelBox(showPercentage, data_ready);
      }
    });

  if (showCallOut) {
    // Add the polylines between chart and labels:
    svg
      .selectAll("allPolylines")
      .data(data_ready)
      .enter()
      .append("polyline")
      .attr("stroke", function(d) {
        return color(d.data.name);
      })
      .style("fill", "none")
      .attr("stroke-width", 0.5)
      .attr("points", function(d) {
        let posA = arc.centroid(d); // line insertion in the slice
        let posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
        let posC = outerArc.centroid(d); // Label position = almost the same as posB
        let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] = radius * 1.2 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
        return [posA, posB, posC];
      });
    //makePolyLines();
    labelBox(showPercentage);
  }

  if (getPercentageOfLegendHeight < 65 && width > w) {
    legend = mainSvg
      .append("g")
      .attr("class", `legend_${elementId}`)
      .attr("transform", function(d) {
        return "translate(" + width / 4 + ",420)";
      });

    lg = legend
      .selectAll("g")
      .data(data_ready)
      .enter()
      .append("g")
      .attr("transform", position);
    lg.append("rect") // append rectangle squares to legend
      .attr("width", legendRectSize) // width of rect size is defined above
      .attr("height", legendRectSize) // height of rect size is defined above
      .style("fill", function(d) {
        return color(d.data.name);
      })
      .style("shape-rendering", "crispedges") // each fill is passed a color
      //.style('stroke', function(d){ return(color(d.data.key)) }) // each stroke is passed a color
      .on("click", function(event, label) {
        let getParentNode = d3.select(this.parentNode);
        let rect = d3.select(this); // this refers to the colored squared just clicked
        let enabled = true; // set enabled true to default
        let totalEnabled = d3.sum(
          data_ready.map(function(d) {
            // can't disable all options
            return d.data.enabled ? 1 : 0; // return 1 for each enabled entry. and summing it up
          })
        );

        if (getParentNode.attr("class") === "disabled") {
          // if class is disabled
          getParentNode.attr("class", ""); // remove class disabled
        } else {
          // else
          // if (totalEnabled < 2) return; // if less than two labels are flagged, exit
          getParentNode.attr("class", "disabled"); // otherwise flag the square disabled
          enabled = false; // set enabled to false
        }

        pie.value(function(d) {
          if (d.name === label.data.name) d.enabled = !d.enabled; // if entry label matches legend label
          return d.enabled ? d.count : 0; // update enabled property and return count or 0 based on the entry's status
        });

        data_ready = pie(data);
        path = path.data(data_ready); // update pie with new data
        path
          .transition() // transition of redrawn pie
          .duration(1000) //
          .attrTween("d", function(d) {
            // 'd' specifies the d attribute that we'll be animating
            let interpolate = d3.interpolate(this._current, d); // this = current path element
            this._current = interpolate(0); // interpolate between current value and the new value of 'd'
            let arc = d3
              .arc()
              .innerRadius(0)
              .outerRadius(radius);
            return function(t) {
              return arc(interpolate(t));
            };
          });

        if (showCallOut) {
          makePolyLines();
          labelBox(showPercentage, data_ready);
        }
      })
      .on("mouseover", function(event, dt) {
        let getColorCode = d3.select(this).style("fill");
        createPiePattern(defs, getColorCode);
        createLegendPattern(defs, getColorCode);
        svg.selectAll("path").style("fill", function(d) {
          if (dt.data.name == d.data.name) {
            return "url(#test1)";
          }
        });
        d3.select(this).style("fill", "url(#legend_pattern)");
      })
      .on("mouseleave", function() {
        defs.selectAll("pattern").remove();
        d3.select(this).style("fill", function(d) {
          return color(d.data.name);
        });
        svg.selectAll("path").style("fill", function(d) {
          return color(d.data.name);
        });
      });

    lg.append("text")
      .attr("x", legendRectSize + legendSpacing - 2)
      .attr("y", legendRectSize - 2)
      .style("fill", "#767676")
      .style(
        "font-family",
        "'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana"
      )
      .style("font-weight", "400")
      .style("font-size", "12px")
      .style("cursor", "default")
      .text(function(d) {
        return d.data.name;
      })
      .on("click", function(label) {
        let getParentNode = d3.select(this.parentNode);
        let rect = d3.select(this); // this refers to the colored squared just clicked
        let enabled = true; // set enabled true to default
        let totalEnabled = d3.sum(
          data_ready.map(function(d) {
            // can't disable all options
            return d.data.enabled ? 1 : 0; // return 1 for each enabled entry. and summing it up
          })
        );

        if (getParentNode.attr("class") === "disabled") {
          // if class is disabled
          //rect.attr('class', ''); // remove class disabled
          getParentNode.attr("class", "");
        } else {
          //if (totalEnabled < 2) return; // if less than two labels are flagged, exit
          //rect.attr('class', 'add_opacity'); // otherwise flag the square disabled
          getParentNode.attr("class", "disabled");
          enabled = false; // set enabled to false
        }

        pie.value(function(d) {
          if (d.name === label.data.name) d.enabled = !d.enabled; // if entry label matches legend label
          return d.enabled ? d.count : 0; // update enabled property and return count or 0 based on the entry's status
        });

        data_ready = pie(data);
        path = path.data(data_ready); // update pie with new data
        path
          .transition() // transition of redrawn pie
          .duration(1000) //
          .attrTween("d", function(d) {
            // 'd' specifies the d attribute that we'll be animating
            let interpolate = d3.interpolate(this._current, d); // this = current path element
            this._current = interpolate(0); // interpolate between current value and the new value of 'd'
            let arc = d3
              .arc()
              .innerRadius(0)
              .outerRadius(radius);
            return function(t) {
              return arc(interpolate(t));
            };
          });

        if (showCallOut) {
          makePolyLines();
          labelBox(showPercentage, data_ready);
        }
      })
      .on("mouseover", function(ev, dt) {
        let getColorCode = color(dt.data.name);
        createPiePattern(defs, getColorCode);
        createLegendPattern(defs, getColorCode);
        svg.selectAll("path").style("fill", function(d) {
          if (dt.data.name == d.data.name) {
            return "url(#test1)";
          }
        });

        lg.selectAll("rect").style("fill", function(d) {
          if (dt.data.name == d.data.name) {
            return "url(#legend_pattern)";
          } else {
            return color(d.data.name);
          }
        });
      })
      .on("mouseleave", function() {
        defs.selectAll("pattern").remove();
        svg.selectAll("path").style("fill", function(d) {
          return color(d.data.name);
        });
        lg.selectAll("rect").style("fill", function(d) {
          return color(d.data.name);
        });
      });

    let increment = 0;
    columnObject = {};

    legend.selectAll("g").each(function(d) {
      let columnIndex = increment % calcColumn;
      let getText = d3.select(this);
      let getWidth = getText.node().getBBox().width;

      let columnName = "column" + (increment % calcColumn);
      if (columnObject[columnName] !== undefined) {
        if (getWidth > columnObject[columnName])
          columnObject[columnName] = getWidth;
      } else {
        columnObject[columnName] = getWidth;
      }
      increment++;
    });

    //update position of legend text
    lg.attr("transform", updatePosition);

    //update position of legend graph
    legend.attr("transform", function(d) {
      let getWidth = d3
        .select(this)
        .node()
        .getBBox();
      //set the position center
      let positionCalc = (width - getWidth.width) / 2;
      if (height <= 238) {
        if (showCallOut) calcRadius = calcRadius - 20;
        else calcRadius = calcRadius - margin;
      }
      return (
        "translate(" +
        positionCalc +
        "," +
        (calcHeightOfPie / 2 + calcRadius + 45) +
        ")"
      );
    });
  }

  function position(d, i) {
    let charLength = d.data.name.length;
    //let w = 180 + legendRectSize + legendSpacing+ maxCharacter; // width of each entry (so you can position the next column)

    //   let calcColumn=parseInt(width/w);
    // if(calcColumn>4){
    //   calcColumn=4;
    // }
    let c = calcColumn; // number of columns
    //   let columnName='column'+(i % c);
    //   if(columnObject[columnName]!==undefined){
    //     if(charLength>columnObject[columnName])
    //     columnObject[columnName]=charLength;
    //   }else{
    //     columnObject[columnName]=charLength;
    //   }

    let h = legendRectSize + legendSpacing; // height of each entry
    let tx = 10; // tx/ty are essentially margin values
    let ty = 10;
    let x = (i % c) * w + tx;
    let y = Math.floor(i / c) * h + ty;
    return "translate(" + x + "," + y + ")";
  }

  function updatePosition(d, i) {
    let c = calcColumn; // number of columns
    let columnIndex = i % c;
    let finalwidth = 0;
    for (let index = 0; index < columnIndex; index++) {
      let columnName = "column" + index;
      //finalwidth +=(columnObject[columnName]*6.295)+ legendRectSize + legendSpacing;
      finalwidth += columnObject[columnName] + legendRectSize; //+ legendSpacing;
    }

    let h = legendRectSize + legendSpacing; // height of each entry
    let tx = 10; // tx/ty are essentially margin values
    let ty = 10;
    //let x = i % c * w + tx;
    let x = finalwidth; //+ tx;
    let y = Math.floor(i / c) * h + ty;
    return "translate(" + x + "," + y + ")";
  }

  function arrayItemMax(arr) {
    return arr.reduce(function(result, val) {
      return Math.max(
        !isNaN(result) ? result : 0,
        !isNaN(val) ? parseInt(val) : val.length
      );
    }, 0);
  }

  function makePolyLines() {
    let polyline = svg.selectAll("polyline").data(data_ready);

    polyline.enter().append("polyline");

    svg.selectAll("polyline").style("display", function(d) {
      if (d.value == 0) {
        return "none";
      } else {
        return "block";
      }
    });

    polyline
      .transition()
      .duration(1000)
      .attrTween("points", function(d) {
        this._current = this._current || d;
        let interpolate = d3.interpolate(this._current, d);
        this._current = interpolate(0);
        return function(t) {
          let d2 = interpolate(t);
          let pos = outerArc.centroid(d2);
          pos[0] = radius * 1.2 * (midAngle(d2) < Math.PI ? 1 : -1);
          return [arc.centroid(d2), outerArc.centroid(d2), pos];
        };
      });

    polyline.exit().remove();
  }

  function midAngle(d) {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
  }

  //   function makeTexts() {
  //     labels.selectAll("text").style("display", function (d) {
  //       if (d.value == 0) {
  //         return "none";
  //       } else {
  //         return "block";
  //       }
  //     });
  //   }

  function labelBox(showPercentage) {
    svg.selectAll(".labels").remove();
    let labels = svg
      .selectAll("allPolylines")
      .data(data_ready)
      .enter()
      .append("g")
      .attr("class", "labels")
      .attr("transform", function(d) {
        let width = 24;
        let pos = outerArc.centroid(d);
        let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] =
          radius * 1.2 * (midangle < Math.PI ? 1 : -1) +
          (midangle < Math.PI ? -width / 3 : -width);
        return "translate(" + pos + ")";
      });

    labels.style("display", function(d) {
      if (d.value == 0) {
        return "none";
      } else {
        return "block";
      }
    });

    labels
      .append("rect")
      .attr("transform", "translate(0,0)")
      .attr("width", function(d) {
        let width = 24;
        let getDataCount = d.data.count.toString().length;
        if (showPercentage) getDataCount = getDataCount + 1;

        if (getDataCount > 1) {
          return width + +getDataCount * 4;
        }
        return width;
      })
      .attr("height", "24")
      .attr("x", function(d) {
        return 0;
      })
      .attr("y", "-12")
      .attr("fill", function(d) {
        return color(d.data.name);
      })
      .attr("stroke-width", 0)
      .attr("stroke", "none")
      .style("shape-rendering", "crispedges");

    labels
      .append("text")
      .text(function(d) {
        if (showPercentage) {
          let getFilterData = data_ready
            .filter((x) => x.data.enabled)
            .map((x, i) => {
              return {
                value: ((x.endAngle - x.startAngle) / (2 * Math.PI)) * 100,
                index: i,
                name: x.data.name,
              };
            });
          let index = -1;
          let getObj = getFilterData.filter((x) => {
            if (x.name === d.data.name) return x;
          });
          if (getObj != null && getObj.length > 0) {
            index = getObj[0].index;
          }
          let getRoundOffValues = roundOff(
            getFilterData.map((x) => x.value),
            100
          );

          let getPer = Math.round(
            ((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100
          );
          let getArea = ((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100;

          let getExactPer = getRoundOffValues[index];

          if (index > -1) {
            getPer = getExactPer;
          }

          return `${getPer}%`;
        }
        return d.data.count;
      })
      .style("text-anchor", function(d) {
        return "middle"; //(midangle < Math.PI ? 'start' : 'end')
      })
      .attr("transform", "translate(0,0)")
      .attr("x", function(d) {
        let width = 24;
        let val = width / 2;
        let getDataCount = d.data.count.toString().length;
        if (showPercentage) getDataCount = getDataCount + 1;

        if (getDataCount > 1) {
          val = (width + +getDataCount * 4) / 2;
        }
        return val;
      })
      .attr("y", "4")
      .style("fill", "#ffffff")
      .style(
        "font-family",
        "'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana"
      )
      .style("font-weight", "400")
      .style("font-size", "12px")
      .style("cursor", "default")
      .style("shape-rendering", "crispedges");

    labels.exit().remove();
  }

  let percentageFormat = d3.format("%");
  function createPiePattern(defs, getColorCode) {
    let pt = defs
      .append("pattern")
      .attr("id", "test1")
      .attr("patternUnits", "userSpaceOnUse")
      .attr("width", 10)
      .attr("height", 10);
    pt.append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 10)
      .attr("height", 10)
      .attr("transform", "translate(0,0)")
      .attr("fill", getColorCode)
      .attr("opacity", 0.75);
    pt.append("path")
      .attr("d", "M 5 -5 L -5 5 M 0 10 L 10 0 M 15 5 L 5 15")
      .attr("stroke", getColorCode)
      .attr("transform", "translate(0,0)")
      .attr("stroke-width", 4);
  }

  function createLegendPattern(defs, getColorCode) {
    let pt = defs
      .append("pattern")
      .attr("id", "legend_pattern")
      .attr("patternUnits", "userSpaceOnUse")
      .attr("width", 5)
      .attr("height", 5);
    pt.append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 5)
      .attr("height", 5)
      .attr("transform", "translate(0,0)")
      .attr("fill", getColorCode)
      .attr("opacity", 0.75);
    pt.append("path")
      .attr("d", "M 2.5 -2.5 L -2.5 2.5 M 0 5 L 5 0 M 7.5 2.5 L 2.5 7.5")
      .attr("stroke", getColorCode)
      .attr("transform", "translate(0,0)")
      .attr("stroke-width", 2);
  }

  function roundOff(orig, target) {
    let i = orig.length,
      j = 0,
      total = 0,
      change,
      newVals = [],
      next,
      factor1,
      factor2,
      len = orig.length,
      marginOfErrors = [];

    // map original values to new array
    while (i--) {
      total += newVals[i] = Math.round(orig[i]);
    }

    change = total < target ? 1 : -1;

    while (total !== target) {
      // select number that will be less affected by change determined
      // in terms of itself e.g. Incrementing 10 by 1 would mean
      // an error of 10% in relation to itself.
      for (i = 0; i < len; i++) {
        next = i === len - 1 ? 0 : i + 1;

        factor2 = errorFactor(orig[next], newVals[next] + change);
        factor1 = errorFactor(orig[i], newVals[i] + change);

        if (factor1 > factor2) {
          j = next;
        }
      }

      newVals[j] += change;
      total += change;
    }

    for (i = 0; i < len; i++) {
      marginOfErrors[i] =
        newVals[i] && Math.abs(orig[i] - newVals[i]) / orig[i];
    }

    for (i = 0; i < len; i++) {
      for (j = 0; j < len; j++) {
        if (j === i) continue;

        let roundUpFactor =
          errorFactor(orig[i], newVals[i] + 1) +
          errorFactor(orig[j], newVals[j] - 1);
        let roundDownFactor =
          errorFactor(orig[i], newVals[i] - 1) +
          errorFactor(orig[j], newVals[j] + 1);
        let sumMargin = marginOfErrors[i] + marginOfErrors[j];

        if (roundUpFactor < sumMargin) {
          newVals[i] = newVals[i] + 1;
          newVals[j] = newVals[j] - 1;
          marginOfErrors[i] =
            newVals[i] && Math.abs(orig[i] - newVals[i]) / orig[i];
          marginOfErrors[j] =
            newVals[j] && Math.abs(orig[j] - newVals[j]) / orig[j];
        }

        if (roundDownFactor < sumMargin) {
          newVals[i] = newVals[i] - 1;
          newVals[j] = newVals[j] + 1;
          marginOfErrors[i] =
            newVals[i] && Math.abs(orig[i] - newVals[i]) / orig[i];
          marginOfErrors[j] =
            newVals[j] && Math.abs(orig[j] - newVals[j]) / orig[j];
        }
      }
    }

    function errorFactor(oldNum, newNum) {
      return Math.abs(oldNum - newNum) / oldNum;
    }

    return newVals;
  }
};

const PieChartComponent = (props) => {
  const d3Container = useRef(null);

  useEffect(() => {
    props.getWidgetSize();
    if (props.data !== undefined && d3Container.current) {
      const svg = d3.select(d3Container.current);
      svg.selectAll("*").remove();
      const w = props.width - 20;
      const h = props.height - 20;
      pieChartObject(
        svg,
        props.widgetId,
        props.data,
        props.showCallOut,
        props.showPercentage,
        props.colorPallets,
        w,
        h
      );
    }
  }, [
    props.data,
    props.width,
    props.height,
    props.wid,
    props.showCallOut,
    props.showPercentage,
  ]);

  return (
    <svg
      ref={d3Container}
      width={props.width - 20}
      height={props.height - 20}
    ></svg>
  );
};

export default PieChartComponent;
