import classes from "./Modal.module.css";
import { Fragment, React } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";

const BackDrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClick}></div>;
};

const ModalOverlay = (props) => {
  const getTheme = useSelector((state) => state.layout.theme);

  return (
    <div className={`${classes.dialog} ${classes["dialog-theme-custom"]}`}>
      <div className={classes["dialog-content"]} style={props.width}>
        {props.title && (
          <div
            className={
              getTheme !== "Light" ? classes["header-dark"] : classes["header"]
            }
          >
            <span className={classes["title"]}>{props.title}</span>
          </div>
        )}
        <div
          className={
            getTheme !== "Light"
              ? classes["dialogContent-dark"]
              : classes["dialogContent"]
          }
          style={props.contentCss}
        >
          {props.children}
        </div>
        <div onClick={props.onclose} className={classes["dialog-close"]}></div>
      </div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const Modal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <BackDrop onClick={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          onclose={props.onClose}
          width={props.width}
          title={props.title}
          contentCss={props.contentCss}
        >
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default Modal;
