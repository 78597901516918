import React from "react";
import Dashboard from "./Dashboard/Dashboard";
import Header from "./Header/Header";
import { useSelector } from "react-redux";

const Layout = (props) => {
  const getTheme = useSelector((state) => state.layout.theme);
  let containerCss = "";
  if (getTheme !== "Light") {
    containerCss = "dark";
  }

  return (
    <div className={containerCss}>
      <Header onShowLogin={props.onShowLoginHandler} />
      <Dashboard />
    </div>
  );
};

export default Layout;
