const defaultSAWData = {
  time_intervals: [
    {
      time: "7:00 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "7:10 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "7:20 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "7:30 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "7:40 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "7:50 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "8:00 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "8:10 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "8:20 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "8:30 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "8:40 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "8:50 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "9:00 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "9:10 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "9:20 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "9:30 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "9:40 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "9:50 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "10:00 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "10:10 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "10:20 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "10:30 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "10:40 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "10:50 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "11:00 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "11:10 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "11:20 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "11:30 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "11:40 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "11:50 AM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "12:00 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "12:10 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "12:20 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "12:30 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "12:40 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "12:50 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "1:00 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "1:10 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "1:20 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "1:30 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "1:40 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "1:50 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "2:00 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "2:10 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "2:20 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "2:30 PM",
      attempts: 0,
      msi: 0,
    },
    {
      time: "2:30 PM",
      attempts: 0,
      msi: 0,
    },
  ],
  zones: [
    {
      start: "7:30 AM",
      end: "9:30 AM",
    },
    {
      start: "10:30 AM",
      end: "11:30 AM",
    },
    {
      start: "1:00 PM",
      end: "3:00 PM",
    },
  ],
  tokens: [
    {
      time: "7:00 am",
      label: "Susan",
    },
    {
      time: "7:00 am",
      label: "Susan",
    },
    {
      time: "8:30 am",
      label: "David",
    },
    {
      time: "8:40 am",
      label: "Sean",
    },
  ],
  actual: 29,
  time_mark: 100,
  cost: 523,
};

export default defaultSAWData;
