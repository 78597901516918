import React from "react";
import styles from "./WidgetButton.module.css";
const WidgetButtonControl = (props) => {
  return (
    <div
      className={`${styles["widget-button"]} settings-btn-dark`}
      tabIndex="0"
      style={props.cssStyle}
      onClick={props.onClick}
    >
      <div className={`${styles["widget-button-content"]}`}>
        <span className={styles["widget-button-text"]}>{props.title}</span>
      </div>
    </div>
  );
};

export default WidgetButtonControl;
