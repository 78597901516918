import React from "react";
import { useSelector, useDispatch } from "react-redux";
import deletePageImage from "../../../../assets/delete_page.png";
import styles from "./DeleteImg.module.css";
import { Delete_Page } from "./../../../../Constants";
import { layoutActions } from "../../../../store/layout-slice";
import { pageActions } from "../../../../store/page-slice";

const DeleteImg = (props) => {
  const dispatch = useDispatch();
  const layoutId = useSelector((state) => state.layout.layoutId);
  const pageList = useSelector((state) => state.layout.PageList);
  const userId = useSelector((state) => state.layout.UserId);

  const onDeleteHandler = async () => {
    if (props.isSharedByOtherUser) {
      alert("This is a shared page and cannot be deleted.");
      return false;
    }
    if (layoutId === props.id) {
      alert(
        "You cannot delete the page that is currently loaded. To delete this page, please load another page first and then return here."
      );
      return false;
    }
    if (
      window.confirm(
        "Are you sure you want to delete this page? In addition to the page, you will lose the settings associated with any widgets on that page."
      )
    ) {
      const deletePage = await fetch(
        `${Delete_Page}?userId=${userId}&pageId=${props.id}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (deletePage.ok) {
        const getFilteredList = pageList.filter((item) => item.Id != props.id);
        dispatch(layoutActions.setPageList(getFilteredList));
        dispatch(pageActions.closePageList());
      }
    }
  };

  return (
    <React.Fragment>
      <img
        title="Delete Page"
        className={styles["delete-img"]}
        src={deletePageImage}
        alt="No Image"
        onClick={onDeleteHandler}
      />
      <span
        title="Delete Page"
        className={styles["delete-spn"]}
        onClick={onDeleteHandler}
      >
        DELETE
      </span>
    </React.Fragment>
  );
};

export default DeleteImg;
