export const IS_TESTAPI_SERVICE_URL_Enabled = false;
export const MIS_SERVICE_URL =
  "https://ws-mis.nsyteful.com/ballistixService.svc/";
export const MIS_TEST_SERVICE_URL =
  "http://ws-mis-staging2.ballistix.com/BallistixService.svc/";

export const MIS_ServiceUrl_Widget_Data_Api = IS_TESTAPI_SERVICE_URL_Enabled
  ? "http://getwidgetservice2.local/api/ballistix/"
  : "https://api.nsyteful.com/api/ballistix/";

// export const MIS_ServiceUrl_Widget_Data_Api = IS_TESTAPI_SERVICE_URL_Enabled
//   ? "https://api.nsyteful.com/api/ballistix/"
//   : "https://api.nsyteful.com/api/ballistix/";

export const Configurator_URL = "https://uiconfigurator.nsyteful.com";

export const LOGIN_URL =
  (IS_TESTAPI_SERVICE_URL_Enabled ? MIS_TEST_SERVICE_URL : MIS_SERVICE_URL) +
  "nsyte_Login";

export const Change_Password =
  (IS_TESTAPI_SERVICE_URL_Enabled ? MIS_TEST_SERVICE_URL : MIS_SERVICE_URL) +
  "changePassword";

export const Forgot_Password =
  (IS_TESTAPI_SERVICE_URL_Enabled ? MIS_TEST_SERVICE_URL : MIS_SERVICE_URL) +
  "forgotPassword";

export const DecodeFrom64Str =
  (IS_TESTAPI_SERVICE_URL_Enabled ? MIS_TEST_SERVICE_URL : MIS_SERVICE_URL) +
  "DecodeFrom64Str";

export const Activate_New_User =
  (IS_TESTAPI_SERVICE_URL_Enabled ? MIS_TEST_SERVICE_URL : MIS_SERVICE_URL) +
  "activateNewUser";

export const Register_New_User =
  (IS_TESTAPI_SERVICE_URL_Enabled ? MIS_TEST_SERVICE_URL : MIS_SERVICE_URL) +
  "saveMembershipUser";

export const EncodeSessionIdToBase64_URL =
  (IS_TESTAPI_SERVICE_URL_Enabled ? MIS_TEST_SERVICE_URL : MIS_SERVICE_URL) +
  "EncodeSessionIdToBase64";

//  rootUrl: 'https://nsyteful.com/api1',
export const ROOT_URL = IS_TESTAPI_SERVICE_URL_Enabled
  ? "https://nsyteful.com/api1"
  : "https://nsyteful.com/api1";
// : "https://dev.nsyteful.com/api";
//: "https://nsyteful.com/api1";

//back-end api's
export const SESSION_DETAIL_URL =
  (IS_TESTAPI_SERVICE_URL_Enabled ? MIS_TEST_SERVICE_URL : MIS_SERVICE_URL) +
  "getSessionDetails";

export const LOGOUT_URL =
  (IS_TESTAPI_SERVICE_URL_Enabled ? MIS_TEST_SERVICE_URL : MIS_SERVICE_URL) +
  "dashboard_SessionTerminate";

export const Connection_List_Url =
  (IS_TESTAPI_SERVICE_URL_Enabled ? MIS_TEST_SERVICE_URL : MIS_SERVICE_URL) +
  "getConnectionList";

export const Get_MetaData_List_Url =
  MIS_ServiceUrl_Widget_Data_Api + "getwidgetmetadata";

export const Get_Widget_Data_Url = MIS_ServiceUrl_Widget_Data_Api + "fetchData";

export const Get_Opportunity_Data_Url =
  MIS_ServiceUrl_Widget_Data_Api + "getOpportunities";
// MIS_ServiceUrl_Widget_Data_Api + "getwidgetdata";

// export const Get_All_Filter_List_Url =
//   MIS_ServiceUrl_Widget_Data_Api + "getAllfilter";

export const Get_All_Filter_List_Url =
  MIS_ServiceUrl_Widget_Data_Api + "getNewFilterList";

// export const Get_Filter_Details_Url =
//   MIS_ServiceUrl_Widget_Data_Api + "getFilterDetails";

export const Get_Filter_Details_Url =
  MIS_ServiceUrl_Widget_Data_Api + "getNewFilterDetails";

export const Get_Last_Refresh_Datetime =
  MIS_ServiceUrl_Widget_Data_Api + "getLastRefreshStatus";

export const Get_Widget_Data_By_Column =
  MIS_ServiceUrl_Widget_Data_Api + "getwidgetdatabycolumn";

export const Get_Video_Feeds = MIS_ServiceUrl_Widget_Data_Api + "getVideoFeeds";
export const Save_Video_Feed =
  MIS_ServiceUrl_Widget_Data_Api + "insertUpdateVideoFeed";

export const Insert_Update_Filter_New =
  MIS_ServiceUrl_Widget_Data_Api + "addUpdateNewFilter";

export const Delete_Filter_New =
  MIS_ServiceUrl_Widget_Data_Api + "deleteNewFilter";

export const Clone_Filter_New =
  MIS_ServiceUrl_Widget_Data_Api + "cloneNewFilter";

export const Get_New_Filters_Deleted_List =
  MIS_ServiceUrl_Widget_Data_Api + "getNewFiltersDeletedList";

export const Restore_New_Filter =
  MIS_ServiceUrl_Widget_Data_Api + "restoreNewFilter";

// Dashboard fron-end apis
export const LOGIN_Detail_URL = ROOT_URL + "/dashboard/login";
export const Check_Page_Name_Exists = ROOT_URL + "/dashboard/widget/pagecheck";
export const Save_Template = ROOT_URL + "/dashboard/widget/template";
export const Get_Widget_List = ROOT_URL + "/dashboard/getWidgetList";
export const Clone_Page = ROOT_URL + "/dashboard/clonePage";
export const Update_Shared_Page =
  ROOT_URL + "/dashboard/widget/UpdateSharedPage";
export const Delete_Page = ROOT_URL + "/dashboard/widget/deletePage";
export const Widget_Types = ROOT_URL + "/dashboard/widgets/types";
export const Get_DataSources = ROOT_URL + "/data";
export const UpdateWidget = ROOT_URL + "/dashboard/widget/updatewidget";
export const Get_Widget_Settings = ROOT_URL + "/dashboard/widget/settings";
export const Remove_Widget = ROOT_URL + "/dashboard/widget/remove";
export const SwapOrder_Widget = ROOT_URL + "/dashboard/layout/swapSortOrder";
export const Update_Layout_Theme = ROOT_URL + "/dashboard/updateLayoutTheme";
export const Twitter_Api = "https://nsyteful.com/TwitterAPIProxy/Search";
export const Layout_Key = "Layout";
export const IsFilterInUse = ROOT_URL + "/dashboard/IsfilterUsed";
export const Update_Filter_Setting =
  ROOT_URL + "/dashboard/widget/updatewidgetfilter";

export const Add_Rotation = ROOT_URL + "/dashboard/addRotation";
export const Get_Rotations = ROOT_URL + "/dashboard/getRotations";
export const Update_Rotation = ROOT_URL + "/dashboard/updateRotation";
export const Delete_Rotation = ROOT_URL + "/dashboard/deleteRotation";
export const Add_Rotation_Page = ROOT_URL + "/dashboard/addRotationPage";
export const Update_Rotation_Page = ROOT_URL + "/dashboard/updateRotationPage";
export const Delete_Rotation_Page = ROOT_URL + "/dashboard/deleteRotationPage";
export const Update_Page_Rotation_Status =
  ROOT_URL + "/dashboard/updatePageRotation";
export const Sort_Rotation_Page = ROOT_URL + "/dashboard/sortRotationPage";
export const Get_Widgets = ROOT_URL + "/dashboard/getWidgets";
export const Clone_Widget = ROOT_URL + "/dashboard/cloneWidget";
export const Get_Deleted_Pages = ROOT_URL + "/dashboard/getDeletedPages";
export const Restore_Delete_Page =
  ROOT_URL + "/dashboard/widget/restoreDeletePage";
export const Get_Deleted_Widgets = ROOT_URL + "/dashboard/getDeletedWidgets";
export const Restore_Deleted_Widget =
  ROOT_URL + "/dashboard/restoreDeletedWidget";
export const Is_Deleted_Widget_Exists =
  ROOT_URL + "/dashboard/isDeletedWidgetExists";
export const Get_Deleted_Rotation = ROOT_URL + "/dashboard/getDeletedRotation";
export const Restore_Deleted_Rotation =
  ROOT_URL + "/dashboard/restoreDeletedRotation";

export const TwitterWidgetRefreshTime = 60000 * 1; // 1 min
export const TableWidgetRefreshTime = 60000 * 2; // 2 min
export const OtccWidgetRefreshTime = 60000 * 10; // 10 min
export const PieChartWidgetRefreshTime = 60000 * 10; // 10 min
export const TextNumericRefreshTime = 60000 * 10; // 10 min
export const QueueSizeGaugeRefreshTime = 60000 * 10; // 10 min
export const BarChartRefreshTime = 60000 * 10; // 10 min
export const VideoFeedRefreshTime = 60000 * 10; // 10 min
export const SAWRefreshTime = 60000 * 10; // 10 min
export const BarLineChartRefreshTime = 60000 * 10; // 10 min
export const OOSRefreshTime = 60000 * 10; // 10 min

export const SubdomainLink = "";
export const SALES = "SALES";
export const SALESV2 = "SALESV2";
export const REPORTS = "Reports";
export const BUBBLE_CHART = "BubbleWidget";
export const BUBBLECHART = "BubbleChart";
export const ROTATION_OFF = "Off";
