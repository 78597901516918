import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

function PopoverControl({ text }) {
  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        rootClose={true}
        overlay={
          <Popover id={`popover-positioned`}>
            <Popover.Body>{text}</Popover.Body>
          </Popover>
        }
      >
        <span style={{ cursor: "pointer" }} title={text}>
          ?
        </span>
      </OverlayTrigger>
    </>
  );
}

export default PopoverControl;
