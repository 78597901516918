import { format, parse, setHours, setMinutes } from "date-fns";
import { useState, React, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ValueEditor } from "react-querybuilder";
import {
  Operator_BeforeToday,
  Operator_Last30days,
  Operator_Last7days,
  Operator_LastMonth,
  Operator_NotNull,
  Operator_Null,
  Operator_ThisMonth,
  Operator_Today,
  Operator_WorkingDaysOnly,
  Operator_Yesterday,
} from "./constants";

const dateFormat = "yyyy-MM-dd hh:mm aa";

export const CustomValueEditor = (props) => {
  const [startDatetime, setStartDatetime] = useState("");
  const [endDatetime, setEndDatetime] = useState("");
  const [text, setText] = useState(props.value);
  const [startNum, setStartNum] = useState("");
  const [endNum, setEndNum] = useState("");

  useEffect(() => {
    if (
      props.inputType === "number" &&
      (props.operator === "between" || props.operator === "notBetween")
    ) {
      const [startNum1, endNum1] =
        props.value !== null ? props.value?.split(",") : ["", ""];
      setStartNum(startNum1);
      setEndNum(endNum1);
    }
  }, []);

  const startDateTimePickerHandler = (d) => {
    setStartDatetime(d ? format(d, dateFormat) : null);
    const [startDate, endDate] =
      props.value !== null ? props.value?.split(",") : [null, null];

    props.handleOnChange(
      [
        d ? format(d, dateFormat) : "",
        !endDatetime ? (!endDate ? "" : endDate) : endDatetime,
      ].join(",")
    );
  };

  const endDateTimePickerHandler = (d) => {
    const [startDate, endDate] =
      props.value !== null ? props.value?.split(",") : [null, null];

    setEndDatetime(d ? format(d, dateFormat) : null);
    props.handleOnChange(
      [
        !startDatetime ? (!startDate ? "" : startDate) : startDatetime,
        d ? format(d, dateFormat) : "",
      ].join(",")
    );
  };

  const startNumHandler = (d) => {
    setStartNum(d);
  };

  const endNumHandler = (d) => {
    setEndNum(d);
  };

  const onStartNumBlurHandler = (d) => {
    if (d !== props.value) {
      const [startNum, endNum] =
        props.value !== null ? props.value?.split(",") : ["", ""];
      props.handleOnChange([d, endNum].join(","));
    }
  };

  const onEndNumBlurHandler = (d) => {
    if (d !== props.value) {
      const [startNum, endNum] =
        props.value !== null ? props.value?.split(",") : ["", ""];
      props.handleOnChange([startNum, d].join(","));
    }
  };

  const onchangeHandler = (d) => {
    setText(d);
  };

  const onBlurHandler = (d) => {
    if (d !== props.value) {
      props.handleOnChange(d);
    }
  };

  if (
    props.fieldData.inputType === "datetime-local" &&
    props.operator !== "between" &&
    props.operator !== "notBetween" &&
    !(
      props.operator === Operator_Today ||
      props.operator === Operator_Yesterday ||
      props.operator === Operator_Last7days ||
      props.operator === Operator_Last30days ||
      props.operator === Operator_WorkingDaysOnly ||
      props.operator === Operator_LastMonth ||
      props.operator === Operator_ThisMonth ||
      props.operator === Operator_BeforeToday ||
      props.operator === Operator_Null ||
      props.operator === Operator_NotNull
    )
  ) {
    const startDate = props.value?.indexOf(",") > -1 ? null : props.value;

    return (
      <div>
        <DatePicker
          dateFormat={dateFormat}
          showTimeSelect
          timeIntervals={10}
          selected={
            !startDate ? null : parse(startDate, dateFormat, new Date())
          }
          onChange={(d) =>
            props.handleOnChange(d ? format(d, dateFormat) : null)
          }
          injectTimes={[
            setHours(setMinutes(new Date(), 5), 12),
            setHours(setMinutes(new Date(), 59), 23),
          ]}
          timeFormat="hh:mm aa"
          className="custom-datepicker"
          placeholderText="yyyy-mm-dd hh:mm am/pm"
        />
      </div>
    );
  } else if (
    props.fieldData.inputType === "datetime-local" &&
    (props.operator === "between" || props.operator === "notBetween")
  ) {
    const [startDate, endDate] =
      props.value !== null ? props.value?.split(",") : [null, null];

    return (
      <div style={{ display: "flex", gap: "0.5em" }} className="rule-value">
        <DatePicker
          dateFormat={dateFormat}
          showTimeSelect
          timeIntervals={10}
          selected={
            !startDatetime
              ? !startDate
                ? null
                : parse(startDate, dateFormat, new Date())
              : parse(startDatetime, dateFormat, new Date())
          }
          onChange={startDateTimePickerHandler}
          injectTimes={[
            setHours(setMinutes(new Date(), 5), 12),
            setHours(setMinutes(new Date(), 59), 23),
          ]}
          timeFormat="hh:mm aa"
          className="custom-datepicker"
          placeholderText="yyyy-mm-dd hh:mm am/pm"
        />
        <DatePicker
          dateFormat={dateFormat}
          showTimeSelect
          timeIntervals={10}
          selected={
            !endDatetime
              ? !endDate
                ? null
                : parse(endDate, dateFormat, new Date())
              : parse(endDatetime, dateFormat, new Date())
          }
          onChange={endDateTimePickerHandler}
          injectTimes={[
            setHours(setMinutes(new Date(), 5), 12),
            setHours(setMinutes(new Date(), 59), 23),
          ]}
          timeFormat="hh:mm aa"
          className="custom-datepicker"
          placeholderText="yyyy-mm-dd hh:mm am/pm"
        />
      </div>
    );
  } else if (
    props.fieldData.inputType === "datetime-local" &&
    (props.operator === Operator_Today ||
      props.operator === Operator_Yesterday ||
      props.operator === Operator_Last7days ||
      props.operator === Operator_Last30days ||
      props.operator === Operator_WorkingDaysOnly ||
      props.operator === Operator_LastMonth ||
      props.operator === Operator_ThisMonth ||
      props.operator === Operator_BeforeToday ||
      props.operator === Operator_Null ||
      props.operator === Operator_NotNull)
  ) {
    return "";
  } else if (
    props.inputType === "text" &&
    props.operator !== Operator_Null &&
    props.operator !== Operator_NotNull
  ) {
    return (
      <input
        className={props.className}
        value={text}
        onChange={(e) => onchangeHandler(e.target.value)}
        onBlur={(e) => onBlurHandler(e.target.value)}
      />
    );
  } else if (
    props.inputType === "number" &&
    (props.operator === "between" || props.operator === "notBetween")
  ) {
    return (
      <div style={{ display: "flex", gap: "0.5em" }} className="rule-value">
        <input
          className={props.className}
          value={startNum}
          type="number"
          min={0}
          onChange={(e) => startNumHandler(e.target.value)}
          onBlur={(e) => onStartNumBlurHandler(e.target.value)}
        />
        <input
          className={props.className}
          value={endNum}
          type="number"
          min={0}
          onChange={(e) => endNumHandler(e.target.value)}
          onBlur={(e) => onEndNumBlurHandler(e.target.value)}
        />
      </div>
    );
  } else if (
    props.inputType === "number" &&
    props.operator !== Operator_Null &&
    props.operator !== Operator_NotNull
  ) {
    return (
      <input
        className={props.className}
        value={text}
        type="number"
        onChange={(e) => onchangeHandler(e.target.value)}
        onBlur={(e) => onBlurHandler(e.target.value)}
      />
    );
  }

  return <ValueEditor {...props} />;
};
