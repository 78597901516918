import React from "react";
import styles from "./LoaderPanel.module.css";

const LoaderPanel = () => {
  return (
    <div
      className={`${styles["tbl-datagrid-bottom-load-panel"]} grid-loader-panel`}
    >
      <div className={`${styles["tbl-loadindicator"]} tbl-widget`}>
        <div className={styles["tbl-loadindicator-wrapper"]}>
          <div className={styles["tbl-loadindicator-content"]}>
            <div className={styles["tbl-loadindicator-icon"]}>
              <div
                className={`${styles["tbl-loadindicator-segment"]} ${styles["tbl-loadindicator-segment7"]}`}
              ></div>
              <div
                className={`${styles["tbl-loadindicator-segment"]} ${styles["tbl-loadindicator-segment6"]}`}
              ></div>
              <div
                className={`${styles["tbl-loadindicator-segment"]} ${styles["tbl-loadindicator-segment5"]}`}
              ></div>
              <div
                className={`${styles["tbl-loadindicator-segment"]} ${styles["tbl-loadindicator-segment4"]}`}
              ></div>
              <div
                className={`${styles["tbl-loadindicator-segment"]} ${styles["tbl-loadindicator-segment3"]}`}
              ></div>
              <div
                className={`${styles["tbl-loadindicator-segment"]} ${styles["tbl-loadindicator-segment2"]}`}
              ></div>
              <div
                className={`${styles["tbl-loadindicator-segment"]} ${styles["tbl-loadindicator-segment1"]}`}
              ></div>
              <div
                className={`${styles["tbl-loadindicator-segment"]} ${styles["tbl-loadindicator-segment0"]}`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderPanel;
