import {
  Fragment,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  React,
} from "react";
import { useSelector } from "react-redux";
import GetMetaData from "../../../../../Helper/Services";
import { GridMetaData_Format } from "../../../../../Helper/Services";
import DataSectionSettings from "../../../../UI/TemplateControls/DataSection";
import WidgetTitleControl from "../../../../UI/TemplateControls/WidgetTitleControl";
import { GetCategoryList } from "../../../../../Helper/WidgetDataCommon";
import { DataSectionObject } from "./../../../../../Helper/CommonModel";

const QueueSizeGaugeTemplate = forwardRef((props, ref) => {
  const [showSettingsPanel, setShowSettingsPanel] = useState(true);
  const [widgetContentPanel, setWidgetContentPanel] = useState(false);
  const [titleContentPanel, setTitleContentPanel] = useState(false);
  const [dtSourceItems, setDtSourceItems] = useState([]);
  const [title, setTitle] = useState("Queue Size Gauge");
  const [shortTitle, setShortTitle] = useState("Queue Size Gauge");
  const sessionId = useSelector((state) => state.login.sessionId);
  const [ddlList, setDdlList] = useState([]);
  const [optimalVal, setOptimalVal] = useState("");
  const [actualVal, setActualVal] = useState("");
  const [titleIsValid, setTitleIsValid] = useState(true);
  const [shortTitleIsValid, setShortTitleIsValid] = useState(true);
  const dataSection = useRef(DataSectionObject);
  const dataSectionRef = useRef();

  useEffect(() => {
    if (
      props.dataSources !== undefined &&
      Object.keys(props.dataSources.Reports).length !== 0
    ) {
      const _items = Object.entries(props.dataSources.Reports)
        .filter((item) => {
          if (item[1] === "Generic Table") {
            return true;
          }
        })
        .map((item) => item[0]);
      setDtSourceItems(_items);
    }

    if (
      props.dataSources !== undefined &&
      props.isEdit === true &&
      props.settings !== undefined &&
      props.settings["reports"] !== undefined
    ) {
      getMetaDataList(props.settings["reports"]);
      setTitle(props.settings["Title"]);
      setShortTitle(props.settings["ShortTitle"]);
      setOptimalVal(props.settings["optimalvalue"]);
      setActualVal(props.settings["actualvalue"]);
    }
  }, [props.dataSources, props.settings]);

  useImperativeHandle(ref, () => ({
    GetValidateData() {
      const getVaildForm = validateForm();
      if (getVaildForm) {
        return {
          formIsValid: getVaildForm,
          data: {
            Filter: dataSection.current.filterName,
            FilterId: dataSection.current.filterDetails.filterid,
            IsSharedFilter: dataSection.current.filterDetails.issharedfilter,
            LastRefreshedTime: "",
            OriginalUserId: dataSection.current.filterDetails.originaluserid,
            ShortTitle: shortTitle,
            Title: title,
            actualvalue: actualVal,
            connection: dataSection.current.connectionName,
            optimalvalue: optimalVal,
            reports: dataSection.current.dataSourceName,
          },
        };
      } else {
        return {
          formIsValid: getVaildForm,
        };
      }
    },
  }));

  const onSettingsClicked = (val) => {
    if (val) {
      setShowSettingsPanel(val);
    } else {
      setShowSettingsPanel(!showSettingsPanel);
    }
    setWidgetContentPanel(false);
    setTitleContentPanel(false);
  };

  const onWidgetContentClicked = (val) => {
    if (val) {
      setWidgetContentPanel(val);
    } else {
      setWidgetContentPanel(!widgetContentPanel);
    }
    setShowSettingsPanel(false);
    setTitleContentPanel(false);
  };

  const onTitleContentPanelClicked = (val) => {
    if (val) {
      setTitleContentPanel(val);
    } else {
      setTitleContentPanel(!titleContentPanel);
    }
    setWidgetContentPanel(false);
    setShowSettingsPanel(false);
  };

  const OnTitleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const OnShortTitleChangeHandler = (event) => {
    setShortTitle(event.target.value);
  };

  const onDatasourceChangeHandler = (dataSourceVal) => {
    getMetaDataList(dataSourceVal);
  };

  function getMetaDataList(dataSourceVal) {
    setDdlList([]);
    if (dataSourceVal !== "") {
      GetMetaData(dataSourceVal, "", sessionId)
        .then((json) => {
          const metaData = GridMetaData_Format(json);
          const getCategories = GetCategoryList(metaData);
          setDdlList(getCategories);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  const onChangeOptimalVal = (event) => {
    setOptimalVal(event.target.value);
  };

  const onChangeActualVal = (event) => {
    setActualVal(event.target.value);
  };

  const validateTitle = () => {
    if (title === "") {
      onTitleContentPanelClicked(true);
      setTitleIsValid(false);
      setTimeout(() => {
        setTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateShortTitle = () => {
    if (shortTitle === "") {
      onTitleContentPanelClicked(true);
      setShortTitleIsValid(false);
      setTimeout(() => {
        setShortTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateForm = () => {
    const dataSectionVal = dataSectionRef.current.validateDataSection();
    dataSection.current = dataSectionVal.data;
    if (!dataSectionVal.isDataSectionValid) {
      onSettingsClicked(true);
      return false;
    }

    const isTitleValid = validateTitle();
    if (!isTitleValid) {
      return false;
    }

    const isShortTitleValid = validateShortTitle();
    if (!isShortTitleValid) {
      return false;
    }
    return true;
  };

  return (
    <Fragment>
      {!props.isEdit && <hr className="style-one" />}
      <div onClick={onSettingsClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {showSettingsPanel && "-"}
          {!showSettingsPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>DATA</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: showSettingsPanel ? "block" : "none",
        }}
      >
        <DataSectionSettings
          dataSourceList={dtSourceItems}
          dtSourceChange={onDatasourceChangeHandler}
          ref={dataSectionRef}
          settings={props.settings}
          isEdit={props.isEdit}
        />
      </div>
      <div onClick={onTitleContentPanelClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {titleContentPanel && "-"}
          {!titleContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET TITLE</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: titleContentPanel ? "block" : "none",
        }}
      >
        <WidgetTitleControl
          titleVal={title}
          shortTitleVal={shortTitle}
          OnTitleChangeHandler={OnTitleChangeHandler}
          OnShortTitleChangeHandler={OnShortTitleChangeHandler}
          isTitleValid={titleIsValid}
          isShortTitleValid={shortTitleIsValid}
        />
      </div>
      <div onClick={onWidgetContentClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {widgetContentPanel && "-"}
          {!widgetContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET CONTENT</p>
      </div>
      <div
        style={{
          paddingLeft: "12px",
          display: widgetContentPanel ? "block" : "none",
        }}
      >
        <p>Optimal Value</p>
        <select
          value-key="optimalvalue"
          onChange={onChangeOptimalVal}
          value={optimalVal}
        >
          <option value="">Please Select</option>
          {ddlList.map((item, i) => {
            return (
              <option key={i} value={item.id}>
                {item.text}
              </option>
            );
          })}
        </select>
        <p>Actual Value</p>
        <select
          value-key="actualvalue"
          onChange={onChangeActualVal}
          value={actualVal}
        >
          <option value="">Please Select</option>
          {ddlList.map((item, i) => {
            return (
              <option key={i} value={item.id}>
                {item.text}
              </option>
            );
          })}
        </select>
      </div>
    </Fragment>
  );
});

export default QueueSizeGaugeTemplate;
