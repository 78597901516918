import React, { useState } from "react";
import styles from "./ClonePageForm.module.css";
import useInput from "../../../hooks/use-input";
import { useSelector, useDispatch } from "react-redux";
import { Check_Page_Name_Exists, Clone_Page } from "../../../Constants";
import { useHistory } from "react-router-dom";
import { loginActions } from "../../../store/login-slice";
import { pageActions } from "../../../store/page-slice";

const isNotEmpty = (value) => value.trim() !== "";
const ClonePageForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pageId = useSelector((state) => state.page.clonePageId);
  const userId = useSelector((state) => state.layout.UserId);
  const sessionId = useSelector((state) => state.login.sessionId);
  const [isEmpty, setIsEmpty] = useState(false);
  const [erroMsg, setErroMsg] = useState("Required");
  const {
    value: pagename,
    isValid: pagenameIsValid,
    hasError: pagenameHasError,
    valueChangeHandler: pagenameChangeHandler,
    inputBlurHandler: pagenameBlurHandler,
    reset: resetpagename,
  } = useInput(isNotEmpty);

  const clonePage = async () => {
    if (pagename.trim() === "") {
      setIsEmpty(true);
      setTimeout(() => {
        setErroMsg("Required");
        setIsEmpty(false);
      }, 5000);
      return false;
    }

    const IsPageExists = await fetch(
      `${Check_Page_Name_Exists}?pageName=${pagename}&userId=${userId}&pageId=${pageId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    if (IsPageExists.ok) {
      const response = await IsPageExists.json();
      if (response === true) {
        setErroMsg("That page name already exists. Please choose another!");
        setIsEmpty(true);

        setTimeout(() => {
          setIsEmpty(false);
        }, 5000);

        return false;
      }

      const clonePage = await fetch(
        `${Clone_Page}?pageName=${pagename}&userId=${userId}&pageId=${pageId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (clonePage.ok) {
        const response = await clonePage.json();
        if (response.Success) {
          const clonePageId = response.Message.pageId;
          history.push("/" + sessionId + "/" + clonePageId);
          dispatch(loginActions.isClonePageEvent(clonePageId));
          dispatch(pageActions.triggerClonePage());
          dispatch(pageActions.triggerPageList());
        } else {
          alert(response.ErrorMessage);
        }
      }
    }
  };

  return (
    <div className="settings">
      <div className="">
        <div className={styles["form-control-width"]}>
          <p>Page Name</p>
          <input
            type="text"
            value={pagename}
            onChange={pagenameChangeHandler}
            onBlur={pagenameBlurHandler}
          />
          {isEmpty && <span style={{ color: "red" }}>{erroMsg}</span>}
        </div>
        <div style={{ paddingTop: "15px" }}>
          <button
            className="dx-widget dx-button dx-button-normal"
            style={{ height: "26px", width: "90px", marginRight: "4px" }}
            onClick={clonePage}
          >
            Clone Page
          </button>
          <button
            className="dx-widget dx-button dx-button-normal"
            style={{ height: "26px", width: "60px" }}
            onClick={props.onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClonePageForm;
