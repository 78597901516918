import { useDispatch } from "react-redux";
import Modal from "../../../UI/Modal";
import { layoutActions } from "../../../../store/layout-slice";
import "./index.css";
import { useCallback, useState } from "react";
import RecycleBinService from "./RecycleBinService";
import { useSelector } from "react-redux";
import ConcateDateWithPageName from "../../../../Helper/PageOperations";
import Loader from "../../../UI/Loader";
import { ConvertToLocalDateTimeWithFormat } from "../../../../Helper/WidgetDataCommon";
import RestoreWidgets from "./RestoreWidgets";
import RestoreRotation from "./RestoreRotations";
import FilterRestore from "./FilterRestore";

const RecycleBin = () => {
  const dispatch = useDispatch();
  const sessionId = useSelector((state) => state.login.sessionId);
  const fullName = useSelector((state) => state.login.userDetail.userFullName);
  const [isPagesActive, setIsPagesActive] = useState(false);
  const [isWidgetsActive, setIsWidgetsActive] = useState(false);
  const [isRotationActive, setIsRotationActive] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [enableLoader, setEnableLoader] = useState(false);
  const [deletedPages, setDeletedPages] = useState([]);

  const onCloseModal = () => {
    dispatch(layoutActions.setRecycleBinPopup());
  };

  const getDeletedPages = () => {
    setIsPagesActive(true);
    setEnableLoader(true);
    RecycleBinService.getDeletedPages(sessionId)
      .then((resp) => {
        if (resp) {
          const pages = ConcateDateWithPageName(resp.Message.pagelist, false);
          let _pages = [...pages].map((x) => {
            let item = { ...x };
            item["DeleteDateTime"] = ConvertToLocalDateTimeWithFormat(
              item.DeleteDateTime,
              "MMM DD YYYY, h:mm A"
            );
            return item;
          });

          setDeletedPages(_pages);
        }
        setEnableLoader(false);
      })
      .catch((err) => {
        console.log("getDeletedPages err:", err);
        setEnableLoader(false);
      });
  };

  const restoreDeletedPage = (pageId) => {
    var r = window.confirm("Are you sure you want to restore this page?");
    if (r == true) {
      RecycleBinService.restoreDeletePage(pageId)
        .then((res) => {
          console.log("res", res);
          const getActivePages = deletedPages.filter((x) => {
            return x.Id !== pageId;
          });
          console.log("getActivePages", getActivePages);
          setDeletedPages(getActivePages);
          RecycleBinService.getPages(sessionId)
            .then((res) => {
              dispatch(layoutActions.setPageList(res.Message.Layout.PageList));
            })
            .catch((err) => {
              console.log("getPages err:", err);
            });
        })
        .catch((err) => {
          console.log("restoreDeletePage err:", err);
        });
    }
  };

  const resetToMainMenu = useCallback(() => {
    setIsWidgetsActive(false);
    setIsRotationActive(false);
    setIsFilterActive(false);
  }, [isWidgetsActive, isRotationActive]);

  return (
    <Modal
      title="Recycle Bin"
      onClose={onCloseModal}
      width={{ width: "40%" }}
      contentCss={{ padding: "0px" }}
    >
      <div
        className="recycle-bin"
        style={{
          display:
            isPagesActive ||
            isWidgetsActive ||
            isRotationActive ||
            isFilterActive
              ? "none"
              : "",
        }}
      >
        <div className="main-container">
          <ul className="list-group">
            <li
              className="list-group-item dark-background"
              style={{ cursor: "pointer" }}
              onClick={getDeletedPages}
            >
              Pages
            </li>
            <li
              className="list-group-item dark-background"
              style={{ cursor: "pointer" }}
              onClick={() => setIsWidgetsActive(true)}
            >
              Widgets
            </li>
            <li
              className="list-group-item dark-background"
              style={{ cursor: "pointer" }}
              onClick={() => setIsFilterActive(true)}
            >
              Filters
            </li>
            <li
              className="list-group-item dark-background"
              style={{ cursor: "pointer" }}
              onClick={() => setIsRotationActive(true)}
            >
              Auto Rotations
            </li>
          </ul>
        </div>
      </div>

      {isPagesActive && (
        <div>
          {!enableLoader && (
            <div className="recycle-bin">
              <ul className="breadcrumb">
                <li>
                  <a
                    href=""
                    onContextMenu={() => {
                      return false;
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      setIsPagesActive(false);
                    }}
                  >
                    Rycycle Bin
                  </a>
                </li>
                <li>Pages</li>
              </ul>
              <div className="main-container">
                <table
                  className="table table-hover"
                  style={{ tableLayout: "fixed", wordWrap: "break-word" }}
                >
                  <thead>
                    <tr>
                      <th>Page Name</th>
                      <th>Last Modified</th>
                      <th className="action-col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deletedPages.map((item) => {
                      return (
                        <tr key={item.Id}>
                          <td title={item.PageName}>{item.PageName}</td>
                          <td
                            title={`${item.DeletedByUsername}, ${item.DeleteDateTime}`}
                          >{`${item.DeletedByUsername}, ${item.DeleteDateTime}`}</td>
                          <td style={{ textAlign: "center" }}>
                            <a
                              href=""
                              title="Restore Page"
                              className="icons_cls"
                              style={{
                                fontSize: "18px",
                                color: "gray",
                                textDecoration: "none",
                              }}
                              onClick={(event) => {
                                event.preventDefault();
                                restoreDeletedPage(item.Id);
                              }}
                            >
                              <i
                                title="Restore Page"
                                className="fa fa-undo"
                              ></i>
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {enableLoader && <Loader styles={{ height: "80px" }} />}
        </div>
      )}

      {isWidgetsActive && <RestoreWidgets resetToMainMenu={resetToMainMenu} />}

      {isRotationActive && (
        <RestoreRotation resetToMainMenu={resetToMainMenu} />
      )}

      {isFilterActive && <FilterRestore resetToMainMenu={resetToMainMenu} />}
    </Modal>
  );
};

export default RecycleBin;
