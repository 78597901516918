import {
  Change_Password,
  Forgot_Password,
  Register_New_User,
} from "../../Constants";

const changePassword = (email, oldPassword, newPassword) => {
  let url = `${Change_Password}`;
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      oldPassword: oldPassword,
      newPassword: newPassword,
    }),
  }).then((response) => {
    return response.text();
  });
};

const forgotPassword = (email, hostName) => {
  let url = `${Forgot_Password}`;
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      hostName: hostName,
    }),
  }).then((response) => {
    return response.text();
  });
};

const registerNewUser = (email, password, firstName, lastName, hostName) => {
  let url = `${Register_New_User}`;
  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      password: password,
      FirstName: firstName,
      LastName: lastName,
      hostName: hostName,
    }),
  }).then((response) => {
    return response.text();
  });
};

const AccountService = {
  changePassword,
  forgotPassword,
  registerNewUser,
};

export default AccountService;
