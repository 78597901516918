import { useEffect, useCallback } from "react";
import ConfiguratorServices from "../ConfiguratorService";
import { useState } from "react";
import Loader from "../../../../UI/Loader";
import { AddConfigurationSetting } from "./AddConfigrationSetting";
import ConfigurationTypeSettingItem from "./ConfigurationTypeSettingItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import ConfigrationConstant from "../Common/ConfigurationConstant";
import ConfigurationConstant from "../Common/ConfigurationConstant";
import GetMetaData, {
  GridMetaData_Format,
} from "../../../../../Helper/Services";
import { ConvertToLocalDateTimeWithFormat } from "../../../../../Helper/WidgetDataCommon";

export const ConfigurationTypeSettings = ({
  dataSourceTitle,
  dataSourceChildTitle,
  sessionId,
  dataSourceId,
  dataSourceType,
  settingId,
  onDataSourceClickHandler,
  onConfigurationTypeClickHandler,
  configurationType,
}) => {
  const email = useSelector((state) => state.login.userDetail.email);
  const userName = useSelector((state) => state.login.userDetail.userFullName);
  const [settingColumns, setSettingColumn] = useState([]);
  const [settingData, setSettingData] = useState([]);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [salesOppOwnerList, setSalesOppOwnerList] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [enableLoader, setEnableLoader] = useState(false);
  const [isSaveMode, setIsSaveMode] = useState(false);
  const [formDetails, setFormDetails] = useState([]);
  const [disableAddEdit, setDisableAddEdit] = useState(false);
  const [lastUpdated, setLastUpdated] = useState("");

  useEffect(() => {
    getClientSettings(true);
  }, [dataSourceId, settingId]);

  const getClientSettings = (loaderEnable) => {
    setEnableLoader(loaderEnable);
    ConfiguratorServices.GetClientSetting(sessionId, dataSourceId, settingId)
      .then((res) => {
        const lastModified = ConvertToLocalDateTimeWithFormat(
          res["LastModified"],
          "MMM DD, h:mm A"
        );
        const lastUpdatedBy = res["LastModifiedBy"];
        if (lastModified !== null && lastUpdatedBy.length > 0) {
          setLastUpdated(`${lastUpdatedBy}, ${lastModified}`);
        }

        setSettingColumn(res["columnMetaData"]);

        //display the calcuation options
        if (dataSourceChildTitle === ConfigrationConstant.Calculation_Options) {
          const data = res["GridData"];
          let isAsap = true;
          let isNotApplicable = true;

          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            element["isShowRow"] = true;
            if (element["options"] === ConfigrationConstant.ALAP) {
              isAsap = false;
            }

            if (element["options"] === ConfigrationConstant.Not_Applicable) {
              isNotApplicable = false;
            }

            if (
              element["parameter"] ===
              ConfigrationConstant.If_Promised_Date_Greater_Closed_Date
            ) {
              element["isShowRow"] = isAsap;
            }

            if (!isNotApplicable) {
              if (
                element["parameter"] ===
                ConfigrationConstant.Case_Aging_with_Promised_Date
              ) {
                element["isShowRow"] = true;
              } else {
                element["isShowRow"] = isNotApplicable;
              }
            }
          }
          setSettingData(data);
        } else {
          setSettingData(res["GridData"]);
        }

        getTimeZoneList(dataSourceChildTitle);
        getTeamList(dataSourceChildTitle);
        getColumnMetaDataList(dataSourceChildTitle);
        getSalesOppOwnerList(dataSourceChildTitle);
        setEnableLoader(false);
      })
      .catch((err) => {
        console.log("GetClientSetting:", err);
        setEnableLoader(false);
      });
  };

  const getTimeZoneList = (settingName) => {
    if (settingName && settingName === "Teams") {
      try {
        ConfiguratorServices.GetTimeZoneList(sessionId)
          .then((res) => {
            setTimeZoneList(res);
            setEnableLoader(false);
          })
          .catch((err) => {
            setEnableLoader(false);
          });
      } catch (e) {
        setEnableLoader(false);
        return true;
      }
    }
  };

  const getTeamList = (settingName) => {
    if (
      settingName &&
      (settingName === ConfigurationConstant.Case_Owners ||
        settingName === ConfigurationConstant.Sales_Opp_Owners ||
        settingName === ConfigurationConstant.Sales_Opp_Owners_Capacity ||
        settingName === ConfigurationConstant.Protected_Calling_Times ||
        settingName === ConfigurationConstant.Holidays ||
        settingName === ConfigurationConstant.Global_Holidays)
    ) {
      try {
        const getTeamSetting = configurationType.filter(function (data) {
          return data.settingName === ConfigurationConstant.Teams;
        });
        if (getTeamSetting && getTeamSetting.length > 0) {
          ConfiguratorServices.GetClientSetting(
            sessionId,
            dataSourceId,
            getTeamSetting[0]["columnSettingsId"]
          )
            .then((res) => {
              setTeamList(res["GridData"]);
              setEnableLoader(false);
            })
            .catch((err) => {
              console.log("Team list call:", err);
              setEnableLoader(false);
            });
        }
      } catch (e) {
        setEnableLoader(false);
        return true;
      }
    }
  };

  const getSalesOppOwnerList = (settingName) => {
    if (
      settingName &&
      settingName === ConfigurationConstant.Sales_Opp_Owners_Capacity
    ) {
      try {
        const getSalesOppOwnerSetting = configurationType.filter(function (
          data
        ) {
          return data.settingName === ConfigurationConstant.Sales_Opp_Owners;
        });
        if (getSalesOppOwnerSetting && getSalesOppOwnerSetting.length > 0) {
          ConfiguratorServices.GetClientSetting(
            sessionId,
            dataSourceId,
            getSalesOppOwnerSetting[0]["columnSettingsId"]
          )
            .then((res) => {
              setSalesOppOwnerList(res["GridData"]);
              setEnableLoader(false);
            })
            .catch((err) => {
              console.log("Team list call:", err);
              setEnableLoader(false);
            });
        }
      } catch (e) {
        setEnableLoader(false);
        return true;
      }
    }
  };

  const getColumnMetaDataList = (settingName) => {
    if (
      settingName &&
      settingName === ConfigurationConstant.Synchronize_Colors
    ) {
      try {
        GetMetaData(dataSourceTitle, "", sessionId)
          .then((res) => {
            const metaData = GridMetaData_Format(res);
            setColumnList(metaData["Grid Meta-Data"]);
            setEnableLoader(false);
          })
          .catch((err) => {
            console.log("GetMetaData:", err);
          });
      } catch (e) {
        setEnableLoader(false);
        return true;
      }
    }
  };

  const onAddSettingHandler = (val) => {
    if (disableAddEdit) return false;
    setIsSaveMode(val);
    let formObjList = [];
    for (const key of settingColumns) {
      const formObj = { ...key };
      formObj["isValid"] = true;
      formObj["errorMessage"] = "";
      formObj["value"] = "";

      if (
        dataSourceChildTitle === ConfigrationConstant.Case_Owners ||
        dataSourceChildTitle === ConfigrationConstant.Sales_Opportunity_Owner
      ) {
        if (formObj["dataField"] === "start_time") {
          formObj["value"] = "09:00";
        }
        if (formObj["dataField"] === "finish_time") {
          formObj["value"] = "17:00";
        }
      }

      //Set default value for Sales Opportunity Owner
      if (dataSourceChildTitle === ConfigrationConstant.Sales_Opp_Owners) {
        if (formObj["dataField"] === "target_exp_dollars") {
          formObj["value"] = "1";
        }
      }

      formObjList.push(formObj);
    }
    setFormDetails(formObjList);
  };

  const onFinishAddSettings = useCallback(() => {
    setIsSaveMode(false);
    getClientSettings(true);
  }, [dataSourceId, settingId]);

  const disabledAddEdit = useCallback((val) => {
    setDisableAddEdit(val);
  }, []);

  const handleDragEnd = (e) => {
    if (!e.destination) return false;
    let tempData = Array.from(settingData);
    const tempData1 = Array.from(settingData);
    let [source_data] = tempData.splice(e.source.index, 1);
    const dest_data = tempData1[e.destination.index];
    tempData.splice(e.destination.index, 0, source_data);
    setSettingData(tempData);

    var objData = {
      dataSourceId: dataSourceId,
      columnSettingId: settingId,
      settingsId: source_data["settingsId"],
      oldDisplayOrder: source_data["displayOrder"],
      newDisplayOrder: dest_data["displayOrder"],
      userEmail: email,
      lastModifiedBy: userName,
    };

    ConfiguratorServices.SortClientSetting(objData, sessionId)
      .then((res) => {
        getClientSettings(false);
      })
      .catch((err) => {
        console.log("SortClientSetting:", err);
      });
  };

  const rePopulateListOnChange = (val) => {
    //bind the calculation option items
    if (dataSourceChildTitle === ConfigrationConstant.Calculation_Options) {
      const data = [...settingData];
      if (val === ConfigrationConstant.ASAP) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          element["isShowRow"] = true;
          if (
            element["parameter"] ===
            ConfigrationConstant.If_Promised_Date_Greater_Closed_Date
          ) {
            element["isShowRow"] = false;
          }
        }
      } else if (val === ConfigrationConstant.ALAP) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          element["isShowRow"] = true;
        }
      }

      if (val === ConfigrationConstant.Not_Applicable) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          if (
            element["parameter"] ===
            ConfigrationConstant.Case_Aging_with_Promised_Date
          ) {
            element["isShowRow"] = true;
          } else {
            element["isShowRow"] = false;
          }
        }
      }

      setSettingData(data);
    }
  };

  const onCancelEvent = () => {
    if (dataSourceChildTitle === ConfigrationConstant.Calculation_Options) {
      const data = [...settingData];
      let isAsap = true;
      let isNotApplicable = true;

      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element["isShowRow"] = true;
        if (element["options"] === ConfigrationConstant.ASAP) {
          isAsap = false;
        }

        if (element["options"] === ConfigrationConstant.Not_Applicable) {
          isNotApplicable = false;
        }

        if (
          element["parameter"] ===
          ConfigrationConstant.If_Promised_Date_Greater_Closed_Date
        ) {
          element["isShowRow"] = isAsap;
        }

        if (!isNotApplicable) {
          if (
            element["parameter"] ===
            ConfigrationConstant.Case_Aging_with_Promised_Date
          ) {
            element["isShowRow"] = true;
          } else {
            element["isShowRow"] = isNotApplicable;
          }
        }
      }
      setSettingData(data);
    }
  };

  return (
    <>
      {!enableLoader && (
        <div className="configurator">
          <ul className="breadcrumb">
            <li>
              <a
                href=""
                onContextMenu={() => {
                  return false;
                }}
                //ng-click="hideDataSouceListMainHandler()"
                onClick={onDataSourceClickHandler}
              >
                Data Sources
              </a>
            </li>
            <li>
              <a
                href=""
                onContextMenu={() => {
                  return false;
                }}
                onClick={(event) => {
                  event.preventDefault();
                  onConfigurationTypeClickHandler("", "", true);
                }}
              >
                {dataSourceTitle}
              </a>
            </li>
            <li>
              {dataSourceChildTitle}
              &nbsp;
              {lastUpdated.length > 0 && (
                <span
                  title={`Last Modified: ${lastUpdated}`}
                  className="fa fa-info-circle"
                ></span>
              )}
            </li>
            <div
              style={{
                marginTop: "-18px",
                cursor: "pointer",
                left: "816px",
                position: "absolute",
                top: "53px",
                padding: "0px !important",
                cursor: disableAddEdit ? "not-allowed" : "pointer",
              }}
              title="Add Record"
              className="AddWidgetIcon dx-widget dx-state-hover-enabled dx-feedback dx-button dx-button-has-icon dx-button-normal button-theme-dark"
              onClick={() => onAddSettingHandler(true)}
            >
              <i
                className="fa fa-plus dx-icon"
                style={{
                  fontSize: "16px",
                  color: "gray",
                  height: "12px",
                  width: "15px",
                  marginTop: "-4px",
                }}
              ></i>
            </div>
          </ul>
          <div className="main-container">
            <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
              <table
                className="table table-hover"
                style={{ tableLayout: "fixed", wordWrap: "break-word" }}
              >
                <thead>
                  <tr>
                    {settingColumns.map((column, index) => {
                      if (
                        column["dataField"] !== "id" &&
                        column["dataField"] !== "client_id" &&
                        column["dataField"] !== "dataSourceId" &&
                        column["dataField"] !== "timezoneID" &&
                        column["dataField"] !== "case_typescol" &&
                        column["dataField"] !== "nsyteful_optionscol" &&
                        column["dataField"] !== "target_opp_points"
                      ) {
                        return <th key={index}>{column["displayName"]}</th>;
                      }
                      return "";
                    })}
                    <th className="action-col">Action</th>
                  </tr>
                </thead>
                <Droppable
                  droppableId="configurationListSort"
                  type="ConfigurationItems"
                >
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {isSaveMode && (
                        <AddConfigurationSetting
                          formDetails={formDetails}
                          onAddSettingHandler={onAddSettingHandler}
                          settingName={dataSourceChildTitle}
                          timeZoneList={timeZoneList}
                          dataSourceId={dataSourceId}
                          columnSettingId={settingId}
                          dataCount={settingData.length}
                          sessionId={sessionId}
                          onFinishAddSettings={onFinishAddSettings}
                          dataSourceType={dataSourceType}
                          teamList={teamList}
                          columnList={columnList}
                          salesOppOwnerList={salesOppOwnerList}
                        />
                      )}
                      {settingData.map((settings, index) => {
                        if (
                          dataSourceChildTitle ===
                          ConfigrationConstant.Calculation_Options
                        ) {
                          if (settings.isShowRow)
                            return (
                              <ConfigurationTypeSettingItem
                                key={index}
                                settingColumns={settingColumns}
                                settings={settings}
                                index={index}
                                isSaveMode={isSaveMode}
                                timeZoneList={timeZoneList}
                                disabledAddEdit={disabledAddEdit}
                                isAddEditDisable={disableAddEdit}
                                settingName={dataSourceChildTitle}
                                dataSourceId={dataSourceId}
                                columnSettingId={settingId}
                                sessionId={sessionId}
                                onFinishAddSettings={onFinishAddSettings}
                                dataSourceType={dataSourceType}
                                onChangeHandler={rePopulateListOnChange}
                                onCancelEvent={onCancelEvent}
                                teamList={teamList}
                                columnList={columnList}
                                salesOppOwnerList={salesOppOwnerList}
                              />
                            );
                          else return "";
                        }
                        return (
                          <ConfigurationTypeSettingItem
                            key={index}
                            settingColumns={settingColumns}
                            settings={settings}
                            index={index}
                            isSaveMode={isSaveMode}
                            timeZoneList={timeZoneList}
                            disabledAddEdit={disabledAddEdit}
                            isAddEditDisable={disableAddEdit}
                            settingName={dataSourceChildTitle}
                            dataSourceId={dataSourceId}
                            columnSettingId={settingId}
                            sessionId={sessionId}
                            onFinishAddSettings={onFinishAddSettings}
                            dataSourceType={dataSourceType}
                            onChangeHandler={rePopulateListOnChange}
                            onCancelEvent={onCancelEvent}
                            teamList={teamList}
                            columnList={columnList}
                            salesOppOwnerList={salesOppOwnerList}
                          />
                        );
                      })}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </DragDropContext>
          </div>
        </div>
      )}
      {enableLoader && <Loader styles={{ height: "80px" }} />}
    </>
  );
};
