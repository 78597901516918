import { useState, React } from "react";
import { PageListItem } from "./PageListItem";
import { AddRotationPage } from "./AddPage";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../../UI/Loader";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import RotationServices from "../../RotationService";
import { useDispatch } from "react-redux";
import { layoutActions } from "../../../../../../store/layout-slice";

export const RotationPages = ({ rotationItem, hidePageList }) => {
  const dispatch = useDispatch();
  const rotationList = useSelector((state) => state.layout.RotationList);
  const userId = useSelector((state) => state.layout.UserId);
  const [rotationPageList, setRotationPageList] = useState([]);
  const [isSavePageEnabled, setIsSavePageEnabled] = useState(false);
  const [disableAddEdit, setDisableAddEdit] = useState(false);
  const [enableLoader, setEnableLoader] = useState(false);

  useEffect(() => {
    const _rotationPageList = rotationList
      .filter(function (dt) {
        return dt.Id === rotationItem.Id;
      })
      .map((item) => item.PageList);
    setRotationPageList([..._rotationPageList[0]]);
  }, [rotationList]);

  const enableSavePageButtonHandler = (val) => {
    setIsSavePageEnabled(val);
  };

  const disableAddEditHandler = (val) => {
    setDisableAddEdit(val);
  };

  const loaderHandler = (val) => {
    setEnableLoader(val);
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return false;
    let tempData = Array.from(rotationPageList);
    let [source_data] = tempData.splice(e.source.index, 1);
    const dest_data = tempData[e.destination.index];
    tempData.splice(e.destination.index, 0, source_data);
    setRotationPageList(tempData);

    RotationServices.sortRotationPage(
      source_data.Id,
      rotationItem.Id,
      dest_data.Id
    )
      .then((res) => {
        getRotations();
      })
      .catch((err) => {
        console.log("sortRotationPage:-", err);
      });
  };

  const getRotations = () => {
    RotationServices.getRotation(userId)
      .then((res) => {
        if (res.Success) {
          dispatch(layoutActions.updateRotationList(res.Message.rotationlist));
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      {!enableLoader && (
        <div className="rotation-page-list-container">
          <ul className="breadcrumb">
            <li>
              <a
                href=""
                onClick={(event) => {
                  event.preventDefault();
                  hidePageList();
                }}
                onContextMenu={() => {
                  return false;
                }}
              >
                Rotation(s)
              </a>
            </li>
            <li>{rotationItem.RotationName} </li>
            <div
              title="Add Page"
              style={{
                left: "816px",
                position: "absolute",
                top: "34.6px",
                padding: "0px !important",
                cursor: disableAddEdit === true ? "not-allowed" : "pointer",
              }}
              className="AddWidgetIcon dx-widget dx-state-hover-enabled dx-feedback dx-button dx-button-has-icon dx-button-normal button-dark1"
              onClick={() => enableSavePageButtonHandler(true)}
            >
              <i
                className="fa fa-plus dx-icon"
                style={{
                  fontSize: "16px",
                  color: "gray",
                  height: "12px",
                  width: "15px",
                  marginTop: "-4px",
                }}
              ></i>
            </div>
          </ul>

          <div className="rotation-page-item-container">
            <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>Page Name</th>
                    <th style={{ width: "40%" }}>Time Interval (mins)</th>
                    <th style={{ width: "10%" }} className="action-col">
                      Action
                    </th>
                  </tr>
                </thead>
                <Droppable droppableId="rotationPageSort" type="PERSON1">
                  {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                      {isSavePageEnabled && (
                        <AddRotationPage
                          enableSaveButtonHandler={enableSavePageButtonHandler}
                          rotationId={rotationItem.Id}
                          loaderHandler={loaderHandler}
                        />
                      )}
                      {rotationPageList &&
                        rotationPageList.map((item, index) => {
                          return (
                            <PageListItem
                              key={item.Id}
                              index={index}
                              isSavePageEnabled={isSavePageEnabled}
                              rotationId={rotationItem.Id}
                              diabledAddEdit={disableAddEditHandler}
                              isDisableAddEdit={disableAddEdit}
                              item={item}
                              loaderHandler={loaderHandler}
                            />
                          );
                        })}

                      {rotationPageList.length === 0 && !isSavePageEnabled && (
                        <tr>
                          <td colSpan="3" style={{ textAlign: "center" }}>
                            Record not found...
                          </td>
                        </tr>
                      )}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </DragDropContext>
          </div>
        </div>
      )}

      {enableLoader && <Loader styles={{ height: "80px" }} />}
    </>
  );
};
