import { useSelector } from "react-redux";
import AccountService from "./AccountService";
import { useState } from "react";

export const ForgotPassword = (props) => {
  const getTheme = useSelector((state) => state.layout.theme);
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [responseMsg, setResponseMsg] = useState("");

  const onSubmitHandler = () => {
    if (email === "") {
      setErrMsg("Required");
      return false;
    }

    const isEmailValid = emailValidation();
    if (!isEmailValid) {
      setErrMsg("Invalid email");
      return false;
    }
    const getUrl = window.location.href;
    console.log("getUrl", getUrl);
    AccountService.forgotPassword(email, getUrl)
      .then((resp) => {
        const message = resp.replace(/"/g, "");
        setResponseMsg(message);
      })
      .catch((err) => {
        console.log("forgotPassword err:", err);
        const message = err.replace(/"/g, "");
        setResponseMsg(message);
      });
  };

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !(!email || regex.test(email) === false);
  };

  return (
    <div>
      {!responseMsg && (
        <form className="settings" name="FrgPassForm">
          <p htmlFor="emailFrgPass">Email</p>
          <input
            type="email"
            name="emailFrgPass"
            onChange={(e) => {
              setEmail(e.target.value);
              setErrMsg("");
            }}
          />
          {errMsg && (
            <span style={{ color: "red", display: "block" }}>{errMsg}</span>
          )}
          <div style={{ paddingTop: "15px" }}>
            <button
              className={`dx-widget dx-button-has-text dx-button dx-button-normal ${
                getTheme !== "Light" ? "button-dark" : ""
              }`}
              style={{ width: "125px" }}
              type="button"
              onClick={onSubmitHandler}
            >
              Get Password
            </button>
          </div>
        </form>
      )}

      {responseMsg && (
        <div>
          <div style={{ padding: "5px" }}>{responseMsg}</div>
          <div style={{ paddingTop: "15px", textAlign: "right" }}>
            <button
              type="submit"
              className={`dx-widget dx-button-has-text dx-button dx-button-normal button`}
              onClick={props.onCloseLoginHandler}
            >
              Ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
