import { useEffect, useState, React } from "react";
import { useSelector } from "react-redux";
import { QueueSizeGaugeRefreshTime } from "../../../../Constants";
import {
  GetWidgetData,
  GetWidgetSettings,
  GetLastRefreshDateTime,
} from "../../../../Helper/Services";
import {
  GetFormattedGridData,
  IsSharedFilterAlert,
} from "../../../../Helper/WidgetDataCommon";
import NoShowDiv from "../../../UI/NoShowTemplate";
import GetQueueSizeDt from "./QueueSizeGaugeWidgetService";
import QueueSizeGaugeD3Component from "./QueueSizeGuageD3Component";

const QueueSizeGaugeWidget = (props) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const isOtherUserPage = useSelector((state) => state.layout.IsOtherUserPage);
  const sharedUserEmail = useSelector((state) => state.layout.SharedUserEmail);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [widgetDt, setWidgetDt] = useState({});
  const [qsgtDt, setQsg] = useState({});
  const [screenMsg, setScreenMsg] = useState("No Data");
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    const getWidgetSetting = () => {
      let isSharedFilter = false;
      let sharedFilterUserId = "";
      let filterId = "";

      GetWidgetSettings(props.widgetId)
        .then((widgetSettingsRes) => {
          setShowNoData(false);
          if (
            widgetSettingsRes["IsSharedFilter"] !== undefined &&
            widgetSettingsRes["IsSharedFilter"] == "true"
          ) {
            isSharedFilter = true;
            sharedFilterUserId = widgetSettingsRes["OriginalUserId"];
            filterId = widgetSettingsRes["FilterId"];
          }

          if (widgetSettingsRes["reports"] !== "") {
            GetWidgetData(
              widgetSettingsRes["Filter"],
              widgetSettingsRes["reports"],
              widgetSettingsRes["connection"],
              sessionId,
              isOtherUserPage,
              sharedUserEmail,
              isSharedFilter,
              sharedFilterUserId,
              filterId
            )
              .then((widgetDataResponse) => {
                GetLastRefreshDateTime(
                  widgetSettingsRes["reports"],
                  "",
                  sessionId
                )
                  .then((lstReponseDt) => {
                    props.onGetNotification(lstReponseDt);
                    if (IsSharedFilterAlert(widgetDataResponse)) {
                      setScreenMsg(widgetDataResponse);
                      setShowNoData(true);
                      return false;
                    }

                    const getFormattedDt =
                      GetFormattedGridData(widgetDataResponse);

                    const getProcessedWidgetDt = GetQueueSizeDt(
                      getFormattedDt,
                      widgetSettingsRes
                    );
                    setQsg(getProcessedWidgetDt);
                    setWidgetDt(getFormattedDt);
                  })
                  .catch((err) => {
                    console.error("GetLastRefreshDateTime:-", err);
                  });
              })
              .catch((err) => {
                console.error("GetWidgetData:-", err);
              });
          } else {
          }
        })
        .catch((err) => {
          console.log("getWidget settings:-", err);
        });
    };

    if (sessionId !== "" && sessionId != null) {
      getWidgetSetting();
      GetWidgetSize();
    }

    window.addEventListener("resize", () => {
      GetWidgetSize();
    });

    const refreshWidget = setInterval(() => {
      console.log("Queue Size Refresh time..", new Date().toLocaleTimeString());
      getWidgetSetting();
    }, QueueSizeGaugeRefreshTime);

    return () => {
      //clear setinterval on unmount component
      clearInterval(refreshWidget);
    };
  }, [sessionId, props.RefreshVersion]);

  const GetWidgetSize = () => {
    const getParentContainer = document.getElementById(
      "content_" + props.widgetId
    );
    if (getParentContainer !== undefined && getParentContainer != null) {
      const getHeight = getParentContainer.clientHeight;
      const getWidth = getParentContainer.clientWidth;
      setHeight(getHeight);
      setWidth(getWidth);
    }
  };

  return (
    <div>
      {widgetDt.length > 0 && showNoData === false && (
        <QueueSizeGaugeD3Component
          height={height}
          width={width}
          data={qsgtDt}
          wid={props.wid}
          getWidgetSize={GetWidgetSize}
        />
      )}
      {widgetDt.length === 0 && showNoData === true && (
        <NoShowDiv screenMsg={screenMsg} />
      )}
    </div>
  );
};

export default QueueSizeGaugeWidget;
