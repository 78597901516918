import { useEffect, useState, React } from "react";
import PercentageTemplate from "./PercentageTemplate";
import TrafficSignalTemplate from "./TrafficSignal";

const TableTd = (props) => {
  const [item, setItem] = useState([]);
  useEffect(() => {
    setItem(props.cellItem);
  }, [props.cellItem]);

  let spanEle = "";

  if (
    !item.template &&
    item.dataType !== "number" &&
    item.dataType !== "decimal" &&
    item.dataType !== "date"
  ) {
    var whiteSpaceCss = "";
    if (
      item.dataField !== "CaseDescription" &&
      item.dataField !== "description" &&
      item.dataField !== "Description" &&
      item.dataField !== "LastNote" &&
      item.dataField !== "lastNote"
    )
      whiteSpaceCss = "pre";

    spanEle = (
      <span
        style={{ whiteSpace: whiteSpaceCss }}
        title={props.rowItem[item.dataField]}
      >
        {props.rowItem[item.dataField]}
      </span>
    );
  } else if (
    !item.template &&
    item.dataType !== "date" &&
    (item.dataType == "number" || item.dataType == "decimal")
  ) {
    if (props.rowItem[item.dataField] === "N/A") {
      spanEle = (
        <span title={props.rowItem[item.dataField]}>
          {props.rowItem[item.dataField]}
        </span>
      );
    } else {
      const val = (+props.rowItem[item.dataField]).toFixed(2);
      spanEle = <span title={val}>{val}</span>;
    }
  } else if (!item.template && item.dataType === "date") {
    var val = item.format(props.rowItem[item.dataField]);
    spanEle = <span title={val}>{val}</span>;
  } else if (
    props.settings.traffic !== undefined &&
    item.dataField === props.settings.traffic
  ) {
    spanEle = (
      <TrafficSignalTemplate
        options={{
          value: props.rowItem[item.dataField],
          column: { dataField: item.dataField, dataType: item.dataType },
        }}
        settings={props.settings}
      />
    );
  } else if (
    item.dataType === "percentage" &&
    item.dataField !== props.settings.traffic
  ) {
    spanEle = (
      <PercentageTemplate
        options={{
          value: props.rowItem[item.dataField],
          column: { dataField: item.dataField, dataType: item.dataType },
        }}
      />
    );
  }

  return spanEle;
};

export default TableTd;
