import React from "react";
import { GetTargetBullets } from "../TableWidgetService";

const TrafficSignalTemplate = (props) => {
  let cellHtml = <div style={{ textAlign: "left", padding: "0px" }}> </div>;

  let value = props.options.value;

  if (props.options.value === "null") {
    cellHtml = "N/A";
  }

  if (props.options.column.dataType === "percentage") {
    if (
      props.settings.percentagevalue &&
      props.settings.percentagevalue === "true"
    ) {
      if (
        (props.options.column.dataField === "ProjectStatus" ||
          props.options.column.dataField === "OpportunityStatus") &&
        props.options.value === 0
      ) {
        cellHtml = (
          <div style={{ textAlign: "center", padding: "0px" }}>
            <img style={{ float: "left" }} alt="" src="" width="20" />
            <span style={{ float: "right", paddingRight: "5px" }}></span>
          </div>
        );
      } else {
        cellHtml = (
          <div style={{ textAlign: "center", padding: "0px" }}>
            <img
              src={GetTargetBullets(
                props.options.value,
                props.settings.blackIcon,
                props.settings.redIcon,
                props.settings.yellowIcon
              )}
              width="20"
            />
          </div>
        );
      }
    } else {
      if (
        (props.options.column.dataField === "ProjectStatus" ||
          props.options.column.dataField === "OpportunityStatus") &&
        value === 0
      ) {
        cellHtml = (
          <div style={{ textAlign: "center", padding: "0px" }}>
            <img style={{ float: "left" }} alt="" src="" width="20" />
            <span style={{ float: "right", paddingRight: "5px" }}></span>
          </div>
        );
      } else {
        value = props.options.value * 100;
        cellHtml = (
          <div
            title={parseInt(value)}
            style={{ textAlign: "left", padding: "0px" }}
          >
            <img
              style={{ float: "left" }}
              src={GetTargetBullets(
                props.options.value,
                props.settings.blackIcon,
                props.settings.redIcon,
                props.settings.yellowIcon
              )}
              width="20"
            />
            <span style={{ float: "right", paddingRight: "5px" }}>
              {`${parseInt(value)}%`}
            </span>
          </div>
        );
      }
    }
  } else {
    if (props.settings.percentagevalue === "true") {
      cellHtml = (
        <div style={{ textAlign: "center", padding: "0px" }}>
          <img
            src={GetTargetBullets(
              props.options.value,
              props.settings.blackIcon,
              props.settings.redIcon,
              props.settings.yellowIcon
            )}
            width="20"
          />
        </div>
      );
    } else {
      cellHtml = (
        <div
          title={parseFloat(value).toFixed(2)}
          style={{ textAlign: "left", padding: "0px" }}
        >
          <img
            style={{ float: "left" }}
            src={GetTargetBullets(
              props.options.value,
              props.settings.blackIcon,
              props.settings.redIcon,
              props.settings.yellowIcon
            )}
            width="20"
          />
          <span style={{ float: "right", paddingRight: "5px" }}>
            {parseFloat(value).toFixed(2)}
          </span>
        </div>
      );
    }
  }

  return cellHtml;
};

export default TrafficSignalTemplate;
