import { useSelector } from "react-redux";
import RotationItem from "./RotationItem";
import "./index.css";
import { AddRotation } from "./AddRotation";
import { useState, React } from "react";
import Loader from "../../../../UI/Loader";

const RotationList = ({ displayPagesHandler }) => {
  const rotationList = useSelector((state) => state.layout.RotationList);

  const [enableLoader, setEnableLoader] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [disableAddEdit, setDisableAddEdit] = useState(false);

  const loaderHandler = (val) => {
    setEnableLoader(val);
  };

  const enableSaveButtonHandler = (val) => {
    setIsSaveEnabled(val);
  };

  const disableAddEditHandler = (val) => {
    setDisableAddEdit(val);
  };

  return (
    <>
      {!enableLoader && (
        <div className="rotation-list">
          <ul className="breadcrumb">
            <li>Rotation(s)</li>
            <div
              title="Add Rotation"
              style={{
                left: "816px",
                position: "absolute",
                top: "34.6px",
                padding: "0px !important",
                cursor: disableAddEdit === true ? "not-allowed" : "pointer",
              }}
              className="AddWidgetIcon dx-widget dx-state-hover-enabled dx-feedback dx-button dx-button-has-icon dx-button-normal button-theme-dark"
              onClick={() => {
                if (disableAddEdit) return false;
                enableSaveButtonHandler(true);
              }}
            >
              <i
                className="fa fa-plus dx-icon"
                style={{
                  fontSize: "16px",
                  color: "gray",
                  height: "12px",
                  width: "15px",
                  marginTop: "-4px",
                }}
              ></i>
            </div>
          </ul>

          <div className="rotation-list-container">
            <ul className="list-group">
              {isSaveEnabled && (
                <AddRotation
                  key={Math.random(100, 1000)}
                  loaderHandler={loaderHandler}
                  enableSaveButtonHandler={enableSaveButtonHandler}
                />
              )}

              {rotationList.map((item, index) => {
                return (
                  <RotationItem
                    item={item}
                    key={index}
                    isSaveEnabled={isSaveEnabled}
                    loaderHandler={loaderHandler}
                    diabledAddEdit={disableAddEditHandler}
                    isDisableAddEdit={disableAddEdit}
                    displayPagesHandler={displayPagesHandler}
                  />
                );
              })}
            </ul>
            {rotationList.length === 0 && !isSaveEnabled && (
              <div style={{ textAlign: "center", padding: "30px" }}>
                <p>Select "+", above, to add a Rotation.</p>
              </div>
            )}
          </div>
        </div>
      )}
      {enableLoader && <Loader styles={{ height: "80px" }} />}
    </>
  );
};

export default RotationList;
