import React from "react";
import { useDispatch } from "react-redux";
import { pageActions } from "../../../store/page-slice";
import ModalOptional from "./../../UI/ModalOptional";
import ClonePageForm from "./ClonePageForm";

const ClonePage = () => {
  const dispatch = useDispatch();
  const closePopupHandler = () => {
    dispatch(pageActions.triggerClonePage());
  };
  return (
    <ModalOptional title="Clone Existing Page" onClose={closePopupHandler}>
      <ClonePageForm onClose={closePopupHandler} />
    </ModalOptional>
  );
};

export default ClonePage;
