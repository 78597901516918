import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import DatasourceControl from "../../../../UI/TemplateControls/DatasourceControl";
import WidgetTitleControl from "../../../../UI/TemplateControls/WidgetTitleControl";

const CircularGuageTemplate = forwardRef((props, ref) => {
  const [showSettingsPanel, setShowSettingsPanel] = useState(true);
  const [widgetContentPanel, setWidgetContentPanel] = useState(false);
  const [titleContentPanel, setTitleContentPanel] = useState(false);
  const [dtSourceVal, setDtSourceVal] = useState("");
  const [dtSourceIsValid, setDtSourceIsValid] = useState(true);
  const [gauageType, setGauageType] = useState({ value: "", isValid: true });
  const [title, setTitle] = useState("");
  const [shortTitle, setShortTitle] = useState("");

  const [filters, setFilters] = useState([]);
  const [dtSourceItems, setDtSourceItems] = useState([]);
  const [titleIsValid, setTitleIsValid] = useState(true);
  const [shortTitleIsValid, setShortTitleIsValid] = useState(true);
  const GaugeType = [
    {
      id: 1,
      value: "Forward-booked days",
      shortValue: "Fwd-bkd days",
    },
    {
      id: 2,
      value: "Pending-appointment days",
      shortValue: "Pndg-apt days",
    },
    {
      id: 3,
      value: "Pending-opportunity days",
      shortValue: "Pndg-app days",
    },
  ];

  useEffect(() => {
    if (Object.keys(props.dataSources.Filters).length !== 0) {
      setFilters(props.dataSources.Filters);
    }

    if (Object.keys(props.dataSources.DataSources).length !== 0) {
      const _items = Object.entries(props.dataSources.DataSources).map(
        (item) => {
          return { id: item[0], text: item[1] };
        }
      );
      setDtSourceItems(_items);
    }
  }, [props.dataSources]);

  useImperativeHandle(ref, () => ({
    GetValidateData() {
      const getVaildForm = validateForm();
      if (getVaildForm) {
        return {
          formIsValid: getVaildForm,
          data: {
            Filter: dtSourceVal || 258,
            ShortTitle: shortTitle,
            Title: title,
            DataSource: gauageType.value,
          },
        };
      } else {
        return {
          formIsValid: getVaildForm,
        };
      }
    },
  }));

  const onSettingsClicked = (val) => {
    if (val) {
      setShowSettingsPanel(val);
    } else {
      setShowSettingsPanel(!showSettingsPanel);
    }
    setWidgetContentPanel(false);
    setTitleContentPanel(false);
  };

  const onWidgetContentClicked = (val) => {
    if (val) {
      setWidgetContentPanel(val);
    } else {
      setWidgetContentPanel(!widgetContentPanel);
    }
    setShowSettingsPanel(false);
    setTitleContentPanel(false);
  };

  const onTitleContentPanelClicked = (val) => {
    if (val) {
      setTitleContentPanel(val);
    } else {
      setTitleContentPanel(!titleContentPanel);
    }
    setWidgetContentPanel(false);
    setShowSettingsPanel(false);
  };

  const dataSourceChangeHandler = (event) => {
    setDtSourceVal(event.target.value);
    const getGaugeType = GaugeType.filter((x) => x.id == gauageType.value);
    const getFilter = filters.filter((x) => x.id == event.target.value);
    if (getFilter.length > 0) {
      if (getGaugeType.length > 0) {
        setTitle(getGaugeType[0].value + ":" + getFilter[0].text);
        setShortTitle(getGaugeType[0].shortValue + ":" + getFilter[0].text);
      }
    }
  };

  const OnTitleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const OnShortTitleChangeHandler = (event) => {
    setShortTitle(event.target.value);
  };

  const onChangeGuageType = (event) => {
    setGauageType({ ...gauageType, value: event.target.value });
    const getGaugeType = GaugeType.filter((x) => x.id == event.target.value);
    if (getGaugeType.length > 0) {
      setTitle(getGaugeType[0].value);
      setShortTitle(getGaugeType[0].shortValue);
    }
  };

  const validateTitle = () => {
    if (title === "") {
      onTitleContentPanelClicked(true);
      setTitleIsValid(false);
      setTimeout(() => {
        setTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateShortTitle = () => {
    if (shortTitle === "") {
      onTitleContentPanelClicked(true);
      setShortTitleIsValid(false);
      setTimeout(() => {
        setShortTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateFilter = () => {
    if (dtSourceVal === "") {
      onSettingsClicked(true);
      setDtSourceIsValid(false);
      setTimeout(() => {
        setDtSourceIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateDatasource = () => {
    if (gauageType.value === "") {
      onWidgetContentClicked(true);
      setGauageType({ ...gauageType, isValid: false });
      setTimeout(() => {
        setGauageType({ ...gauageType, isValid: true });
      }, 3000);
      return false;
    }
    return true;
  };

  const validateForm = () => {
    const isFilterValid = validateFilter();
    if (!isFilterValid) {
      return false;
    }

    const isGaugeTypeValid = validateDatasource();
    if (!isGaugeTypeValid) {
      return false;
    }

    const isTitleValid = validateTitle();
    if (!isTitleValid) {
      return false;
    }

    const isShortTitleValid = validateShortTitle();
    if (!isShortTitleValid) {
      return false;
    }

    return true;
  };

  return (
    <React.Fragment>
      <hr className="style-one" />
      <div onClick={onSettingsClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {showSettingsPanel && "-"}
          {!showSettingsPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>DATA</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: showSettingsPanel ? "block" : "none",
        }}
      >
        <DatasourceControl
          value={dtSourceVal}
          onChangeHandler={dataSourceChangeHandler}
          isValid={dtSourceIsValid}
          dtList={filters}
        />
      </div>
      <div onClick={onTitleContentPanelClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {titleContentPanel && "-"}
          {!titleContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET TITLE</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: titleContentPanel ? "block" : "none",
        }}
      >
        <WidgetTitleControl
          titleVal={title}
          shortTitleVal={shortTitle}
          OnTitleChangeHandler={OnTitleChangeHandler}
          OnShortTitleChangeHandler={OnShortTitleChangeHandler}
          isTitleValid={titleIsValid}
          isShortTitleValid={shortTitleIsValid}
        />
      </div>

      <div onClick={onWidgetContentClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {widgetContentPanel && "-"}
          {!widgetContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET CONTENT</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: widgetContentPanel ? "block" : "none",
        }}
      >
        <p>Gauge Type</p>
        <select
          value-key="DataSource"
          value={gauageType.value}
          onChange={onChangeGuageType}
        >
          <option value="">Please Select</option>
          {dtSourceItems.map((item, index) => {
            return (
              <option key={index} value={item.id}>
                {item.text}
              </option>
            );
          })}
        </select>
        {!gauageType.isValid && (
          <span style={{ color: "red", paddingLeft: "5px" }}>Required</span>
        )}

        <br />
      </div>
      <br />
    </React.Fragment>
  );
});

export default CircularGuageTemplate;
