import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  React,
} from "react";
import { useSelector } from "react-redux";
import { GridMetaData_Format } from "../../../../../Helper/Services";
import { GetCategoryList } from "../../../../../Helper/WidgetDataCommon";
import GetMetaData from "../../../../../Helper/Services";
import DataSectionSettings from "../../../../UI/TemplateControls/DataSection";
import WidgetTitleControl from "../../../../UI/TemplateControls/WidgetTitleControl";
import BarChartContent from "./ConfigContent/BarChartContent";
import { GetValueList } from "../../../../../Helper/WidgetDataCommon";
import { DataSectionObject } from "../../../../../Helper/CommonModel";

const BarChartTemplate = forwardRef((props, ref) => {
  const [showSettingsPanel, setShowSettingsPanel] = useState(true);
  const [widgetContentPanel, setWidgetContentPanel] = useState(false);
  const [titleContentPanel, setTitleContentPanel] = useState(false);
  const [dtSourceItems, setDtSourceItems] = useState([]);
  const [title, setTitle] = useState("Bar Chart");
  const [shortTitle, setShortTitle] = useState("Bar Chart");
  const sessionId = useSelector((state) => state.login.sessionId);
  const [ddlList, setDdlList] = useState([]);
  const [getValues, setGetValues] = useState([]);
  const [titleIsValid, setTitleIsValid] = useState(true);
  const [shortTitleIsValid, setShortTitleIsValid] = useState(true);
  const dataSection = useRef(DataSectionObject);
  const dataSectionRef = useRef();
  const [barname, setBarname] = useState({ value: "", isValid: true });
  const [barvalue, setBarvalue] = useState("None");
  const [operationperform, setOperationperform] = useState("");
  const [stackcategory, setStackcategory] = useState("");
  const [callout, setCallouts] = useState("");

  useEffect(() => {
    if (
      props.dataSources != undefined &&
      Object.keys(props.dataSources.Reports).length !== 0
    ) {
      const _items = Object.entries(props.dataSources.Reports)
        .filter((item) => {
          if (item[1] === "Generic Table") {
            return true;
          }
        })
        .map((item) => item[0]);
      setDtSourceItems(_items);
    }

    if (
      props.dataSources != undefined &&
      props.isEdit === true &&
      props.settings !== undefined &&
      props.settings["reports"] != undefined
    ) {
      getMetaData(props.settings["reports"]);
      setTitle(props.settings["Title"]);
      setShortTitle(props.settings["ShortTitle"]);
      setBarname({
        value: props.settings["barname"],
        isValid: true,
      });
      setBarvalue(props.settings["barvalues"]);
      setOperationperform(props.settings["operationperform"]);
      setStackcategory(props.settings["stackcategory"]);
      setCallouts(props.settings["callouts"]);
    }
  }, [props.dataSources, props.settings]);

  useImperativeHandle(ref, () => ({
    GetValidateData() {
      const getVaildForm = validateForm();
      if (getVaildForm) {
        return {
          formIsValid: getVaildForm,
          data: {
            Filter: dataSection.current.filterName,
            FilterId: dataSection.current.filterDetails.filterid,
            IsSharedFilter: dataSection.current.filterDetails.filterid,
            LastRefreshedTime: "",
            OriginalUserId: dataSection.current.filterDetails.originaluserid,
            ShortTitle: shortTitle,
            Title: title,
            barname: barname.value,
            barvalues: barvalue,
            callouts: callout,
            connection: dataSection.current.connectionName,
            operationperform: operationperform,
            reports: dataSection.current.dataSourceName,
            stackcategory: stackcategory,
          },
        };
      } else {
        return {
          formIsValid: getVaildForm,
        };
      }
    },
  }));

  const onSettingsClicked = (val) => {
    if (val) {
      setShowSettingsPanel(val);
    } else {
      setShowSettingsPanel(!showSettingsPanel);
    }
    setWidgetContentPanel(false);
    setTitleContentPanel(false);
  };

  const onWidgetContentClicked = (val) => {
    if (val) {
      setWidgetContentPanel(val);
    } else {
      setWidgetContentPanel(!widgetContentPanel);
    }
    setShowSettingsPanel(false);
    setTitleContentPanel(false);
  };

  const onTitleContentPanelClicked = (val) => {
    if (val) {
      setTitleContentPanel(val);
    } else {
      setTitleContentPanel(!titleContentPanel);
    }
    setWidgetContentPanel(false);
    setShowSettingsPanel(false);
  };

  const OnTitleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const OnShortTitleChangeHandler = (event) => {
    setShortTitle(event.target.value);
  };

  const onChangeBarValues = (event) => {
    setBarvalue(event.target.value);
    if (event.target.value === "None") {
      setOperationperform("");
    } else {
      setOperationperform("Sum");
    }
  };
  const onChangeBarname = (event) => {
    setBarname({ ...barname, value: event.target.value, isValid: true });
  };
  const onChangeOperationperform = (event) => {
    setOperationperform(event.target.value);
  };
  const onChangeStackcategory = (event) => {
    setStackcategory(event.target.value);
  };
  const onChangeCallouts = (event) => {
    setCallouts(event.target.value);
  };

  const onDatasourceChangeHandler = (dataSourceVal) => {
    if (dataSourceVal !== "") {
      getMetaData(dataSourceVal);
    }
  };

  const getMetaData = (dataSourceVal) => {
    GetMetaData(dataSourceVal, "", sessionId)
      .then((json) => {
        const metaData = GridMetaData_Format(json);
        const getCategories = GetCategoryList(metaData);
        const getNumericValues = GetValueList(metaData);
        setDdlList(getCategories);
        setGetValues(getNumericValues);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const validateTitle = () => {
    if (title === "") {
      onTitleContentPanelClicked(true);
      setTitleIsValid(false);
      setTimeout(() => {
        setTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateShortTitle = () => {
    if (shortTitle === "") {
      onTitleContentPanelClicked(true);
      setShortTitleIsValid(false);
      setTimeout(() => {
        setShortTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateBarname = () => {
    if (barname.value === "") {
      onWidgetContentClicked(true);
      setBarname({ ...barname, isValid: false });
      setTimeout(() => {
        setBarname({ ...barname, isValid: true });
      }, 3000);
      return false;
    }
    return true;
  };

  const validateForm = () => {
    const dataSectionVal = dataSectionRef.current.validateDataSection();
    dataSection.current = dataSectionVal.data;

    if (!dataSectionVal.isDataSectionValid) {
      onSettingsClicked(true);
      return false;
    }

    const isTitleValid = validateTitle();
    if (!isTitleValid) {
      return false;
    }

    const isShortTitleValid = validateShortTitle();
    if (!isShortTitleValid) {
      return false;
    }
    const isBarnameValid = validateBarname();
    if (!isBarnameValid) {
      return false;
    }

    return true;
  };

  return (
    <Fragment>
      {!props.isEdit && <hr className="style-one" />}
      <div onClick={onSettingsClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {showSettingsPanel && "-"}
          {!showSettingsPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>DATA</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: showSettingsPanel ? "block" : "none",
        }}
      >
        <DataSectionSettings
          dataSourceList={dtSourceItems}
          dtSourceChange={onDatasourceChangeHandler}
          ref={dataSectionRef}
          settings={props.settings}
          isEdit={props.isEdit}
        />
      </div>
      <div onClick={onTitleContentPanelClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {titleContentPanel && "-"}
          {!titleContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET TITLE</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: titleContentPanel ? "block" : "none",
        }}
      >
        <WidgetTitleControl
          titleVal={title}
          shortTitleVal={shortTitle}
          OnTitleChangeHandler={OnTitleChangeHandler}
          OnShortTitleChangeHandler={OnShortTitleChangeHandler}
          isTitleValid={titleIsValid}
          isShortTitleValid={shortTitleIsValid}
        />
      </div>
      <div onClick={onWidgetContentClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {widgetContentPanel && "-"}
          {!widgetContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET CONTENT</p>
      </div>
      <div
        style={{
          paddingLeft: "12px",
          display: widgetContentPanel ? "block" : "none",
        }}
      >
        <BarChartContent
          ddlList={ddlList}
          valueList={getValues}
          barname={barname}
          barvalue={barvalue}
          operationperform={operationperform}
          stackcategory={stackcategory}
          callout={callout}
          onChangeBarValues={onChangeBarValues}
          onChangeBarname={onChangeBarname}
          onChangeOperationperform={onChangeOperationperform}
          onChangeStackcategory={onChangeStackcategory}
          onChangeCallouts={onChangeCallouts}
        />
      </div>
    </Fragment>
  );
});

export default BarChartTemplate;
