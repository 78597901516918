import React from "react";
const PercentageTemplate = (props) => {
  let cellHtml = <div></div>;
  let value = props.options.value;
  if (value != undefined && value != "null") {
    if (
      (props.options.column.dataField === "ProjectStatus" ||
        props.options.column.dataField == "OpportunityStatus") &&
      value === 0
    ) {
      cellHtml = "";
    } else {
      if (props.options.column.dataType === "percentage") {
        value = (props.options.value * 100).toFixed(10);
      }
      const val = Math.round(value) + "%";
      cellHtml = (
        <div
          style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          title={val}
        >
          {val}
        </div>
      );
    }
  } else {
    cellHtml = "N/A";
  }

  return cellHtml;
};

export default PercentageTemplate;
