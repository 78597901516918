import $ from "jquery";

const pieChartSettingColumnConfig = (data) => {
  if (data.category != undefined && data.category != "") {
    data.category = _removeSpaceAndUnderscore(data.category);
  }

  if (data.value != undefined && data.value != "") {
    data.value = _removeSpaceAndUnderscore(data.value);
  }

  return data;
};

const _removeSpaceAndUnderscore = (val) => {
  if (val.indexOf("_") > -1) {
    val = val.replace(/_/g, "");
  }

  if (val != undefined && val != "") {
    val = val.replace(/ /g, "");
  }

  return val;
};

const getSortedData = (data, prop, isAsc) => {
  return data.sort((a, b) => {
    return (a[prop] < b[prop] ? -1 : 1) * (isAsc ? 1 : -1);
  });
};

const GetResult = (settings, gridData) => {
  const data = pieChartSettingColumnConfig(settings);
  let category = "";
  if (data.category != undefined) {
    category = data.category;
  }

  let value = "";
  if (data.value != undefined) {
    value = data.value;
  }

  let operation = "";
  if (data.operation != undefined) {
    operation = data.operation;
  }

  let duplicateValues = {};
  let singleValues = [];

  // get calculated dataset on category only
  if (category != "" && value == "None") {
    $.each(gridData, function () {
      if (!duplicateValues[this[category]]) {
        duplicateValues[this[category]] = true;

        let i = 0;
        let k = 0;
        let catToCheck = this[category];
        $.each(gridData, function () {
          k++;
          if (catToCheck == this[category]) {
            i++;
          }
        });

        let item = {};
        item["name"] = this[category];
        item["count"] = i;
        singleValues.push(item);
      }
    });
  } else if (category != "" && value != "None" && operation != "") {
    // get calculated dataset on both category and value selected
    $.each(gridData, function () {
      // Sum
      if (!duplicateValues[this[category]]) {
        duplicateValues[this[category]] = true;

        let i = 0;
        let j = 0;
        let catToCheck = this[category];
        let valueArr = [];
        $.each(gridData, function () {
          if (catToCheck == this[category]) {
            let val = this[value];
            if (val != "") {
              i = parseInt(i) + parseInt(this[value]);
              j++;
              valueArr.push(parseInt(this[value]));
            }
          }
        });

        let item = {};
        if (operation == "Sum") {
          item["name"] = this[category];
          item["count"] = i;
        } else if (operation == "Average") {
          item["name"] = this[category];
          item["count"] = parseInt(i / j);
        } else if (operation == "Max") {
          item["name"] = this[category];
          item["count"] = parseInt(Math.max.apply(Math, valueArr));
        } else if (operation == "Min") {
          item["name"] = this[category];
          item["count"] = parseInt(Math.min.apply(Math, valueArr));
        }

        singleValues.push(item);
      }
    });
  } else {
    // no category and value is selected.
  }

  getSortedData(singleValues, "name", 1);

  return singleValues;
};

const getColorPallet = (singles, colorsResp) => {
  var colorsPallet = [];
  //var getjsonDt = json2.slice();
  if (colorsResp != null && colorsResp.length > 0) {
    colorsPallet = singles.map((item) => {
      var getColor = colorsResp.find((cls) => {
        return cls.Value === item.name;
      });
      if (getColor != null) {
        return getColor.Colour;
      }
    });
  }

  return colorsPallet;
};

export default GetResult;
export const GetColorPallet = getColorPallet;
