import { useEffect, useState, React } from "react";
import { useSelector } from "react-redux";
import { OtccWidgetRefreshTime } from "../../../../Constants";
import GetMetaData, {
  GetLastRefreshDateTime,
  GetWidgetData,
  GetWidgetSettings,
} from "../../../../Helper/Services";
import {
  GetFormattedGridData,
  IsSharedFilterAlert,
} from "../../../../Helper/WidgetDataCommon";
import OtccD3Component from "./OtccD3Component";
import GetFinalData from "./OtccWidgetService";

const OtccWidget = (props) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const isOtherUserPage = useSelector((state) => state.layout.IsOtherUserPage);
  const sharedUserEmail = useSelector((state) => state.layout.SharedUserEmail);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [widgetDt, setWidgetDt] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [screenMsg, setScreenMsg] = useState("No Data");
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    const getWidgetSetting = () => {
      let isSharedFilter = false;
      let sharedFilterUserId = "";
      let filterId = "";

      GetWidgetSettings(props.widgetId)
        .then((res) => {
          setShowNoData(false);
          if (
            res["IsSharedFilter"] !== undefined &&
            res["IsSharedFilter"] == "true"
          ) {
            isSharedFilter = true;
            sharedFilterUserId = res["OriginalUserId"];
            filterId = res["FilterId"];
          }

          // GetMetaData(res["reports"], "", sessionId)
          //   .then((json) => {
          GetWidgetData(
            res["Filter"],
            res["reports"],
            res["connection"],
            sessionId,
            isOtherUserPage,
            sharedUserEmail,
            isSharedFilter,
            sharedFilterUserId,
            filterId
          )
            .then((widgetDataResponse) => {
              GetLastRefreshDateTime(res["reports"], "", sessionId)
                .then((lstReponseDt) => {
                  props.onGetNotification(lstReponseDt);

                  if (IsSharedFilterAlert(widgetDataResponse)) {
                    setScreenMsg(widgetDataResponse);
                    setShowNoData(true);
                    return false;
                  }

                  const getFormattedDt = GetFormattedGridData(
                    widgetDataResponse
                  );

                  setWidgetDt(getFormattedDt);
                  const finalDt = GetFinalData(
                    getFormattedDt,
                    res["reports"],
                    res["period"]
                  );

                  setFinalData(finalDt);

                  if (getFormattedDt.length === 0) {
                    setShowNoData(true);
                  }
                })
                .catch((err) => {
                  console.error("GetLastRefreshDateTime:-", err);
                });
            })
            .catch((err) => {
              console.error("GetWidgetData:-", err);
            });
          // })
          // .catch((err) => {
          //   console.error(err);
          // });
        })
        .catch((err) => {});
    };

    if (sessionId !== "" && sessionId != null) {
      getWidgetSetting();
      GetWidgetSize();
    }

    window.addEventListener("resize", () => {
      GetWidgetSize();
    });

    const refreshWidget = setInterval(() => {
      console.log("Otcc set Timout..", new Date().toLocaleTimeString());
      getWidgetSetting();
    }, OtccWidgetRefreshTime);

    return () => {
      //clear setinterval on unmount component
      clearInterval(refreshWidget);
    };
  }, [sessionId, props.RefreshVersion]);

  const GetWidgetSize = () => {
    const getParentContainer = document.getElementById(
      "content_" + props.widgetId
    );
    if (getParentContainer !== undefined && getParentContainer != null) {
      const getHeight = getParentContainer.clientHeight;
      const getWidth = getParentContainer.clientWidth;
      setHeight(getHeight);
      setWidth(getWidth);
    }
  };

  return (
    <div>
      {widgetDt.length > 0 && showNoData === false && (
        <OtccD3Component
          data={finalData}
          height={height}
          width={width}
          wid={props.wid}
          getWidgetSize={GetWidgetSize}
        />
      )}
      {widgetDt.length === 0 && showNoData === true && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "rgb(153, 153, 153)",
            fontSize: "17px",
          }}
        >
          {screenMsg}
        </div>
      )}
    </div>
  );
};

export default OtccWidget;
