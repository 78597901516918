import React from "react";
import { useEffect } from "react";
import ConfiguratorServices from "./ConfiguratorService";
import { useSelector } from "react-redux";
import { useState } from "react";
import Loader from "../../../UI/Loader";

export const DatasourceList = ({ onSelectDatasource }) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const [dtList, setDtList] = useState([]);
  const [enableLoader, setEnableLoader] = useState(false);

  useEffect(() => {
    getDataSources();
  }, []);

  const getDataSources = () => {
    setEnableLoader(true);
    try {
      ConfiguratorServices.GetDatasources(sessionId)
        .then((res) => {
          console.log("res", res);
          setDtList(res);
          setEnableLoader(false);
        })
        .catch((err) => {
          console.log("GetDatasources:-", err);
          setEnableLoader(false);
        });
    } catch (error) {
      console.log("GetDatasources:-", error);
      setEnableLoader(false);
    }
  };

  const onDatasourceClick = (item) => {
    console.log("datasource details", item);
    onSelectDatasource({
      id: item.datasourceId,
      name: item.datasourceName,
      type: item.datasourceType,
    });
  };

  return (
    <>
      {!enableLoader && (
        <div className="configurator">
          <ul className="breadcrumb">
            <li>Data Sources</li>
          </ul>
          <div className="main-container">
            <ul className="list-group">
              {dtList.map((item) => {
                return (
                  <li
                    key={item.datasourceId}
                    className="list-group-item dark-background"
                    style={{ cursor: "pointer" }}
                    onClick={() => onDatasourceClick(item)}
                  >
                    {item.datasourceName}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
      {enableLoader && <Loader styles={{ height: "80px" }} />}
    </>
  );
};
