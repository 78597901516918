import {
  ConvertUTCDateToLocalDate_SalesWidget,
  GetSalesWidgetFormattedData,
} from "../../../../Helper/WidgetDataCommon";

import * as moment from "moment";
import defaultSAWData from "./JsonData";

const GetActivityData = (data) => {
  let tokenData = [];
  let jsonData = GetSalesWidgetFormattedData(data); //jsonData2.Table1;

  // timezone conversion
  for (let i = 0; i < jsonData.length; i++) {
    let getDate = jsonData[i].CurrentDayTime;
    let locDate = ConvertUTCDateToLocalDate_SalesWidget(getDate);
    let dt = new Date(locDate);
    let time_loc =
      dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    jsonData[i].Time = time_loc;
    jsonData[i].CurrentDayTime = locDate;
  }

  let getCurrentDate = new Date();
  let startDate = "";
  let endDate = "";
  let endtime = "";
  let filteredJson = [];
  let filteredJsonBySalesPerson = [];
  let formatDate =
    getCurrentDate.getMonth() +
    1 +
    "/" +
    getCurrentDate.getDate() +
    "/" +
    getCurrentDate.getFullYear();

  let activityData = jsonData;
  let getFinalListByCurrentDate = [];
  let token_data = [];
  let maxDate = "";
  if (activityData.length > 0) {
    maxDate = activityData[activityData.length - 1].CurrentDayTime;
  }

  filteredJson = activityData.filter(function (row) {
    let element = row.CurrentDayTime;
    if (element.indexOf(formatDate) != -1) {
      return true;
    } else {
      return false;
    }
  });

  // filter data by date if data is not found than decrement date and  find again.
  if (filteredJson.length == 0) {
    let getDate = getCurrentDate;
    getDate.setDate(getDate.getDate() - 1);
    let formatDate1 =
      getDate.getMonth() +
      1 +
      "/" +
      getDate.getDate() +
      "/" +
      getDate.getFullYear();
    filteredJson = activityData.filter(function (row) {
      let element = row.CurrentDayTime;
      if (element.indexOf(formatDate1) != -1) {
        return true;
      } else {
        return false;
      }
    });

    if (filteredJson.length == 0) {
      let getDate1 = getCurrentDate;
      getDate1.setDate(getDate1.getDate() - 1);
      let formatDate2 =
        getDate1.getMonth() +
        1 +
        "/" +
        getDate1.getDate() +
        "/" +
        getDate1.getFullYear();
      filteredJson = activityData.filter(function (row) {
        let element = row.CurrentDayTime;
        if (element.indexOf(formatDate2) != -1) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (filteredJson.length == 0) {
      let getDate2 = new Date(maxDate);
      let formatDate3 =
        getDate2.getMonth() +
        1 +
        "/" +
        getDate2.getDate() +
        "/" +
        getDate2.getFullYear();
      filteredJson = activityData.filter(function (row) {
        let element = row.CurrentDayTime;
        if (element.indexOf(formatDate3) != -1) {
          return true;
        } else {
          return false;
        }
      });
    }
  }

  let getFirstRow = filteredJson[0];
  let getFirstPersonName = getFirstRow["Salesperson"];

  if (filteredJson.length > 0) {
    filteredJsonBySalesPerson = filteredJson.filter(function (row) {
      let element = row["Salesperson"];
      if (element.indexOf(getFirstPersonName) != -1) {
        return true;
      } else {
        return false;
      }
    });
  }

  let getUniqueCurrentDateTime = [];
  for (let i = 0; i < filteredJson.length; i++) {
    if (
      getUniqueCurrentDateTime.indexOf(filteredJson[i].CurrentDayTime) === -1
    ) {
      getUniqueCurrentDateTime.push(filteredJson[i].CurrentDayTime);
    }
  }

  for (let j = 0; j < getUniqueCurrentDateTime.length; j++) {
    if (j == 0) {
      startDate = getUniqueCurrentDateTime[j];
    }
    if (j == getUniqueCurrentDateTime.length - 1) {
      endDate = getUniqueCurrentDateTime[j];
    }
    let dateFilter1 = getUniqueCurrentDateTime[j];

    let getTime = "";
    let getCurrentDateTime_rec = "";
    let filteredJsonData = filteredJson.filter(function (row) {
      let element = row.CurrentDayTime;
      if (element.indexOf(dateFilter1) != -1) {
        return true;
      } else {
        return false;
      }
    });

    let msitotal = 0;
    let attempttotal = 0;
    let time = "";
    for (let i = 0; i < filteredJsonData.length; i++) {
      if (i == 0) {
        getTime = filteredJsonData[i].Time;
        getCurrentDateTime_rec = filteredJsonData[i].CurrentDayTime;
      }

      if (i == filteredJsonData.length - 1) {
        endtime = moment(getTime, "h:m").format("h:mm a");
      }

      let salesPersonName = filteredJsonData[i]["Salesperson"].split(" ");
      time = moment(filteredJsonData[i].Time, "h:m").format("h:mm a");
      if (filteredJsonData[i]["Display_Token"] == "True") {
        token_data.push({
          time: time,
          label: salesPersonName[0],
        });
      }

      const commulative_msi = parseFloat(
        filteredJsonData[i]["CumulativeValue_MSI"]
      );
      const commulative_attempt = parseFloat(
        filteredJsonData[i]["CumulativeValue_Attempt"]
      );

      msitotal =
        parseFloat(msitotal) + (isNaN(commulative_msi) ? 0 : commulative_msi);
      attempttotal =
        parseFloat(attempttotal) +
        (isNaN(commulative_attempt) ? 0 : commulative_attempt);
    }

    let getCurrentDateTime = new Date();
    let getData_DateTime = new Date(getCurrentDateTime_rec);

    if (getCurrentDateTime.getDate() == getData_DateTime.getDate()) {
      if (getCurrentDateTime >= getData_DateTime) {
        time = moment(getTime, "h:m").format("h:mm a");
        getFinalListByCurrentDate.push({
          attempts: attempttotal,
          msi: msitotal,
          time: time,
        });
      }
    } else {
      time = moment(getTime, "h:m").format("h:mm a");
      getFinalListByCurrentDate.push({
        attempts: attempttotal,
        msi: msitotal,
        time: time,
      });
    }
  }

  jsonData = getFinalListByCurrentDate;
  console.log("json", jsonData);
  if (jsonData.length === 0) {
    console.log("jsonData.length", jsonData.length);
    const dt = defaultSAWData;
    jsonData = dt.time_intervals;
  }

  return {
    activity: jsonData,
    startDateTime: startDate,
    endDateTime: endDate,
    endTime: endtime,
    tokenData: token_data,
  };
};

const getStageData = (data) => {
  let throughput = 0;
  let stageData = GetSalesWidgetFormattedData(data);
  for (let t = 0; t < stageData.length; t++) {
    if (!isNaN(parseFloat(stageData[t]["Dollar Value(Throughput)"])))
      throughput += parseFloat(
        stageData[t]["Dollar Value(Throughput)"].replace(/,/g, "")
      );
  }
  return { throughput: throughput };
};

const getCapacityData = (data) => {
  let getSumCapacity = 0;
  let slotCount = 0;
  let bluelineValue = 0;
  let pointsTarget = 0;
  let activityTarget = 0;
  let capData = GetSalesWidgetFormattedData(data);
  getSumCapacity = capData[capData.length - 1]["y_axis"];
  bluelineValue = capData[capData.length - 1]["bluelineValue"];
  if (isNaN(bluelineValue) || slotCount == undefined) {
    bluelineValue = 0;
  }
  slotCount = bluelineValue;

  for (let i = 0; i < capData.length; i++) {
    if (!isNaN(parseFloat(capData[i]["Activity Target"]))) {
      activityTarget += parseFloat(capData[i]["Activity Target"]);
    }

    if (!isNaN(parseFloat(capData[i]["Expected Dollars Target"]))) {
      pointsTarget += parseFloat(
        capData[i]["Expected Dollars Target"].replace(/,/g, "")
      );
    }
  }

  if (isNaN(getSumCapacity)) {
    getSumCapacity = 0;
  }

  return {
    slotCount: slotCount,
    bluelineValue: bluelineValue,
    pointsTarget: pointsTarget,
    activityTarget: activityTarget,
    sumCapacity: getSumCapacity,
  };
};

const getPCTData = (data, startDate, jsonData) => {
  const _PCTData = GetSalesWidgetFormattedData(data);

  let getUniqueStartEndTime = [];
  let getPCTStartAndEndTime = [];
  for (let i = 0; i < _PCTData.length; i++) {
    if (getUniqueStartEndTime.indexOf(_PCTData[i].Start) === -1) {
      getUniqueStartEndTime.push(_PCTData[i].Start);

      const startDateTime = new Date(startDate);
      const _startDate =
        startDateTime.getMonth() +
        1 +
        "/" +
        startDateTime.getDate() +
        "/" +
        startDateTime.getFullYear();
      const gen_datetime_start = new Date(
        _startDate + " " + _PCTData[i]["Start"]
      );
      const dt = new Date(gen_datetime_start);
      let time_loc =
        dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
      const start_time = moment(time_loc, "h:m").format("h:mm a");

      const gen_datetime_finish = new Date(
        _startDate + " " + _PCTData[i]["Finish"]
      );
      const dt_finish = new Date(gen_datetime_finish);
      time_loc =
        dt_finish.getHours() +
        ":" +
        dt_finish.getMinutes() +
        ":" +
        dt_finish.getSeconds();
      const finish_time = moment(time_loc, "h:m").format("h:mm a");

      getPCTStartAndEndTime.push({ start: start_time, end: finish_time });
    }
  }

  //match and compare PCT time.
  for (let i = 0; i < getPCTStartAndEndTime.length; i++) {
    let startDateArr = [];
    let endDateArr = [];
    const getStartTime = getPCTStartAndEndTime[i]["start"];
    const getEndTime = getPCTStartAndEndTime[i]["end"];
    const findStartTime = jsonData.filter((s) => s["time"] == getStartTime);
    const findEndTime = jsonData.filter((s) => s["time"] == getEndTime);
    const startDateTime = new Date(startDate);
    const _startDate =
      startDateTime.getMonth() +
      1 +
      "/" +
      startDateTime.getDate() +
      "/" +
      startDateTime.getFullYear();
    const gen_datetime_start = new Date(_startDate + " " + getStartTime);
    const gen_datetime_end = new Date(_startDate + " " + getEndTime);
    const st1 = new Date(gen_datetime_start);
    const ed1 = new Date(gen_datetime_end);

    if (findStartTime.length == 0 && findEndTime.length == 0) {
      for (let j = 0; j < jsonData.length; j++) {
        const getTime = jsonData[j]["time"];
        const gen_dt = new Date(_startDate + " " + getTime);
        const dt1 = new Date(gen_dt);
        if (
          st1.getHours() == dt1.getHours() &&
          st1.getMinutes() > dt1.getMinutes()
        ) {
          startDateArr.push(getTime);
        }

        if (
          ed1.getHours() == dt1.getHours() &&
          ed1.getMinutes() > dt1.getMinutes()
        ) {
          endDateArr.push(getTime);
        }
      }

      if (startDateArr.length > 0) {
        getPCTStartAndEndTime[i]["start"] =
          startDateArr[startDateArr.length - 1];
      }

      if (endDateArr.length > 0) {
        getPCTStartAndEndTime[i]["end"] = endDateArr[endDateArr.length - 1];
      }
    }
  }

  return { zone_pct: getPCTStartAndEndTime };
};

const finalSAWJsonObject = (ativityDt, capDt, stgDt, pctDt) => {
  let json = {
    time_intervals: [],
    zones: [],
    tokens: [],
    actual: 0,
    time_mark: 0,
    cost: 0,
  };

  let throughput = stgDt.throughput;
  if (isNaN(throughput)) {
    throughput = 0;
  }

  const _throughput = Math.round(throughput);
  let actual = Math.round((_throughput / capDt.pointsTarget) * 100);
  if (isNaN(actual)) {
    actual = 0;
  }

  //Calculation of donut blueline
  const _startTime = new Date(ativityDt.startDateTime);
  const _endTime = new Date(ativityDt.endDateTime);
  const timeNow = new Date().getTime();
  const PeriodDuration = _endTime.getTime() - _startTime.getTime();
  const PeriodElapsed = timeNow - _startTime.getTime();
  let percentageOfBlueLine = parseFloat((PeriodElapsed / PeriodDuration) * 100); /// 100;

  if (timeNow > _endTime) {
    percentageOfBlueLine = 100;
  }

  json.zones = [];
  if (ativityDt.activity.length > 0) {
    json.zones = pctDt.zone_pct;
    json.time_intervals = ativityDt.activity;
  }
  json.capacity = capDt.sumCapacity;
  json.tokens = ativityDt.tokenData;
  json.startdatetime = ativityDt.startDateTime;
  json.enddatetime = ativityDt.endDateTime;
  json.cost = Math.round(throughput);
  json.actual = actual;
  json.endtime = ativityDt.endTime;
  json.time_mark = percentageOfBlueLine;
  json.activityTarget = Math.round(capDt.activityTarget);
  json.slotCount = Math.round(capDt.slotCount);
  return json;
};

export default GetActivityData;
export const GetStageData = getStageData;
export const GetCapacityData = getCapacityData;
export const GetPCTData = getPCTData;
export const FinalSAWJsonObject = finalSAWJsonObject;
