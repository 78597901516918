import { Fragment } from "react";

const OOSContent = (props) => {
  return (
    <Fragment>
      <p>X-Axis Value</p>
      <input
        type="number"
        min="1"
        value={props.xaxisVal.value}
        onChange={props.onChangeXAxisHandler}
      />
      {!props.xaxisVal.isValid && (
        <span style={{ color: "red", paddingLeft: "5px" }}>Required</span>
      )}

      <p>Bubble Text</p>
      <select
        onChange={props.onBubbleTextChange}
        value={props.bubbleText.value}
      >
        <option value="">Please Select</option>
        {props.bubbleTextList.map((item, i) => {
          return (
            <option key={i} value={item.value}>
              {item.text}
            </option>
          );
        })}
      </select>
      {!props.bubbleText.isValid && (
        <span style={{ color: "red", paddingLeft: "5px" }}>Required</span>
      )}
    </Fragment>
  );
};

export default OOSContent;
