import React from "react";
export const PageDropdownList = ({ onChageHandler, errMsg, value, items }) => {
  return (
    <>
      <select
        style={{ width: "95%", lineHeight: "1", borderRadius: "5px" }}
        onChange={onChageHandler}
        value={value}
      >
        <option value="">Select</option>
        {items &&
          items.map((obj) => {
            if (obj.Id)
              return (
                <option key={obj.Id} value={obj.Id}>
                  {obj.PageName}
                </option>
              );
            else return "";
          })}
      </select>
      {errMsg && <span style={{ color: "red" }}>{errMsg}</span>}
    </>
  );
};
