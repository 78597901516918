import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import DataSectionSettings from "../../../../UI/TemplateControls/DataSection";
import WidgetTitleControl from "../../../../UI/TemplateControls/WidgetTitleControl";
import PieChartContent from "./ConfigContent/PieChartContent";
import GetMetaData from "../../../../../Helper/Services";
import { GridMetaData_Format } from "../../../../../Helper/Services";
import { useSelector } from "react-redux";
import {
  GetValueList,
  GetCategoryList,
} from "../../../../../Helper/WidgetDataCommon";
import {
  DataSectionObject,
  PieContentSection,
} from "../../../../../Helper/CommonModel";

const PieChartTemplate = forwardRef((props, ref) => {
  const [showSettingsPanel, setShowSettingsPanel] = useState(true);
  const [widgetContentPanel, setWidgetContentPanel] = useState(false);
  const [titleContentPanel, setTitleContentPanel] = useState(false);
  const [dtSourceItems, setDtSourceItems] = useState([]);
  const [title, setTitle] = useState("Pie Chart");
  const [shortTitle, setShortTitle] = useState("PIE");
  const [titleIsValid, setTitleIsValid] = useState(true);
  const [shortTitleIsValid, setShortTitleIsValid] = useState(true);
  const [category, setCategory] = useState("");
  const [values, setvalues] = useState("None");
  const [operation, setOperation] = useState("N/A");
  const [callout, setCallout] = useState("1");
  const [categoies, setCategoies] = useState([]);
  const [valuesList, setValuesList] = useState([]);
  const sessionId = useSelector((state) => state.login.sessionId);
  //const [dataSection, setDataSection] = useState(DataSectionObject);
  const pieContentSection = useRef(PieContentSection);
  const dataSectionRef = useRef();
  const pieContentRef = useRef();
  const dsObjRef = useRef(DataSectionObject);

  useEffect(() => {
    if (
      props.dataSources != undefined &&
      Object.keys(props.dataSources.Reports).length !== 0
    ) {
      const _items = Object.entries(props.dataSources.Reports)
        .filter((item) => {
          if (item[1] === "Generic Table") {
            return true;
          }
        })
        .map((item) => item[0]);
      setDtSourceItems(_items);
    }

    if (
      props.dataSources != undefined &&
      props.isEdit === true &&
      props.settings !== undefined &&
      props.settings["reports"] != undefined
    ) {
      getCategories(props.settings["reports"]);
      setTitle(props.settings["Title"]);
      setShortTitle(props.settings["ShortTitle"]);
      setCategory(props.settings["category"]);
      setvalues(props.settings["value"]);
      setOperation(props.settings["operation"]);
      setCallout(props.settings["callout"]);
    }
  }, [props.dataSources, props.settings]);

  useImperativeHandle(ref, () => ({
    GetValidateData() {
      const getVaildForm = validateForm();
      if (getVaildForm) {
        return {
          formIsValid: getVaildForm,
          data: {
            reports: dsObjRef.current.dataSourceName,
            connection: dsObjRef.current.connectionName,
            Filter: dsObjRef.current.filterName,
            IsSharedFilter: dsObjRef.current.filterDetails.issharedfilter,
            OriginalUserId: dsObjRef.current.filterDetails.originaluserid,
            FilterId: dsObjRef.current.filterDetails.filterid,
            Title: title,
            ShortTitle: shortTitle,
            category: pieContentSection.current.data.category,
            value: pieContentSection.current.data.values,
            callout: pieContentSection.current.data.callout,
            operation: pieContentSection.current.data.operation,
          },
        };
      } else {
        return {
          formIsValid: getVaildForm,
        };
      }
    },
  }));

  const onSettingsClicked = (val) => {
    if (val) {
      setShowSettingsPanel(val);
    } else {
      setShowSettingsPanel(!showSettingsPanel);
    }
    setWidgetContentPanel(false);
    setTitleContentPanel(false);
  };

  const onWidgetContentClicked = (val) => {
    if (val) {
      setWidgetContentPanel(val);
    } else {
      setWidgetContentPanel(!widgetContentPanel);
    }
    setShowSettingsPanel(false);
    setTitleContentPanel(false);
  };

  const onTitleContentPanelClicked = (val) => {
    if (val) {
      setTitleContentPanel(val);
    } else {
      setTitleContentPanel(!titleContentPanel);
    }
    setWidgetContentPanel(false);
    setShowSettingsPanel(false);
  };

  const OnTitleChangeHandler = (event) => {
    setTitle(event.target.value);
  };

  const OnShortTitleChangeHandler = (event) => {
    setShortTitle(event.target.value);
  };

  const onDatasourceChangeHandler = (dataSourceVal) => {
    if (dataSourceVal !== "") {
      getCategories(dataSourceVal);
    } else {
      setCategoies([]);
      setValuesList([]);
    }
  };

  const getCategories = (dataSourceVal) => {
    GetMetaData(dataSourceVal, "", sessionId)
      .then((json) => {
        const metaData = GridMetaData_Format(json);
        const getValues = GetValueList(metaData);
        const getCategories = GetCategoryList(metaData);
        setCategoies(getCategories);
        setValuesList(getValues);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const validateTitle = () => {
    if (title === "") {
      onTitleContentPanelClicked(true);
      setTitleIsValid(false);
      setTimeout(() => {
        setTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateShortTitle = () => {
    if (shortTitle === "") {
      onTitleContentPanelClicked(true);
      setShortTitleIsValid(false);
      setTimeout(() => {
        setShortTitleIsValid(true);
      }, 3000);
      return false;
    }
    return true;
  };

  const validateForm = () => {
    const dataSectionVal = dataSectionRef.current.validateDataSection();
    dsObjRef.current = dataSectionVal.data;
    //setDataSection(dataSectionVal.data);
    if (!dataSectionVal.isDataSectionValid) {
      onSettingsClicked(true);
      return false;
    }

    const isTitleValid = validateTitle();
    if (!isTitleValid) {
      return false;
    }

    const isShortTitleValid = validateShortTitle();
    if (!isShortTitleValid) {
      return false;
    }

    pieContentRef.current.validatePieContent();
    if (!pieContentSection.current.isValid) {
      onWidgetContentClicked(true);
      return false;
    }
    return true;
  };

  const getPieContent = (pieContentVal) => {
    pieContentSection.current = pieContentVal;
  };

  return (
    <Fragment>
      {!props.isEdit && <hr className="style-one" />}
      <div onClick={onSettingsClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {showSettingsPanel && "-"}
          {!showSettingsPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>DATA</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: showSettingsPanel ? "block" : "none",
        }}
      >
        <DataSectionSettings
          dataSourceList={dtSourceItems}
          dtSourceChange={onDatasourceChangeHandler}
          ref={dataSectionRef}
          settings={props.settings}
          isEdit={props.isEdit}
        />
      </div>
      <div onClick={onTitleContentPanelClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {titleContentPanel && "-"}
          {!titleContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET TITLE</p>
      </div>
      <div
        style={{
          paddingLeft: "10px",
          display: titleContentPanel ? "block" : "none",
        }}
      >
        <WidgetTitleControl
          titleVal={title}
          shortTitleVal={shortTitle}
          OnTitleChangeHandler={OnTitleChangeHandler}
          OnShortTitleChangeHandler={OnShortTitleChangeHandler}
          isTitleValid={titleIsValid}
          isShortTitleValid={shortTitleIsValid}
        />
      </div>
      <div onClick={onWidgetContentClicked}>
        <div style={{ float: "left", fontSize: "20px", marginTop: "-4px" }}>
          {widgetContentPanel && "-"}
          {!widgetContentPanel && "+"}
        </div>
        <p style={{ paddingLeft: "12px" }}>WIDGET CONTENT</p>
      </div>
      <div
        style={{
          paddingLeft: "12px",
          display: widgetContentPanel ? "block" : "none",
        }}
      >
        <React.StrictMode>
          <PieChartContent
            categoryVal={category}
            categoryList={categoies}
            values={values}
            operationVal={operation}
            calloutVal={callout}
            valueList={valuesList}
            ref={pieContentRef}
            onContentChange={getPieContent}
          />
        </React.StrictMode>
      </div>
    </Fragment>
  );
});

export default PieChartTemplate;
